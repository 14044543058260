import Vector from '../../../assets/images/icons/vector';
import { Typography, Grid, Box } from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import { FormattedMessage, useIntl } from 'react-intl';

const AlertAtribute = ({ alert }) => {
  const intl = useIntl();

  return intl.formatMessage({ id: alert }) != alert ? (
    <Box
      sx={{
        background: '#FDECEA',
        borderRadius: '4px',
        width: { xs: '95%', md: '40%' },
        display: 'flex',
        padding: '15px',
        alignItems: 'center',
        marginTop: '30px',
        justifyContent: 'flex-start',
        margin: { xs: '0 auto', md: '0px 0px 0px 15px' },
      }}>
      <Box sx={{ marginTop: '5%' }}>
        <Vector />
      </Box>
      <Typography sx={{ color: 'rgba(0, 0, 0, 60%);', fontSize: '14px', lineHeight: '143%' }}>
        {intl.formatMessage({ id: alert })}
      </Typography>
    </Box>
  ) : null;
};

export default AlertAtribute;
