import SvgIcon from '@material-ui/core/SvgIcon';

const Enter = () => (
  <SvgIcon viewBox="0 0 36 36">
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4211 1.05119V4.20909H3L5.8421 1.36698L4.73684 0.261719L0 4.99856L4.73684 9.7354L5.8421 8.63014L3 5.78803H15V1.05119H13.4211Z" fill="#303778" />
    </svg>
  </SvgIcon>
);

export default Enter;
