import SvgIcon from '@material-ui/core/SvgIcon';

const Vector = () => (
  <SvgIcon viewBox="0 0 36 36">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.08301 12.7502H10.9163V14.5835H9.08301V12.7502ZM9.08301 5.41683H10.9163V10.9168H9.08301V5.41683ZM9.99051 0.833496C4.93051 0.833496 0.833008 4.94016 0.833008 10.0002C0.833008 15.0602 4.93051 19.1668 9.99051 19.1668C15.0597 19.1668 19.1663 15.0602 19.1663 10.0002C19.1663 4.94016 15.0597 0.833496 9.99051 0.833496ZM9.99967 17.3335C5.94801 17.3335 2.66634 14.0518 2.66634 10.0002C2.66634 5.9485 5.94801 2.66683 9.99967 2.66683C14.0513 2.66683 17.333 5.9485 17.333 10.0002C17.333 14.0518 14.0513 17.3335 9.99967 17.3335Z" fill="#F44336" />
    </svg>
  </SvgIcon>
);
export default Vector;
