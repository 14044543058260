import { Box, Grid, Typography, Snackbar, Alert, CircularProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../../../../components/select';
import confirmEmailImg from '../../../../assets/images/confirmEmailImg.png';
// import Input from '../../../../../components/input';
import Button from '../../../../components/button';
import Link from '../../../../components/link';
import React from 'react';
import ButtonBack from '../../../../components/buttonBack';
// import { Formik } from 'formik';
// import { api } from '../../../../api/index'
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { makeStyles } from '@material-ui/styles';
import ChangeEmailPartial from '../signUp/partials/changeEmailPartial';
import HelpersPaymentXs from '../../../../assets/images/icons/helpersPaymentXs';
import HelpersPayment from '../../../../assets/images/icons/helpersPayment';
import { ASK_RESET_PASSWORD } from '../../../../api/mutations/login';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import LanguageSwitcher from 'components/languageSwitcher';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  link: {
    '&:hover': {
      color: 'rgb(245, 115, 98)',
    },
  },
});
function ConfirmEmail(props: any) {
  const location: any = useLocation();
  const email = location.state?.email;

  const [changeEmail, setChangeEmail] = React.useState(true);
  const [isSignInLoading, setIsSignInLoading] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleEmailState = () => {
    setChangeEmail(false);
  };
  const [askResetPassword, { loading: isAskResetPasswordLoading, error: askResetPasswordError }] =
    useMutation(ASK_RESET_PASSWORD, {
      onCompleted: (data) => {
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Email was sent successfully. Please check your mail',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
        setIsSignInLoading(false);
      },
      onError: (error) => {
        setIsSignInLoading(false);
        const message = JSON.parse(error.message);
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: ' + message?.content,
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
        if (message?.content) {
          console.log(message?.content);
        }
      },
      context: {
        removeHeader: true,
      },
    });

  return (
    <Grid container sx={{ background: '#fafafa', height: '100vh' }}>
      <Grid item md={12} sx={{ display: { xs: 'none', md: 'flex' }, padding: '30px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/login" colorLink="grey" underline="none">
            <ButtonBack />
          </Link>
        </Box>
        <Box>
          <LanguageSwitcher />

          {/* <Select startAdornmentProp={true} defaultValue="en">
            <MenuItem value={'en'}>En</MenuItem>
            <MenuItem value={'fr'}>Fr</MenuItem>
          </Select> */}
        </Box>
      </Grid>
      <Grid container justifyContent="center">
        {changeEmail ? (
          <Grid
            xs={12}
            md={3}
            sx={{
              textAlign: 'center',
              marginTop: { xs: '100px', md: '-100px' },
              padding: { xs: '15px', md: '0px' },
            }}>
            <Box sx={{ textAlign: 'center' }}>
              <img src={confirmEmailImg} alt="" />

              <Typography
                sx={{
                  fontSize: '30px',
                  fontWeight: 'bold',
                  lineHeight: '35px',
                  color: '#252A32',
                  mb: 2,
                }}>
                <FormattedMessage id="sign_emailconf_confemail" />
              </Typography>
              <Typography sx={{ fontSize: '14px', lineHeight: '22px', color: '#717A90' }}>
                <FormattedMessage id="signin_recieved_link" /> {email}{' '}
                <FormattedMessage id="signin_to_confirm_email_address" />
              </Typography>
              <Box
                mt={1}
                mb={2}
                sx={{
                  display: 'flex',
                  background: { xs: '#FDECEA', md: '#EFF2F8' },
                  borderRadius: { xs: '4px', md: '10px' },
                  padding: '15px',
                }}>
                <Box mt={1} sx={{ width: '40px', mr: '10px' }}>
                  <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <HelpersPayment />
                  </Box>
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <HelpersPaymentXs />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', md: '12px' },
                      lineHeight: '18px',
                      color: { xs: '#000', md: '#717A90' },
                      textAlign: 'left',
                      opacity: { xs: '0.4', md: '1' },
                      fontFamily: 'Gilroy-Medium',
                    }}>
                    <FormattedMessage id="sign_emailconf_checkspam" />
                  </Typography>
                </Box>
              </Box>
              {isSignInLoading ? (
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50px', margin: '20px auto 0px' }}>
                    <CircularProgress />
                  </Box>
                </Box>
              ) : (
                <Button
                  sx={{ borderRadius: { xs: '4px', md: '10px' } }}
                  titleBtn={<FormattedMessage id="sign_emailconf_resendlink" />}
                  colorBtn="white"
                  onClick={() => {
                    askResetPassword({
                      variables: {
                        email: localStorage.getItem('email'),
                        type: 'EMAIL_VERIFICATION',
                      },
                    });
                    setIsSignInLoading(true);
                  }}
                />
              )}
              <Typography
                mt={2}
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  color: '#252A32',
                  textAlign: 'center',
                }}>
                <FormattedMessage id="sign_emailconf_confirmissues" />
              </Typography>
              <Typography
                className={classes.link}
                onClick={handleEmailState}
                sx={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  lineHeight: '22px',
                  color: '#F0998D',
                  textAlign: 'center',
                }}>
                <FormattedMessage id="sign_emailconf_changeemail" />
              </Typography>
            </Box>
          </Grid>
        ) : (
          <ChangeEmailPartial />
        )}
      </Grid>
    </Grid>
  );
}

export default ConfirmEmail;
