import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import DateTimePicker from '@material-ui/lab/DateTimePicker';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { useMutation } from '@apollo/client';
import { EDIT_SPECIFIC_CONCEPT } from '../../../api/mutations/calendar';
import { GET_MONTHLY_CALENDARS } from '../../../api/queries/calendar/monthly';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContainerCloseButton: {
    color: 'black',
    fontSize: '14px',
  },
  dialogRatingContent: {
    textAlign: 'center',
  },
  dialogRatingTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '28px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '8px 0',
  },
  dialogRatingDescription: {
    fontFamily: 'Gilroy-Regular',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '8px 0',
  },
  dialogRatingStars: {
    padding: '20px 0',
  },
  dialogRatingCommentLabel: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '4px 16px',
  },
  weekOfTitle: {
    fontSize: '16px',
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: '160%',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#000000',
  },
  dialogRatingTextarea: { margin: '4px 16px' },
  dialogRatingTextareaComponent: {
    width: '100%',
    padding: '8px 16px',
    background: '#FFFFFF',
    border: '1px solid #EEEEEE',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  dialogRatingButton: {
    background: '#EE9A8F',
    borderRadius: '4px',
    padding: '8px 16px',
    color: '#fff',
  },
  sendButton: {
    paddingTop: '32px',
    paddingBottom: '16px',
    margin: '0 16px',
  },
}));

interface DialogEditingProps {
  open: boolean; //to open and close dialog
  onConfirm: () => void; //called when clicked Send
  onClose: () => void; //called when dialog closed}
  // handleFeedback:()=> void;
  weeklyPost: any;
  publication: any;
}

const DialogEditing: React.FC<DialogEditingProps> = ({
  open,
  onConfirm,
  onClose,
  weeklyPost,
  publication,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<any>({
    date: '',
    teasing: '',
    type: '',
    concept: '',
  });
  const dispatch = useDispatch();
  const handleChange = (prop, newValue) => {
    setValue({ ...value, [prop]: newValue });
  };
  React.useEffect(() => {
    setValue({
      date: publication.publicationTime ? publication.publicationTime : '',
      teasing: publication.category ? publication.category : '',
      type: publication.type ? publication.type : '',
      concept: publication.concept ? publication.concept : '',
    });
  }, []);
  const [editConcept, { loading: loadingEditConcept, error }] = useMutation(EDIT_SPECIFIC_CONCEPT, {
    onCompleted: (data) => {
      console.log(data);
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Publication was edited successfully',
        variant: 'alert',
        alertSeverity: 'success',
        autoHideDuration: '6000',
      });
      onClose();
    },
    onError: (error) => {
      const message = JSON.parse(error.message);
    },
  });
  console.log('publication', publication);
  const handleEditingConcept = (id) => {
    console.log('ididididi', id);
    editConcept({
      variables: {
        id: id,
        conceptionStatus: 'In client review',
        concept: value.concept,
        publicationTime: moment(value.date),
        type: value.type,
        category: value.teasing,
      },
      refetchQueries: [{ query: GET_MONTHLY_CALENDARS }],
    });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{
        style: {
          borderRadius: '24px',
          width: '430px',
        },
      }}>
      <Box>
        <Box
          sx={{ margin: '0 16px' }}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Typography className={classes.weekOfTitle}>
            <FormattedMessage id="calendar_monthly_calendar_week_of" />
            {moment(weeklyPost.dueDate).format('DD/MM/YYYY')}
          </Typography>
          <IconButton className={classes.dialogContainerCloseButton} onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid p={2} container justifyContent="space-between" alignItems="center">
          <Grid item xs={5}>
            <Typography>
              <FormattedMessage id="calendar_monthly_date" />
            </Typography>
          </Grid>
          <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                // label={publication.publicationTime}
                inputFormat="dd-MM-yyyy"
                value={value.date}
                onChange={(newValue) => {
                  handleChange('date', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '100%', '.MuiFormHelperText-root': { display: 'none' } }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid p={2} container justifyContent="space-between" alignItems="center">
          <Grid item xs={5}>
            <Typography>
              <FormattedMessage id="calendar_monthly_category" />
            </Typography>
          </Grid>
          <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TextField
              value={value.teasing}
              onChange={(e) => handleChange('teasing', e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid p={2} container justifyContent="space-between" alignItems="center">
          <Grid item xs={5}>
            <Typography>
              <FormattedMessage id="calendar_monthly_type" />
            </Typography>
          </Grid>
          <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Select
              sx={{ padding: '6px', width: '100%' }}
              size="small"
              labelId="demo-simple-select-disabled-label"
              id="demo-simple-select-disabled"
              name="category"
              onChange={(e) => handleChange('type', e.target.value)}
              value={value.type}>
              <MenuItem value="Story">Story</MenuItem>
              <MenuItem value={'Post'}>Post</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Box p={2}>
          <Typography>
            <FormattedMessage id="calendar_monthly_type" />
          </Typography>
        </Box>
        <Box p={2}>
          <TextField
            value={value.concept}
            onChange={(e) => handleChange('concept', e.target.value)}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
          />
        </Box>
        <Box p={2}>
          {loadingEditConcept ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              sx={{
                background: '#EE9A8F',
                textTransform: 'capitalize',
                '&:hover': { background: '#EE9A8F' },
                width: '100%',
                color: '#fff',
              }}
              onClick={() => handleEditingConcept(publication.id)}>
              <FormattedMessage id="calendar_monthly_save" />
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogEditing;
