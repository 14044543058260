import React from 'react';
import { Controller } from 'react-hook-form';
import { Typography, Grid, Box, Slider } from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';

interface RenderFieldProps {
  control: any;
  activeStep: number;
  handlStep: () => void;
  watch?: any;
  setValue?: any;
  setActiveStep: (activeStep: number) => void;
  field: {
    label: string;
    name: string;
    type: string;
    title: string;
    placeholder: string;
    subtitle?: any;
    alert?: any;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}

const SingleValueSelector = ({
  field,
  control,
  activeStep,
  handlStep,
  watch,
  setValue,
}: RenderFieldProps) => {
  const valuetext = (value: number) => {
    return `${value}${field.constraints.units}`;
  };
  console.log('watchwatch', watch());
  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }}>
      <Controller
        name={field.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box
            sx={{
              border: '1px solid #E0E0E0',
              borderRadius: '10px',
              width: '100%',
              padding: '5px 10px',
              marginTop: '50px',
            }}>
            <Slider
              aria-label="Temperature"
              sx={{ marginTop: '5px' }}
              defaultValue={(field.constraints?.limits?.min + field.constraints?.limits?.max) / 2}
              value={value && value}
              onChange={(e: any, newValue: any) => {
                onChange(newValue);
              }}
              //TODO make this value dynamic
              min={field?.constraints?.limits?.min}
              max={field?.constraints?.limits?.max}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
            />
          </Box>
        )}
      />
    </Grid>
  );
};

export default SingleValueSelector;
