import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from '../store/actions';
import accountReducer from '../store/reducers/accountReducer';

// project imports
// import axios from 'utils/axios';
import Loader from '../components/Loader';
import { initialLoginContextProps } from '../types';
import { GET_USER_INFORMATION } from '../api/queries/login';
import { REFRESH_TOKEN } from '../api/mutations/login';

import { useLazyQuery, useMutation } from '@apollo/client';

// constant

const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const setSession = (token?: string | null) => {
  if (token) {
    localStorage.setItem('token', token);
    // axios.defaults.to = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    // delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext({
  ...initialState,
  login: (e: string) => Promise.resolve(),
  logout: () => {},
});

export const JWTProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const [
    generateToken,
    { data: refreshTokenResponse, loading: isrefreshTokenLoading, error: refreshTokenError },
  ] = useMutation(REFRESH_TOKEN, {
    onCompleted: (data) => {
      localStorage.setItem('token', data.user_refresh_access.access_token);
      localStorage.setItem('refresh_token', data.user_refresh_access.refresh_token);
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          ...state,
          isLoggedIn: true,
          // userId,
        },
      });
    },
    onError: (error) => {
      console.log('🔥🔥 ~ JWTProvider ~ error generateToken onError ', error);
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          ...state,
          isLoggedIn: false,
          userId: null,
        },
      });
    },
    context: {
      removeHeader: true,
    },
    // ... other options
  });

  const login = async (token: string) => {
    setSession(token);
    dispatch({
      type: LOGIN,
      payload: {
        ...state,
      },
    });
  };
  const logout = () => {
    localStorage.removeItem('role');
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const [verifyUser, { data, loading, error }] = useLazyQuery(GET_USER_INFORMATION, {
    onCompleted: (data) => {
      data?.user?.length && localStorage.setItem('customer_id', data.user[0].customer_id);
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          ...state,
          isLoggedIn: true,
          // userId,
        },
      });
    },
    onError: (error) => {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken) {
        generateToken({
          variables: {
            refresh_token: refreshToken,
          },
        });
      } else {
        dispatch({
          type: ACCOUNT_INITIALIZE,
          payload: {
            ...state,
            isLoggedIn: false,
            userId: null,
          },
        });
      }
    },
  });

  useEffect(() => {
    const init = async () => {
      try {
        const token = localStorage.getItem('token');

        if (token) {
          //fetch plans and additional plans

          setSession(token);
          // const response = await axios.get('/api/account/me');
          // const { userId } = response.data;
          dispatch({
            type: ACCOUNT_INITIALIZE,
            payload: {
              ...state,
              isLoggedIn: true,
              // userId,
            },
          });
        } else {
          console.log('🔥🔥 ~ init ~ err');

          dispatch({
            type: ACCOUNT_INITIALIZE,
            payload: {
              ...state,
              isLoggedIn: false,
              userId: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        console.log('🔥🔥 ~ init ~real err', err);
        dispatch({
          type: ACCOUNT_INITIALIZE,
          payload: {
            ...state,
            isLoggedIn: false,
            userId: null,
          },
        });
      }
    };

    verifyUser();
  }, []);

  if (!state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
