import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LanguageSwitcher from 'components/languageSwitcher';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '28px',
    lineHeight: '134%',
    letterSpacing: '0px',
    color: '#252A32',
  },
  description: {
    fontFamily: 'Gilroy',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0em',
    color: '#717A90',
  },
  usernameBox: {
    width: '178px',
    left: '822px',
    top: '4.5px',
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  userNameText: {
    fontFamily: 'Gilroy',
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.15000000596046448px',
  },
  avatarBox: {
    fontFamily: 'Gilroy',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
}));
interface Props {
  userName: string;
  title: string;
  description: string;
}

const ProfileHeader = ({ userName, title, description }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item container spacing={1} xs={10}>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              <FormattedMessage id={title} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              <FormattedMessage id={description} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <LanguageSwitcher />
          </Box>
        </Grid>
        {/* <Grid item sx={{display: {xs: 'none', md: 'block'}}}>
          <Box
            className={classes.usernameBox}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="10px">
            <Avatar className={classes.avatarBox}>BM</Avatar>
            <Typography className={classes.userNameText}>{userName}</Typography>
          </Box>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default ProfileHeader;
