import SvgIcon from '@material-ui/core/SvgIcon';

const HelpersPayment = () => (
  <SvgIcon sx={{marginTop: '20px'}}>
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5835 12.75H10.4168V14.5834H8.5835V12.75ZM8.5835 5.41671H10.4168V10.9167H8.5835V5.41671ZM9.491 0.833374C4.431 0.833374 0.333496 4.94004 0.333496 10C0.333496 15.06 4.431 19.1667 9.491 19.1667C14.5602 19.1667 18.6668 15.06 18.6668 10C18.6668 4.94004 14.5602 0.833374 9.491 0.833374ZM9.50016 17.3334C5.4485 17.3334 2.16683 14.0517 2.16683 10C2.16683 5.94837 5.4485 2.66671 9.50016 2.66671C13.5518 2.66671 16.8335 5.94837 16.8335 10C16.8335 14.0517 13.5518 17.3334 9.50016 17.3334Z"
        fill="#F44336"
      />
    </svg>
  </SvgIcon>
);

export default HelpersPayment;
