import { Box, Stack, Typography } from '@material-ui/core';
import bulb from '../../../assets/images/bulb.png';
import Illustrations from '../../../assets/images/Illustrations.png';
import { FormattedMessage, useIntl } from 'react-intl';

interface HelpAndTipsProps {
  title: string;
  description: string;
  handleActiveHelp?: () => void;
}
const HelpBlock = ({ title, description, handleActiveHelp }: HelpAndTipsProps) => {
  return (
    <Box sx={{ padding: '80px 30px' }}>
      <Stack direction="row" spacing={2} onClick={handleActiveHelp}>
        <img src={bulb} />
        <Typography
          sx={{
            marginTop: '5px !important',
            fontSize: '20px',
            fontFamily: 'Gilroy-SemiBold',
            color: '#252A32',
          }}>
          <FormattedMessage id="action-text_help-and-tips" />
        </Typography>
      </Stack>
      <Box sx={{ width: '80%', margin: '40px auto 20px' }}>
        <img src={Illustrations} />
      </Box>
      <Typography
        sx={{
          marginTop: '5px !important',
          fontSize: '16px',
          fontFamily: 'Gilroy-SemiBold',
          color: '#252A32',
          lineHeight: '160%',
          letterSpacing: '0.15px',
        }}>
        <FormattedMessage id={title} />
      </Typography>
      <Typography
        sx={{
          marginTop: '5px !important',
          fontSize: '14px',
          fontFamily: 'Gilroy-SemiBold',
          color: '#717A90',
          lineHeight: '143%',
          letterSpacing: '0.15px',
        }}>
        <FormattedMessage id={description} />
      </Typography>
    </Box>
  );
};

export default HelpBlock;
