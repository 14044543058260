/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    label: 'Instagram password',
    name: 'instagram_password',
    type: 'TEXT_FIELD',
    title: 'onboarding_accesssm_instagram-password_question_title',
    subtitle: 'onboarding_accesssm_instagram-password_question_subtitle',
    placeholder: 'onboarding_accesssm_instagram-password_question_placeholder',
    alert: 'onboarding_accesssm_instagram-password_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_accesssm_instagram-password_help-and-tips_title',
      content: 'onboarding_accesssm_instagram-password_help-and-tips_content',
      media: {},
    },
  },
];
