import { Box, Grid, Typography } from '@material-ui/core';
import confirmEmailImg from '../../../../../assets/images/confirmEmailImg.png';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
// import React from 'react'
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
// import { api } from '../../../../api/index'

function ChangeEmailPartial(props: any) {
  return (
    <Box
      sx={{
        width: { xs: '90%', sm: '40%', md: '30%', lg: '25%', xl: '18%' },
        margin: '0 auto',
        textAlign: 'center',
        paddingTop: { xs: '20%', md: '5%' },
      }}>
      <img src={confirmEmailImg} alt="" />
      <Typography
        sx={{
          fontSize: '30px',
          fontWeight: 'bold',
          lineHeight: '35px',
          color: '#252A32',
          mb: 2,
        }}>
        <FormattedMessage id="sign_emailconf_confemail" />
      </Typography>
      <Typography mb={1} sx={{ fontSize: '14px', lineHeight: '22px', color: '#717A90' }}>
        Enter your new email below
      </Typography>
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'This field is required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values) => {
          // api.tokenForgotPassword({
          //     email: values.email
          // }).then(res => {
          //     console.log(res.token);
          //     if (res.token) {
          //     }
          // }).catch(error => {
          //     console.log(error);
          // });
        }}>
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input
              sx={{ marginTop: '30px' }}
              placeholder="Email"
              type="text"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            <Typography sx={{ color: 'red', mt: 1 }}>
              {errors.email && touched.email && errors.email}
            </Typography>
            <Button
              sx={{ marginTop: '10px', fontFamily: { xs: 'Gilroy', md: 'Gilroy-SemiBold' } }}
              titleBtn="Change"
              colorBtn="red"
              type="submit"
            />
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default ChangeEmailPartial;
