// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

// -----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Snikpic" width="100" />
     *
     */
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="23" fill="#F0998D" />
      <path
        d="M19.067 9V13.6214C19.067 13.6214 15.5149 13.8952 15.5719 17.1553C15.528 18.009 15.8085 18.8476 16.3571 19.503C16.9057 20.1585 17.6819 20.5823 18.5299 20.6893H27.1331C27.1331 20.6893 34.773 20.8018 34.6625 29.3889C34.5692 36.4876 26.5929 37 26.5929 37V32.3786C26.5929 32.3786 29.9761 32.1028 30.0881 29.117C30.1401 28.6308 30.0879 28.1392 29.9349 27.6748C29.782 27.2104 29.5318 26.7839 29.2011 26.4238C28.8703 26.0637 28.4667 25.7782 28.0169 25.5863C27.5672 25.3945 27.0818 25.3007 26.5929 25.3112H18.2606C18.2606 25.3112 10.9349 24.6409 11.0004 17.1553C11.065 9.812 17.7381 9.01463 19.067 9Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
