import { Redirect } from 'react-router-dom';
// project imports
import useAuth from '../../hooks/useAuth';
import config from '../../config';
import { GuardProps } from '../../types';

//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();

  // if (isLoggedIn) {
  //   return <Redirect to={config.defaultPath} />;
  // }

  return children;
};

export default GuestGuard;
