import React from 'react';

import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useLocation } from 'react-router-dom';
const noHeadersRoutes = [
  '/login',
  '/signup',
  '/verify',
  '/reset-password',
  '/resend-link-email',
  '/password/reset',
  '/password/reset/',
  '/shared-monthly-calendar',
  '/shared-weekly-calendar',
];

//checks if location path is one of the noHeaderRoutes
const checkIsNoHeaderRoute = (value: string) =>
  noHeadersRoutes.some((element) => value.includes(element));

const SnikpicApolloClient = ({ children }) => {
  const { pathname } = useLocation();
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const authLink = setContext((_, { headers, removeHeader }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...(!removeHeader &&
          !checkIsNoHeaderRoute(pathname) && { authorization: token && `Bearer ${token}` }),
      },
    };
  });

  const client = new ApolloClient({
    // link: authLink.concat(httpLink),
    link: from([authLink, httpLink, errorLink]),
    cache: new InMemoryCache(),
  });

  return children(client);
};

export default SnikpicApolloClient;
