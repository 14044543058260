/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    label: 'Objectives',
    name: 'facebook_post_boosting_objectives',
    type: 'MULTIPLE_SELECT_TICKBOXES',
    title: 'onboarding_postboost_objectives-with-boosts_question_title',
    subtitle: 'onboarding_postboost_objectives-with-boosts_question_subtitle',
    placeholder: 'onboarding_postboost_objectives-with-boosts_question_placeholder',
    alert: 'onboarding_postboost_objectives-with-boosts_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'FOLLOWERSHIP',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_followership',
          active: false,
        },
        {
          key: 'AWARENESS_AND_REACH',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_awarness-and-reach',
          active: false,
        },
        {
          key: 'WEBSITE_TRAFFIC',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_website-traffic',
          active: false,
        },
        {
          key: 'STORE_TRAFFIC',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_store-traffic',
          active: false,
        },
        {
          key: 'ENGAGEMENT',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_engagement',
          active: false,
        },
        {
          key: 'MESSAGES',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_messages',
          active: false,
        },
        {
          key: 'LEAD_GENERATION',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_lead-generation',
          active: false,
        },
        {
          key: 'APP_INSTALLS',
          value: 'onboarding_postboost_objectives-with-boosts_answer-option_app-installs',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_postboost_objectives-with-boosts_help-and-tips_title',
      content: 'onboarding_postboost_objectives-with-boosts_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Budget',
    name: 'facebook_post_boosting_budget',
    type: 'TEXT_FIELD',
    title: 'onboarding_postboost_money-per-month_question_title',
    subtitle: 'onboarding_postboost_money-per-month_question_subtitle',
    placeholder: 'onboarding_postboost_money-per-month_question_placeholder',
    alert: 'onboarding_postboost_money-per-month_question_alert',
    validation: [
      {
        regex: '',
        error_message: '',
      },
    ],
    constraints: {},
    help_and_tips: {
      show: false,
      title: 'onboarding_postboost_money-per-month_help-and-tips_title',
      content: 'onboarding_postboost_money-per-month_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Followership product/service offer',
    name: 'facebook_post_boosting_followership_product_service_offer',
    type: 'TEXT_AREA',
    skippable: true,
    title: 'onboarding_postboost_followership-selected-products-offer-value_question_title',
    subtitle: 'onboarding_postboost_followership-selected-products-offer-value_question_subtitle',
    placeholder:
      'onboarding_postboost_followership-selected-products-offer-value_question_placeholder',
    alert: 'onboarding_postboost_followership-selected-products-offer-value_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_postboost_followership-selected-products-offer-value_help-and-tips_title',
      content:
        'onboarding_postboost_followership-selected-products-offer-value_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Lead generation information',
    name: 'facebook_post_boosting_lead_generation_information',
    type: 'TEXT_AREA',
    skippable: true,
    title: 'onboarding_postboost_lead-selected-information-needed_question_title',
    subtitle: 'onboarding_postboost_lead-selected-information-needed_question_subtitle',
    placeholder: 'onboarding_postboost_lead-selected-information-needed_question_placeholder',
    alert: 'onboarding_postboost_lead-selected-information-needed_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_postboost_lead-selected-information-needed_help-and-tips_title',
      content: 'onboarding_postboost_lead-selected-information-needed_help-and-tips_content',
      media: {},
    },
  },
];
