/* eslint-disable import/no-anonymous-default-export */
export default [
  //TODO
  //   Change “label 1” to “Name”
  // Change “lebel 2” to “Link”
  // Add in description: those can be: websites, blogs, Instagram pages, Twitter pages etc.

  {
    name: 'content_sources',
    label: 'Content sources',
    type: 'TAG_SELECTOR_SELECT',
    placeholder: 'onboarding_sources-information_source-of-content_question_placeholder',
    title: 'onboarding_sources-information_source-of-content_question_title',
    subtitle: 'onboarding_sources-information_source-of-content_question_subtitle',
    alert: 'onboarding_sources-information_source-of-content_question_alert',
    labels: [
      'onboarding_sources-information_source-of-content_answer-label_topic',
      'onboarding_sources-information_source-of-content_answer-label_url',
    ],

    // {
    //   []
    //   label1: {
    //     key: '',
    //     default: 'Topic',
    //   },
    //   label2: {
    //     key: '',
    //     default: 'URL',
    //   },
    // },
    validation: [],
    constraints: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_sources-information_source-of-content_help-and-tips_title',
      content: 'onboarding_sources-information_source-of-content_help-and-tips_content',
      media: {},
    },
  },
  {
    name: 'content_documents',
    label: 'Content documents',
    type: 'UPLOAD_FILES',
    title: 'onboarding_sources-information_documents-to-share_question_title',
    subtitle: 'onboarding_sources-information_documents-to-share_question_subtitle',
    placeholder: 'onboarding_sources-information_documents-to-share_question_placeholder',
    alert: 'onboarding_sources-information_documents-to-share_question_alert',
    validation: [],
    constraints: {
      s3_upload_path: '/content/documents/',
      cropping: {
        isAllowed: true,
        width_factor: 16,
        height_factor: 9,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title:
          'onboarding_sources-information_documents-to-share_answer-additional-text-field_title',
        placeholder:
          'onboarding_sources-information_documents-to-share_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_sources-information_documents-to-share_help-and-tips_title',
      content: 'onboarding_sources-information_documents-to-share_help-and-tips_content',
      media: {},
    },
  },
  {
    name: 'content_competition_instagram_pages',
    label: 'Competitor instagram pages',
    type: 'TAG_SELECTOR_SELECT',
    title: 'onboarding_sources-information_pages-of-competitors_question_title',
    subtitle: 'onboarding_sources-information_pages-of-competitors_question_subtitle',
    placeholder: 'onboarding_sources-information_pages-of-competitors_question_placeholder',
    alert: 'onboarding_sources-information_pages-of-competitors_question_alert',
    labels: [
      'onboarding_sources-information_pages-of-competitors_answer-label_link',

      'onboarding_sources-information_pages-of-competitors_answer-label_like-dislike',
    ],
    validation: [],
    constraints: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_sources-information_pages-of-competitors_help-and-tips_title',
      content: 'onboarding_sources-information_pages-of-competitors_help-and-tips_content',
      media: {},
    },
  },
  {
    name: 'content_news_category_and_trends',
    label: 'News category and trends',
    type: 'TEXT_AREA',
    placeholder: 'onboarding_sources-information_news-trends_question_placeholder',
    title: 'onboarding_sources-information_news-trends_question_title',
    subtitle: 'onboarding_sources-information_news-trends_question_subtitle',
    alert: 'onboarding_sources-information_news-trends_question_alert',
    validation: [],
    constraints: {},
    help_and_tips: {
      show: false,
      title: 'onboarding_sources-information_news-trends_help-and-tips_title',
      content: 'onboarding_sources-information_news-trends_help-and-tips_content',
      media: {},
    },
  },
  {
    name: 'content_inspiring_instagram_pages',
    label: 'Inspiring instagram pages',
    placeholder: 'onboarding_sources-information_interesting-pages_question_placeholder',
    type: 'TAG_SELECTOR_SELECT',
    title: 'onboarding_sources-information_interesting-pages_question_title',
    subtitle: 'onboarding_sources-information_interesting-pages_question_subtitle',
    alert: 'onboarding_sources-information_interesting-pages_question_alert',
    labels: [
      'onboarding_sources-information_interesting-pages_answer-label_link',

      'onboarding_sources-information_interesting-pages_answer-label_like-dislike',
    ],
    validation: [],
    constraints: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_sources-information_interesting-pages_help-and-tips_title',
      content: 'onboarding_sources-information_interesting-pages_help-and-tips_content',
      media: {},
    },
  },
];
