import { gql } from '@apollo/client';

const GET_MONTHLY_CALENDARS = gql`
  query getMonthlyCalendars {
    user {
      monthlyCalendars(_order_by: [{ dueDate: "desc" }]) {
        id
        dueDate
        conceptionStatus
        start
        end
        calendars {
          id
          dueDate
          publications {
            id
            publicationTime
            type
            conceptionStatus
            concept
            category
          }
        }
      }
    }
  }
`;
const GET_SHARED_MONTHLY_CALENDAR = gql`
  query MyQuery($id: String) {
    monthlyCalendars(id: $id, _order_by: [{ dueDate: "desc" }]) {
      id
      dueDate
      conceptionStatus
      start
      end
      calendars {
        id
        dueDate
        publications {
          id
          publicationTime
          type
          conceptionStatus
          concept
          category
        }
      }
    }
  }
`;

export { GET_MONTHLY_CALENDARS, GET_SHARED_MONTHLY_CALENDAR };
