import { makeStyles } from '@material-ui/styles';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';

interface ButtonProps extends MuiButtonProps {
    titleBtn: any;
    colorBtn?: string;
}

const useStyles = makeStyles({
    root: {
        background: (props: ButtonProps) =>
            props.colorBtn === 'green' ? '#4CAF50' : '#FFB400',
        textTransform: 'none',
        lineHeight: '143%',
        color: '#fff',
        border: 'none',
        width: '100%',
        borderRadius: '4px',
        '&:disabled': {
            background: 'rgba(76, 175, 80, 0.5)',
            color: '#fff'
        },
        '&:hover': {
            backgroundColor: (props: ButtonProps) =>
                props.colorBtn === 'green' ? '#4CAF50' : '#FFB400',
                color: '#fff',
        },
    },
});

export default function ButtonAction(props: ButtonProps) {
    const classes = useStyles(props);
    return (
        <MuiButton
            {...props}
            sx={{
                fontFamily: 'Gilroy-SemiBold',
                letterSpacing: '0.15px',
                fontSize: { xs: '12px', md: '14px' },
                padding: '7px'
            }}
            className={classes.root}>
            {props.titleBtn}
        </MuiButton>
    );
}
