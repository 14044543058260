import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogFeedbackCloseButton: {
    color: 'black',
    fontSize: '14px',
  },
  dialogFeedbackContent: {
    textAlign: 'center',
  },
  dialogFeedbackTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '28px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  dialogFeedbackDescription: {
    fontFamily: 'Gilroy-Regular',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '32px 0',
  },
  dialogFeedbackConfirmButton: {
    marginTop: '24px',
    marginBottom: '70px',
    background: '#EE9A8F',
    borderRadius: '4px',
    color: '#fff',
    padding: '6px 16px',
  },
}));

interface DialogFeedbackProps {
  open: boolean; //to open and close dialog
  onConfirm: () => void; //called when clicked Back To Home
  onClose: () => void; //called when dialog closed
}

const DialogFeedback: React.FC<DialogFeedbackProps> = ({ open, onConfirm, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{
        style: {
          borderRadius: '24px',
          width: '600px',
        },
      }}>
      <Box>
        <Box sx={{ margin: '0 16px' }} display="flex" justifyContent="flex-end">
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.dialogFeedbackContent}>
          <Box className={classes.dialogFeedbackTitle}>
            <FormattedMessage id="calendar_monthly_dialog_feedback_title" />
          </Box>
          <Box className={classes.dialogFeedbackDescription}>
            <FormattedMessage id="calendar_monthly_dialog_feedback_description" />
          </Box>
          <Box>
            <Button className={classes.dialogFeedbackConfirmButton} onClick={() => onConfirm()}>
              <FormattedMessage id="calendar_monthly_dialog_feedback_back_to_home" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogFeedback;
