import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  Box,
  Slider,
} from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import { makeStyles } from '@material-ui/core/styles';
import Vector from '../../../assets/images/icons/vector';
import { FormattedMessage } from 'react-intl';
var _ = require('lodash');
interface RenderFieldProps {
  control: any;
  activeStep: number;
  watch?: any;
  setValue?: any;
  handlStep: () => void;
  setActiveStep: (activeStep: number) => void;
  field: {
    label: string;
    name: string;
    type: string;
    title: string;
    placeholder: string;
    subtitle?: any;
    alert?: any;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D3D3D3',
    borderRadius: '4px',
    textAlign: 'center',
    marginBottom: '5px',
  },
  active: {
    border: '1px solid #F0998D',
    background: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '4px',
    marginBottom: '5px',
  },
}));
const RangeSelector = ({
  field,
  control,
  activeStep,
  handlStep,
  watch,
  setValue,
}: RenderFieldProps) => {
  const classes = useStyles();
  const [fielsRadio, setFielsRadio] = React.useState<any>();
  const [valueSlider, setValueSlider] = React.useState<number[]>([20, 37]);
  // React.useEffect(() => {
  //   if (!watch().customer_age) {
  //     setValue('customer_age', [20, 37]);
  //   } else {
  //     setValue('customer_age', watch().customer_age);
  //   }
  // }, []);

  React.useEffect(() => {
    setFielsRadio(field.constraints.enum);
  }, []);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number[]);
    let arrActive = fielsRadio;
    arrActive.forEach((i) => {
      i.active = false;
    });
    setFielsRadio([...arrActive]);
  };

  const handleCheck = (e: any) => {
    let arrActive = fielsRadio;
    setValueSlider([e.min, e.max]);
    arrActive.forEach((i) => {
      i.active = false;
    });
    arrActive.forEach((i) => {
      if (i.min === e.min) {
        i.active = !i.active;
      }
    });
    setFielsRadio([...arrActive]);
  };
  function valuetext(value: number) {
    return `${value}`;
  }
  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }}>
      <Controller
        key={field.name}
        name={field.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <RadioGroup
              onChange={(event) => {
                const arrayValue = event?.target?.value;
                if (arrayValue) {
                  const values = arrayValue.split(',');
                  onChange([Number(values[0]), Number(values[1])]);
                }
              }}
              value={value}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              sx={{ marginLeft: '10px', width: '100%' }}>
              {fielsRadio &&
                fielsRadio.map((i) => (
                  <>
                    <FormControlLabel
                      key={i.key}
                      sx={{
                        width: {
                          xs: '23%',
                          md: '100px',
                        },
                        padding: '15px',
                        paddingLeft: { xs: '15px', md: '25px' },
                      }}
                      // className={classes.active}
                      className={_.isEqual(value, [i.min, i.max]) ? classes.active : classes.root}
                      value={[i.min, i.max]}
                      onClick={() => handleCheck(i)}
                      control={<Radio sx={{ display: 'none' }} />}
                      label={i.min + '-' + i.max}
                    />
                  </>
                ))}
            </RadioGroup>
            <Box
              sx={{
                border: '1px solid #E0E0E0',
                borderRadius: '10px',
                width: '100%',
                padding: '5px 10px',
                marginTop: '50px',
              }}>
              <Slider
                sx={{ marginTop: '5px' }}
                value={value ? value : [50, 60]}
                onChange={(e: any, newValue: any) => {
                  handleChange(e, newValue);
                  onChange(newValue);
                }}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
              />
            </Box>
          </>
        )}
      />
    </Grid>
  );
};

export default RangeSelector;
