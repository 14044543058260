import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { styled } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
{
  /* <FormattedMessage id={alert} />; */
}

interface ButtonProps extends MuiButtonProps {}

const ButtonBack = (props: ButtonProps) => {
  const back = (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 1L1 5L5 9"
        stroke="#303778"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const ColorButton = styled(Button)(() => ({
    color: '#303778',
    textTransform: 'inherit',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Gilroy-SemiBold',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
  }));
  return (
    <ColorButton
      sx={{ minWidth: props.title == 'Previous question' ? '150px' : '100px' }}
      startIcon={back}>
      {props.title == 'none' ? (
        ''
      ) : props.title == 'Home' ? (
        <FormattedMessage id="onboarding_home" />
      ) : props.title == 'Previous question' ? (
        <FormattedMessage id="onboarding_previous_question" />
      ) : (
        <FormattedMessage id="sign_emailconf_back" />
      )}
    </ColorButton>
  );
};
export default ButtonBack;
