import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Grid,
  Typography,
  Tab,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { styled } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Publication from './Publication';
import DescriptionPublication from './DescriptionPublication';
import { CHANGE_TASKS_COMPLITION_STATUS } from '../../../api/mutations/tasks';
import ButtonTasks from '../ButtonTasks';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Gilroy-SemiBold !important',
  fontSize: '16px',
  color: '#000',
  border: '1px solid #E0E0E0',
  padding: '10px',
  maxWidth: '100%',
  borderRadius: '4px',
  '&.Mui-selected': {
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid #EE9A8F',
    padding: '10px',
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: '#fff',
  },
  '.MuiTabs-scroller': {
    borderRight: 'none !important',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
}));
const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  '& .MuiTabs-indicator': {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});
interface ToDoTableProps {}

const WeeklyCalendarTask = ({
  weeklyCalendars,
  loadingGetWeeklyCalendarsTasks,
  idTask,
  selectedTask,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();

  const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = React.useState<number | null>(null);
  const [statePublication, setStatePublication] = React.useState(false);
  const [valHashTag, setValHashTag] = React.useState<any>([]);
  const [valProuctToTag, setValProuctToTag] = React.useState<any>([]);
  const [itemsAccountTag, setItemsAccountTag] = React.useState<any>([]);
  const [showPublicationsList, setShowPublicationsList] = React.useState<boolean>(true);
  const [publicationId, setPublicationId] = React.useState('');
  const [markAsDoneAndRework, setMarkAsDoneAndRework] = React.useState(false);
  const [valuesPublication, setValuesPublication] = React.useState<any>();
  const [dateNow, setDateNow] = React.useState<any>();
  React.useEffect(() => {
    setDateNow(moment());
  }, []);
  const [completionStatus, setCompletionStatus] = React.useState(null);
  const [designsImg, setDesignsImg] = React.useState<any>(['']);
  const [staus, setStatus] = React.useState(null);
  const handleChangeStep = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };
  const handleValuesPublication = (publication) => {
    setValuesPublication(publication);
  };

  React.useEffect(() => {
    if (matchDownXs) {
      setActiveStep(null);
    } else {
      setActiveStep(0);
    }
  }, [matchDownXs]);

  React.useEffect(() => {
    if (weeklyCalendars) {
      let arrAssets: any = [];
      if (activeStep) {
        setValuesPublication(weeklyCalendars.publications[activeStep]);
        weeklyCalendars?.publications[activeStep] &&
          weeklyCalendars?.publications[activeStep].assets?.forEach((item) => {
            arrAssets.push({ url: item.url });
          });

        setDesignsImg([...arrAssets]);
      } else {
        setValuesPublication(weeklyCalendars.publications[0]);
        weeklyCalendars.publications[0].assets?.forEach((item) => {
          arrAssets.push({ url: item.url });
        });
        setDesignsImg([...arrAssets]);
      }
    }
  }, [weeklyCalendars, activeStep]);

  React.useEffect(() => {
    if (valuesPublication?.hashtags) {
      let valHashtags: any = [];
      let arrHashtags: any = valuesPublication.hashtags.split(',');
      arrHashtags.forEach((element) => {
        valHashtags.push({
          val: element,
          title: element,
        });
      });
      setValHashTag(valHashtags);
    }
    if (valuesPublication?.productsToTag) {
      let valProductsToTag: any = [];
      let arrProductsToTag: any = valuesPublication.productsToTag.split(',');
      arrProductsToTag.forEach((element) => {
        valProductsToTag.push({
          val: element,
          title: element,
        });
      });
      setValProuctToTag(valProductsToTag);
    }
    if (valuesPublication?.tags) {
      let valtags: any = [];
      let arrtags: any = valuesPublication.tags.split(',');
      arrtags.forEach((element) => {
        valtags.push({
          val: element,
          title: element,
        });
      });
      setItemsAccountTag(valtags);
    }
  }, [valuesPublication]);
  const handleTimeIsAfter = (time: any) => {
    if (dateNow) {
      let nowTime = moment(dateNow);
      time = moment(time);
      return dateNow.isAfter(time);
    }
  };
  return (
    <Box>
      {/* <Grid mt={3} mb={3} spacing={2} container>
        <Grid item xs={12} sm={2}>
          <Select fullWidth>
            <MenuItem>1</MenuItem>
            <MenuItem>2</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Select fullWidth>
            <MenuItem>1</MenuItem>
            <MenuItem>2</MenuItem>
          </Select>
        </Grid>
      </Grid> */}
      {loadingGetWeeklyCalendarsTasks ? (
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box mb={3} sx={{ display: { xs: 'block', sm: 'flex' } }}>
            <Box
              sx={{
                border: '1px solid rgba(245, 194, 188, 0.5)',
                borderRadius: '4px',
                p: '10px',
                width: { xs: '100%', sm: '230px' },
                mr: { xs: 0, sm: '20px' },
                mb: { xs: '20px', sm: 0 },
              }}>
              <Typography
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  letterSpacing: '0.1px',
                  fontSize: '14px',
                  textAlign: 'center',
                  fontFamily: 'Gilroy-SemiBold',
                }}>
                {selectedTask.title}
              </Typography>
            </Box>
            <Box
              sx={{
                border: '1px solid rgba(245, 194, 188, 0.5)',
                borderRadius: '4px',
                p: '10px',
                width: { xs: '100%', sm: '200px' },
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img width="20px" src={selectedTask.brand[0].picture[0].url} />
                <Typography
                  ml={1}
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    letterSpacing: '0.1px',
                    fontSize: '14px',
                    fontFamily: 'Gilroy-SemiBold',
                  }}>
                  {selectedTask.brand[0].name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            p={3}
            sx={{
              border: '1.5px solid rgba(188, 206, 245, 0.5)',
              borderRadius: '10px',
              background: '#fff',
            }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item container xs={12} sm={10} spacing={2} alignItems="center">
                <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs={4}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: 'Gilroy-SemiBold',
                      lineHeight: '160%',
                      color: '#000',
                    }}>
                    {intl.formatMessage({ id: 'calendar_weekly_calendar' })}
                    {/* {'Weekly Calendar ' +
                      moment(selectedTask?.calendar[0].dueDate).format('MMMM YYYY')} */}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      border: '1px solid rgba(245, 194, 188, 0.5)',
                      borderRadius: '4px',
                      padding: '15px',
                    }}>
                    <Typography
                      sx={{
                        fontFamily: 'Gilroy-SemiBold',
                        fontSize: '14px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        textAlign: 'center',
                      }}>
                      {'Task due date: ' + moment(selectedTask?.dueDate).format('DD/MM/YYYY')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Gilroy-SemiBold',
                        letterSpacing: '0.46px',
                        color: '#4CAF50',
                        borderRadius: '10px',
                        padding: '10px',
                        background: 'rgba(76, 175, 80, 0.08)',
                        border: '1px solid rgba(76, 175, 80, 0.08)',
                        mr: '10px',
                      }}>
                      {selectedTask?.status}
                    </Button>
                    {/* <Button
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Gilroy-SemiBold',
                        letterSpacing: '0.46px',
                        color: '#000',
                        border: '1px solid #F44336',
                        borderRadius: '10px',
                        padding: '10px',
                      }}>
                      {completionStatus ? completionStatus : selectedTask?.completion}
                    </Button> */}
                    <ButtonTasks
                      titleBtn={selectedTask?.completion}
                      status={selectedTask?.completion}
                      colorBtn={
                        selectedTask.completion == 'Done'
                          ? 'green'
                          : handleTimeIsAfter(selectedTask.dueDate)
                          ? 'red'
                          : selectedTask.completion != 'Not open yet' &&
                            selectedTask.completion != 'To do'
                          ? 'orange'
                          : ''
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box
                    sx={{
                      border: '1px solid rgba(245, 194, 188, 0.5)',
                      borderRadius: '4px',
                      p: '10px',
                      width: '100%',
                    }}>
                    <Typography
                      sx={{
                        color: '#EE9A8F',
                        letterSpacing: '0.1px',
                        fontSize: '14px',
                        textAlign: 'center',
                        fontFamily: 'Gilroy-SemiBold',
                      }}>
                      {'Week of ' + moment(selectedTask.calendar[0].dueDate).format('DD/MM/YYYY')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {matchDownXs ? (
              <Grid mt={3} container>
                {showPublicationsList ? (
                  <Grid
                    mb={2}
                    item
                    xs={12}
                    sm={3}
                    sx={{
                      boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.05)',
                      borderRadius: '10px',
                      p: '16px 16px 0',
                      overflow: 'scroll',
                      maxHeight: '1062px',
                    }}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: 'Gilroy-SemiBold',
                        lineHeight: '160%',
                        color: '#000',
                        mb: '20px',
                      }}>
                      <FormattedMessage id="tasks_publication_list" />
                    </Typography>

                    <StyledTabs
                      orientation="vertical"
                      value={activeStep}
                      onChange={handleChangeStep}
                      aria-label="styled tabs example">
                      {weeklyCalendars &&
                        weeklyCalendars.publications.map((publication) => (
                          <StepsTab
                            onClick={() => {
                              handleValuesPublication(publication);
                              setShowPublicationsList(false);
                            }}
                            sx={{
                              fontFamily: 'Gilroy-Medium !important',
                              borderRadius: '0px',
                              textTransform: 'none !important',
                              mb: '16px',
                            }}
                            label={
                              <Publication
                                publication={publication}
                                idTask={idTask}
                                statePublication={statePublication}
                                publicationId={publicationId}
                                setMarkAsDoneAndRework={setMarkAsDoneAndRework}
                              />
                            }
                          />
                        ))}
                    </StyledTabs>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={9}>
                    <DescriptionPublication
                      valuesPublication={valuesPublication}
                      idTask={idTask}
                      weeklyCalendars={weeklyCalendars}
                      selectedTask={selectedTask}
                      activePublication={statePublication}
                      setActivePublication={setStatePublication}
                      valHashTag={valHashTag}
                      setValHashTag={setValHashTag}
                      valProuctToTag={valProuctToTag}
                      setValProuctToTag={setValProuctToTag}
                      itemsAccountTag={itemsAccountTag}
                      setItemsAccountTag={setItemsAccountTag}
                      setShowPublicationsList={setShowPublicationsList}
                      setPublicationId={setPublicationId}
                      markAsDoneAndRework={markAsDoneAndRework}
                      setMarkAsDoneAndRework={setMarkAsDoneAndRework}
                      setCompletionStatus={setCompletionStatus}
                      setStatus={setStatus}
                      completionStatus={completionStatus}
                      designsImg={designsImg}
                      setDesignsImg={setDesignsImg}
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid mt={3} container>
                <Grid
                  mb={2}
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10px',
                    p: '16px 16px 0',
                    overflow: 'scroll',
                    maxHeight: '1062px',
                  }}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: 'Gilroy-SemiBold',
                      lineHeight: '160%',
                      color: '#000',
                      mb: '20px',
                    }}>
                    <FormattedMessage id="tasks_publication_list" />
                  </Typography>

                  <StyledTabs
                    orientation="vertical"
                    value={activeStep}
                    onChange={handleChangeStep}
                    aria-label="styled tabs example">
                    {weeklyCalendars &&
                      weeklyCalendars.publications.map((publication) => (
                        <StepsTab
                          onClick={() => {
                            handleValuesPublication(publication);
                            setShowPublicationsList(false);
                          }}
                          sx={{
                            fontFamily: 'Gilroy-Medium !important',
                            borderRadius: '0px',
                            textTransform: 'none !important',
                            mb: '16px',
                          }}
                          label={
                            <Publication
                              publication={publication}
                              idTask={idTask}
                              statePublication={statePublication}
                              publicationId={publicationId}
                              setMarkAsDoneAndRework={setMarkAsDoneAndRework}
                            />
                          }
                        />
                      ))}
                  </StyledTabs>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <DescriptionPublication
                    valuesPublication={valuesPublication}
                    idTask={idTask}
                    weeklyCalendars={weeklyCalendars}
                    selectedTask={selectedTask}
                    activePublication={statePublication}
                    setActivePublication={setStatePublication}
                    valHashTag={valHashTag}
                    setValHashTag={setValHashTag}
                    valProuctToTag={valProuctToTag}
                    setValProuctToTag={setValProuctToTag}
                    itemsAccountTag={itemsAccountTag}
                    setItemsAccountTag={setItemsAccountTag}
                    setShowPublicationsList={setShowPublicationsList}
                    setPublicationId={setPublicationId}
                    markAsDoneAndRework={markAsDoneAndRework}
                    setMarkAsDoneAndRework={setMarkAsDoneAndRework}
                    setCompletionStatus={setCompletionStatus}
                    setStatus={setStatus}
                    completionStatus={completionStatus}
                    designsImg={designsImg}
                    setDesignsImg={setDesignsImg}
                  />
                </Grid>
              </Grid>
            )}

            {/* <Grid item xs={12} sm={9}>
                <DescriptionPublication
                  valuesPublication={valuesPublication}
                  idTask={idTask}
                  weeklyCalendars={weeklyCalendars}
                  selectedTask={selectedTask}
                  activePublication={statePublication}
                  setActivePublication={setStatePublication}
                  valHashTag={valHashTag}
                  setValHashTag={setValHashTag}
                  valProuctToTag={valProuctToTag}
                  setValProuctToTag={setValProuctToTag}
                  itemsAccountTag={itemsAccountTag}
                  setItemsAccountTag={setItemsAccountTag}
                  setShowPublicationsList={setShowPublicationsList}
                />
              </Grid> */}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WeeklyCalendarTask;
