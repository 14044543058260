import React from 'react';

import { Controller } from 'react-hook-form';
import { FormControlLabel, RadioGroup, Radio, Typography, Grid, Box } from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import { styled } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Vector from '../../../assets/images/icons/vector';
import { FormattedMessage } from 'react-intl';
interface RenderFieldProps {
  control: any;
  activeStep: number;
  handlStep: () => void;
  setActiveStep: (activeStep: number) => void;
  field: {
    name: string;
    label: string;
    type: string;
    description?: string;
    title: string;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
    paddingRight: '8px',
    marginBottom: '5px',
    paddingLeft: '0px !important',
  },
  active: {
    border: '1px solid #F0998D',
    background: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '10px',
    paddingRight: '8px',
    marginBottom: '5px',
  },
}));
const SingleSelect = ({ field, control, activeStep, handlStep }: RenderFieldProps) => {
  const classes = useStyles();
  const [valuesToShow, setValuesToShow] = React.useState<any>();

  React.useEffect(() => {
    setValuesToShow(field.constraints.enum);
  }, []);
  console.log('field', field.title);

  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }}>
      <Controller
        key={field.name}
        name={field.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) =>
          field.title === 'onboarding_collab_validate-influencer-profiles-before_question_title' ? (
            <RadioGroup
              onChange={onChange}
              value={value}
              aria-label="gender"
              name="row-radio-buttons-group"
              sx={{ marginLeft: '10px', width: '100%' }}>
              {field &&
                field.constraints.enum.map((radioValue) => (
                  <FormControlLabel
                    key={radioValue.key}
                    sx={{ width: '100%' }}
                    className={radioValue.key === value ? classes.active : classes.root}
                    value={radioValue.key}
                    control={<Radio checked={radioValue.key === value} />}
                    label={<FormattedMessage id={radioValue.value} />}
                  />
                ))}
            </RadioGroup>
          ) : (
            <RadioGroup
              onChange={onChange}
              value={value}
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              sx={{ marginLeft: '10px', width: '100%' }}>
              {field &&
                field.constraints.enum.map((radioValue) => (
                  <FormControlLabel
                    key={radioValue.key}
                    sx={{ width: { xs: '100%', md: '150px' } }}
                    className={radioValue.key === value ? classes.active : classes.root}
                    value={radioValue.key}
                    control={<Radio checked={radioValue.key === value} />}
                    label={<FormattedMessage id={radioValue.value} />}
                  />
                ))}
            </RadioGroup>
          )
        }
      />
      {field.description && (
        <Box
          sx={{
            background: '#FDECEA',
            borderRadius: '4px',
            width: { xs: '100%', md: '40%' },
            display: 'flex',
            padding: '15px',
            alignItems: 'center',
            marginTop: '30px',
          }}>
          <Box sx={{ marginTop: '5%' }}>
            <Vector />
          </Box>
          <Typography sx={{ color: 'rgba(0, 0, 0, 60%);', fontSize: '14px', lineHeight: '143%' }}>
            We advise you to not be too broad, the more precise you age range is, the better the
            communication will be
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default SingleSelect;
