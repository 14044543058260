import { gql } from '@apollo/client';

const USER_SIGN_UP = gql`
  mutation signUpUser($email: String!, $password: String!, $type: String!) {
    user_sign_up(arg1: { email: $email, password: $password, type: $type }) {
      access_token
      refresh_token
    }
  }
`;

const USER_SIGN_IN = gql`
  mutation signInUser($email: String!, $password: String!) {
    user_login(arg1: { email: $email, password: $password }) {
      state
      access_token
      refresh_token
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    user_reset_password(arg1: { password: $password, token: $token }) {
      message
    }
  }
`;

const ASK_RESET_PASSWORD = gql`
  mutation askForResetPassword($email: String!, $type: String!) {
    user_generate_token(arg1: { email: $email, type: $type }) {
      token
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation MyMutation($token: String!) {
    user_verify_email(arg1: { token: $token }) {
      state
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation refreshToken($refresh_token: String!) {
    user_refresh_access(arg1: { refresh_token: $refresh_token }) {
      access_token
      refresh_token
    }
  }
`;

export {
  USER_SIGN_UP,
  USER_SIGN_IN,
  RESET_PASSWORD,
  ASK_RESET_PASSWORD,
  VERIFY_EMAIL,
  REFRESH_TOKEN,
};
