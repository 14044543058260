import { gql } from '@apollo/client';

const VALIDATE_MONTHLY_CONCEPT = gql`
  mutation validateMonthlyConcept($id: String!, $status: String!) {
    update_publications(id: $id, clientApproval: $status) {
      id
      publicationId
      clientApproval
      conceptionStatus
    }
  }
`;
const VALIDATE_ONE_MONTHLY_POST = gql`
  mutation validateOneConcept($id: String!, $conceptionStatus: String!) {
    update_publications(id: $id, conceptionStatus: $conceptionStatus) {
      id
      publicationId
      conceptionStatus
    }
  }
`;
const VALIDATE_ALL_MONTHLY_POST = gql`
  mutation validateAllConceptPost($ids: [String]!, $status: String!) {
    validate_publications_concept(arg1: {ids: $ids, status: $status}) {
     validatedIds
      errors
  }
}
`;
const FEEDBACK_MONTHLY_CONCEPT = gql`
mutation feedbackMonthlyConcept($id: String!, $feedback: String, $rating: Int) {
  update_monthlyCalendars(id: $id, feedback: $feedback, rating: $rating) {
    id
    monthlyCalendarId
    feedback
    rating
  }
}
`;
const EDIT_SPECIFIC_CONCEPT = gql`
mutation editSpecifcConcept($id: String!, $conceptionStatus: String!, $concept: String!, $publicationTime: String!, $type: String!, $category: String!) {
  update_publications(id: $id, conceptionStatus: $conceptionStatus, concept: $concept, publicationTime: $publicationTime, type: $type, category: $category) {
    id
    publicationId
    conceptionStatus
    concept
    publicationTime
    type
    category
  }
}
`;

const EDIT_MONTHLY_CONCEPT = gql`
  mutation editMonthlyConcept(
    $id: String!
    $conceptionStatus: String
    $concept: String
    $publicationTime: String
    $type: String
    $category: String
  ) {
    update_publications(
      id: $id
      conceptionStatus: $conceptionStatus
      concept: $concept
      publicationTime: $publicationTime
      type: $type
      category: $category
    ) {
      id
      publicationId
      conceptionStatus
      concept
      publicationTime
      type
      category
    }
  }
`;

const PROVIDE_MONTHLY_FEEDBACK = gql`
  mutation provideMonthlyFeedback($id: String!, $feedback: String, $rating: Int) {
    update_monthlyCalendars(id: $id, feedback: $feedback, rating: $rating) {
      id
      monthlyCalendarId
      feedback
      rating
    }
  }
`;

export {
  VALIDATE_MONTHLY_CONCEPT,
  EDIT_MONTHLY_CONCEPT,
  PROVIDE_MONTHLY_FEEDBACK,
  VALIDATE_ONE_MONTHLY_POST,
  VALIDATE_ALL_MONTHLY_POST,
  FEEDBACK_MONTHLY_CONCEPT,
  EDIT_SPECIFIC_CONCEPT
};
