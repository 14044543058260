/* eslint-disable import/no-anonymous-default-export */
export default [
  // Text field - Brand name
  {
    label: 'Brand name',
    name: 'brand_name',
    type: 'TEXT_FIELD',
    title: 'onboarding_general-info_brand-name_question_title',
    subtitle: 'onboarding_general-info_brand-name_question_subtitle',
    placeholder: 'onboarding_general-info_brand-name_question_placeholder',
    alert: 'onboarding_general-info_brand-name_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_brand-name_help-and-tips_title',
      content: 'onboarding_general-info_brand-name_help-and-tips_content',
      media: {},
    },
  },
  // Single selector - Brand category
  {
    label: 'Brand category',
    name: 'brand_category',
    type: 'SINGLE_SELECT',
    title: 'onboarding_general-info_brand-category_question_title',
    subtitle: 'onboarding_general-info_brand-category_question_subtitle',
    placeholder: 'onboarding_general-info_brand-category_question_placeholder',
    alert: 'onboarding_general-info_brand-category_question_alert',
    validation: [],
    constraints: {
      can_specify_other: true,
      other_placeholder: {
        key: 'onboarding_general_information_brand_category_other_placeholder',
        default: 'Please specify your category',
      },
      enum: [
        {
          key: 'FOOD_AND_DRINKS',
          value: 'onboarding_general-info_brand-category_answer-option_food-and-drink',
        },
        {
          key: 'FASHION',
          value: 'onboarding_general-info_brand-category_answer-option_fashion',
        },
        {
          key: 'JEWELRY',
          value: 'onboarding_general-info_brand-category_answer-option_jewelry',
        },
        {
          key: 'HAIRDRESSING',
          value: 'onboarding_general-info_brand-category_answer-option_hairdressing',
        },
        {
          key: 'HOME',
          value: 'onboarding_general-info_brand-category_answer-option_home',
        },
        {
          key: 'TRAVEL_AND_MOBILITY',
          value: 'onboarding_general-info_brand-category_answer-option_travel',
        },
        {
          key: 'BAR_AND_CAFE',
          value: 'onboarding_general-info_brand-category_answer-option_bar',
        },
        {
          key: 'HEALTH',
          value: 'onboarding_general-info_brand-category_answer-option_health',
        },
        {
          key: 'RESTAURANT',
          value: 'onboarding_general-info_brand-category_answer-option_restaurant',
        },
        {
          key: 'BEAUTY',
          value: 'onboarding_general-info_brand-category_answer-option_beauty',
        },
        {
          key: 'ACCESSORIES',
          value: 'onboarding_general-info_brand-category_answer-option_accessories',
        },
        {
          key: 'OTHER',
          value: 'onboarding_general-info_brand-category_answer-option_other',
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_brand-category_help-and-tips_title',
      content: 'onboarding_general-info_brand-category_help-and-tips_content',
      media: {},
    },
  },
  // Text area - Activity description
  {
    label: 'Activity description',
    name: 'activity_description',
    type: 'TEXT_AREA',
    title: 'onboarding_general-info_describe-your-activity_question_title',
    placeholder: 'onboarding_general-info_describe-your-activity_question_placeholder',
    subtitle: 'onboarding_general-info_describe-your-activity_question_subtitle',
    alert: 'onboarding_general-info_describe-your-activity_question_alert',
    constraint: {
      maximum_length: 1700,
    },
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_describe-your-activity_help-and-tips_title',
      content: 'onboarding_general-info_describe-your-activity_help-and-tips_content',
      media: {},
    },
  },
  //
  {
    label: 'Product / Service',
    name: 'product_service',
    type: 'TAG_SELECTOR_SELECT',
    title: 'onboarding_general-info_product-service_question_title',
    subtitle: 'onboarding_general-info_product-service_question_subtitle',
    placeholder: 'onboarding_general-info_product-service_question_placeholder',
    alert: 'onboarding_general-info_product-service_question_alert',
    skippable: true,
    labels: [
      'onboarding_general-info_product-service_answer-label_product-name',
      'onboarding_general-info_product-service_answer-label_product-short-description',
      'onboarding_general-info_product-service_answer-label_product-short-description',
    ],
    help_and_tips: {
      show: true,
      title: 'onboarding_general-info_product-service_help-and-tips_title',
      content: 'onboarding_general-info_product-service_help-and-tips_content',
      media: {},
    },
  },
  // Text field - Website
  {
    label: 'Website',
    name: 'website',
    type: 'TEXT_FIELD',
    title: 'onboarding_general-info_website_question_title',
    skippable: true,
    subtitle: 'onboarding_general-info_website_question_subtitle',
    placeholder: 'onboarding_general-info_website_question_placeholder',
    alert: 'onboarding_general-info_website_question_alert',
    validation: [
      // {
      //   regex: /\b(https?:\/\/\S*\b)/g,
      //   error_message: {
      //     key: 'url_validation_error',
      //     default: 'This is not a valid URL',
      //   },
      // },
    ],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_website_help-and-tips_title',
      content: 'onboarding_general-info_website_help-and-tips_content',
      media: {},
    },
  },
  // Text field - Instagram handle
  {
    label: 'Instagram handle',
    name: 'instagram_handle',
    type: 'TEXT_FIELD',
    title: 'onboarding_general-info_instagram-handle_question_title',
    placeholder: 'onboarding_general-info_instagram-handle_question_placeholder',
    skippable: true,
    subtitle: 'onboarding_general-info_instagram-handle_question_subtitle',
    alert: 'onboarding_general-info_instagram-handle_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_instagram-handle_help-and-tips_title',
      content: 'onboarding_general-info_instagram-handle_help-and-tips_content',
      media: {},
    },
  },
  // Text field - Facebook link
  {
    label: 'Facebook link',
    name: 'facebook_link',
    type: 'TEXT_FIELD',
    title: 'onboarding_general-info_facebook-link_question_title',
    placeholder: 'onboarding_general-info_facebook-link_question_placeholder',
    skippable: true,
    subtitle: 'onboarding_general-info_facebook-link_question_subtitle',
    alert: 'onboarding_general-info_facebook-link_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_facebook-link_help-and-tips_title',
      content: 'onboarding_general-info_facebook-link_help-and-tips_content',
      media: {},
    },
  },
  // Text area - How would you describe your activity ?
  {
    label: 'Additional links',
    name: 'additional_links',
    type: 'TEXT_AREA',
    title: 'onboarding_general-info_revelant-link_question_title',
    subtitle: 'onboarding_general-info_revelant-link_question_subtitle',
    alert: 'onboarding_general-info_revelant-link_question_alert',
    placeholder: 'onboarding_general-info_revelant-link_question_placeholder',
    constraint: {},
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_revelant-link_help-and-tips_title',
      content: 'onboarding_general-info_revelant-link_help-and-tips_content',
      media: {},
    },
  },
  // Single select - Number of employees
  {
    label: 'Number of employees',
    name: 'number_of_employees',
    type: 'SINGLE_SELECT',
    title: 'onboarding_general-info_number-of-employees_question_title',
    placeholder: 'onboarding_general-info_number-of-employees_question_placeholder',
    subtitle: 'onboarding_general-info_number-of-employees_question_subtitle',
    alert: 'onboarding_general-info_number-of-employees_question_alert',
    validation: [],
    constraints: {
      can_specify_other: false,
      enum: [
        {
          key: '0',
          value: 'onboarding_general-info_number-of-employees_answer-option_zero',
        },
        {
          key: '1-5',
          value: 'onboarding_general-info_number-of-employees_answer-option_onetofive',
        },
        {
          key: '5-10',
          value: 'onboarding_general-info_number-of-employees_answer-option_fivetoten',
        },
        {
          key: '10-20',
          value: 'onboarding_general-info_number-of-employees_answer-option_tentotwenty',
        },
        {
          key: '20-50',
          value: 'onboarding_general-info_number-of-employees_answer-option_twentytofifty',
        },
        {
          key: '50+',
          value: 'onboarding_general-info_number-of-employees_answer-option_fittyplus',
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_number-of-employees_help-and-tips_title',
      content: 'onboarding_general-info_number-of-employees_help-and-tips_content',
      media: {},
    },
  },
  // Text area - Mission and values
  {
    label: 'Mission and values',
    name: 'mission_and_values',
    type: 'TEXT_AREA',
    title: 'onboarding_general-info_missions-and-values_question_title',
    placeholder: 'onboarding_general-info_missions-and-values_question_placeholder',
    subtitle: 'onboarding_general-info_missions-and-values_question_subtitle',
    alert: 'onboarding_general-info_missions-and-values_question_alert',
    constraint: {
      maximum_length: 1700,
    },
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_missions-and-values_help-and-tips_title',
      content: 'onboarding_general-info_missions-and-values_help-and-tips_content',
      media: {},
    },
  },
  // Text area - Strength compared to competition
  {
    label: 'Strength compared to competition',
    name: 'strength_compared_to_competition',
    type: 'TEXT_AREA',
    title: 'onboarding_general-info_strength-competition_question_title',
    subtitle: 'onboarding_general-info_strength-competition_question_subtitle',
    alert: 'onboarding_general-info_strength-competition_question_alert',
    placeholder: 'onboarding_general-info_strength-competition_question_placeholder',
    constraint: {
      maximum_length: 1700,
    },
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_general-info_strength-competition_help-and-tips_title',
      content: 'onboarding_general-info_strength-competition_help-and-tips_content',
      media: {},
    },
  },
];
