import React from 'react';
import { Box, Grid, Button, Typography, LinearProgress, Avatar } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import CollectionsIcon from '@material-ui/icons/Collections';
import VideoCameraBackIcon from '@material-ui/icons/VideoCameraBack';
import PeopleIcon from '@material-ui/icons/People';
import moment from 'moment';
const BoardCardDetail = ({ publication }) => {
  console.log('publication', publication)
  const [dateNow, setDateNow] = React.useState<any>();
  React.useEffect(() => {
    setDateNow(moment());
  }, []);

  const handleTime = (time, daysOrHours) => {
    let nowTime = moment(dateNow);
    time = moment(time);
    return nowTime.diff(time, daysOrHours).toString().replace('-', '');
  };
  const handleTimeIsAfter = (time: any) => {
    if (dateNow) {
      let nowTime = moment(dateNow);
      time = moment(time);
      return dateNow.isAfter(time);
    }
  };
  return (
    <Box mb={3}>
      <Box mb={3} sx={{ border: '1px solid #E0E0E0', width: '100%' }}></Box>
      <Box sx={{ display: 'flex', justifyContent: { xs: 'space-between', sm: 'flex-start' }, alignItems: 'center' }}>
        <Typography sx={{
          color: '#000',
          fontSize: '16px',
          lineHeight: '125%',
          letterSpacing: '0.15px',
          fontWeight: 400,
          mr: '15px'
        }}>Last 7 days summary</Typography>
        <Box>
          <Button sx={{ background: '#EE9A8F', color: '#fff', p: '0px 10px', borderRadius: '5px', '&:hover': { background: '#EE9A8F' } }}>View more</Button>
        </Box>
      </Box>
      <Grid mt={1} container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: 'flex', border: '1px solid #FFB547', borderRadius: '10px', p: '16px', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ p: '15px 20px', background: 'rgba(255, 152, 0, 0.08)', borderRadius: '5px' }}>
                <CollectionsIcon sx={{ fill: '#FFB547', fontSize: '14px' }} />
              </Box>
              <Box ml={3}>
                <Typography sx={{
                  color: '#000',
                  fontSize: '20px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 700,
                }}>{publication.counts?.fbPostsLastSevenDaysCount + '/3'}</Typography>
                <Typography sx={{
                  color: '#000',
                  fontSize: '14px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 400,
                  opacity: 0.5,
                  mt: '5px'
                }}>Post</Typography>
              </Box>
            </Box>
            <Box>
              <WarningIcon sx={{ fill: '#FF9800', fontSize: '16px' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: 'flex', border: '1px solid #F88078', borderRadius: '10px', p: '16px', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ p: '15px 20px', background: 'rgba(244, 67, 54, 0.08)', borderRadius: '5px' }}>
                <VideoCameraBackIcon sx={{ fill: '#F88078', fontSize: '14px' }} />
              </Box>
              <Box ml={3}>
                <Typography sx={{
                  color: '#000',
                  fontSize: '20px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 700,
                }}>{'2/10'}</Typography>
                <Typography sx={{
                  color: '#000',
                  fontSize: '14px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 400,
                  opacity: 0.5,
                  mt: '5px'
                }}>Story</Typography>
              </Box>
            </Box>
            <Box>
              <CancelIcon sx={{ fill: '#F44336', fontSize: '16px' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: 'flex', border: '1px solid #7BC67E', borderRadius: '10px', p: '16px', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ p: '15px 20px', background: 'rgba(76, 175, 80, 0.08)', borderRadius: '5px' }}>
                <PeopleIcon sx={{ fill: '#7BC67E', fontSize: '14px' }} />
              </Box>
              <Box ml={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{
                    color: '#000',
                    fontSize: '20px',
                    lineHeight: '125%',
                    letterSpacing: '0.15px',
                    fontWeight: 700,
                    mr: '5px'
                  }}>100</Typography>
                  <Typography sx={{
                    color: publication.counts?.igFollowersLifetimeDeltaLastSevenDaysAbs.includes('-') ? '#F44336' : '#4CAF50',
                    fontSize: '10px',
                    lineHeight: '125%',
                    letterSpacing: '0.15px',
                    fontWeight: 700,
                  }}>{`${publication.counts?.igFollowersLifetimeDeltaLastSevenDaysAbs}  than last week`}</Typography>
                </Box>
                <Typography sx={{
                  color: '#000',
                  fontSize: '14px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 400,
                  opacity: 0.5,
                  mt: '5px'
                }}>Followers </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography mt={3} mb={3} sx={{
        color: '#000',
        fontSize: '16px',
        lineHeight: '125%',
        letterSpacing: '0.15px',
        fontWeight: 400,
        mr: '15px'
      }}>Ongoing workflow</Typography>
      <Grid container spacing={3}>
        {publication.monthlyCalendars?.map((detail, index) => (
          <Grid key={index + 1} item xs={12} sm={6}>
            <Box sx={{ border: '1px solid #F2F2F2', borderRadius: '5px', p: '15px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{
                  color: '#000',
                  fontSize: '10px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 400,
                  fontFamily: 'Gilroy-Medium'
                }}>{'Due by ' + moment(detail.date).format('DD/MM/YYYY')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{
                    borderRadius: '5px',
                    background: handleTimeIsAfter(detail.dueDate) ? 'rgba(244, 67, 54, 0.08)' : 'rgba(255, 152, 0, 0.08)',
                    p: '4px',
                    mr: '10px'
                  }}>
                    <Typography sx={{
                      color: handleTimeIsAfter(detail.dueDate) ? '#F44336' : '#FF9800',
                      fontSize: '10px',
                      lineHeight: '125%',
                      letterSpacing: '0.15px',
                      fontWeight: 400,
                      fontFamily: 'Gilroy-Medium'
                    }}>{!handleTimeIsAfter(detail.dueDate) ? 'Late by ' + handleTime(detail.dueDate, 'days') + ' days' : 'Due ' + handleTime(detail.dueDate, 'hours') + ' hours ago'}</Typography>
                  </Box>
                  {!handleTimeIsAfter(detail.dueDate) ? <WarningIcon sx={{ fill: '#FF9800', fontSize: '13px' }} />:
                  <CancelIcon sx={{ fill: '#F44336', fontSize: '13px' }} />}
                </Box>
              </Box>
              <Typography mt={3} mb={2} sx={{
                color: '#000',
                fontSize: '12px',
                lineHeight: '125%',
                letterSpacing: '0.15px',
                fontWeight: 400,
                fontFamily: 'Gilroy'
              }}>{'name'}</Typography>
              <LinearProgress sx={{ height: '8px', borderRadius: '100px' }} variant="determinate" value={30} />
              <Box mt={1} mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{
                  color: '#000',
                  fontSize: '10px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 400,
                  fontFamily: 'Gilroy-SemiBold',
                  opacity: 0.5
                }}>{'Design done'}</Typography>
                <Typography sx={{
                  color: '#000',
                  fontSize: '10px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 400,
                  fontFamily: 'Gilroy-Medium',
                }}>{'30%'}</Typography>
              </Box>
              <Box mt={1} mb={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ width: '16px', height: '16px' }} alt="Travis Howard" src={''} />
                  <Typography sx={{
                    color: '#000',
                    fontSize: '10px',
                    lineHeight: '125%',
                    letterSpacing: '0.15px',
                    fontWeight: 400,
                    fontFamily: 'Gilroy-Medium',
                    ml: '10px'
                  }}>{'Esther Howard'}</Typography>
                </Box>
                <Button sx={{ background: '#EE9A8F', color: '#fff', p: '0px 10px', borderRadius: '5px', '&:hover': { background: '#EE9A8F' } }}>Message</Button>
              </Box>
            </Box>
          </Grid>))}
      </Grid>
    </Box>
  );
};

export default BoardCardDetail;
