import { PaletteMode } from '@material-ui/core';
const languageSelected = localStorage.getItem('languageSelected');

const config: {
  basename: string;
  defaultPath: string;
  fontFamily: string;
  borderRadius: number;
  outlinedFilled: boolean;
  theme: PaletteMode;
  presetColor: string;
  i18n: string;
  rtlLayout: boolean;
  jwt: {
    secret: string;
    timeout: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
} = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/snikpic/react/default'
  basename: '',
  defaultPath: '/login',
  fontFamily: 'Gilroy',
  borderRadius: 10,
  outlinedFilled: true,
  theme: 'light',
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: languageSelected ? languageSelected : 'en',
  rtlLayout: false,
  jwt: {
    secret: 'SECRET-KEY',
    timeout: '1 days',
  },
  firebase: {
    apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
    authDomain: 'snikpic-material-react.firebaseapp.com',
    projectId: 'snikpic-material-react',
    storageBucket: 'snikpic-material-react.appspot.com',
    messagingSenderId: '901111229354',
    appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
    measurementId: 'G-MGJHSL8XW3',
  },
};

export default config;
