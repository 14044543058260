import React from 'react';
import { Box, Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { styled } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import { MobileView, BrowserView } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';

const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Gilroy-SemiBold !important',
  fontSize: '16px',
  color: '#000',
  border: '1px solid #F2ADA6',
  borderRadius: '10px',
  '&.Mui-selected': {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.4000000059604645px',
    color: '#fff',
    background: '#F2ADA6',
  },
  '.MuiTabs-indicator': {
    backgroundColor: '#fff !important',
  },
}));

const tabsToRender = [
  {
    name: 'profile_overall_strategy',
    id: 'readOnlyEmbedTagFromStrategies',
  },
  {
    name: 'profile_design_templates',
    id: 'readOnlyEmbedTagFromDesignTemplates',
  },
  {
    name: 'profile_first_publications',
    id: 'readOnlyEmbedTagFromPublications',
  },
  {
    name: 'profile_facebook_boost_strategy',
    id: 'readOnlyEmbedTagFromFacebookBoostStrategies',
  },
  {
    name: 'profile_google_ads_strategy',
    id: 'readOnlyEmbedTagFromGoogleAdsStrategies',
  },
  // {
  //   name: 'Influencer Strategy',
  //   id: 'readOnlyEmbedTagFromDesignTemplates',
  // },
];

const MyStrategy = ({ data }) => {
  const [value, setValue] = React.useState('readOnlyEmbedTagFromStrategies');
  const [scripts, setScripts] = React.useState();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (data) {
      setScripts(data.user[0]['brandAssets'][0]);
    }
  }, [data]);

  const renderFieldsTab = (value) => {
    if (scripts && scripts[value]) {
      const values = (scripts[value] as string).split('<script src="');
      const divId =
        values[0] &&
        (values[0] as string)
          .replace(String.fromCharCode(92), '')
          .replace('<div id="', '')
          .replace('"></div>', '');
      const srcUrl =
        values[1] &&
        (values[1] as string).replace(String.fromCharCode(92), '').replace('"></script>', '');

      return (
        <Box>
          <div id={divId}></div>

          <Helmet>
            <script async src={srcUrl}></script>
          </Helmet>
        </Box>
      );
    }
  };
  return (
    <Box mt={2} height="100vh">
      <Box>
        <BrowserView>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={value}
            onChange={handleChange}
            sx={{
              maxWidth: { xs: '401px', md: '1300px' },
              '.MuiTabs-indicator': { display: 'none' },
            }}>
            {tabsToRender.map((tab) => (
              <StepsTab
                value={tab.id}
                sx={{
                  fontFamily: 'Gilroy-Medium !important',
                  textTransform: 'capitalize !important',
                  marginRight: '5px',
                }}
                label={<FormattedMessage id={tab.name} />}
              />
            ))}
          </Tabs>
        </BrowserView>
        <MobileView>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={value}
            onChange={handleChange}
            sx={{
              maxWidth: { xs: '100%', md: '1300px' },
              '.MuiTabs-indicator': { display: 'none' },
            }}>
            {tabsToRender.map((tab) => (
              <StepsTab
                value={tab.id}
                sx={{
                  fontFamily: 'Gilroy-Medium !important',
                  textTransform: 'capitalize !important',
                  mb: '10px',
                  maxWidth: '100%',
                }}
                label={<FormattedMessage id={tab.name} />}
              />
            ))}
          </Tabs>
        </MobileView>
      </Box>
      <Box>{renderFieldsTab(value)}</Box>
    </Box>
  );
};

export default MyStrategy;
