import React from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Accordion,
  MenuItem,
  Select,
  Button,
  ToggleButtonGroup,
  AccordionSummary,
  AccordionDetails,
  ToggleButton,
  Input,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  CardMedia,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import ImageViewer from 'react-simple-image-viewer';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import CancelIcon from '@material-ui/icons/Cancel';
import BackupIcon from '@material-ui/icons/Backup';
import EmojiPicker from 'emoji-picker-react';
import { useDropzone } from 'react-dropzone';
import { CHANGE_TASKS_COMPLITION_STATUS } from '../../../api/mutations/tasks';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { CHANGE_PUBLICATION_WEEKLY_CALENDAR_TASKS } from '../../../api/mutations/tasks';
import { GENERATE_UPLOAD_URL } from '../../../api/mutations/onBoard';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
interface DescriptionPublicationProps {
  valuesPublication: any;
  idTask: string;
  weeklyCalendars: any;
  selectedTask: any;
  activePublication: boolean;
  setActivePublication: any;
  valHashTag: any;
  setValHashTag: any;
  valProuctToTag: any;
  setValProuctToTag: any;
  itemsAccountTag: any;
  setItemsAccountTag: any;
  setShowPublicationsList: any;
  setPublicationId: any;
  markAsDoneAndRework: boolean;
  setMarkAsDoneAndRework: any;
  setCompletionStatus: any;
  setStatus: any;
  completionStatus: string | null;
  designsImg: any;
  setDesignsImg: any;
}
const hashtag: any = [];
const accountToTag: any = [];
const prouctToTag: any = [];
const DescriptionPublication = ({
  valuesPublication,
  idTask,
  weeklyCalendars,
  selectedTask,
  setActivePublication,
  activePublication,
  valHashTag,
  setValHashTag,
  valProuctToTag,
  setValProuctToTag,
  itemsAccountTag,
  setItemsAccountTag,
  setShowPublicationsList,
  setPublicationId,
  markAsDoneAndRework,
  setMarkAsDoneAndRework,
  setCompletionStatus,
  setStatus,
  completionStatus,
  designsImg,
  setDesignsImg,
}: DescriptionPublicationProps) => {
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  const intl = useIntl();
  let history = useHistory();

  const [active, setActive] = React.useState(true);
  const [activeCopyWrite, setActiveCopyWrite] = React.useState(true);
  const [activeDesign, setActiveDesign] = React.useState(true);
  const [valueCaption, setValueCaption] = React.useState('');
  const [buttonClicked, setButtonClicked] = React.useState<string | null>(null);
  const [currentImage, setCurrentImage] = React.useState(0);

  const formik = useFormik({
    initialValues: {
      type: '',
      category: '',
      caption: '',
      concept: '',
      commentsOnDesignOpen: '',
      location: '',
      commentsOnTextOpen: '',
      publicationTime: '',
      productsToTag: '',
      tags: '',
      toBeBoosted: false,
    },
    onSubmit: (values) => {
      let arrValHashTags: any = [];
      if (valHashTag) {
        valHashTag.forEach((element) => {
          arrValHashTags.push(element.title.replace('Add ', ''));
        });
      }
      let arrValAccoumtToTags: any = [];
      if (itemsAccountTag) {
        itemsAccountTag.forEach((element) => {
          arrValAccoumtToTags.push(element.title.replace('Add ', ''));
        });
      }
      let arrValProductToTags: any = [];
      if (valProuctToTag) {
        valProuctToTag.forEach((element) => {
          arrValProductToTags.push(element.title.replace('Add ', ''));
        });
      }
      changePublicationWeeklyCalendarTasks({
        variables: {
          id: valuesPublication.id,
          conceptionStatus: 'In client review',
          concept: formik.values.concept,
          publicationTime: formik.values.publicationTime,
          type: formik.values.type,
          category: formik.values.category,
          caption: formik.values.caption,
          commentsOnTextOpen: formik.values.commentsOnTextOpen,
          tags: `${formik.values.tags}`,
          productsToTag: `${formik.values.productsToTag}`,
          location: formik.values.location,
          toBeBoosted: formik.values.toBeBoosted,
          designs: `${designsImg}`,
          commentsOnDesignOpen: formik.values.commentsOnDesignOpen,
          hashtags: `${arrValHashTags}`,
        },
      });

      handleAssets();
    },
  });
  const [generateUploadUrl, { data, loading, error }] = useMutation(GENERATE_UPLOAD_URL);

  const [alignment, setAlignment] = React.useState('fr');
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [files, setFiles] = React.useState<any>([]);
  const [editingMode, seteditingMode] = React.useState(false);
  const [disableMarkAsDoneTemp, setDisableMarkAsDoneTemp] = React.useState(false);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const deleteImg = (url: string) => {
    let arrAssets = designsImg.filter((item) => item.url !== url);
    setDesignsImg([...arrAssets]);
  };
  const handleAssets = () => {
    axios({
      method: 'patch',
      url: 'https://api.airtable.com/v0/app0NetByCV5AKHUc/Publications',
      headers: { Authorization: `Bearer keyWToErDpxpknOpg` },
      data: {
        records: [
          {
            id: valuesPublication.id,
            fields: {
              Assets: designsImg,
            },
          },
        ],
      },
    })
      .then(function (response) {
        console.log('response', response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [
    changePublicationWeeklyCalendarTasks,
    {
      loading: loadingChangePublicationWeeklyCalendarTasks,
      error: errorChangePublicationWeeklyCalendarTasks,
    },
  ] = useMutation(CHANGE_PUBLICATION_WEEKLY_CALENDAR_TASKS, {
    onCompleted: (data) => {
      onClickSave();
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Publication successfully modified',
        variant: 'alert',
        alertSeverity: 'success',
        autoHideDuration: '6000',
      });
    },
    onError: (error) => {
      const message = JSON.parse(error.message);
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Error: ' + message?.content,
        variant: 'alert',
        alertSeverity: 'error',
        autoHideDuration: '6000',
      });
    },
  });
  const [
    changeTaskCompletitionStatus,
    { loading: loadingChangeTaskCompletitionStatus, error: errorChangeTaskCompletitionStatus },
  ] = useMutation(CHANGE_TASKS_COMPLITION_STATUS, {
    onCompleted: (data) => {
      console.log('🔥🔥 ~ buttonClicked', buttonClicked);
      if (buttonClicked === 'markAsDone') {
        setCompletionStatus('Done');
      }

      if (buttonClicked === 'requestRework') {
        setCompletionStatus('Rework required');
      }

      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Completion status successfully modified',
        variant: 'alert',
        alertSeverity: 'success',
        autoHideDuration: '6000',
      });
      setDisableMarkAsDoneTemp(true);
      if (buttonClicked === 'markAsDone') {
        history.push('/tasks/my-overview');
      }
    },
    onError: (error) => {
      const message = JSON.parse(error.message);
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Error: ' + message?.content,
        variant: 'alert',
        alertSeverity: 'error',
        autoHideDuration: '6000',
      });
    },
  });

  const handleChangeCompletionStatus = () => {
    setButtonClicked('requestRework');
    setTimeout(() => {
      changeTaskCompletitionStatus({
        variables: {
          id: idTask,
          completition_status: 'Rework required',
        },
        update: (cache, { data: { update_tasks } }) => {},
      });
    }, 10);
  };
  const handleChangeCompletionStatusMarkAsDone = () => {
    setButtonClicked('markAsDone');

    setTimeout(() => {
      changeTaskCompletitionStatus({
        variables: {
          id: idTask,
          completition_status: 'Done',
        },
      });
    }, 10);
  };
  React.useEffect(() => {
    if (weeklyCalendars) {
      let storageItem = JSON.parse(`${localStorage.getItem('idActive')}`);
      let idTaskJSON = '';
      if (storageItem) {
        const keys = Object.keys(storageItem);
        keys.forEach((item) => {
          if (item == idTask) {
            idTaskJSON = item;
          }
        });
      }
      if (
        idTaskJSON !== '' &&
        storageItem[idTaskJSON].length === weeklyCalendars.publications.length
      )
        setMarkAsDoneAndRework(true);
    }
  });
  React.useEffect(() => {
    if (valuesPublication) {
      for (var key in formik.values) {
        if (valuesPublication.hasOwnProperty(key)) {
          if (key == 'toBeBoosted') {
            formik.setFieldValue(key, valuesPublication[key] ? valuesPublication[key] : false);
          } else {
            formik.setFieldValue(key, valuesPublication[key] ? valuesPublication[key] : '');
          }
        }
      }
    }
  }, [valuesPublication]);
  const onClickSave = () => {
    if (localStorage.getItem('idActive') == null) {
      let arrIdTask: any = {};
      arrIdTask[idTask] = [valuesPublication.id];
      localStorage.setItem('idActive', JSON.stringify(arrIdTask));
      setActivePublication(!activePublication);
    } else if (localStorage.getItem('idActive') !== null) {
      let storageItem = JSON.parse(`${localStorage.getItem('idActive')}`);
      let idTaskJSON = '';
      const keys = Object.keys(storageItem);
      keys.forEach((item) => {
        if (item == idTask) {
          idTaskJSON = item;
        }
      });
      if (keys.indexOf(idTask) == -1) {
        storageItem[idTask] = [valuesPublication.id];
        localStorage.setItem('idActive', JSON.stringify(storageItem));
        setActivePublication(!activePublication);
      }
      if (idTaskJSON !== '' && storageItem[idTaskJSON].indexOf(valuesPublication.id) == -1) {
        storageItem[idTaskJSON].push(valuesPublication.id);
        localStorage.setItem('idActive', JSON.stringify(storageItem));
        setActivePublication(!activePublication);
      }
    }
  };
  const handleChangeStatusPublication = () => {
    if (localStorage.getItem('idActive') !== null) {
      let storageItem = JSON.parse(`${localStorage.getItem('idActive')}`);
      let idTaskJSON = '';
      const keys = Object.keys(storageItem);
      keys.forEach((item) => {
        if (item == idTask) {
          idTaskJSON = item;
        }
      });
      if (keys.indexOf(idTask) !== -1) {
        let indexId = 0;
        setPublicationId(valuesPublication.id);
        storageItem[idTask].forEach((element, key) => {
          if (element == valuesPublication.id) {
            indexId = key;
          }
        });
        if (indexId == 0) {
          storageItem[idTask].shift();
        } else {
          storageItem[idTask].splice(indexId, 1);
        }
        localStorage.setItem('idActive', JSON.stringify(storageItem));
        setActivePublication(!activePublication);
      }
    }
  };
  const handleChangeStatusPublication2 = () => {
    seteditingMode(true);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((element, index) => {
        generateUploadUrl({
          variables: {
            path: `workflows/${selectedTask.brand[0].name}/calendars/${weeklyCalendars.id}/${
              index + 1
            }/${index + 1}_${element.name}`,
          },
          onCompleted: (data) => {
            axios({
              method: 'put',
              url: data.generate_upload_url.url,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: element,
            })
              .then(function (response) {
                let arrImg: any = designsImg;
                arrImg.push({ url: data.generate_upload_url.url.split('?X-Amz-Algorithm')[0] });
                setDesignsImg([...arrImg]);
              })
              .catch(function (error) {
                console.log(error);
              });
          },
        });
      });
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const onEmojiClick = (event, emojiObject) => {
    formik.setFieldValue('caption', formik.values.caption + emojiObject.emoji);
    setOpenEmoji(false);
  };
  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };
  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <Box sx={{ pl: { xs: '0', sm: '20px' } }}>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2} sx={{ border: '1px solid #E0E0E0', borderRadius: '10px' }}>
          <Accordion
            expanded={active}
            onChange={() => setActive(!active)}
            sx={{
              borderBottomLeftRadius: '10px !important',
              borderBottomRightRadius: '10px !important',
              borderTopLeftRadius: '10px !important',
              borderTopRightRadius: '10px !important',
            }}>
            <AccordionSummary
              sx={{
                '.MuiAccordionSummary-content': { margin: '0 !important' },
                cursor: 'default !important',
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '160%',
                  letterSpacing: '0.16px',
                  color: '#000',
                  fontFamily: 'Gilroy-SemiBold',
                }}>
                <FormattedMessage id="tasks_concept" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} sx={{ p: { xs: '0', sm: '0' } }}>
                <Grid item xs={12} sm={2}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="calendar_to_do_type" />
                  </Typography>
                  <Select
                    fullWidth
                    name="type"
                    value={formik.values.type}
                    onChange={(e) => {
                      formik.setFieldValue('type', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}>
                    <MenuItem value="Post">
                      <FormattedMessage id="tasks_post" />
                    </MenuItem>
                    <MenuItem value="Story">
                      <FormattedMessage id="tasks_story" />
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_schedule" />
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      inputFormat="dd-MM-yyyy"
                      value={formik.values.publicationTime}
                      onChange={(newValue) => {
                        formik.setFieldValue('publicationTime', newValue);
                        handleChangeStatusPublication2();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '100%',
                            '.MuiFormHelperText-root': { display: 'none' },
                            '.MuiOutlinedInput-input': { background: '#fff' },
                            '.MuiOutlinedInput-root': { background: '#fff' },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_category" />
                  </Typography>
                  {/* <Select
                    fullWidth
                    name="category"
                    value={formik.values.category}
                    onChange={(e) => {
                      formik.setFieldValue('category', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}>
                    <MenuItem value="Post">Post</MenuItem>
                    <MenuItem value="Story">Story</MenuItem>
                  </Select> */}
                  <TextField
                    fullWidth
                    placeholder="Write down the concept"
                    name="category"
                    value={formik.values.category}
                    onChange={(e) => {
                      formik.setFieldValue('category', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_concept" />
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Write down the concept"
                    name="concept"
                    value={formik.values.concept}
                    onChange={(e) => {
                      formik.setFieldValue('concept', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mb={2} sx={{ border: '1px solid #E0E0E0', borderRadius: '10px' }}>
          <Accordion
            expanded={activeCopyWrite}
            onChange={() => setActiveCopyWrite(!activeCopyWrite)}
            sx={{
              borderBottomLeftRadius: '10px !important',
              borderBottomRightRadius: '10px !important',
              borderTopLeftRadius: '10px !important',
              borderTopRightRadius: '10px !important',
            }}>
            <AccordionSummary
              sx={{
                '.MuiAccordionSummary-content': { margin: '0 !important' },
                cursor: 'default !important',
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '160%',
                  letterSpacing: '0.16px',
                  color: '#000',
                  fontFamily: 'Gilroy-SemiBold',
                }}>
                <FormattedMessage id="tasks_copywriting" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} sx={{ p: { xs: '0', sm: '0' } }}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="calendar_weekly_comments_on_text" />
                  </Typography>
                  <TextField
                    name="commentsOnTextOpen"
                    value={formik.values.commentsOnTextOpen}
                    onChange={(e) => {
                      formik.setFieldValue('commentsOnTextOpen', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                  />
                </Grid>
                <Grid item xs={12} sm={6} pl={1}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_caption" />
                  </Typography>
                  <Box sx={{ position: 'relative' }}>
                    <TextField
                      fullWidth
                      multiline
                      inputProps={{ maxLength: 2200 }}
                      name="caption"
                      value={formik.values.caption}
                      onChange={(e) => {
                        formik.setFieldValue('caption', e.target.value);
                        handleChangeStatusPublication2();
                      }}
                      minRows={5}
                      maxRows={5}
                      placeholder="Write down the caption"
                      sx={{
                        '.MuiOutlinedInput-input': {
                          // pt: '50px !important',
                          // height: '70px !important',
                          background: '#fff',
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        letterSpacing: '0.15px',
                        opacity: 0.5,
                        mt: '2px',
                      }}>{`Characters: ${formik.values.caption.length}/2200`}</Typography>
                    {/* <Box sx={{ position: 'absolute', top: '10px', left: '10px' }}>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={alignment}
                                                exclusive
                                                onChange={handleChange}
                                                sx={{
                                                    '& .MuiToggleButtonGroup-grouped': { border: 0 },
                                                    '.Mui-selected': {
                                                        color: '#fff !important',
                                                        background: '#EE9A8F !important',
                                                    },
                                                }}>
                                                <ToggleButton
                                                    value="fr"
                                                    sx={{
                                                        fontSize: '12px',
                                                        letterSpacing: '0.46px',
                                                        fontFamily: 'Gilroy-SemiBold',
                                                        border: '1px solid rgba(245, 194, 188, 0.5) !important',
                                                        borderBottomLeftRadius: '4px !important',
                                                        borderBottomRightRadius: '4px !important',
                                                        borderTopLeftRadius: '4px !important',
                                                        borderTopRightRadius: '4px !important',
                                                        maxHeight: '35px',
                                                        color: '#EE9A8F',
                                                    }}>
                                                    FR
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="en"
                                                    sx={{
                                                        fontSize: '12px',
                                                        letterSpacing: '0.46px',
                                                        fontFamily: 'Gilroy-SemiBold',
                                                        border: '1px solid rgba(245, 194, 188, 0.5) !important',
                                                        ml: '7px !important',
                                                        borderBottomLeftRadius: '4px !important',
                                                        borderBottomRightRadius: '4px !important',
                                                        borderTopLeftRadius: '4px !important',
                                                        borderTopRightRadius: '4px !important',
                                                        maxHeight: '35px',
                                                        color: '#EE9A8F',
                                                    }}>
                                                    EN
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="nl"
                                                    sx={{
                                                        fontSize: '12px',
                                                        letterSpacing: '0.46px',
                                                        fontFamily: 'Gilroy-SemiBold',
                                                        border: '1px solid rgba(245, 194, 188, 0.5) !important',
                                                        ml: '7px !important',
                                                        borderBottomLeftRadius: '4px !important',
                                                        borderBottomRightRadius: '4px !important',
                                                        borderTopLeftRadius: '4px !important',
                                                        borderTopRightRadius: '4px !important',
                                                        maxHeight: '35px',
                                                        color: '#EE9A8F',
                                                    }}>
                                                    NL
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box> */}
                    <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                      <EmojiEmotionsIcon
                        sx={{ fill: 'rgba(0, 0, 0, 0.12)', cursor: 'pointer' }}
                        onClick={() => setOpenEmoji(!openEmoji)}
                      />
                    </Box>
                    {openEmoji && (
                      <Box sx={{ position: 'absolute', top: '40px', right: '5px', zIndex: '222' }}>
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              {/* <Typography
                mt={2}
                sx={{
                  fontSize: '16px',
                  lineHeight: '160%',
                  letterSpacing: '0.16px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  mb: '10px',
                }}>
                Hashtag
              </Typography>
              <Autocomplete
                freeSolo
                selectOnFocus
                clearOnBlur
                multiple
                id="fixed-tags-demo"
                value={valHashTag}
                onChange={(event, newValue) => {
                  setValHashTag([...newValue]);
                  handleChangeStatusPublication2();
                }}
                options={hashtag}
                getOptionLabel={(option) => option.title}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.title);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Add #${inputValue}`,
                    });
                  }

                  return filtered;
                }}
                sx={{
                  '.MuiAutocomplete-tag': { borderRadius: '20px', fontSize: '14px' },
                  '.MuiOutlinedInput-root': { background: '#fff' },
                  '.MuiOutlinedInput-input': { background: '#fff' },
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      deleteIcon={<CancelIcon sx={{ fill: '#fff' }} />}
                      label={option.title.replace('Add ', '')}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => <TextField {...params} placeholder="Enter a hashtag" />}
              /> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_account_to_tag" />
                  </Typography>
                  {/* <Autocomplete
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    multiple
                    id="fixed-tags-demo"
                    value={itemsAccountTag}
                    onChange={(event, newValue) => {
                      setItemsAccountTag([...newValue]);
                      handleChangeStatusPublication2();
                    }}
                    options={accountToTag}
                    getOptionLabel={(option) => option.title}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add #${inputValue}`,
                        });
                      }

                      return filtered;
                    }}
                    sx={{
                      '.MuiAutocomplete-tag': { borderRadius: '20px', fontSize: '14px' },
                      '.MuiOutlinedInput-root': { background: '#fff' },
                      '.MuiOutlinedInput-input': { background: '#fff' },
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon sx={{ fill: '#fff' }} />}
                          label={option.title.replace('Add ', '')}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Enter an account to tag" />
                    )}
                  /> */}
                  <TextField
                    fullWidth
                    name="tags"
                    value={formik.values.tags}
                    onChange={(e) => {
                      formik.setFieldValue('tags', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}
                    placeholder="Enter an account to tag"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_products_to_tag" />
                  </Typography>
                  {/* <Autocomplete
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    multiple
                    id="fixed-tags-demo"
                    value={valProuctToTag}
                    onChange={(event, newValue) => {
                      setValProuctToTag([...newValue]);
                      handleChangeStatusPublication2();
                    }}
                    options={prouctToTag}
                    getOptionLabel={(option) => option.title}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add #${inputValue}`,
                        });
                      }

                      return filtered;
                    }}
                    sx={{
                      '.MuiAutocomplete-tag': { borderRadius: '20px', fontSize: '14px' },
                      '.MuiOutlinedInput-root': { background: '#fff' },
                      '.MuiOutlinedInput-input': { background: '#fff' },
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          deleteIcon={<CancelIcon sx={{ fill: '#fff' }} />}
                          label={option.title.replace('Add ', '')}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Enter a product to tag" />
                    )}
                  /> */}
                  <TextField
                    fullWidth
                    name="productsToTag"
                    value={formik.values.productsToTag}
                    onChange={(e) => {
                      formik.setFieldValue('productsToTag', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}
                    placeholder="Enter a product to tag"
                  />
                </Grid>
              </Grid>
              <Typography
                mt={2}
                mb={2}
                sx={{
                  fontSize: '16px',
                  lineHeight: '160%',
                  letterSpacing: '0.16px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  mb: '10px',
                }}>
                <FormattedMessage id="tasks_location" />
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Input
                    name="location"
                    value={formik.values.location}
                    onChange={(e) => {
                      formik.setFieldValue('location', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    disableUnderline
                    sx={{
                      border: '1px solid #c0c0c0',
                      borderRadius: '10px',
                      p: '10px',
                      width: '100%',
                      background: '#fff',
                    }}
                    id="input-with-icon-adornment"
                    // startAdornment={
                    //     <InputAdornment position="start">
                    //         <SearchIcon />
                    //     </InputAdornment>
                    // }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '16px !important',
                        lineHeight: '160% !important',
                        letterSpacing: '0.16px !important',
                        color: 'rgba(0, 0, 0, 0.87) !important',
                        fontFamily: 'Gilroy !important',
                      },
                    }}
                    control={
                      <Checkbox
                        value={formik.values.toBeBoosted}
                        onChange={() => {
                          formik.setFieldValue('toBeBoosted', !formik.values.toBeBoosted);
                          handleChangeStatusPublication2();
                        }}
                      />
                    }
                    label={<FormattedMessage id="tasks_publication_to_be_boosted" />}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '10px' }}>
          <Accordion
            expanded={activeDesign}
            onChange={() => setActiveDesign(!activeDesign)}
            sx={{
              borderBottomLeftRadius: '10px !important',
              borderBottomRightRadius: '10px !important',
              borderTopLeftRadius: '10px !important',
              borderTopRightRadius: '10px !important',
            }}>
            <AccordionSummary
              sx={{
                '.MuiAccordionSummary-content': { margin: '0 !important' },
                cursor: 'default !important',
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '160%',
                  letterSpacing: '0.16px',
                  color: '#000',
                  fontFamily: 'Gilroy-SemiBold',
                }}>
                <FormattedMessage id="tasks_design" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_comments_on_design" />
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    name="commentsOnDesignOpen"
                    value={formik.values.commentsOnDesignOpen}
                    onChange={(e) => {
                      formik.setFieldValue('commentsOnDesignOpen', e.target.value);
                      handleChangeStatusPublication2();
                    }}
                    sx={{ '.MuiOutlinedInput-input': { background: '#fff' } }}
                    placeholder={intl.formatMessage({ id: 'tasks_write_down_the_concept' })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      letterSpacing: '0.16px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      mb: '10px',
                    }}>
                    <FormattedMessage id="tasks_designs" />
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <div {...getRootProps({ className: 'dropzon dropzone' })}>
                        <input {...getInputProps()} />
                        <BackupIcon sx={{ width: '20px', textAlign: 'center' }} />
                      </div>
                    </Grid>
                    <Grid item container xs={12} sm={8} spacing={1}>
                      {designsImg.length > 0 &&
                        designsImg.map((i, index) => (
                          <Grid item xs={4}>
                            <Box sx={{ position: 'relative' }}>
                              <CardMedia
                                sx={{ objectFit: 'cover', borderRadius: '5px', cursor: 'pointer' }}
                                component="img"
                                onClick={() => openImageViewer(index)}
                                width="100%"
                                height="100%"
                                image={i.url}
                                alt="alt image"
                              />
                              <Box
                                onClick={() => deleteImg(i.url)}
                                sx={{
                                  position: 'absolute',
                                  top: '1px',
                                  right: '1px',
                                  cursor: 'pointer',
                                }}>
                                <CancelIcon sx={{ fontSize: '15px' }} />
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                    {isViewerOpen && designsImg.length > 0 && (
                      <Box
                        sx={{
                          position: 'fixed',
                          zIndex: 9999,
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                        }}>
                        <ImageViewer
                          src={designsImg?.map((publication) => publication.url)}
                          currentIndex={currentImage}
                          onClose={closeImageViewer}
                          disableScroll={false}
                          backgroundStyle={{
                            backgroundColor: 'rgba(0,0,0,0.9)',
                          }}
                          closeOnClickOutside={true}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          mt={3}
          p={1}
          sx={{
            boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.05)',
            borderRadius: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'sticky',
            background: '#fff',
            bottom: 10,
            width: '100%',
            zIndex: '111',
          }}>
          {!markAsDoneAndRework || editingMode ? (
            <Grid container spacing={1} alignItems="center" justifyContent={'flex-end'}>
              <Grid item xs={6} sm={3} md={2}>
                <Button
                  type="submit"
                  sx={{
                    background: '#EE9A8F',
                    borderRadius: '4px',
                    color: '#fff',
                    width: '100%',
                    '&:hover': { background: '#EE9A8F' },
                    fontFamily: 'Gilroy-Medium',
                    letterSpacing: '0.46px',
                  }}>
                  {loadingChangePublicationWeeklyCalendarTasks ? (
                    <CircularProgress sx={{ color: '#fff' }} />
                  ) : (
                    'Save publication'
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={3} md={2} sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Button
                  sx={{
                    background: 'rgba(238, 154, 143, 0.05)',
                    borderRadius: '4px',
                    color: '#EE9A8F',
                    width: '100%',
                    '&:hover': { background: '#EE9A8F' },
                    border: '1px solid #EE9A8F',
                    fontFamily: 'Gilroy-Medium',
                    letterSpacing: '0.46px',
                  }}
                  onClick={() => setShowPublicationsList(true)}>
                  <FormattedMessage id="sign_emailconf_back" />
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={1}
              sx={{
                width: { xs: '100%', sm: '50%' },
              }}
              alignItems="center"
              justifyContent={'flex-end'}>
              <Grid item xs={6} sm={4}>
                <Button
                  disabled={
                    selectedTask.completion !== 'To do' ? true : false || disableMarkAsDoneTemp
                  }
                  sx={{
                    background: '#EE9A8F',
                    borderRadius: '4px',
                    color: '#fff',
                    width: '100%',
                    '&:hover': { background: '#EE9A8F' },
                    fontFamily: 'Gilroy-Medium',
                    letterSpacing: '0.46px',
                  }}
                  onClick={() => {
                    handleChangeCompletionStatusMarkAsDone();
                  }}>
                  {loadingChangeTaskCompletitionStatus ? (
                    <CircularProgress sx={{ color: '#fff' }} />
                  ) : (
                    <FormattedMessage id="tasks_mark_task_as_done" />
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControlLabel
                  disabled={selectedTask.completion === 'Rework required' ? true : false}
                  onChange={() => {
                    handleChangeCompletionStatus();
                  }}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '16px !important',
                      lineHeight: '160% !important',
                      letterSpacing: '0.16px !important',
                      color: 'rgba(0, 0, 0, 0.87) !important',
                      fontFamily: 'Gilroy !important',
                    },
                  }}
                  control={
                    <Checkbox
                      checked={
                        selectedTask?.completion === 'Rework required' ||
                        completionStatus === 'Rework required'
                      }
                    />
                  }
                  label="Request rework"
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    background: 'rgba(238, 154, 143, 0.05)',
                    borderRadius: '4px',
                    color: '#EE9A8F',
                    width: '100%',
                    '&:hover': { background: '#EE9A8F' },
                    border: '1px solid #EE9A8F',
                    display: { xs: 'block', sm: 'none' },
                    mt: '10px',
                    fontFamily: 'Gilroy-Medium',
                    letterSpacing: '0.46px',
                  }}
                  onClick={() => setShowPublicationsList(true)}>
                  <FormattedMessage id="sign_emailconf_back" />
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default DescriptionPublication;
