import React from 'react';
import ProfileHeader from '../../components/profile/ProfileHeader';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@material-ui/styles';

import { useQuery } from '@apollo/client';
import { FETCH_FORMS, GET_STRATEGIES } from '../../api/queries/onBoard';
import blocksToRender from './blocks/blocks';
import MyInformation from './MyInformation';
import MyStrategy from './my-strategy/MyStrategy';
import { FormattedMessage } from 'react-intl';

const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Gilroy-Regular',
  fontSize: '16px',
  color: '#717A90',
  '&.Mui-selected': {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4000000059604645px',
    // color: theme.palette.primary.main,
  },
}));

interface Props {}

const findIfFieldIsCompleted = (object, fieldName) => {
  if (
    (object[fieldName] && object[fieldName] !== undefined && object[fieldName].length > 0) ||
    typeof object[fieldName] == 'number'
  ) {
    return true;
  }

  return false;
  // return !!object.hasOwnProperty(fieldName);
};

const findForm = (arrayOfForms, formName) => {
  const form = arrayOfForms?.find((form) => form.form === formName);
  if (form) return form;
};

const findThePercentageCompleted = (fields, formName, arrayOfForms) => {
  const formFound = findForm(arrayOfForms, formName);
  if (formFound) {
    let numberOfUpdatedFields = 0;
    fields.map((field) => {
      if (findIfFieldIsCompleted(formFound.answers, field.name)) {
        numberOfUpdatedFields = numberOfUpdatedFields + 1;
      }
    });
    if (numberOfUpdatedFields > 0) {
      return ((numberOfUpdatedFields / fields.length) * 100).toFixed(0);
    } else return 0;
  } else {
    return 0;
  }
};

const Profile = (props: Props) => {
  const { data, loading, error } = useQuery(FETCH_FORMS, {});
  const {
    data: dataStrategy,
    loading: loadingStrategy,
    error: errorStrategy,
  } = useQuery(GET_STRATEGIES, {});
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const renderFieldsTab = (step) => {
    switch (step) {
      case 0:
        return (
          <MyInformation
            totalPercentage={totalPercentage}
            checkIfFieldsCompleted={checkIfFieldsCompleted}
            findThePercentageCompleted={findThePercentageCompleted}
            data={data}
            findForm={findForm}
          />
        );
      case 1:
        return <MyStrategy data={dataStrategy} />;
    }
  };
  const checkIfFieldsCompleted = (formName, fields) => {
    console.log('🔥🔥 ~ checkIfFieldsCompleted ~ fields', fields);
    const formFound = findForm(data?.form, formName);

    if (formFound) {
      let updatedStatusFields = fields.map((field) => {
        if (findIfFieldIsCompleted(formFound.answers, field.name)) {
          return { title: field.label, completed: true };
        } else {
          return { title: field.label, completed: false };
        }
      });
      return updatedStatusFields;
    }
    return fields.map((field) => {
      return { title: field.label, completed: false };
    });
  };

  const totalPercentage = React.useMemo(() => {
    let total = 0;
    blocksToRender.map((block) => {
      total = total + Number(findThePercentageCompleted(block.fields, block.name, data?.form));
    });
    const averageCompletion = total / blocksToRender.length;

    return averageCompletion.toFixed(0);
  }, [data?.form]);

  // const [totalSteps, setTotalSteps] = React.useState(11);
  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <ProfileHeader
          userName="Besart Marku1"
          title="profile_my_profile"
          description="profile_complete_your_profile"
        />
      </Grid>
      {/* <Grid item xs={12}>
          <CompletionStatus />
        </Grid> */}

      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange}>
          <StepsTab
            sx={{
              fontFamily: 'Gilroy-Medium !important',
              textTransform: 'capitalize !important',
              minWidth: { md: 240 },
            }}
            label={<FormattedMessage id="profile_my_information" />}
          />
          <StepsTab
            sx={{ fontFamily: 'Gilroy-Medium !important', textTransform: 'capitalize !important' }}
            label={<FormattedMessage id="profile_my_strategy" />}
          />
        </Tabs>
        {/* <Box
          sx={{
            background: '#FFFFFF',
            borderRadius: '10px',
            boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)',
            marginTop: '20px',
          }}>
          <Grid container alignItems="center" sx={{ padding: '10px' }}>
            <Grid item xs={12} md={1}>
              <Typography
                sx={{ fontFamily: 'Gilroy-SemiBold', color: '#252A32', fontSize: '16px' }}>
                {totalPercentage}%
              </Typography>
              <Typography sx={{ fontFamily: 'Gilroy', color: '#F0998D', fontSize: '12px' }}>
                Complete
              </Typography>
            </Grid>
            <Grid item xs={10} md={7} sx={{ marginTop: { xs: '10px', md: '0px' } }}>
              <LinearProgress
                variant="determinate"
                value={+totalPercentage}
                sx={{ height: { xs: '5px', md: '10px' }, borderRadius: '5px' }}
              />
            </Grid>
          </Grid>
        </Box>
        {blocksToRender.map((block) => (
          <CollapseSection
            fields={block.fieldsToRender}
            sectionTitle={block.title.default}
            formDataFromApi={findForm(data?.form, block.name)}
            formName={block.name}
            sectionDescription={block.description.default}
            items={checkIfFieldsCompleted(block.name, block.fields)}
            complitedProgress={Number(
              findThePercentageCompleted(block.fields, block.name, data?.form),
            )}
          />
        ))} */}
        {renderFieldsTab(value)}
      </Grid>
    </Grid>
  );
};

export default Profile;
