import TextField from './TextField';
import MultipleTextField from './MultipleTextField';
import MultipleSelect from './MultipleSelect';
import SingleSelect from './SingleSelect';
import Selector from './GridSelect';
import UploadFile from './UploadFile/UploadFile';
import TagSelector from './TagSelector';
import RangeSelector from './RangeSelector';
import SingleValueSelector from './SingleValueSelector';
import BrandColor from './BrandColor';
import AddMoreFields from './AddMoreFields';

interface RenderProps {
  control: any;
  watch?: () => void;
  activeStep: number;
  handlStep: () => void;
  setValue?: any;
  setActiveStep: (activeStep: number) => void;
  field: {
    name: string;
    label: string;
    description?: string;
    type: string;
    title: string;
    placeholder: string;
    subtitle?: any;
    alert?: any;
    validation: {
      regex: string;
      error_message: string;
    }[];
    labels?: any;
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}

const RenderField = ({
  field,
  control,
  activeStep,
  handlStep,
  setActiveStep,
  watch,
  setValue,
}: RenderProps) => {
  const renderField = (type: String) => {
    switch (type) {
      case 'TEXT_FIELD':
        return (
          <TextField
            field={field}
            textArea={false}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'SINGLE_SELECT':
        return (
          <Selector
            control={control}
            activeStep={activeStep}
            field={field}
            handlStep={handlStep}
            setValue={setValue}
            watch={watch}
          />
        );
      case 'TEXT_AREA':
        return (
          <TextField
            field={field}
            textArea={true}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'MULTIPLE_FIELDS':
        return (
          <MultipleTextField
            field={field}
            select={false}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'MULTIPLE_SELECT_TICKBOXES':
        return (
          <MultipleSelect
            field={field}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'SINGLE_SELECT_TICKBOXES':
        return (
          <SingleSelect
            field={field}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'LOGO':
        return (
          <UploadFile
            field={field}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
            fonts={false}
            watch={watch}
            setValue={setValue}
          />
        );
      case 'UPLOAD_FILES':
        return (
          <UploadFile
            field={field}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
            fonts={true}
            watch={watch}
            setValue={setValue}
          />
        );
      case 'TAG_SELECTOR':
        return (
          <TagSelector
            field={field}
            control={control}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
            watch={watch}
            setValue={setValue}
          />
        );
      case 'RANGE_SELECTOR':
        return (
          <RangeSelector
            field={field}
            control={control}
            watch={watch}
            setValue={setValue}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'SINGLE_VALUE_SELECTOR':
        return (
          <SingleValueSelector
            field={field}
            control={control}
            watch={watch}
            setValue={setValue}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'COLOR_PICKER':
        return (
          <BrandColor
            field={field}
            control={control}
            watch={watch}
            setValue={setValue}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      case 'TAG_SELECTOR_SELECT':
        return (
          <AddMoreFields
            field={field}
            control={control}
            watch={watch}
            setValue={setValue}
            activeStep={activeStep}
            handlStep={handlStep}
            setActiveStep={setActiveStep}
          />
        );
      default:
        break;
    }
  };
  return <>{renderField(field.type)}</>;
};

export default RenderField;
