import React from 'react';
import {
  Box,
  Grid,
  Typography,
  CardMedia,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const Publication = ({
  publication,
  idTask,
  statePublication,
  publicationId,
  setMarkAsDoneAndRework,
}) => {
  const [activePublication, setActivePublication] = React.useState<any>();
  React.useEffect(() => {
    let storageItem = JSON.parse(`${localStorage.getItem('idActive')}`);
    if (publicationId && publicationId == publication.id) {
      setActivePublication(false);
      setMarkAsDoneAndRework(false);
    }
    if (storageItem) {
      const keys = Object.keys(storageItem);
      keys.forEach((item) => {
        if (item == idTask) {
          storageItem[item].forEach((element) => {
            if (element == publication.id) {
              setActivePublication(true);
            }
          });
        }
      });
    }
  }, [statePublication, publicationId]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={3}>
          <Typography
            sx={{
              color: '#333333',
              fontSize: '12px',
              fontFamily: 'Gilroy-Medium',
              textAlign: 'left',
              opacity: 0.9,
            }}>
            {publication.type}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {/* <CardMedia component="img" width="24px" height="24px" image={''} alt="alt" /> */}
        </Grid>
      </Grid>
      <Typography
        mt={1}
        sx={{
          color: '#333333',
          fontSize: '10px',
          fontFamily: 'Gilroy-Medium',
          textAlign: 'left',
        }}>
        {moment(publication.publicationTime).format('MMMM - DD') +
          ',  ' +
          moment(publication.publicationTime).format('YYYY')}
      </Typography>
      {/* <Typography
        mt={1}
        sx={{
          color: '#333333',
          fontSize: '10px',
          fontFamily: 'Gilroy-Medium',
          textAlign: 'left',
          opacity: 0.5,
        }}>
        January 26, 2022
      </Typography> */}
      <Divider sx={{ background: '#E0E0E0', m: '15px 0 5px' }} />
      {activePublication !== true ? (
        <Box sx={{ width: '100%', textAlign: 'left' }}>
          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': { fontSize: '10px' },
            }}
            control={<Radio disabled sx={{ '& .MuiSvgIcon-root': { fontSize: 12 } }} />}
            label={<FormattedMessage id="tasks_pending" />}
          />
        </Box>
      ) : (
        <Box sx={{ width: '100%', textAlign: 'left' }}>
          <RadioGroup defaultValue="active">
            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': { fontSize: '10px' },
              }}
              control={
                <Radio
                  value="active"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                  checkedIcon={<CheckCircleIcon sx={{ fill: '#27AE60' }} />}
                />
              }
              label={<FormattedMessage id="tasks_saved" />}
            />
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};

export default Publication;
