/* eslint-disable import/no-anonymous-default-export */

export default [
  {
    label: 'What is your brand name ?',
    name: 'band_name',
    type: 'TEXT_FIELD',
    title: 'onboarding_fbads_nameofthebrand_question_title',
    placeholder: 'onboarding_fbads_nameofthebrand_question_placeholder',
    alert: 'onboarding_fbads_nameofthebrand_question_alert',
    subtitle: 'onboarding_fbads_nameofthebrand_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_nameofthebrand_help-and-tips_title',
      content: 'onboarding_fbads_nameofthebrand_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'Why do you want to conduct advertising campaigns ?',
    name: 'objectives_with_campaing',
    type: 'SINGLE_SELECT',
    title: 'onboarding_fbads_objectives-with-campaing_question_title',
    subtitle: 'onboarding_fbads_objectives-with-campaing_question_subtitle',
    placeholder: 'onboarding_fbads_objectives-with-campaing_question_placeholder',
    alert: 'onboarding_fbads_objectives-with-campaing_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'FOLLOWERSHIP',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_followership',
          active: false,
        },
        {
          key: 'AWARENESS_AND_REACH',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_awareness-and-reach',
          active: false,
        },
        {
          key: 'WEBSITE_TRAFFIC',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_website-traffic',
          active: false,
        },
        {
          key: 'STORE_TRAFFIC',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_store-traffic',
          active: false,
        },
        {
          key: 'ENGAGEMENT',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_engagement',
          active: false,
        },
        {
          key: 'MESSAGES',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_messages',
          active: false,
        },
        {
          key: 'LEAD_GENERATION',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_lead-generation',
          active: false,
        },
        {
          key: 'APP_INSTALLS',
          value: 'onboarding_fbads_objectives-with-campaing_answer-option_app-installs',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_objectives-with-campaing_help-and-tips_title',
      content: 'onboarding_fbads_objectives-with-campaing_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'How much of a budget do you have to spend on Facebook & Instagram ads?',
    name: 'budget_on_campaign',
    type: 'TEXT_FIELD',
    title: 'onboarding_fbads_budget-on-campaign_question_title',
    placeholder: 'onboarding_fbads_budget-on-campaign_question_placeholder',
    alert: 'onboarding_fbads_budget-on-campaign_question_alert',
    subtitle: 'onboarding_fbads_budget-on-campaign_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_budget-on-campaign_help-and-tips_title',
      content: 'onboarding_fbads_budget-on-campaign_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What product or service category(ies) do you want to push first?',
    name: 'what_to_push_first',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_what-to-push-first_question_title',
    placeholder: 'onboarding_fbads_what-to-push-first_question_placeholder',
    alert: 'onboarding_fbads_what-to-push-first_question_alert',
    subtitle: 'onboarding_fbads_what-to-push-first_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_what-to-push-first_help-and-tips_title',
      content: 'onboarding_fbads_what-to-push-first_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'Breakdown of sales by product/service category',
    name: 'sales_breakdown',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_sales-breakdown_question_title',
    placeholder: 'onboarding_fbads_sales-breakdown_question_placeholder',
    alert: 'onboarding_fbads_sales-breakdown_question_alert',
    subtitle: 'onboarding_fbads_sales-breakdown_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_sales-breakdown_help-and-tips_title',
      content: 'onboarding_fbads_sales-breakdown_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'Breakdown of sales by sales channel',
    name: 'channel_breakdown',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_channel-breakdown_question_title',
    placeholder: 'onboarding_fbads_channel-breakdown_question_placeholder',
    alert: 'onboarding_fbads_channel-breakdown_question_alert',
    subtitle: 'onboarding_fbads_channel-breakdown_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_channel-breakdown_help-and-tips_title',
      content: 'onboarding_fbads_channel-breakdown_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What is your annual income for the last three years?',
    name: 'last_three_years_income',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_last-three-years-income_question_title',
    placeholder: 'onboarding_fbads_last-three-years-income_question_placeholder',
    alert: 'onboarding_fbads_last-three-years-income_question_alert',
    subtitle: 'onboarding_fbads_last-three-years-income_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_last-three-years-income_help-and-tips_title',
      content: 'onboarding_fbads_last-three-years-income_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What is your monthly income for the last 12 months?',
    name: 'last_one_year_income',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_last-one-year-income_question_title',
    placeholder: 'onboarding_fbads_last-one-year-income_question_placeholder',
    alert: 'onboarding_fbads_last-one-year-income_question_alert',
    subtitle: 'onboarding_fbads_last-one-year-income_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_last-one-year-income_help-and-tips_title',
      content: 'onboarding_fbads_last-one-year-income_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What is your average margin?',
    name: 'average_margin',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_average-margin_question_title',
    placeholder: 'onboarding_fbads_average-margin_question_placeholder',
    alert: 'onboarding_fbads_average-margin_question_alert',
    subtitle: 'onboarding_fbads_average-margin_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_average-margin_help-and-tips_title',
      content: 'onboarding_fbads_average-margin_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What is your average total revenue per client?',
    name: 'average_total_revenue',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_average-total-revenue_question_title',
    placeholder: 'onboarding_fbads_average-total-revenue_question_placeholder',
    alert: 'onboarding_fbads_average-total-revenue_question_alert',
    subtitle: 'onboarding_fbads_average-total-revenue_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_average-total-revenue_help-and-tips_title',
      content: 'onboarding_fbads_average-total-revenue_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What is the revenue of the average customer shopping cart ?',
    name: 'average_shopping_cart_revenue',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_average-shopping-cart-revenue_question_title',
    placeholder: 'onboarding_fbads_average-shopping-cart-revenue_question_placeholder',
    alert: 'onboarding_fbads_average-shopping-cart-revenue_question_alert',
    subtitle: 'onboarding_fbads_average-shopping-cart-revenue_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_average-shopping-cart-revenue_help-and-tips_title',
      content: 'onboarding_fbads_average-shopping-cart-revenue_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'How many people visit your website each month ?',
    name: 'visite_per_month_on_website',
    type: 'TEXT_FIELD',
    title: 'onboarding_fbads_visite-per-month-on-website_question_title',
    placeholder: 'onboarding_fbads_visite-per-month-on-website_question_placeholder',
    alert: 'onboarding_fbads_visite-per-month-on-website_question_alert',
    subtitle: 'onboarding_fbads_visite-per-month-on-website_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_visite-per-month-on-website_help-and-tips_title',
      content: 'onboarding_fbads_visite-per-month-on-website_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What are the most visited pages on your website ?',
    name: 'pages_most_visited',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_pages-most-visited_question_title',
    placeholder: 'onboarding_fbads_pages-most-visited_question_placeholder',
    alert: 'onboarding_fbads_pages-most-visited_question_alert',
    subtitle: 'onboarding_fbads_pages-most-visited_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_pages-most-visited_help-and-tips_title',
      content: 'onboarding_fbads_pages-most-visited_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'Where does most of the traffic to your website currently come from?',
    name: 'where_traffic_come_from',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_where-traffic-come-from_question_title',
    placeholder: 'onboarding_fbads_where-traffic-come-from_question_placeholder',
    alert: 'onboarding_fbads_where-traffic-come-from_question_alert',
    subtitle: 'onboarding_fbads_where-traffic-come-from_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_where-traffic-come-from_help-and-tips_title',
      content: 'onboarding_fbads_where-traffic-come-from_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label:
      'Do you have testimonials from your customers and/or situations where your customers use your product/service (text, images, videos)?',
    name: 'testimonials_from_customers',
    type: 'UPLOAD_FILES',
    title: 'onboarding_fbads_testimonials-from-customers_question_title',
    placeholder: 'onboarding_fbads_testimonials-from-customers_question_placeholder',
    alert: 'onboarding_fbads_testimonials-from-customers_question_alert',
    subtitle: 'onboarding_fbads_testimonials-from-customers_question_subtitle',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/testimonals-clients/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_fbads_testimonials-from-customers-additional-text-field_title',
        placeholder:
          'onboarding_fbads_testimonials-from-customers-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_testimonials-from-customers_help-and-tips_title',
      content: 'onboarding_fbads_testimonials-from-customers_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label:
      'If you have already run Google Ads campaigns for your brand, please upload the keyword performance report.',
    name: 'keywords_on_google_ads',
    type: 'UPLOAD_FILES',
    title: 'onboarding_fbads_keywords-on-google-ads_question_title',
    placeholder: 'onboarding_fbads_keywords-on-google-ads_question_placeholder',
    alert: 'onboarding_fbads_keywords-on-google-ads_question_alert',
    subtitle: 'onboarding_fbads_keywords-on-google-ads_question_subtitle',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/keywords-google-ads/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_fbads_keywords-on-google-additional-text-field_title',
        placeholder: 'onboarding_fbads_keywords-on-google-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_keywords-on-google-ads_help-and-tips_title',
      content: 'onboarding_fbads_keywords-on-google-ads_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'Listing client',
    name: 'listing_client',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_fbads_listing-client_question_title',
    placeholder: 'onboarding_fbads_listing-client_question_placeholder',
    subtitle: 'onboarding_fbads_listing-client_question_subtitle',
    alert: 'onboarding_fbads_listing-client_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'Yes',
          value: 'onboarding_fbads_listing-client_answer-option_yes',
          active: false,
        },
        {
          key: 'No',
          value: 'onboarding_fbads_listing-client_answer-option_no',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_what-guarantee_help-and-tips_title',
      content: 'onboarding_fbads_what-guarantee_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What guarantee(s) do you offer to your customers?',
    name: 'what_guarantee',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_what-guarantee_question_title',
    placeholder: 'onboarding_fbads_what-guarantee_question_placeholder',
    alert: 'onboarding_fbads_what-guarantee_question_alert',
    subtitle: 'onboarding_fbads_what-guarantee_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_what-guarantee_help-and-tips_title',
      content: 'onboarding_fbads_what-guarantee_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'What can you "offer" to your prospects to get them to contact you? *',
    name: 'what_offer',
    type: 'UPLOAD_FILES',
    title: 'onboarding_fbads_what-offer_question_title',
    placeholder: 'onboarding_fbads_what-offer_question_placeholder',
    alert: 'onboarding_fbads_what-offer_question_alert',
    subtitle: 'onboarding_fbads_what-offer_question_subtitle',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/what-offer/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_fbads_what-offer-additional-text-field_title',
        placeholder: 'onboarding_fbads_what-offer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_what-offer_help-and-tips_title',
      content: 'onboarding_fbads_what-offer_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label:
      'Have you run Facebook/Instagram ads in the past? What types of ads, for what budgets and for what results?',
    name: 'ads_in_the_past',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_ads-in-the-past_question_title',
    placeholder: 'onboarding_fbads_ads-in-the-past_question_placeholder',
    alert: 'onboarding_fbads_ads-in-the-past_question_alert',
    subtitle: 'onboarding_fbads_ads-in-the-past_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_ads-in-the-past_help-and-tips_title',
      content: 'onboarding_fbads_ads-in-the-past_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label:
      'Have you run Facebook/Instagram ads in the past? What types of ads, for what budgets and for what results?',
    name: 'ads_in_the_past',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_ads-in-the-past_question_title',
    placeholder: 'onboarding_fbads_ads-in-the-past_question_placeholder',
    alert: 'onboarding_fbads_ads-in-the-past_question_alert',
    subtitle: 'onboarding_fbads_ads-in-the-past_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_ads-in-the-past_help-and-tips_title',
      content: 'onboarding_fbads_ads-in-the-past_help-and-tips_subtitle',
      media: {},
    },
  },
  {
    label: 'Do you have any additional comments, information or remarks?',
    name: 'add_comments_and_remarks',
    type: 'TEXT_AREA',
    title: 'onboarding_fbads_add-comments-and-remarks_question_title',
    placeholder: 'onboarding_fbads_add-comments-and-remarks_question_placeholder',
    alert: 'onboarding_fbads_add-comments-and-remarks_question_alert',
    subtitle: 'onboarding_fbads_add-comments-and-remarks_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_fbads_add-comments-and-remarks_help-and-tips_title',
      content: 'onboarding_fbads_add-comments-and-remarks_help-and-tips_subtitle',
      media: {},
    },
  },
];
