import { Redirect, Switch } from 'react-router-dom';

// routes
import BusinessUserRoutes from './MainRoutes';
import FreelancerRoutes from './FreelancerRoutes';
import LoginRoutes from './LoginRoutes';
import PlanRoutes from './PlansRoutes';
import FullScreenRoutes from './FullScreenRoutes';

// project imports
import config from '../config';

// -----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={config.defaultPath} />
      <>
        {/* Routes for plans pages */}
        <PlanRoutes />

        {/* Route for login */}
        <LoginRoutes />

        {/* Routes for inside dashboard pages */}
        <BusinessUserRoutes />
        <FreelancerRoutes />
        <FullScreenRoutes />
      </>
    </Switch>
  );
};

export default Routes;
