import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import ToDoTableDesktop from '../../../components/calendar/to-do/ToDoTableDesktop';
import { MobileView, BrowserView } from 'react-device-detect';
import ToDoTableMobile from '../../../components/calendar/to-do/ToDoTableMobile';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function ToDo({
  calendars,
  loadingMonthlyCalendars,
  handleViewMore,
}: {
  calendars: any;
  loadingMonthlyCalendars: boolean;
  handleViewMore: (arg1: string, arg2: string) => void;
}) {
  console.log('🔥🔥 ~ ToDo ~ calendars', calendars);
  // const monthCalendarsToShow = useMemo(() => {
  //   if (calendars.length) {
  //     const toDoCalendars = calendars[0].monthlyCalendars.filter(
  //       (monthlyCalendar) => monthlyCalendar.conceptionStatus === 'In client review',
  //     );

  //     return toDoCalendars;
  //   }
  // }, [calendars]);

  const classes = useStyles();
  return loadingMonthlyCalendars ? (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center">
      <CircularProgress />
    </Box>
  ) : (
    <div>
      <BrowserView>
        <ToDoTableDesktop monthCalendarsToShow={calendars} handleViewMore={handleViewMore} />
      </BrowserView>
      <MobileView>
        <ToDoTableMobile monthCalendarsToShow={calendars} handleViewMore={handleViewMore} />
      </MobileView>
    </div>
  );
}

export default ToDo;
