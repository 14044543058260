import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogAddEventTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '28px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: '20px',
    paddingBottom: '20px',
    lineHeight: '133.4%',
  },
  dialogAddEventTitleValue: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    mt: '20px',
  },
  dialogAddEventLabel: {
    fontFamily: 'Gilroy',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  dialogAddEventDatePicker: {
    '& > *': {
      width: '100%',
    },
  },
  dialogAddEventSelect: {
    width: '100%',
  },
  dialogTextareaComponent: {
    width: '100%',
    padding: '8px 16px',
    background: '#FFFFFF',
    border: '1px solid #EEEEEE',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  buttonRequest: {
    background: '#EE9A8F',
    borderRadius: '4px',
    color: 'white',
    width: '100%',
  },
  cancelButton: {
    background: 'white',
    border: '1px solid #EE9A8F',
    borderRadius: '4px',
    color: '#EE9A8F',
    width: '100%',
  },
}));

interface DialogAddEventProps {
  open: boolean; //to open and close dialog
  onClose: () => void; //called when dialog closed
  onSuccess: () => void;
}
const DialogAddPublication: React.FC<DialogAddEventProps> = ({ open, onClose, onSuccess }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '24px',
          width: '600px',
        },
      }}>
      <Grid container display="flex" justifyContent="flex-end">
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid
        container
        className={classes.dialogAddEventTitle}
        display="flex"
        justifyContent="center">
        <FormattedMessage id="calendar_monthly_dialog_add_publication_title" />
        <Typography className={classes.dialogAddEventTitleValue}>
          <FormattedMessage id="calendar_monthly_dialog_add_publication_description" />
        </Typography>
      </Grid>
      <Formik
        initialValues={{ componentOnVisual: '', commentsOnCaption: '' }}
        onSubmit={(values) => {
          console.log(values);
        }}>
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ padding: '16px' }}>
              <Grid container display="flex" py={1}>
                <Grid item sm={6} xs={12} pr={1}>
                  <Box mb={2} className={classes.dialogAddEventLabel}>
                    <FormattedMessage id="calendar_monthly_dialog_add_publication_comments" />
                  </Box>
                  <Box>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="lable"
                      minRows={6}
                      name="componentOnVisual"
                      className={classes.dialogTextareaComponent}
                      onChange={handleChange}
                      value={values.componentOnVisual}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12} pr={1}>
                  <Box mb={2} className={classes.dialogAddEventLabel}>
                    <FormattedMessage id="calendar_monthly_dialog_add_publication_comments_caption" />
                  </Box>
                  <Box>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="lable"
                      minRows={6}
                      name="commentsOnCaption"
                      className={classes.dialogTextareaComponent}
                      onChange={handleChange}
                      value={values.commentsOnCaption}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container py={1} px={2} justifyContent="flex-end">
              <Grid item xs={12} md={6} py={1} px={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <Button className={classes.buttonRequest} type="submit">
                      <FormattedMessage id="calendar_monthly_dialog_add_publication_change_request" />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button className={classes.cancelButton}>
                      <FormattedMessage id="calendar_monthly_dialog_add_publication_cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DialogAddPublication;
