import SvgIcon from '@material-ui/core/SvgIcon';

const TypeStory = () => (
  <SvgIcon viewBox="0 0 36 36">
    <svg width="35" height="35" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_1767_5852" fill="white">
        <path d="M10.7194 2.48047C10.5574 1.83976 10.9454 1.18178 11.6004 1.09369C13.1041 0.891456 14.6387 1.0169 16.0975 1.46727C17.9386 2.0357 19.5879 3.09982 20.8646 4.54309C22.1413 5.98636 22.9962 7.75312 23.3357 9.64989C23.6752 11.5466 23.4863 13.5003 22.7895 15.2968C22.0927 17.0933 20.915 18.6635 19.3854 19.8353C17.8557 21.0071 16.0331 21.7354 14.1171 21.9404C12.2012 22.1454 10.2657 21.8192 8.52276 20.9976C7.14183 20.3466 5.92393 19.4045 4.94984 18.2413C4.52554 17.7346 4.68264 16.9871 5.23429 16.6231C5.78593 16.2592 6.52217 16.4188 6.96328 16.9109C7.68356 17.7145 8.55986 18.3693 9.54324 18.8328C10.8889 19.4672 12.3832 19.719 13.8625 19.5608C15.3418 19.4025 16.749 18.8402 17.93 17.9354C19.111 17.0307 20.0203 15.8185 20.5582 14.4314C21.0962 13.0444 21.2421 11.536 20.9799 10.0716C20.7178 8.60713 20.0577 7.24307 19.072 6.12876C18.0863 5.01445 16.813 4.19287 15.3915 3.754C14.3527 3.43328 13.264 3.32598 12.1903 3.43376C11.5327 3.49977 10.8814 3.12117 10.7194 2.48047Z" />
      </mask>
      <path d="M10.7194 2.48047C10.5574 1.83976 10.9454 1.18178 11.6004 1.09369C13.1041 0.891456 14.6387 1.0169 16.0975 1.46727C17.9386 2.0357 19.5879 3.09982 20.8646 4.54309C22.1413 5.98636 22.9962 7.75312 23.3357 9.64989C23.6752 11.5466 23.4863 13.5003 22.7895 15.2968C22.0927 17.0933 20.915 18.6635 19.3854 19.8353C17.8557 21.0071 16.0331 21.7354 14.1171 21.9404C12.2012 22.1454 10.2657 21.8192 8.52276 20.9976C7.14183 20.3466 5.92393 19.4045 4.94984 18.2413C4.52554 17.7346 4.68264 16.9871 5.23429 16.6231C5.78593 16.2592 6.52217 16.4188 6.96328 16.9109C7.68356 17.7145 8.55986 18.3693 9.54324 18.8328C10.8889 19.4672 12.3832 19.719 13.8625 19.5608C15.3418 19.4025 16.749 18.8402 17.93 17.9354C19.111 17.0307 20.0203 15.8185 20.5582 14.4314C21.0962 13.0444 21.2421 11.536 20.9799 10.0716C20.7178 8.60713 20.0577 7.24307 19.072 6.12876C18.0863 5.01445 16.813 4.19287 15.3915 3.754C14.3527 3.43328 13.264 3.32598 12.1903 3.43376C11.5327 3.49977 10.8814 3.12117 10.7194 2.48047Z" stroke="#757575" stroke-width="4" mask="url(#path-1-inside-1_1767_5852)" />
      <mask id="path-2-inside-2_1767_5852" fill="white">
        <path d="M4.64121 15.5845C4.04743 15.8747 3.32379 15.6301 3.10304 15.0072C2.67282 13.7932 2.46962 12.5101 2.50366 11.2225C2.52112 10.5619 3.13378 10.1057 3.78815 10.1981C4.44252 10.2906 4.88866 10.8976 4.89343 11.5585C4.89929 12.3698 5.02693 13.1758 5.27206 13.9492C5.47173 14.5792 5.23498 15.2944 4.64121 15.5845Z" />
      </mask>
      <path d="M4.64121 15.5845C4.04743 15.8747 3.32379 15.6301 3.10304 15.0072C2.67282 13.7932 2.46962 12.5101 2.50366 11.2225C2.52112 10.5619 3.13378 10.1057 3.78815 10.1981C4.44252 10.2906 4.88866 10.8976 4.89343 11.5585C4.89929 12.3698 5.02693 13.1758 5.27206 13.9492C5.47173 14.5792 5.23498 15.2944 4.64121 15.5845Z" stroke="#757575" stroke-width="4" mask="url(#path-2-inside-2_1767_5852)" />
      <mask id="path-3-inside-3_1767_5852" fill="white">
        <path d="M3.97848 9.22731C3.33763 9.06587 2.94258 8.41211 3.17585 7.79377C3.46194 7.03542 3.8349 6.31272 4.28724 5.64016C4.65605 5.09177 5.41779 5.03497 5.92068 5.46376C6.42356 5.89255 6.47489 6.64413 6.12473 7.20461C5.88974 7.58074 5.686 7.97554 5.51558 8.385C5.26164 8.99514 4.61933 9.38875 3.97848 9.22731Z" />
      </mask>
      <path d="M3.97848 9.22731C3.33763 9.06587 2.94258 8.41211 3.17585 7.79377C3.46194 7.03542 3.8349 6.31272 4.28724 5.64016C4.65605 5.09177 5.41779 5.03497 5.92068 5.46376C6.42356 5.89255 6.47489 6.64413 6.12473 7.20461C5.88974 7.58074 5.686 7.97554 5.51558 8.385C5.26164 8.99514 4.61933 9.38875 3.97848 9.22731Z" stroke="#757575" stroke-width="4" mask="url(#path-3-inside-3_1767_5852)" />
      <path d="M13.5 8V16" stroke="#757575" stroke-width="1.88889" stroke-linecap="round" />
      <path d="M9.5 12L17.5 12" stroke="#757575" stroke-width="1.88889" stroke-linecap="round" />
    </svg>

  </SvgIcon>
);

export default TypeStory;
