import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Divider, List, Typography, CircularProgress, Box } from '@material-ui/core';

// project imports
import NavItem from './../NavItem';
import NavCollapse from './../NavCollapse';
import { GenericCardProps } from '../../../../../types/index';
import { GENERATE_CUSTOMER_PORTAL } from 'api/queries/onBoard';
import { useQuery, useLazyQuery } from '@apollo/client';
import BillingIcon from 'assets/icons/Billing.png';
import LogoutIcon from 'assets/icons/Logout.png';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import LanguageSwitcher from 'components/languageSwitcher';

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: '2px',
    marginBottom: '10px',
  },
}));

const checkRoles = (roles) => {
  const arrRolesUser = JSON.parse(`${localStorage.getItem('role')}`);
  if (arrRolesUser) {
    if (roles) {
      // let stateRole = false
      for (const roleUser of arrRolesUser) {
        if (roles.includes(roleUser)) {
          // stateRole = true
          return true;
        }
      }
      return false;
    }
  }
  return false;
};

//-----------------------|| SIDEBAR MENU LIST GROUP ||-----------------------//

export interface NavGroupProps {
  handleLeftDrawerToggle: () => void;

  item: {
    id?: string;
    type?: string;
    icon?: GenericCardProps['iconPrimary'];
    children?: NavGroupProps['item'][];
    title?: React.ReactNode | string;
    caption?: React.ReactNode | string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
  };
}

const NavGroup = ({ item, handleLeftDrawerToggle }: NavGroupProps) => {
  const classes = useStyles();
  const [billingUrl, setbillingUrl] = React.useState(null);
  const [skipQuery, setSkipQuery] = React.useState(true);
  const { logout } = useAuth();

  const { data, loading, error } = useQuery(GENERATE_CUSTOMER_PORTAL, {
    skip: skipQuery,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSkipQuery(true);

      const newWindow = window.open(
        data?.stripe_customer_portal_generate?.url,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;

      // window.open(data?.stripe_customer_portal_generate?.url, '_blank');
    },
  });

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return (
          <NavCollapse
            key={menu.id}
            menu={menu}
            level={1}
            handleLeftDrawerToggle={handleLeftDrawerToggle}
          />
        );
      case 'item':
        return (
          <NavItem
            key={menu.id}
            item={menu}
            level={1}
            handleLeftDrawerToggle={handleLeftDrawerToggle}
          />
        );
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const BillingIconComp = () => {
    return <img src={BillingIcon} />;
  };

  const LogoutIconComp = () => {
    return <img src={LogoutIcon} />;
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          height: { xs: 'calc(100vh - 80px)', sm: '100%' },
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div>
          <List
            subheader={
              item.title && (
                <Typography
                  variant="caption"
                  className={classes.menuCaption}
                  display="block"
                  gutterBottom>
                  {item.title}
                  {item.caption && (
                    <Typography
                      variant="caption"
                      className={classes.subMenuCaption}
                      display="block"
                      gutterBottom>
                      {item.caption}
                    </Typography>
                  )}
                </Typography>
              )
            }>
            {items}
          </List>
          {/* group divider */}
          {/* <Box sx={{ ml: '20px', display: { xs: 'none', sm: 'block' } }}>
            <LanguageSwitcher />
          </Box> */}
        </div>
        <List>
          <Divider className={classes.menuDivider} />
          {loading ? (
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            checkRoles(['BUSINESS_USER']) && (
              <div
                onClick={() => {
                  setSkipQuery(false);
                }}>
                <NavItem
                  item={{
                    id: 'billing',
                    title: <FormattedMessage id="menu_item_billing" />,
                    type: 'item',
                    // url: '/profile',
                    external: 'true',
                    icon: BillingIconComp,

                    breadcrumbs: false,
                  }}
                  level={1}
                />
              </div>
            )
          )}
          <div onClick={() => handleLogout()}>
            <NavItem
              item={{
                id: 'logout',
                title: <FormattedMessage id="menu_item_logout" />,

                type: 'item',
                // url: '/profile',
                external: 'true',
                icon: LogoutIconComp,

                breadcrumbs: false,
              }}
              level={1}
            />
          </div>
        </List>
      </Box>
    </React.Fragment>
  );
};

export default NavGroup;
