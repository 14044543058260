import React from 'react';
import { CircularProgress, Box, MenuItem, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { withStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import TypePost from 'assets/images/icons/typePost';
import TypeStory from 'assets/images/icons/typeStory';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { EDIT_SPECIFIC_CONCEPT } from '../../../api/mutations/calendar';
import { GET_MONTHLY_CALENDARS } from '../../../api/queries/calendar/monthly';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  FETCH_USER_TASKS_WITH_FILTERS,
  FETCH_USER_TASKS_WITH_NAME_BRAND,
  GET_MONTHLY_CALENDARS_ID,
} from 'api/queries/tasks';
interface PropsPublication {
  publication: any;
  calendarId?: string;
  shouldFieldsBeDisabled?: boolean;
}
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '10px',
      padding: '10px',
    },

    input: {
      padding: '3px',
      fontSize: '16px',
    },
    adornedStart: {
      paddingLeft: '10px',
    },
  }),
)(InputBase);
const Publication = ({ publication, calendarId, shouldFieldsBeDisabled }: PropsPublication) => {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState<any>({
    publicationTime: null,
    type: null,
    category: null,
    concept: null,
  });

  const [editConcept, { loading: loadingEditConcept, error }] = useMutation(EDIT_SPECIFIC_CONCEPT, {
    onCompleted: (data) => {
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Publication was edited successfully',
        variant: 'alert',
        alertSeverity: 'success',
        autoHideDuration: '6000',
      });
    },
    onError: (error) => {
      const message = JSON.parse(error?.message);
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Error: ' + message?.content,
        variant: 'alert',
        alertSeverity: 'error',
        autoHideDuration: '6000',
      });
    },
    refetchQueries: [{ query: GET_MONTHLY_CALENDARS_ID, variables: { id: calendarId } }],
  });

  const handleCalendarChange = (value) => {
    console.log('🔥🔥 ~ handleCalendarChange ~ value', value);
    setValues({
      ...values,
      publicationTime: value,
    });
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });

    if (name !== 'concept')
      editConcept({
        variables: {
          id: publication.id,
          conceptionStatus: 'In client review',
          ...values,
          [name]: value,
        },
      });
  };
  const handleOnBlur = () => {
    editConcept({
      variables: {
        id: publication.id,
        conceptionStatus: 'In client review',
        ...values,
      },
    });
  };

  React.useEffect(() => {
    setValues({
      // publicationTime: moment(publication.publicationTime).format('DD/MM/YYYY hh:mm'),
      publicationTime: publication.publicationTime,
      type: publication.type,
      category: publication.category,
      concept: publication.concept,
    });
  }, []);
  return (
    <Box>
      {publication && (
        <Grid mt={2} container>
          <Grid item sx={{ width: '20%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                disabled={shouldFieldsBeDisabled}
                inputFormat="dd-MM-yyyy hh:mm"
                value={moment(values.publicationTime)}
                // onChange={(newValue) => handleCalendarChange(moment(newValue))}
                onChange={(newValue) => handleChange('publicationTime', moment(newValue))}
                // onClose={() => {
                //   setTimeout(() => {
                //     handleChange('publicationTime', values.publicationTime);
                //   }, 300);
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '90%', '.MuiFormHelperText-root': { display: 'none' } }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sx={{ width: '20%' }}>
            <MuiSelect
              disabled={shouldFieldsBeDisabled}
              value={values.type}
              onChange={(e) => handleChange('type', e.target.value)}
              variant="outlined"
              input={<BootstrapInput sx={{ textAlign: 'center' }} />}>
              <MenuItem value={'Story'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TypeStory />
                  <Box>&nbsp;&nbsp;Story </Box>
                </Box>
              </MenuItem>
              <MenuItem value={'Post'}>
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TypePost />
                  <Box>&nbsp;&nbsp;Post </Box>
                </Box>
              </MenuItem>
            </MuiSelect>
          </Grid>
          <Grid item sx={{ width: '20%' }}>
            <TextField
              disabled={shouldFieldsBeDisabled}
              value={values.category}
              onChange={(e) => handleChange('category', e.target.value)}
              onBlur={() => handleOnBlur()}
              sx={{ width: '100%' }}
              variant="outlined"
            />
          </Grid>
          <Grid item sx={{ width: '40%' }}>
            <TextField
              disabled={shouldFieldsBeDisabled}
              value={values.concept}
              onChange={(e) => handleChange('concept', e.target.value)}
              onBlur={() => handleOnBlur()}
              sx={{ width: '100%' }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Publication;
