/* eslint-disable import/no-anonymous-default-export */
export default [
  //Section - Profile
  {
    label: 'Profile Picture',
    name: 'profile_picture',
    type: 'LOGO',
    skippable: true,
    title: 'onboarding_collab_upload-logo-influencers_question_title',
    subtitle: 'onboarding_collab_upload-logo-influencers_question_subtitle',
    placeholder: 'onboarding_collab_upload-logo-influencers_question_placeholder',
    alert: 'onboarding_collab_upload-logo-influencers_question_alert',
    validation: [
      {
        regex: '',
        error_message: '',
      },
    ],
    constraints: {
      s3_upload_path: '/assets/profile_picture/',
      cropping: {
        isAllowed: true,
        width_factor: 1,
        height_factor: 1,
      },
      allowed_extensions: [
        {
          mime_type: 'image/png',
          extension: '.png',
        },
        {
          mime_type: 'image/jpe',
          extension: '.jpe',
        },
        {
          mime_type: 'image/jpg',
          extension: '.jpg',
        },
        {
          mime_type: 'image/jpeg',
          extension: '.jpeg',
        },
        // {
        //   mime_type: 'image/svg+xml',
        //   extension: '.svg',
        // },
      ],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_collab_upload-logo-influencers_answer-additional-text-field_title',
        placeholder:
          'onboarding_collab_upload-logo-influencers_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_upload-logo-influencers_help-and-tips_title',
      content: 'onboarding_collab_upload-logo-influencers_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Profile cover',
    name: 'profile_cover',
    type: 'LOGO',
    skippable: true,
    title: 'onboarding_collab_upload-cover_question_title',
    subtitle: 'onboarding_collab_upload-cover_question_subtitle',
    placeholder: 'onboarding_collab_upload-cover_question_placeholder',
    alert: 'onboarding_collab_upload-cover_question_alert',
    validation: [
      {
        regex: '',
        error_message: '',
      },
    ],
    constraints: {
      s3_upload_path: '/assets/profile_cover/',
      cropping: {
        isAllowed: false,
        width_factor: 16,
        height_factor: 9,
      },
      allowed_extensions: [
        {
          mime_type: 'image/png',
          extension: '.png',
        },
        {
          mime_type: 'image/jpe',
          extension: '.jpe',
        },
        {
          mime_type: 'image/jpg',
          extension: '.jpg',
        },
        {
          mime_type: 'image/jpeg',
          extension: '.jpeg',
        },
        // {
        //   mime_type: 'image/svg+xml',
        //   extension: '.svg',
        // },
      ],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_collab_upload-cover_answer-additional-text-field_title',
        placeholder: 'onboarding_collab_upload-cover_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_upload-cover_help-and-tips_title',
      content: 'onboarding_collab_upload-cover_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Profile description',
    name: 'profile_description',
    type: 'TEXT_AREA',
    title: 'onboarding_collab_describe-brand-to-influencers_question_title',
    subtitle: 'onboarding_collab_describe-brand-to-influencers_question_subtitle',
    placeholder: 'onboarding_collab_describe-brand-to-influencers_question_placeholder',
    alert: 'onboarding_collab_describe-brand-to-influencers_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_describe-brand-to-influencers_help-and-tips_title',
      content: 'onboarding_collab_describe-brand-to-influencers_help-and-tips_content',
      media: {},
    },
  },

  //Section - Type of influencers

  {
    label: 'Note on target influencers',
    name: 'target_influencers_note',
    type: 'TEXT_AREA',
    skippable: true,
    title: 'onboarding_collab_want-matched-other-influencers_question_title',
    placeholder: 'onboarding_collab_want-matched-other-influencers_question_placeholder',
    subtitle: 'onboarding_collab_want-matched-other-influencers_question_subtitle',
    alert: 'onboarding_collab_want-matched-other-influencers_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_want-matched-other-influencers_help-and-tips_title',
      content: 'onboarding_collab_want-matched-other-influencers_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Special characteristics of influencers',
    name: 'special_characteristics_of_influencers',
    type: 'TEXT_FIELD',
    skippable: true,
    title: 'onboarding_collab_particular-characteristic-influencers-audience_question_title',
    subtitle: 'onboarding_collab_particular-characteristic-influencers-audience_question_subtitle',
    placeholder:
      'onboarding_collab_particular-characteristic-influencers-audience_question_placeholder',
    alert: 'onboarding_collab_particular-characteristic-influencers-audience_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_particular-characteristic-influencers-audience_help-and-tips_title',
      content:
        'onboarding_collab_particular-characteristic-influencers-audience_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Need to validate influencers first?',
    name: 'influencer_validation',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_collab_validate-influencer-profiles-before_question_title',
    placeholder: 'onboarding_collab_validate-influencer-profiles-before_question_placeholder',
    subtitle: 'onboarding_collab_validate-influencer-profiles-before_question_subtitle',
    alert: 'onboarding_collab_validate-influencer-profiles-before_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'Yes',
          value: 'onboarding_collab_validate-influencer-profiles-before_answer-option_yes',
          active: false,
        },
        {
          key: 'No',
          value: 'onboarding_collab_validate-influencer-profiles-before_answer-option_no',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_validate-influencer-profiles-before_help-and-tips_title',
      content: 'onboarding_collab_validate-influencer-profiles-before_help-and-tips_content',
      media: {},
    },
  },

  //Section - Type of collaboration

  {
    label: 'Allow contests ?',
    name: 'allow_contests',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_collab_influencers-contests_question_title',
    subtitle: 'onboarding_collab_influencers-contests_question_subtitle',
    placeholder: 'onboarding_collab_influencers-contests_question_placeholder',
    alert: 'onboarding_collab_influencers-contests_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'Yes',
          value: 'onboarding_collab_influencers-contests_answer-option_yes',
          active: false,
        },
        {
          key: 'No',
          value: 'onboarding_collab_influencers-contests_answer-option_no',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_influencers-contests_help-and-tips_title',
      content: 'onboarding_collab_influencers-contests_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Collaboration - Number of posts',
    name: 'collaboration_number_of_posts',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_collab_number-of-posts_question_title',
    subtitle: 'onboarding_collab_number-of-posts_question_subtitle',
    alert: 'onboarding_collab_number-of-posts_question_alert',
    placeholder: 'onboarding_collab_number-of-posts_question_placeholder',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'ONE_POST',
          value: 'onboarding_collab_number-of-posts_answer-option_one',
          active: false,
        },
        {
          key: 'TWO_POST',
          value: 'onboarding_collab_number-of-posts_answer-option_two',
          active: false,
        },
        {
          key: 'THREE_POST',
          value: 'onboarding_collab_number-of-posts_answer-option_three',
          active: false,
        },
        {
          key: 'FOUR_POST',
          value: 'onboarding_collab_number-of-posts_answer-option_four',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_number-of-posts_help-and-tips_title',
      content: 'onboarding_collab_number-of-posts_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Collaboration - Number of stories',
    name: 'collaboration_number_of_stories',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_collab_how-many-stories-from-influencers_question_title',
    subtitle: 'onboarding_collab_how-many-stories-from-influencers_question_subtitle',
    alert: 'onboarding_collab_how-many-stories-from-influencers_question_alert',
    placeholder: 'onboarding_collab_how-many-stories-from-influencers_question_placeholder',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'ONE_STORY',
          value: 'onboarding_collab_how-many-stories-from-influencers_answer-option_one',
          active: false,
        },
        {
          key: 'TWO_STORIES',
          value: 'onboarding_collab_how-many-stories-from-influencers_answer-option_two',
          active: false,
        },
        {
          key: 'THREE_STORIES',
          value: 'onboarding_collab_how-many-stories-from-influencers_answer-option_three',
          active: false,
        },
        {
          key: 'FOUR_STORIES',
          value: 'onboarding_collab_how-many-stories-from-influencers_answer-option_four',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_how-many-stories-from-influencers_help-and-tips_title',
      content: 'onboarding_collab_how-many-stories-from-influencers_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Pictures for repost',
    name: 'pictures_for_repost',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_collab_influencers-pictures-repost_question_title',
    subtitle: 'onboarding_collab_influencers-pictures-repost_question_subtitle',
    placeholder: 'onboarding_collab_influencers-pictures-repost_question_placeholder',
    alert: 'onboarding_collab_influencers-pictures-repost_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'Yes',
          value: 'onboarding_collab_influencers-pictures-repost_answer-option_yes',
          active: false,
        },
        {
          key: 'No',
          value: 'onboarding_collab_influencers-pictures-repost_answer-option_no',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_influencers-pictures-repost_help-and-tips_title',
      content: 'onboarding_collab_influencers-pictures-repost_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Particular aspect to highlight',
    name: 'particular_aspect_to_highlight',
    type: 'TEXT_FIELD',
    skippable: true,
    title: 'onboarding_collab_particular-aspect-to-influencers-highlight_question_title',
    subtitle: 'onboarding_collab_particular-aspect-to-influencers-highlight_question_subtitle',
    placeholder:
      'onboarding_collab_particular-aspect-to-influencers-highlight_question_placeholder',
    alert: 'onboarding_collab_particular-aspect-to-influencers-highlight_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_particular-aspect-to-influencers-highlight_help-and-tips_title',
      content: 'onboarding_collab_particular-aspect-to-influencers-highlight_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Collaboration guidelines',
    name: 'collaboration_guidelines',
    type: 'TEXT_AREA',
    skippable: true,
    title: 'onboarding_collab_influencers-specific-guidelines_question_title',
    subtitle: 'onboarding_collab_influencers-specific-guidelines_question_subtitle',
    placeholder: 'onboarding_collab_influencers-specific-guidelines_question_placeholder',
    alert: 'onboarding_collab_influencers-specific-guidelines_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_influencers-specific-guidelines_help-and-tips_title',
      content: 'onboarding_collab_influencers-specific-guidelines_help-and-tips_content',
      media: {},
    },
  },

  //Section - Reward

  {
    label: 'Collaboration gift',
    name: 'collaboration_gift',
    type: 'TEXT_AREA',
    title: 'onboarding_collab_what-to-offer-to-influencers_question_title',
    subtitle: 'onboarding_collab_what-to-offer-to-influencers_question_subtitle',
    placeholder: 'onboarding_collab_what-to-offer-to-influencers_question_placeholder',
    alert: 'onboarding_collab_what-to-offer-to-influencers_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_what-to-offer-to-influencers_help-and-tips_title',
      content: 'onboarding_collab_what-to-offer-to-influencers_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Collaboration gift link',
    name: 'collaboration_gift_link',
    type: 'TEXT_FIELD',
    title: 'onboarding_collab_where-influencers-see-products_question_title',
    subtitle: 'onboarding_collab_where-influencers-see-products_question_subtitle',
    placeholder: 'onboarding_collab_where-influencers-see-products_question_placeholder',
    alert: 'onboarding_collab_where-influencers-see-products_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_where-influencers-see-products_help-and-tips_title',
      content: 'onboarding_collab_where-influencers-see-products_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Collaboration - Gift value',
    name: 'collaboration_gift_value',
    type: 'TEXT_FIELD',
    title: 'onboarding_collab_value-of-gift_question_title',
    subtitle: 'onboarding_collab_value-of-gift_question_subtitle',
    placeholder: 'onboarding_collab_value-of-gift_question_placeholder',
    alert: 'onboarding_collab_value-of-gift_question_alert',
    validation: [
      {
        regex: '',
        error_message: '',
      },
    ],
    constraints: {},
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_value-of-gift_help-and-tips_title',
      content: 'onboarding_collab_value-of-gift_help-and-tips_content',
      media: {},
    },
  },
  //Section - Logistics

  {
    label: 'Gift reception method',
    name: 'gift_reception_method',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_collab_how-influencers-receiving-gift_question_title',
    placeholder: 'onboarding_collab_how-influencers-receiving-gift_question_placeholder',
    alert: 'onboarding_collab_how-influencers-receiving-gift_question_alert',
    subtitle: 'onboarding_collab_how-influencers-receiving-gift_question_subtitle',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'package',
          value: 'onboarding_collab_how-influencers-receiving-gift_answer-option_packages',
          active: false,
        },
        {
          key: 'physical store',
          value: 'onboarding_collab_how-influencers-receiving-gift_answer-option_physical-store',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_how-influencers-receiving-gift_help-and-tips_title',
      content: 'onboarding_collab_how-influencers-receiving-gift_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Physical store address',
    name: 'physical_store_address',
    type: 'TEXT_FIELD',
    skippable: true,
    title: 'onboarding_collab_address-of-store_question_title',
    subtitle: 'onboarding_collab_address-of-store_question_subtitle',
    placeholder: 'onboarding_collab_address-of-store_question_placeholder',
    alert: 'onboarding_collab_address-of-store_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_address-of-store_help-and-tips_title',
      content: 'onboarding_collab_address-of-store_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Physical store availability',
    name: 'physical_store_availability',
    type: 'TEXT_AREA',
    skippable: true,
    title: 'onboarding_collab_when-influencers-come_question_title',
    subtitle: 'onboarding_collab_when-influencers-come_question_subtitle',
    placeholder: 'onboarding_collab_when-influencers-come_question_placeholder',
    alert: 'onboarding_collab_when-influencers-come_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_when-influencers-come_help-and-tips_title',
      content: 'onboarding_collab_when-influencers-come_help-and-tips_content',
      media: {},
    },
  },

  //ADD In the store, how can influencers get out with their gift ?
  {
    label: 'Physical store reception',
    name: 'physical_store_reception',
    type: 'MULTIPLE_SELECT_TICKBOXES',
    skippable: true,
    title: 'onboarding_collab_get-out-with-gift_question_title',
    subtitle: 'onboarding_collab_get-out-with-gift_question_subtitle',
    placeholder: 'onboarding_collab_get-out-with-gift_question_placeholder',
    alert: 'onboarding_collab_get-out-with-gift_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'With vouchers',
          value: 'onboarding_collab_get-out-with-gift_answer-option_vouchers',
          active: false,
        },
        {
          key: 'Without voucher',
          value: 'onboarding_collab_get-out-with-gift_answer-option_when-coming',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_get-out-with-gift_help-and-tips_title',
      content: 'onboarding_collab_get-out-with-gift_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Physical store extra information',
    name: 'physical_store_extra_information',
    type: 'TEXT_AREA',
    skippable: true,
    title: 'onboarding_collab_physical-store-anything-else_question_title',
    subtitle: 'onboarding_collab_physical-store-anything-else_question_subtitle',
    placeholder: 'onboarding_collab_physical-store-anything-else_question_placeholder',
    alert: 'onboarding_collab_physical-store-anything-else_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_physical-store-anything-else_help-and-tips_title',
      content: 'onboarding_collab_physical-store-anything-else_help-and-tips_content',
      media: {},
    },
  },
  //ADD In the store, How do you want to proceed ?

  {
    label: 'Package reception',
    name: 'package_reception',
    type: 'MULTIPLE_SELECT_TICKBOXES',
    skippable: true,
    title: 'onboarding_collab_how-to-proceed_question_title',
    subtitle: 'onboarding_collab_how-to-proceed_question_subtitle',
    placeholder: 'onboarding_collab_how-to-proceed_question_placeholder',
    alert: 'onboarding_collab_how-to-proceed_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'With promocode',
          value: 'onboarding_collab_how-to-proceed_answer-option_promo-codes',
          active: false,
        },
        {
          key: 'Without promocode',
          value: 'onboarding_collab_how-to-proceed_answer-option_tell-me',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_how-to-proceed_help-and-tips_title',
      content: 'onboarding_collab_how-to-proceed_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Package extra information',
    name: 'package_extra_information',
    type: 'TEXT_AREA',
    skippable: true,
    title: 'onboarding_collab_packages-anything-else_question_title',
    subtitle: 'onboarding_collab_packages-anything-else_question_subtitle',
    placeholder: 'onboarding_collab_packages-anything-else_question_placeholder',
    alert: 'onboarding_collab_packages-anything-else_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_collab_packages-anything-else_help-and-tips_title',
      content: 'onboarding_collab_packages-anything-else_help-and-tips_content',
      media: {},
    },
  },
];
