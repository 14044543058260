import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Button from '../../components/button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Stack from '@material-ui/core/Stack';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '24px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  sectionTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '18px',
    lineHeight: '29px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: '#252A32',
  },
  sectionDescription: {
    fontFamily: 'Gilroy',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#717A90',
    width: '70%',
  },
  fieldName: {
    fontFamily: 'Gilroy',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
  },
  completeButton: {
    letterSpacing: '0em',
    textAlign: 'left',
    fontFamily: 'Gilroy-Medium',
    fontSize: '14px',
    lineHeight: '16px',
    textDecorationLine: 'underline',
    color: '#F0998D',
    cursor: 'pointer',
  },
  completedText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#F0998D',
  },
  fieldGridItem: {
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: '10px',
    minHeight: '75px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  leftsmall: {
    left: '12px',
  },
  leftmedium: {
    left: '9px',
  },
  leftbig: {
    left: '5px',
  },
}));
interface Props {
  sectionTitle: string;
  sectionDescription: string;
  items: any;
  complitedProgress: number;
  formDataFromApi: any;
  formName: string;
  fields: any;
}

const CollapseSection = ({
  sectionTitle,
  sectionDescription,
  items,
  complitedProgress,
  formDataFromApi,
  formName,
  fields,
}: Props) => {
  const classes = useStyles();
  let history = useHistory();

  const [activeStep, setActiveStep] = React.useState(false);
  const handleActive = () => {
    setActiveStep(!activeStep);
  };

  const handleClick = () => {
    if (formDataFromApi) {
      history.push({
        pathname: `/complete-form/${formDataFromApi.form}/${formDataFromApi.id}`,
        state: { answers: formDataFromApi?.answers, fields: fields },
      });
    } else {
      history.push({
        pathname: `/complete-form/${formName}`,
        state: { fields: fields },
      });
    }
  };
  const handleClickStep = (step) => {
    if (formDataFromApi) {
      history.push({
        pathname: `/complete-form/${formDataFromApi.form}/${formDataFromApi.id}`,
        state: { answers: formDataFromApi?.answers, step: step, fields: fields },
      });
    } else {
      history.push({
        pathname: `/complete-form/${formName}`,
        state: { step: step, fields: fields },
      });
    }
  };

  return (
    <div className={classes.root}>
      <Accordion onChange={handleActive}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Grid
            onClick={handleActive}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottomLeftRadius: '10px !important' }}>
            <Grid item container spacing={2} xs={12} md={10}>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>
                  <FormattedMessage id={sectionTitle} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.sectionDescription}>
                  <FormattedMessage id={sectionDescription} />
                </Typography>
              </Grid>
            </Grid>
            {activeStep ? (
              <Grid item sx={{ marginTop: { xs: '0px', md: '-10px' } }}>
                <Button
                  titleBtn={<FormattedMessage id="profile_complete" />}
                  colorBtn={'white'}
                  onClick={() => handleClick()}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item sx={{ marginTop: { xs: '0px', md: '-10px' }, marginRight: '15px' }}>
              <Box
                sx={{
                  position: 'relative',
                  border: '3px solid rgb(249, 216, 211)',
                  borderRadius: '50%',
                  width: '45px',
                  height: '45px',
                  marginTop: '10px',
                }}>
                <CircularProgress
                  variant="determinate"
                  value={complitedProgress}
                  size={45}
                  sx={{ margin: '-3px 0px 0px -2px' }}
                />
                <Box
                  className={
                    complitedProgress == 100
                      ? classes.leftbig
                      : complitedProgress > 10 && complitedProgress !== 100
                      ? classes.leftmedium
                      : classes.leftsmall
                  }
                  sx={{
                    display: 'flex',
                    position: 'absolute',
                    top: '2px',
                    color: '#0F2851',
                  }}>
                  <Typography sx={{ fontSize: '14px', fontFamily: 'Gilroy', lineHeight: '266%' }}>
                    {complitedProgress}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              {items.map((item, key) => (
                <Grid item xs={12} md={6}>
                  <Stack
                    className={classes.fieldGridItem}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ margin: '0px' }}
                    spacing={0.5}>
                    <Typography sx={{ margin: '0px' }} className={classes.fieldName}>
                      <FormattedMessage id={item.title} />
                    </Typography>
                    {item.completed === false && (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        onClick={() => handleClickStep(key + 1)}>
                        <Grid item>
                          <Typography
                            sx={{ margin: '0px !important' }}
                            className={classes.completeButton}>
                            <FormattedMessage id="profile_complete" />
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton size="small">
                            <ArrowForwardIosOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Stack>
                    )}
                    {item.completed === true && (
                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={6}
                        spacing={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClickStep(key + 1)}>
                        <Grid item>
                          <IconButton size="small">
                            <CheckIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{ margin: '0px !important' }}
                            className={classes.completedText}>
                            <FormattedMessage id="profile_completed" />
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CollapseSection;
