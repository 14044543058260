/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    label: 'Audience age',
    name: 'audience_age',
    type: 'RANGE_SELECTOR',
    title: 'onboarding_audience-description_audience-age_question_title',
    subtitle: 'onboarding_audience-description_audience-age_question_subtitle',
    placeholder: 'onboarding_audience-description_audience-age_question_placeholder',
    validation: [],
    alert: 'onboarding_audience-description_audience-age_question_alert',
    constraints: {
      limits: {
        min: 0,
        max: 100,
      },
      units: '',
      enum: [
        {
          min: 15,
          max: 24,
          active: false,
        },
        {
          min: 25,
          max: 34,
          active: false,
        },
        {
          min: 35,
          max: 44,
          active: false,
        },
        {
          min: 45,
          max: 60,
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_audience-age_help-and-tips_title',
      content: 'onboarding_audience-description_audience-age_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Audience gender',
    name: 'audience_gender',
    type: 'MULTIPLE_SELECT_TICKBOXES',
    title: 'onboarding_audience-description_audience-gender_question_title',
    subtitle: 'onboarding_audience-description_audience-gender_question_subtitle',
    placeholder: 'onboarding_audience-description_audience-gender_question_placeholder',
    alert: 'onboarding_audience-description_audience-gender_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'female',
          value: 'onboarding_audience-description_audience-gender_answer-option_female',
          active: false,
        },
        {
          key: 'male',
          value: 'onboarding_audience-description_audience-gender_answer-option_male',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_audience-gender_help-and-tips_title',
      content: 'onboarding_audience-description_audience-gender_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Audience language',
    name: 'audience_language',
    type: 'TAG_SELECTOR',
    placeholder: 'onboarding_audience-description_audience-language_question_placeholder',
    title: 'onboarding_audience-description_audience-language_question_title',
    subtitle: 'onboarding_audience-description_audience-language_question_subtitle',
    alert: 'onboarding_audience-description_audience-language_question_alert',
    validation: [],
    constraints: {
      enum: [
        { id: 1, label: 'English' },
        { id: 2, label: 'French' },
        { id: 3, label: 'Dutch' },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_audience-language_help-and-tips_title',
      content: 'onboarding_audience-description_audience-language_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Product/service quality',
    name: 'audience_product_service_quality',
    type: 'TEXT_AREA',
    placeholder: 'onboarding_audience-description_added-value_question_placeholder',
    title: 'onboarding_audience-description_added-value_question_title',
    subtitle: 'onboarding_audience-description_added-value_question_subtitle',
    alert: 'onboarding_audience-description_added-value_question_alert',
    validation: [],
    constraints: {
      enum: [
        { id: 1, label: 'Cheap' },
        { id: 2, label: 'Convenient' },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_added-value_help-and-tips_title',
      content: 'onboarding_audience-description_added-value_help-and-tips_content',
      media: {},
    },
  },

  {
    name: 'audience_locations',
    label: 'Audience locations',
    type: 'TAG_SELECTOR_SELECT',
    placeholder: 'onboarding_audience-description_audience-location_question_placeholder',
    title: 'onboarding_audience-description_audience-location_question_title',
    subtitle: 'onboarding_audience-description_audience-location_question_subtitle',
    alert: 'onboarding_audience-description_audience-location_question_alert',
    labels: [
      'onboarding_audience-description_audience-location_answer-label_country',
      'onboarding_audience-description_audience-location_answer-label_city',
    ],
    validation: [],
    constraints: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_audience-location_help-and-tips_title',
      content: 'onboarding_audience-description_audience-location_help-and-tips_content',
      media: {},
    },
  },

  //TODO: Add places where audience lives

  {
    label: 'Audience interests',
    name: 'audience_interests',
    type: 'TEXT_AREA',
    placeholder: 'onboarding_audience-description_audience-interest_question_placeholder',
    title: 'onboarding_audience-description_audience-interest_question_title',
    subtitle: 'onboarding_audience-description_audience-interest_question_subtitle',
    alert: 'onboarding_audience-description_audience-interest_question_alert',
    validation: [],
    constraints: {
      enum: [
        { id: 1, label: 'Sports' },
        { id: 2, label: 'Dance' },
        { id: 3, label: 'Art' },
        { id: 4, label: 'Technology' },
        { id: 5, label: 'Gamer' },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_audience-interest_help-and-tips_title',
      content: 'onboarding_audience-description_audience-interest_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Audience platform',
    name: 'audience_platform',
    type: 'MULTIPLE_SELECT_TICKBOXES',
    title: 'onboarding_audience-description_audience-platform_question_title',
    placeholder: 'onboarding_audience-description_audience-platform_question_placeholder',
    subtitle: 'onboarding_audience-description_audience-platform_question_subtitle',
    alert: 'onboarding_audience-description_audience-platform_question_alert',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'Instagram ',
          value: 'onboarding_audience-description_audience-platform_answer-option_instagram',
          active: false,
        },
        {
          key: 'Facebook ',
          value: 'onboarding_audience-description_audience-platform_answer-option_facebook',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_audience-platform_help-and-tips_title',
      content: 'onboarding_audience-description_audience-platform_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Audience description',
    name: 'audience_description',
    type: 'TEXT_AREA',
    title: 'onboarding_audience-description_audience-what-else_question_title',
    subtitle: 'onboarding_audience-description_audience-what-else_question_subtitle',
    alert: 'onboarding_audience-description_audience-what-else_question_alert',
    placeholder: 'onboarding_audience-description_audience-what-else_question_placeholder',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_audience-description_audience-what-else_help-and-tips_title',
      content: 'onboarding_audience-description_audience-what-else_help-and-tips_content',
      media: {},
    },
  },
];
