import React from 'react';
import ProfileHeader from '../../components/profile/ProfileHeader';
import { CircularProgress, Box, Typography, Popover } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@material-ui/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TaskContent from 'components/tasks/TaskContentDesktop';
import TaskContentMobile from 'components/tasks/TaskContentMobile';
import { MobileView, BrowserView } from 'react-device-detect';
import { useLazyQuery, useQuery, gql } from '@apollo/client';
import DialogTaks from 'components/tasks/DialogTaks';
import DialogFilters from 'components/tasks/DialogFilters';
import { GET_BRANDS } from '../../api/queries/tasks';
import MonthlyCalendar from './myWork/MonthlyCalendar';
import WeeklyCalendar from './myWork/WeeklyCalendar';
import { FormattedMessage } from 'react-intl';
import { FETCH_TASKS_FILTERED_TASK_NAME } from '../../api/queries/tasks';

import { Switch, Route, useHistory, Redirect, useLocation } from 'react-router-dom';

interface Brand {
  name: string;
  __typename: string;
}

interface UpdateTask {
  id: string;
  dueDate: Date;
  name: string;
  description: string;
  status: string;
  title: string;
  completion: string;
  brand: Brand[];
  __typename: string;
}

const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Gilroy-Regular',
  fontSize: '16px',
  color: '#717A90',
  '&.Mui-selected': {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4000000059604645px',
  },
}));

interface Props {}

const Tasks = (props: Props) => {
  const [activeStepString, setActiveStepString] = React.useState('/tasks/my-overview');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElOrder, setAnchorElOrder] = React.useState<HTMLButtonElement | null>(null);
  const [valApplyOrderBy, setValApplyOrderBy] = React.useState<any>('');
  const [searchVal, setSearchVal] = React.useState('');
  let history = useHistory();

  const [valuesSelectOrerBy, setValuesSelectOrerBy] = React.useState<any>({
    orderBy: '',
    sort: '',
  });
  const [valuesfilters, setValuesfilters] = React.useState<any>({
    completionStatus: ['To do'],
    brand: [],
    taskName: [],
  });
  const [selectedOrder, setSelectedOrder] = React.useState<string>('');

  const [itemsFilters, setItemsFilters] = React.useState<any>({
    completionStatus: [
      {
        title: 'Not open yet',
        active: false,
      },
      {
        title: 'To do',
        active: true,
      },
      ,
      {
        title: 'Done',
        active: false,
      },
      ,
      {
        title: 'Rework required',
        active: false,
      },
    ],
  });

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const openOrder = Boolean(anchorElOrder);
  const idOrder = openOrder ? 'simple-popover' : undefined;

  const [count, setCount] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [loadingFilters, setLoadingFilters] = React.useState(false);

  const [current, setCurrent] = React.useState<UpdateTask[]>([]);
  const [brand, setBrand] = React.useState<any>(['']);
  const [complitionStatus, setComplitionStatus] = React.useState<any>(null);
  const email = localStorage.getItem('email');

  const [sortByDateValue, setSortByDateValue] = React.useState<string>('asc');
  const [sortByField, setSortByField] = React.useState<string>('dueDate');

  const handleValuesSelectOrerBy = (prop) => (event) => {
    setValuesSelectOrerBy({
      ...valuesSelectOrerBy,
      [prop]: event.target.value,
    });
  };
  const handleCheckboxFilter = (name) => {
    let arrCheckbox = itemsFilters.completionStatus;
    arrCheckbox.forEach((element) => {
      if (element.title == name) {
        element.active = !element.active;
      }
    });
    let arrComplitionStatus: any = [];
    arrCheckbox.forEach((i) => {
      if (i.active == true) {
        arrComplitionStatus.push(i.title);
      }
    });
    setComplitionStatus([arrComplitionStatus[0]]);
    setValuesfilters({
      ...valuesfilters,
      completionStatus: arrComplitionStatus,
    });

    setItemsFilters({
      ...itemsFilters,
      completionStatus: arrCheckbox,
    });
  };
  const clearFilter = () => {
    let arrCheckbox = itemsFilters.completionStatus;
    arrCheckbox.forEach((element) => {
      element.active = false;
    });
    setItemsFilters({
      ...itemsFilters,
      completionStatus: arrCheckbox,
    });
    setValuesfilters({
      completionStatus: [],
      brand: [],
      taskName: [],
    });
  };
  const handleValuesSelectFilters = (name, value) => {
    if (name === 'brand') {
      setBrand([value[0]]);
      setValuesfilters({
        ...valuesfilters,
        brand: value,
      });
    }
    if (name === 'taskName') {
      setValuesfilters({
        ...valuesfilters,
        taskName: value,
      });
    }
  };

  const getMoreData = () => {
    setCount((prevState) => prevState + 1);
  };
  const FETCH_TASKS_PAGINATION = gql`
 query getTasks($completition_status: JSON!, $brand:JSON!, $title:JSON!,$ownerEmail:JSON!,$sort:JSON! ) {
    tasks(
      _filter: {
        _and: [
            {ownerEmail: $ownerEmail}
            {completion: { _in: $completition_status } }
            {brand: {_in: $brand} }
            {title: { _in: $title } }
            # {flowGroupTitle: { _in: ["Conception-Creation"] } }
          ]
      }
      _page: ${count}
      _page_size: 10
      _order_by: $sort
    ) {
      id
      dueDate
      name
      description
      status
      completion
      flowGroup
      flowGroupTitle
      title
      viewLink
      brand {
        name
        picture
      }
      calendar {
        id
        dueDate
      }
      monthlyCalendar {
        id
        dueDate
      }
      title
     }
  }
  `;

  // # ${title && ` { title: {_in: [title]} }`}

  const filterTaskForTaskName = (value) => {
    setSearchVal(value);
    getTasksFilteredTaskName({
      variables: {
        title: value,
      },
    });
  };
  const [
    getTasksFilteredTaskName,
    {
      loading: loadingGetTasksFilteredTaskName,
      error: errorGetTasksFilteredTaskName,
      data: dataGetTasksFilteredTaskNam,
    },
  ] = useLazyQuery(FETCH_TASKS_FILTERED_TASK_NAME, {
    onCompleted: (data) => {
      setCurrent(data.user[0].tasks);
    },
    onError: (error) => {
      setHasMore(false);
      const message = JSON.parse(error.message);
    },
  });

  const [getTasks, { loading: loadingGetPaginationTasks, error: errorGetPaginationTasks, data }] =
    useLazyQuery(FETCH_TASKS_PAGINATION, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        const arrayOfTasks = data.tasks;
        // setCurrent(data.user[0].tasks);
        if (count === 0) {
          setCurrent(arrayOfTasks);
        } else {
          if (current) {
            const array3 = current.concat(arrayOfTasks);
            setCurrent(array3);
          }
        }
        if (data.tasks.length < 10) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      },
      onError: (error) => {
        setHasMore(false);
      },
      nextFetchPolicy: 'standby',
    });

  React.useEffect(() => {
    getTasks({
      variables: {
        sort: [{ [sortByField]: sortByDateValue }],
        ownerEmail: [email],
        completition_status:
          valuesfilters?.completionStatus && valuesfilters?.completionStatus.length
            ? valuesfilters?.completionStatus
            : [''],
        brand: valuesfilters?.brand && valuesfilters?.brand.length ? valuesfilters?.brand : [''],
        title: valuesfilters?.title && valuesfilters?.title.length ? valuesfilters?.title : [''],
      },
    });
  }, [count]);

  const updateData = (data: UpdateTask) => {
    var index = current.findIndex((x) => x.id === data.id);
    if (index === -1) console.log('errosr');
    // handle error
    else
      setCurrent([
        ...current.slice(0, index),
        { ...current[index], ...data, brand: current[index]['brand'] },
        ...current.slice(index + 1),
      ]);

    // handle error
  };
  const renderMyOverviewTab = () => {
    return (
      <Box minHeight="50vh">
        <BrowserView>
          {loadingGetPaginationTasks && count === 0 && current?.length === 0 ? (
            <Box
              mt={2}
              height="100%"
              display="flex"
              flexDirection="row"
              justifyContent={'center'}
              alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            current && (
              <Box mt={2}>
                <TaskContent
                  getCalendarTask={getCalendarTask}
                  radioOrderBy={selectedOrder ? selectedOrder : ''}
                  fields={current}
                  hasMore={hasMore}
                  getMoreData={getMoreData}
                  setCurrent={setCurrent}
                  count={count}
                  updateData={updateData}
                />
              </Box>
            )
          )}
        </BrowserView>
        <MobileView>
          {loadingGetPaginationTasks && count === 0 && current?.length === 0 ? (
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            current && (
              <TaskContentMobile
                getCalendarTask={getCalendarTask}
                radioOrderBy={valApplyOrderBy.orderBy ? valApplyOrderBy.orderBy : 'Brand'}
                fields={current}
                setCurrent={setCurrent}
                hasMore={hasMore}
                getMoreData={getMoreData}
                count={count}
                updateData={updateData}
              />
            )
          )}
        </MobileView>
      </Box>
    );
  };
  const { loading: loadingBrands } = useQuery(GET_BRANDS, {
    onCompleted: (data) => {
      let arrBrand: any = [];
      data.user[0].tasks.forEach((i) => {
        arrBrand.push(i.brand[0].name);
      });
      let filteredBrand = arrBrand.filter((el, id) => arrBrand.indexOf(el) === id);
      setItemsFilters({
        ...itemsFilters,
        brand: filteredBrand,
      });
    },
  });
  // const [
  //   getMonthlyCalendars,
  //   { data: monthlyCalendarsData, loading: loadingMonthlyCalendars, error },
  // ] = useLazyQuery(GET_MONTHLY_CALENDARS, {
  //   onCompleted: (data) => {
  //     // setItemsMonthlyCalendars(data.user[0].monthlyCalendars[0])
  //   },
  //   onError: (error) => {
  //     const message = JSON.parse(error.message);
  //     console.log(message);
  //   },
  // });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // if (newValue === 1) {
    //   getMonthlyCalendars({
    //     variables: { status: 'In creation' },
    //   });
    // }
    history.push({
      pathname: newValue,
    });
    // setActiveStep(newValue);
  };
  const getCalendarTask = (item) => {
    if (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) {
      const calendarId = item.monthlyCalendar[0].id;
      history.push({
        pathname: `/tasks/my-work/monthly/${calendarId}`,
        state: {
          selectedTask: {
            ...item,
            idTask: item.id,
            completionStatus: item.completion,
            status: item.status,
          },
        },
      });
    }

    if (item.flowGroup === 'Calendar') {
      history.push({
        pathname: `/tasks/my-work/weekly/${item.id}`,
        state: {
          selectedTask: {
            ...item,
            idTask: item.id,
            completionStatus: item.completion,
            status: item.status,
          },
        },
      });
    }
    // if (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) {
    //   console.log('item', item);
    //   setSelectIdCalendars({
    //     brand: item.brand[0].name,
    //     taskName: item.title,
    //   });
    //   setPropsCalendar({
    //     calendarType: 'monthly',
    //     idTask: item.id,
    //   });
    //   getMonthlyCalendarsId({
    //     variables: { id: item.id },
    //   });
    // }
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElOrder(event.currentTarget);
  };
  const handleCloseOrder = () => {
    setAnchorElOrder(null);
  };

  const applyOrderBy = () => {
    setValApplyOrderBy(valuesSelectOrerBy);
    handleCloseOrder();
  };

  const applyFilters = () => {
    setCurrent([]);
    // setTimeout(() => {
    setLoadingFilters(true);
    setCount(0);
    setHasMore(true);
    setLoadingFilters(false);
    setAnchorEl(null);
    setAnchorElOrder(null);
    // if (count === 0) {
    getTasks({
      variables: {
        sort: [{ [sortByField]: sortByDateValue }],
        ownerEmail: [email],
        completition_status:
          valuesfilters?.completionStatus && valuesfilters?.completionStatus.length
            ? valuesfilters?.completionStatus
            : [''],
        brand: valuesfilters?.brand && valuesfilters?.brand.length ? valuesfilters?.brand : [''],
        title: valuesfilters?.title && valuesfilters?.title.length ? valuesfilters?.title : [''],
      },
    });
    // }, 100);

    // } else setCount(0);
  };

  const { pathname } = useLocation();

  const valueOfTab = pathname.includes('my-work') ? '/tasks/my-work' : pathname;
  return (
    <Box>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <ProfileHeader
            userName="Besart Marku1"
            title="tasks_my_task"
            description="tasks_complete_all_tasks"
          />
        </Grid>

        <Grid item xs={12}>
          <Tabs value={valueOfTab} onChange={handleChange} sx={{ width: { xs: '100%' } }}>
            <StepsTab
              value={`/tasks/my-overview`}
              sx={{
                fontFamily: 'Gilroy-Medium !important',
                textTransform: 'capitalize !important',
                minWidth: { xs: '50%', md: 240 },
              }}
              label={<FormattedMessage id="tasks_my_overview" />}
            />
            <StepsTab
              value={`/tasks/my-work`}
              disabled={activeStepString == '/tasks/my-overview' ? true : false}
              sx={{
                fontFamily: 'Gilroy-Medium !important',
                textTransform: 'capitalize !important',
                minWidth: { xs: '50%', md: 240 },
              }}
              label={<FormattedMessage id="tasks_my_work" />}
            />
          </Tabs>
        </Grid>
        {pathname === '/tasks/my-overview' && (
          <Grid
            container
            sx={{ padding: '25px 0px 0px 25px', display: { md: 'flex' } }}
            justifyContent="space-between"
            alignItems="center">
            <Grid item container md={6} xs={12} alignItems="center" spacing={2}>
              <Grid item xs={4} md={3}>
                <Typography
                  sx={{
                    fontFamily: 'Gilroy-SemiBold',
                    color: '#000',
                    fontSize: '20px',
                    lineHeight: '160%',
                  }}>
                  <FormattedMessage id="tasks_task_board" />
                </Typography>
              </Grid>
              <Grid item xs={3} md={2}>
                <Box>
                  <Box
                    // aria-describedby={id}
                    onClick={(e: any) => handleClick(e)}
                    sx={{ display: 'flex', cursor: 'pointer' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Gilroy-SemiBold',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        letterSpacing: '0.4px',
                        marginRight: '5px',
                      }}>
                      <FormattedMessage id="tasks_filter" />
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fill: '#EE9A8F' }} />
                  </Box>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <DialogFilters
                      items={itemsFilters}
                      valuesfilters={valuesfilters}
                      handleValuesSelectFilters={handleValuesSelectFilters}
                      handleCheckboxFilter={handleCheckboxFilter}
                      applyFilters={applyFilters}
                      clearFilter={clearFilter}
                      loadingGetFilteredTasks={loadingFilters}
                    />
                  </Popover>
                </Box>
              </Grid>
              <Grid item xs={3} md={2}>
                <Box>
                  <Box
                    aria-describedby={id}
                    onClick={(e: any) => handleClickOrder(e)}
                    sx={{ display: 'flex', cursor: 'pointer' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Gilroy-SemiBold',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        letterSpacing: '0.4px',
                        marginRight: '5px',
                      }}>
                      <FormattedMessage id="tasks_order" />
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fill: '#EE9A8F' }} />
                  </Box>
                  <Popover
                    id={idOrder}
                    open={openOrder}
                    anchorEl={anchorElOrder}
                    onClose={handleCloseOrder}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <DialogTaks
                      valuesSelectOrerBy={valuesSelectOrerBy}
                      handleValuesSelectOrerBy={handleValuesSelectOrerBy}
                      applyFilters={applyFilters}
                      setSortByDateValue={setSortByDateValue}
                      sortByDateValue={sortByDateValue}
                      sortByField={sortByField}
                      setSortByField={setSortByField}
                      loadingGetFilteredTasks={loadingFilters}
                    />
                  </Popover>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <>
        <Switch>
          <Route exact path="/tasks/my-overview" render={() => renderMyOverviewTab()} />
          <Route exact path="/tasks/my-work/monthly/:calendarId" component={MonthlyCalendar} />
          <Route exact path="/tasks/my-work/weekly/:calendarId" component={WeeklyCalendar} />
          <Redirect exact from="/tasks" to="/tasks/my-overview" />
        </Switch>
      </>

      {/* {renderMyOverviewTab(activeStep)} */}
    </Box>
  );
};

export default Tasks;
