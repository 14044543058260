import SvgIcon from '@material-ui/core/SvgIcon';

const CircleBrand = () => (
  <SvgIcon sx={{ marginTop: '8px', marginLeft: '7px', padding: '0px' }}>
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4469 0.916504L4.03005 7.33339L1.11328 4.41663" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  </SvgIcon>
);

export default CircleBrand;
