import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Dialog, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContainer: {
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '24px',
  },
  dialogContainerCloseButton: {
    color: 'black',
    fontSize: '14px',
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogContentTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '28px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '20px 0',
  },
  dialogContentDescription: {
    fontFamily: 'Gilroy-Regular',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '40px 0',
  },
  dialogContentConfirmButton: {
    background: '#EE9A8F',
    borderRadius: '4px',
    color: '#fff',
    padding: '6px 16px',
  },
  dialogContentButtonMessage: {
    padding: '24px 0',
    textTransform: 'capitalize',
    textDecoration: 'underline',
  },
}));

interface DialogConfirmProps {
  open: boolean; //to open and close dialog
  onConfirm: () => void; //called when clicked I confirm
  dontDisplayTheMessageAgain?: () => void; //called when clicked Dont display this message again
  onClose: () => void; //called when dialog closed
  handleValidateAll: () => void;
  loadingUpdateMonthlyPostAll?: any;
  // loadingFeedbackMonthly: any;
}

const DialogConfirm: React.FC<DialogConfirmProps> = ({
  open,
  onConfirm,
  dontDisplayTheMessageAgain,
  onClose,
  handleValidateAll,
  loadingUpdateMonthlyPostAll,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{
        style: {
          borderRadius: '24px',
          width: '600px',
        },
      }}>
      <Box>
        <Box sx={{ margin: '0 16px' }} display="flex" justifyContent="flex-end">
          <IconButton className={classes.dialogContainerCloseButton} onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.dialogContent}>
          <Box className={classes.dialogContentTitle}>
            <FormattedMessage id="calendar_monthly_dialog_confirm_title" />
          </Box>
          <Box className={classes.dialogContentDescription}>
            <FormattedMessage id="calendar_monthly_dialog_confirm_description" />
          </Box>
          <Box pb={1}>
            <Button
              className={classes.dialogContentConfirmButton}
              onClick={() => handleValidateAll()}>
              {loadingUpdateMonthlyPostAll ? (
                <CircularProgress sx={{ color: '#fff' }} />
              ) : (
                <FormattedMessage id="calendar_monthly_dialog_confirm_validate_month" />
              )}
            </Button>
          </Box>
          <Box>
            {/* <Button
              className={classes.dialogContentButtonMessage}
              onClick={() => {
                dontDisplayTheMessageAgain && dontDisplayTheMessageAgain();
              }}>
              No, I want to review the concepts
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogConfirm;
