import generalInformationFields from './fields/generalInformation';
import collaborating_with_influencer from './fields/collaborating_with_influencer';
import brand_assets from './fields/brand_assets';
import audience_description from './fields/audience_description';
import sourse_of_information from './fields/source_of_information';
import social_media_objectives from './fields/social_media_objectives';
import access_to_social_media from './fields/access_to_social_media';
import google_ads from './fields/google_ads';
import facebook_post_boosting from './fields/facebook_post_boosting';
import facebook_ads_plus from './fields/facebook_ads_plus';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    name: 'social_media_objectives',
    label: 'Social media objectives',
    fields: social_media_objectives.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_smobjectives_socialmediaobjective',
    },
    description: {
      key: '',
      default: 'onboarding_smobjectives_tellusmore',
    },
    fieldsToRender: social_media_objectives,
  },
  {
    name: 'general_information',
    label: 'General Information',
    fields: generalInformationFields.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'General information',
    },
    description: {
      key: '',
      default: 'onboarding_generalinfo_aboutyourbrand',
    },
    fieldsToRender: generalInformationFields,
  },
  {
    name: 'brand_assets',
    label: 'onboarding_brandassests_brandassets',
    fields: brand_assets.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_brandassests_brandassets',
    },
    description: {
      key: '',
      default: 'onboarding_brandassests_sharebrandvisual',
    },
    fieldsToRender: brand_assets,
  },

  {
    name: 'audience_description',
    label: 'onboarding_audiencedesc_description',
    fields: audience_description.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_audiencedesc_description',
    },
    description: {
      key: '',
      default: 'onboarding_audiencedesc_tellusmore',
    },
    fieldsToRender: audience_description,
  },
  {
    name: 'source_of_information',
    label: 'onboarding_sourceofinfo_sourceofinformation',
    fields: sourse_of_information.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_sourceofinfo_sourceofinformation',
    },
    description: {
      key: '',
      default: 'onboarding_sourceofinfo_sharesources',
    },
    fieldsToRender: sourse_of_information,
  },
  // {
  //   name: 'source_of_information',
  //   label: 'Source Of Information',
  //   fields: generalInformationFields.map((field) => {
  //     return { name: field.name, label: field.title };
  //   }),
  //   fieldsToRender: generalInformationFields,
  // },

  {
    name: 'access_to_social_media',
    label: 'onboarding_accesssm_accesstosm',
    fields: access_to_social_media.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_accesssm_accesstosm',
    },
    description: {
      key: '',
      default: 'onboarding_accesssm_sharecreditentials',
    },
    fieldsToRender: access_to_social_media,
  },
  {
    name: 'collaborating_with_influencers',
    label: 'Collaborating with influencers',
    fields: collaborating_with_influencer.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_collab_collabwithinfluencers',
    },
    description: {
      key: '',
      default: 'onboarding_collab_conditions',
    },
    fieldsToRender: collaborating_with_influencer,
  },
  {
    name: 'google_ads',
    label: 'Google Ads',
    fields: google_ads.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_googleads_googleads',
    },
    description: {
      key: '',
      default: 'onboarding_googleads_requiredinfo',
    },
    fieldsToRender: google_ads,
  },
  {
    name: 'facebook_post_boosting',
    label: 'onboarding_fbpostboost_facebookpostboosting',
    fields: facebook_post_boosting.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_fbpostboost_facebookpostboosting',
    },
    description: {
      key: '',
      default: 'onboarding_fbpostboost_requiredinfo',
    },
    fieldsToRender: facebook_post_boosting,
  },
  {
    name: 'facebook_ads_plus',
    label: 'onboarding_facebookadsplus_facebookadsplus',
    fields: facebook_ads_plus.map((field) => {
      return { name: field.name, label: field.title };
    }),
    title: {
      key: '',
      default: 'onboarding_facebookadsplus_facebookadsplus',
    },
    description: {
      key: '',
      default: 'onboarding_facebookadsplus_requiredinfo',
    },
    fieldsToRender: facebook_ads_plus,
  },
];
