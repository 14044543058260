import { gql } from '@apollo/client';

const TASKS_WITH_FILTERS = gql`
  query fetchTasks {
    tasks(_page_size: 50) {
      id
      dueDate
      name
      description
      status
      completion
      flowGroup
      title
      brand {
        name
      }
      calendar {
        id
        dueDate
      }
      monthlyCalendar {
        id
      }
    }
  }
`;
const GET_WEEKLY_CALENDAR_TASKS = gql`
  query getWeeklyCalendarTasks($id: String) {
    user {
      calendars(id: $id) {
        id
        calendarId
        taskClientReviewStatus
        dueDate
        dueDateMonth
        publications {
          id
          publicationId
          clientApproval
          publicationTime
          concept
          type
          category
          caption
          assets
          tags
          productsToTag
          location
          toBeBoosted
          additionalCommentsDone
          commentsOnTextOpen
          commentsOnDesignOpen
          hashtags
        }
      }
    }
  }
`;

const FETCH_TASKS = gql`
  query tasksWithFilters($completition_status: String, $brand: [String], $title: String) {
    user {
      tasks(completion: $completition_status, brand: $brand, title: $title, _page_size: 50) {
        id
        dueDate
        name
        description
        status
        completion
        flowGroup
        title
        brand {
          name
        }
        calendar {
          id
          dueDate
        }
        monthlyCalendar {
          id
          dueDate
        }
        title
      }
    }
  }
`;
const GET_BRANDS = gql`
  query getBrands {
    user {
      tasks {
        brand {
          id
          name
          email
        }
      }
    }
  }
`;
const GET_FILTERED_TASKS = gql`
  query getFilteredTasks($completition_status: String, $brand: [String], $title: String) {
    user {
      tasks(completion: $completition_status, brand: $brand, title: $title) {
        id
        dueDate
        name
        description
        status
        completion
        flowGroup
        title
        brand {
          name
        }
        calendar {
          id
          dueDate
        }
        monthlyCalendar {
          id
          dueDate
        }
        title
      }
    }
  }
`;
const GET_MONTHLY_CALENDARS_ID = gql`
  query getMonthlyCalendarsId($id: String) {
    user {
      monthlyCalendars(id: $id, conceptionStatus: "In creation", _order_by: [{ dueDate: "asc" }]) {
        id
        dueDate
        conceptionStatus
        start
        end
        calendars {
          id
          dueDate
          publications {
            id
            publicationTime
            type
            conceptionStatus
            concept
            category
          }
        }
      }
    }
  }
`;
const GET_MONTHLY_CALENDARS = gql`
  query getMonthlyCalendars {
    user {
      monthlyCalendars(conceptionStatus: "In creation", _order_by: [{ dueDate: "asc" }]) {
        id
        dueDate
        conceptionStatus
        start
        end
        calendars {
          id
          dueDate
          publications {
            id
            publicationTime
            type
            conceptionStatus
            concept
            category
          }
        }
      }
    }
  }
`;
const FETCH_USER_TASKS_WITH_FILTERS = gql`
  query fetchUserTasksWithFilters($completition_status: String, $brand: [String], $title: String) {
    user {
      tasks(
        completion: $completition_status
        brand: $brand
        title: $title
        _page: 1
        _page_size: 2
        _order_by: [{ dueDate: "asc" }]
      ) {
        id
        dueDate
        name
        description
        status
        completion
        flowGroup
        title
        brand {
          name
        }
        calendar {
          id
          dueDate
        }
        monthlyCalendar {
          id
          dueDate
        }
        title
      }
    }
  }
`;
const FETCH_USER_TASKS_WITH_NAME_BRAND = gql`
  query MyQuery($brand: [String], $title: String, $completion: String) {
    tasks(completion: "Done", title: $title, brand: $brand) {
      id
      dueDate
      name
      description
      status
      completion
      flowGroup
      title
      brand {
        name
      }
      monthlyCalendar {
        dueDate
        id
        start
        end
        calendars {
          id
          dueDate
          type
          publications {
            id
            publicationTime
            type
            conceptionStatus
            concept
            category
          }
        }
      }
    }
  }
`;
const FETCH_TASKS_FILTERED_TASK_NAME = gql`
  query getTasks($title: String) {
    user {
      tasks(title: $title) {
        id
        dueDate
        name
        description
        status
        completion
        flowGroup
        title
        brand {
          name
        }
        calendar {
          id
          dueDate
        }
        monthlyCalendar {
          id
          dueDate
        }
        title
      }
    }
  }
`;
export {
  FETCH_TASKS,
  TASKS_WITH_FILTERS,
  GET_BRANDS,
  GET_FILTERED_TASKS,
  GET_MONTHLY_CALENDARS,
  GET_MONTHLY_CALENDARS_ID,
  FETCH_USER_TASKS_WITH_FILTERS,
  FETCH_USER_TASKS_WITH_NAME_BRAND,
  GET_WEEKLY_CALENDAR_TASKS,
  FETCH_TASKS_FILTERED_TASK_NAME,
};
