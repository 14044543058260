// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome } from '@tabler/icons';
import CalendarIcon from 'assets/icons/CalendarIcon.png';
import ProfileIcon from 'assets/icons/ProfileIcon.png';

// constant
const icons = {
  IconBrandChrome: IconBrandChrome,
};

const CalendarIconComp = () => {
  return <img src={CalendarIcon} />;
};

const ProfileIconComp = () => {
  return <img src={ProfileIcon} />;
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const devRoutes = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'My Profile',
      title: <FormattedMessage id="menu_item_profile" />,
      type: 'item',
      url: '/profile',
      icon: ProfileIconComp,
      breadcrumbs: false,
      roles: ['BUSINESS_USER'],
    },
    {
      id: 'My Calendar',
      title: <FormattedMessage id="menu_item_calendar" />,
      type: 'item',
      url: '/calendar',
      icon: CalendarIconComp,
      breadcrumbs: false,
      roles: ['BUSINESS_USER'],
    },
    {
      id: 'Tasks',
      title: <FormattedMessage id="menu_item_tasks" />,
      type: 'item',
      url: '/tasks',
      icon: icons['IconBrandChrome'],
      breadcrumbs: false,
      roles: ['FREELANCER_USER'],
    },
    {
      id: 'Status Board',
      title: <FormattedMessage id="menu_item_status_board" />,
      type: 'item',
      url: '/managersTasks',
      icon: icons['IconBrandChrome'],
      breadcrumbs: false,
      roles: ['FREELANCER_USER'],
    },
  ],
};

export const prodRoutes = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'My Profile',
      title: <FormattedMessage id="menu_item_profile" />,

      type: 'item',
      url: '/profile',
      icon: ProfileIconComp,
      breadcrumbs: false,
      roles: ['BUSINESS_USER'],
    },
    {
      id: 'My Calendar',
      title: <FormattedMessage id="menu_item_calendar" />,

      type: 'item',
      url: '/calendar',
      icon: CalendarIconComp,
      breadcrumbs: false,
      roles: ['BUSINESS_USER'],
    },
    {
      id: 'Tasks',
      title: <FormattedMessage id="menu_item_tasks" />,

      type: 'item',
      url: '/tasks',
      icon: icons['IconBrandChrome'],
      breadcrumbs: false,
      roles: ['FREELANCER_USER'],
    },
    // {
    //   id: 'Status Board',
    //   title: <FormattedMessage id="Status Board" />,
    //   type: 'item',
    //   url: '/managersTasks',
    //   icon: icons['IconBrandChrome'],
    //   breadcrumbs: false,
    //   roles: ['BUSINESS_USER'],
    // }
  ],
};
