import { Box, Grid, LinearProgress, Stack, Typography } from '@material-ui/core';
import * as React from 'react';
import ButtonBack from '../../buttonBack';
import Button from '../../button';
import { MobileView, BrowserView } from 'react-device-detect';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    textTransform: 'none',
    width: '100%',
    border: '1px solid rgb(249, 216, 211)',
    fontFamily: 'Gilroy-SemiBold',
    color: 'rgb(240, 153, 141)',
    padding: '1px',
    '&:hover': {
      borderColor: 'rgb(240, 153, 141)',
    },
  },
});
interface StepperProps {
  activeStep: number;
  totalSteps: number;
  fieldsLength: number;
  prevStep: () => void;
  handleSave: () => void;
  loading: boolean;
}
export default function Stepper({
  activeStep,
  totalSteps,
  prevStep,
  fieldsLength,
  handleSave,
  loading,
}: StepperProps) {
  const history = useHistory();
  let factor = 100 / fieldsLength;
  const classes = useStyles();
  const reternProfile = () => {
    history.push('/profile');
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        bgcolor: 'background.paper',
        minHeight: '60px',
        px: 2,
        boxShadow: '0px 8px 40px rgba(234, 237, 244, 0.6)',
        borderRadius: '0px 0px 30px 30px',
        padding: { xs: '15px 10px', md: '0px 30px' },
      }}>
      <Grid item xs={2} md={2}>
        <Box onClick={reternProfile}>
          <BrowserView>
            <ButtonBack title="Home" />
          </BrowserView>
          <MobileView>
            <ButtonBack title={'none'} />
          </MobileView>
        </Box>
      </Grid>
      <Grid
        item
        xs={8}
        md={5}
        justifyContent="flex-start"
        sx={{ marginLeft: { xs: '-30px', md: '0px' } }}>
        <Stack direction="row" spacing={2} sx={{ marginBottom: { xs: '5px', md: '0px' } }}>
          <Typography sx={{ fontFamily: 'Gilroy-SemiBold', color: '#252A32', fontSize: '16px' }}>
            <FormattedMessage id="onboarding_step" /> {activeStep}/
          </Typography>
          <Typography sx={{ marginLeft: '0px !important', fontSize: '16px' }}>
            {totalSteps}
          </Typography>
        </Stack>
        <LinearProgress
          variant="determinate"
          value={activeStep * factor}
          sx={{ height: { xs: '5px', md: '10px' }, borderRadius: '5px' }}
        />
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ width: { xs: '80%', md: '50%' }, margin: { xs: '0px', md: '-10px auto 0px' } }}>
          <BrowserView>
            {loading ? (
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Button
                titleBtn={<FormattedMessage id="action-text_save" />}
                colorBtn={'white'}
                onClick={() => handleSave()}
              />
            )}
          </BrowserView>
          <MobileView>
            {loading ? (
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <MuiButton className={classes.root} onClick={() => handleSave()}>
                Save
              </MuiButton>
            )}
          </MobileView>
        </Box>
      </Grid>
    </Grid>
  );
}
