import { gql } from '@apollo/client';

const INSERT_FORM = gql`
  mutation insertForm($answers: jsonb, $form: String, $is_completed: Boolean, $version: String) {
    insert_form_one(
      object: { answers: $answers, form: $form, is_completed: $is_completed, version: $version }
    ) {
      id
      form
      version
      is_completed
      answers
    }
  }
`;

const UPDATE_FORM = gql`
  mutation updateForm(
    $id: uuid!
    $answers: jsonb
    $form: String
    $is_completed: Boolean
    $version: String
  ) {
    update_form_by_pk(
      pk_columns: { id: $id }
      _set: { answers: $answers, form: $form, is_completed: $is_completed, version: $version }
    ) {
      id
      form
      version
      is_completed
      answers
    }
  }
`;

const GENERATE_UPLOAD_URL = gql`
  mutation generateUploadUrl($path: String!) {
    generate_upload_url(arg1: { path: $path }) {
      url
    }
  }
`;
export { INSERT_FORM, UPDATE_FORM, GENERATE_UPLOAD_URL };
