import React from 'react';
import { Grid, Box, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/select';
import Button from '../../../components/button';
import WeeklyViewCalendar from './WeeklyViewCalendar';
import DialogConfirm from '../../../components/calendar/dialogs/DialogConfirm';
import DialogRating from '../../../components/calendar/dialogs/DialogRating';
import DialogFeedback from '../../../components/calendar/dialogs/DialogFeedback';
import moment from 'moment';
import {
  VALIDATE_ALL_MONTHLY_POST,
  FEEDBACK_MONTHLY_CONCEPT,
} from '../../../api/mutations/calendar';
import { GET_MONTHLY_CALENDARS } from '../../../api/queries/calendar/monthly';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { FormattedMessage } from 'react-intl';

const items = {
  type: [
    {
      title: 'Story',
      active: false,
    },
    {
      title: 'Post',
      active: false,
    },
  ],
  categories: [
    {
      title: 'All',
      active: false,
    },
    {
      title: 'Information',
      active: false,
    },
    {
      title: 'Promotion',
      active: false,
    },
    {
      title: 'News',
      active: false,
    },
    {
      title: 'Inspiration',
      active: false,
    },
    {
      title: 'Portrait',
      active: false,
    },
    {
      title: 'Quote',
      active: false,
    },
    {
      title: 'Meme',
      active: false,
    },
  ],
  status: [
    {
      title: 'Validated',
      active: false,
    },
    {
      title: 'Pending',
      active: false,
    },
  ],
};
const useStyles = makeStyles((theme) => ({
  root: {},
  activeSwitch: {
    backgroundColor: '#EE9A8F !important',
  },
  noActiveSwitch: {
    backgroundColor: 'rgba(131, 136, 173, 0.08) !important',
  },
  fillActive: {
    fill: '#fff',
  },
  fillNoActive: {
    fill: '#BDBDBD',
  },
  tabs: {
    '.MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
  monthlyCalendarTitle: {
    fontFamily: 'Gilroy-SemiBold',
    color: '#000',
    fontSize: '20px',
    lineHeight: '160%',
  },
  filterTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#EE9A8F',
    letterSpacing: '0.4px',
    marginRight: '5px',
  },
}));
const MonthlyCalendar = ({
  calendars,
  periodToFilterProp,
  loadingMonthlyCalendars,
}: {
  periodToFilterProp: string | null;
  calendars: any;
  loadingMonthlyCalendars: boolean;
}) => {
  const classes = useStyles();
  const [alignment, setAlignment] = React.useState<string | null>('left');
  const [periodToFilter, setPeriodToFilter] = React.useState<string | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [allCalendars, setAllCalendars] = React.useState<any>();
  const [calendarToShow, setCalendarToShow] = React.useState<any>();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [openModal3, setOpenModal3] = React.useState(false);
  const [disabledValidateAll, setDisabledValidateAll] = React.useState(false);
  // const [openModal4, setOpenModal4] = React.useState(true);
  const dispatch = useDispatch();
  const [updateMonthlyPostAll, { loading: loadingUpdateMonthlyPostAll, error }] = useMutation(
    VALIDATE_ALL_MONTHLY_POST,
    {
      onCompleted: (data) => {
        setOpenModal(false);
        // handleConfirmRating();

        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'All publications were validated successfully',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: Failed to validate all posts',
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
        setOpenModal(false);
      },
    },
  );
  const handleValidateAll = () => {
    let arrId: any = [];
    calendarToShow.calendars.forEach((i) => {
      i.publications.forEach((k) => {
        if (k.conceptionStatus !== 'Done') arrId.push(k.id);
      });
    });
    updateMonthlyPostAll({
      variables: {
        ids: arrId,
        status: 'Done',
      },
      refetchQueries: [{ query: GET_MONTHLY_CALENDARS }],
    });
  };
  const [feedbackMonthly, { loading: loadingFeedbackMonthly, error: errorFeedbackMonthly }] =
    useMutation(FEEDBACK_MONTHLY_CONCEPT, {
      onCompleted: (data) => {
        handleConfirmFeedback();
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'success',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        const message = JSON.parse(error.message);
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: ' + message?.content,
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
      },
    });
  const handleFeedback = (value) => {
    feedbackMonthly({
      variables: {
        id: calendarToShow.id,
        feedback: value.comments,
        rating: value.rating,
      },
    });
  };
  const handleConfirmRating = () => {
    setOpenModal2(true);
    setOpenModal(false);
  };
  const handleConfirmFeedback = () => {
    setOpenModal3(true);
    setOpenModal2(false);
  };
  const backToHome = () => {
    setOpenModal3(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };

  const handleDate = (e) => {
    setPeriodToFilter(e);
    let arrCalendarFields = allCalendars.filter((monthlyCalendar) => monthlyCalendar.start === e);
    setCalendarToShow(arrCalendarFields[0]);
  };

  React.useEffect(() => {
    let periodToFilterValue = periodToFilterProp ? periodToFilterProp : periodToFilter;
    setPeriodToFilter(periodToFilterValue);

    if (calendars.length && calendars[0].monthlyCalendars && calendars[0].monthlyCalendars.length) {
      if (periodToFilterValue) {
        let arrCalendarFields = calendars[0].monthlyCalendars.filter(
          (monthlyCalendar) => monthlyCalendar.start === periodToFilterValue,
        );
        setCalendarToShow(arrCalendarFields[0]);
        setAllCalendars(calendars[0].monthlyCalendars);
      } else {
        const firstMonth = calendars[0].monthlyCalendars.find(
          (monthlyCalendar) => monthlyCalendar.conceptionStatus === 'In client review',
        );

        if (firstMonth) {
          setCalendarToShow(firstMonth);
          setPeriodToFilter(firstMonth.start);
          setAllCalendars(calendars[0].monthlyCalendars);
        } else {
          setCalendarToShow(calendars[0].monthlyCalendars[0]);
          setPeriodToFilter(calendars[0].monthlyCalendars[0]['start']);
        }

        setAllCalendars(calendars[0].monthlyCalendars);
      }
    }
  }, [calendars]);
  React.useEffect(() => {
    let arrDone: any = [];
    calendarToShow?.calendars.forEach((element) => {
      let itemDone: any = [];
      element.publications.forEach((item) => {
        if (item.conceptionStatus == 'Done') {
          itemDone.push(item);
        }
      });
      if (element.publications.length == itemDone.length) {
        arrDone.push(element);
      }
      if (calendarToShow.calendars.length == arrDone.length) {
        setDisabledValidateAll(true);
      } else {
        setDisabledValidateAll(false);
      }
    });
  }, [calendarToShow]);

  const periodsToShowOnSelect = React.useMemo(() => {
    if (allCalendars) {
      const periods = allCalendars.map((calendar) => {
        return {
          start: calendar.start,
          label:
            moment(calendar.start).format('DD/MM/YYYY') +
            ' - ' +
            moment(calendar.end).format('DD/MM/YYYY'),
        };
      });

      return periods;
    }

    // {periodsToShowOnSelect &&
    //   periodsToShowOnSelect.map((period: { start: string; label: string }) => (
    //     <MenuItem value={period.start} style={{ width: '100%' }}>
    //       {period.label}

    //     </MenuItem>
    //   ))}

    return [];
  }, [allCalendars]);

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item container xs={12} md={8}>
          <Grid item xs={6} md={3}>
            <Typography className={classes.monthlyCalendarTitle}>
              <FormattedMessage id="calendar_monthly_calendar_tab" />
            </Typography>
          </Grid>
          <Grid item xs={5} md={3} sx={{ marginTop: '-3px', pr: '10px' }}>
            <Select
              lang="month"
              onChange={(e: any) => handleDate(e.target.value)}
              value={periodToFilter || null}>
              {periodsToShowOnSelect &&
                periodsToShowOnSelect.map((period: { start: string; label: string }) => (
                  <MenuItem value={period.start} style={{ width: '100%' }}>
                    {period.label}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          {/* <Grid item xs={4} md={2} sx={{ marginTop: { xs: '20px', md: '-2px' } }}>
            <ToggleButtonGroup
              value={alignment}
              fullWidth={true}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment">
              <ToggleButton
                className={alignment === 'left' ? classes.activeSwitch : classes.noActiveSwitch}
                sx={{
                  border: 'none',
                  borderTopRightRadius: '4px !important',
                  borderBottomRightRadius: '4px !important',
                  padding: '8px 11px 1px !important',
                }}
                value="left"
                aria-label="left aligned">
                <Box>
                  <ListAltIcon
                    className={alignment === 'left' ? classes.fillActive : classes.fillNoActive}
                  />
                </Box>
              </ToggleButton>
              <ToggleButton
                className={alignment === 'left' ? classes.noActiveSwitch : classes.activeSwitch}
                sx={{
                  border: 'none',
                  borderTopLeftRadius: '4px !important',
                  borderBottomLeftRadius: '4px !important',
                  padding: '7px 11px 2px !important',
                }}
                value="center"
                aria-label="justified">
                <Box>
                  <Calendar fill={alignment === 'left' ? false : true} />
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={{ margin: { xs: '25px 0px 0px 20px', md: '5px 0px 0px 20px' } }}>
              <Box
                aria-describedby={id}
                onClick={(e: any) => handleClick(e)}
                sx={{ display: 'flex', cursor: 'pointer' }}>
                <Typography className={classes.filterTitle}>Filter</Typography>
                <KeyboardArrowDownIcon sx={{ fill: '#EE9A8F' }} />
              </Box>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <FiltersOnSubmit
                  setAllFiltersSelected={setAllFiltersSelected}
                  items={itemsRadio}
                  setItemsRadio={setItemsRadio}
                  handleClose={handleClose}
                />
              </Popover>
            </Box>
          </Grid> */}
        </Grid>
        <Grid
          sx={{ marginTop: '-44px', display: { xs: 'none', md: 'flex' } }}
          item
          container
          xs={4}
          spacing={2}
          justifyContent={'flex-end'}>
          <Grid item xs={6}>
            {/* <Button
              disabled={disabledValidateAll}
              titleBtn="Validate All"
              colorBtn="white"
              onClick={handleOpenModal}
            /> */}
            {openModal && (
              <DialogConfirm
                open={openModal}
                onClose={() => setOpenModal(false)}
                onConfirm={handleConfirmRating}
                handleValidateAll={handleValidateAll}
                loadingUpdateMonthlyPostAll={loadingUpdateMonthlyPostAll}
              />
            )}
            {openModal2 && (
              <DialogRating
                open={openModal2}
                onClose={() => setOpenModal2(false)}
                onConfirm={handleConfirmFeedback}
                handleFeedback={handleFeedback}
              />
            )}
            {openModal3 && (
              <DialogFeedback
                open={openModal3}
                onClose={() => setOpenModal3(false)}
                onConfirm={backToHome}
              />
            )}
            {/* {openModal4 && (
              <DialogEditing
                open={openModal4}
                onClose={() => setOpenModal4(false)}
                onConfirm={backToHome}
              />
            )} */}
          </Grid>
          {/* <Grid item xs={6}>
            <Button titleBtn="Add Event" colorBtn="red" onClick={openAddEventDialog} />
            {addEventDialog && (
              <DialogAddEvent
                open={addEventDialog}
                onClose={() => setAddEventDialog(false)}
                onSuccess={successAddEventDialog}
              />
            )}
          </Grid> */}
        </Grid>
        <Grid
          container
          sx={{
            display: {
              xs: 'flex',
              md: 'none',
              position: 'fixed',
              bottom: '0',
              background: '#fff',
              width: '101%',
              left: '3px',
              padding: '0 20px 15px 10px',
              zIndex: 999,
            },
          }}
          justifyContent="space-between"
          spacing={1}>
          <Grid item xs={12} sx={{ zIndex: 99 }}>
            <Button
              disabled={disabledValidateAll}
              titleBtn={<FormattedMessage id="calendar_monthly_validate_all" />}
              colorBtn="white"
              onClick={handleOpenModal}
            />
          </Grid>
          {/* <Grid item xs={6}>
          <Button titleBtn="Add Event" colorBtn="white" />
        </Grid> */}
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '15px', width: '100%' }}>
        <WeeklyViewCalendar calendars={calendarToShow && calendarToShow?.calendars} />
      </Box>
    </Box>
  );
};

export default MonthlyCalendar;
