import React from 'react';

import { Controller } from 'react-hook-form';
// import { useForm } from 'react-hook-form';
import {
  Button,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  TextField,
} from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import { makeStyles } from '@material-ui/core/styles';

import CrossChip from '../../../assets/images/icons/crossChip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormattedMessage } from 'react-intl';
var _ = require('lodash');

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface RenderFieldProps {
  control: any;
  setActiveStep: (activeStep: number) => void;
  activeStep: number;
  watch?: any;
  setValue?: any;
  handlStep: () => void;
  field: {
    name: string;
    label: string;
    description?: string;
    type: string;
    title: string;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
    paddingRight: '10px',
    marginBottom: '5px',
  },
  selectedItemBox: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '10px',
    width: '100%',
    background: '#fff',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px 8px 10px',
  },
  title: {
    fontSize: '28px',
    lineHeight: '134%',
    fontFamily: 'Gilroy-SemiBold',
    color: '#252A32',
    marginBottom: '12px',
  },
  description: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '12px',
  },
  chipLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '143%',
  },
  active: {
    border: '1px solid #F0998D',
    background: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '10px',
    width: '100%',
    padding: '5px 20px',
    marginBottom: '5px',
  },
  colotrLable: {
    '.MuiFormControlLabel-label': {
      color: '#303778',
    },
  },
  colotrLableActive: {
    '.MuiFormControlLabel-label': {
      color: '#EE9A8F',
      fontFamily: 'Gilroy-SemiBold',
    },
  },
}));
const UploadFile = ({
  field,
  control,
  activeStep,
  handlStep,
  watch,
  setValue,
}: RenderFieldProps) => {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState<any>();
  React.useEffect(() => {
    setSelectedItems(watch()[field.name]);
    if (!watch()[field.name]) setValue(field.name, []);
  }, []);
  const handleDeleteChip = (e: any, onChange) => {
    let arrDelete = selectedItems;
    const filteredValues = arrDelete.filter((item) => item.id !== e.id);
    setSelectedItems([...filteredValues]);
    // setValue('Language', filteredValues)
    onChange(filteredValues);
  };

  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }}>
      <Grid item xs={12} container spacing={1}>
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disableClearable
                      multiple
                      id="checkboxes-tags-demo"
                      options={field.constraints.enum}
                      disableCloseOnSelect
                      value={value}
                      onChange={(event: any, newValue: any | null) => {
                        console.log('🔥🔥 ~ newValue newValue', newValue);
                        console.log('🔥🔥 ~  newValue event', event);

                        onChange(_.uniqBy(newValue, 'id'));
                        setSelectedItems(_.uniqBy(newValue, 'id'));
                      }}
                      getOptionLabel={(option) => option.label}
                      renderTags={() => null}
                      renderOption={(props, option, { selected }) => (
                        <li
                          key={option?.id}
                          {...props}
                          aria-selected={
                            value && value.some((objectValue) => objectValue.id === option.id)
                          }>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                              value && value.some((objectValue) => objectValue.id === option.id)
                            }
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Select" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {value &&
                      value.map((i) => (
                        <Box key={i.label} className={classes.selectedItemBox}>
                          <Typography className={classes.chipLabel}>
                            <FormattedMessage id={i.label} />
                          </Typography>
                          <Box>
                            <Button
                              sx={{ padding: '0px', marginRight: '-10px' }}
                              onClick={() => {
                                handleDeleteChip(i, onChange);
                              }}>
                              <CrossChip />
                            </Button>
                          </Box>
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UploadFile;
