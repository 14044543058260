/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    label: 'Relevance check',
    name: 'relevance_check',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_googleads_check-opted-for-this-package_question_title',
    placeholder: 'onboarding_googleads_check-opted-for-this-package_question_placeholder',
    alert: 'onboarding_googleads_check-opted-for-this-package_question_alert',
    subtitle: 'onboarding_googleads_check-opted-for-this-package_question_subtitle',
    validation: [],
    constraints: {
      enum: [
        {
          key: 'Yes',
          value: 'onboarding_googleads_check-opted-for-this-package_answer-option_understood',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_check-opted-for-this-package_help-and-tips_title',
      content: 'onboarding_googleads_check-opted-for-this-package_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Monthly budget',
    name: 'monthly_budget',
    type: 'SINGLE_VALUE_SELECTOR',
    title: 'onboarding_googleads_monthly-budget_question_title',
    placeholder: 'onboarding_googleads_monthly-budget_question_placeholder',
    alert: 'onboarding_googleads_monthly-budget_question_alert',
    subtitle: 'onboarding_googleads_monthly-budget_question_subtitle',
    validation: [
      {
        regex: '',
        error_message: '',
      },
    ],
    constraints: {
      limits: {
        min: 0,
        max: 2000,
      },
      units: '€',
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_monthly-budget_help-and-tips_title',
      content: 'onboarding_googleads_monthly-budget_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Products or categories to promote',
    name: 'product_or_categories_to_promote',
    type: 'TEXT_AREA',
    title: 'onboarding_googleads_main-products-to-promote_question_title',
    placeholder: 'onboarding_googleads_main-products-to-promote_question_placeholder',
    alert: 'onboarding_googleads_main-products-to-promote_question_alert',
    subtitle: 'onboarding_googleads_main-products-to-promote_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_main-products-to-promote_help-and-tips_title',
      content: 'onboarding_googleads_main-products-to-promote_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'URL of purchase confirmation',
    name: 'url_of_purchase_confirmation',
    type: 'TEXT_FIELD',
    title: 'onboarding_googleads_url-after-purchase-confirmation_question_title',
    placeholder: 'onboarding_googleads_url-after-purchase-confirmation_question_placeholder',
    alert: 'onboarding_googleads_url-after-purchase-confirmation_question_alert',
    subtitle: 'onboarding_googleads_url-after-purchase-confirmation_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_url-after-purchase-confirmation_help-and-tips_title',
      content: 'onboarding_googleads_url-after-purchase-confirmation_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Websites of competitors',
    name: 'website_of_competitors',
    type: 'TEXT_AREA',
    title: 'onboarding_googleads_websites-of-top-competitors_question_title',
    placeholder: 'onboarding_googleads_websites-of-top-competitors_question_placeholder',
    alert: 'onboarding_googleads_websites-of-top-competitors_question_alert',
    subtitle: 'onboarding_googleads_websites-of-top-competitors_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_websites-of-top-competitors_help-and-tips_title',
      content: 'onboarding_googleads_websites-of-top-competitors_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Websites visited by the audience',
    name: 'websites_visited_by_the_audience',
    type: 'TEXT_AREA',
    title: 'onboarding_googleads_websites-commonly-visited-by-target_question_title',
    placeholder: 'onboarding_googleads_websites-commonly-visited-by-target_question_placeholder',
    alert: 'onboarding_googleads_websites-commonly-visited-by-target_question_alert',
    subtitle: 'onboarding_googleads_websites-commonly-visited-by-target_question_subtitle',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_websites-commonly-visited-by-target_help-and-tips_title',
      content: 'onboarding_googleads_websites-commonly-visited-by-target_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Logos',
    name: 'logos',
    type: 'UPLOAD_FILES',
    title: 'onboarding_googleads_share-logos_question_title',
    subtitle: 'onboarding_googleads_share-logos_question_subtitle',
    alert: 'onboarding_googleads_share-logos_question_alert',
    placeholder: 'onboarding_googleads_share-logos_question_placeholder',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/google_ads/logos/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_googleads_share-logos_answer-additional-text-field_title',
        placeholder: 'onboarding_googleads_share-logos_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_share-logos_help-and-tips_title',
      content: 'onboarding_googleads_share-logos_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Images',
    name: 'images',
    type: 'UPLOAD_FILES',
    title: 'onboarding_googleads_visuals-of-your-brand-and-products_question_title',
    subtitle: 'onboarding_googleads_visuals-of-your-brand-and-products_question_subtitle',
    placeholder: 'onboarding_googleads_visuals-of-your-brand-and-products_question_placeholder',
    alert: 'onboarding_googleads_visuals-of-your-brand-and-products_question_alert',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/google_ads/images/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title:
          'onboarding_googleads_visuals-of-your-brand-and-products_answer-additional-text-field_title',
        placeholder:
          'onboarding_googleads_visuals-of-your-brand-and-products_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_visuals-of-your-brand-and-products_help-and-tips_title',
      content: 'onboarding_googleads_visuals-of-your-brand-and-products_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Additional comments',
    name: 'additional_comments',
    type: 'TEXT_AREA',
    title: 'onboarding_googleads_anything-else_question_title',
    placeholder: 'onboarding_googleads_anything-else_question_placeholder',
    subtitle: 'onboarding_googleads_anything-else_question_subtitle',
    alert: 'onboarding_googleads_anything-else_question_alert',
    validation: [],
    help_and_tips: {
      show: false,
      title: 'onboarding_googleads_anything-else_help-and-tips_title',
      content: 'onboarding_googleads_anything-else_help-and-tips_content',
      media: {},
    },
  },
];
