import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';

interface LinkPropsTypes extends LinkProps {
  colorLink?: 'grey' | 'orange';
  underline?: string;
  sx?: any;
}
const useStyles = makeStyles({
  root: {
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '19px',
    color: (props: LinkPropsTypes) =>
      props.colorLink === 'grey' ? '#717A90' : '#F0998D !important',
    '&:hover': {
      color: (props: LinkPropsTypes) =>
        props.colorLink === 'grey' ? '#717A90 !important' : '#f57362 !important',
    },
  },
});

const Link = (props: LinkPropsTypes) => {
  const classes = useStyles(props);
  return (
    <RouterLink {...props} className={classes.root}>
      {props.children}
    </RouterLink>
  );
};
export default Link;
