import React from 'react';
import { CircularProgress, Box, Typography, MenuItem, Button, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Publication from './Publication';
import {
  FETCH_USER_TASKS_WITH_FILTERS,
  FETCH_USER_TASKS_WITH_NAME_BRAND,
  GET_MONTHLY_CALENDARS_ID,
} from '../../../api/queries/tasks';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { MobileView, BrowserView } from 'react-device-detect';
import MobilePublication from './publicationMobile';
import { CHANGE_TASKS_COMPLITION_STATUS } from '../../../api/mutations/tasks';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import ButtonTasks from 'components/tasks/ButtonTasks';
import { useIntl } from 'react-intl';

interface Props {}

interface StateProps {
  selectedTask: any;
  propsCalendar: any;
}
const MyWork = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const intl = useIntl();

  const { state } = useLocation<StateProps>();
  const { calendarId } = useParams<{ calendarId: string }>();

  console.log('🔥🔥 ~ state useLocation', state);

  const [valSelectIdCalendars, setValSelectIdCalendars] = React.useState<any>({
    brand: '',
    taskName: '',
  });
  const [itemsTaskNameSelector, setItemsTaskNameSelector] = React.useState<any>([
    'Creation',
    'Client review',
  ]);
  const [allCalendarPeriods, setAllCalendarPeriods] = React.useState<any>();
  const [arrTasks, setArrTasks] = React.useState<any>();
  const [valSelectCalendar, setValSelectCalendar] = React.useState<string>('');
  const [disabledMarkAsone, setDisabledMarkAsone] = React.useState(true);
  const [weeklyPostsOfOneCalendar, setWeeklyPostsOfOneCalendar] = React.useState<any>();
  const [currentTask, setCurrentTask] = React.useState<any>(null);
  const [itemsMonthlyCalendars, setItemsMonthlyCalendars] = React.useState<any>('');
  const [stateQueryWithFilters, setStateQueryWithFilters] = React.useState(true);
  const [dateNow, setDateNow] = React.useState<any>();

  React.useEffect(() => {
    setDateNow(moment());
  }, []);
  const [taskOfCalendar, setTaskOfCalendar] = React.useState<any>({
    idTask: null,
    completionStatus: null,
    status: null,
  });

  const [shouldFieldsBeDisabled, setShouldFieldsBeDisabled] = React.useState<boolean>(true);

  React.useEffect(() => {
    setTaskOfCalendar(state?.selectedTask);
    if (state?.selectedTask?.completionStatus === 'To do') {
      setShouldFieldsBeDisabled(false);
    }
  }, [state]);

  const { loading: loadingMonthlyCalendarsId } = useQuery(GET_MONTHLY_CALENDARS_ID, {
    variables: { id: calendarId },
    onCompleted: (data) => {
      setItemsMonthlyCalendars(data.user[0].monthlyCalendars[0]);
    },
    onError: (error) => {
      const message = JSON.parse(error.message);
      console.log(message);
    },
  });

  const handleChangeCalSelectCalendar = (val) => {
    setValSelectCalendar(val);
    const currentCalendar = allCalendarPeriods.find(
      (calendar) => calendar.start === val.split('&')[0] && calendar.end === val.split('&')[1],
    );
    if (currentCalendar) setCurrentTask(currentCalendar);
  };

  const handleChangeValSelectIdCalendars = (name, value) => {
    setValSelectIdCalendars({
      ...valSelectIdCalendars,
      [name]: value,
    });
  };

  const [
    changeTaskCompletitionStatus,
    { loading: loadingChangeTaskCompletitionStatus, error: errorChangeTaskCompletitionStatus },
  ] = useMutation(CHANGE_TASKS_COMPLITION_STATUS, {
    onCompleted: (data) => {
      setTaskOfCalendar({ ...taskOfCalendar, completionStatus: 'Done' });
      setDisabledMarkAsone(true);
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Completion status successfully modified',
        variant: 'alert',
        alertSeverity: 'success',
        autoHideDuration: '6000',
      });
    },
    onError: (error) => {
      const message = JSON.parse(error.message);
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Error: ' + message?.content,
        variant: 'alert',
        alertSeverity: 'error',
        autoHideDuration: '6000',
      });
    },
  });

  const handleChangeCompletionStatusMarkAsDone = () => {
    changeTaskCompletitionStatus({
      variables: {
        id: taskOfCalendar.idTask,
        completition_status: 'Done',
      },
    });
  };

  const [
    fetchUserTasksWithFilters,
    { data: dataFetchUserTasksWithFilters, loading: loadingFetchUserTasksWithFilters, error },
  ] = useLazyQuery(FETCH_USER_TASKS_WITH_NAME_BRAND, {
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      console.log('🔥🔥 ~ data fetchUserTasksWithFilters onCompleted', data);
      if (data.tasks && data.tasks.length > 0) {
        setArrTasks(data.tasks);
        let arrMontlyCalendar: any = [];
        data.tasks.forEach((task) => {
          if (task.monthlyCalendar && task.monthlyCalendar.length > 0) {
            arrMontlyCalendar.push({
              ...task.monthlyCalendar[0],
              taskId: task.id,
              taskCompletionStatus: task.completion,
            });
          }
        });
        setWeeklyPostsOfOneCalendar(arrMontlyCalendar[0].calendars);
        setCurrentTask(arrMontlyCalendar[0]);
        setAllCalendarPeriods(arrMontlyCalendar);
        setValSelectCalendar(arrMontlyCalendar[0].start + '&' + arrMontlyCalendar[0].end);
      }
    },
    onError: (error) => {
      const message = JSON.parse(error.message);
      console.log(message);
    },
  });
  const handleTimeIsAfter = (time: any) => {
    if (dateNow) {
      let nowTime = moment(dateNow);
      time = moment(time);
      return dateNow.isAfter(time);
    }
  };
  React.useEffect(() => {
    if (valSelectIdCalendars.brand && valSelectIdCalendars.taskName) {
      fetchUserTasksWithFilters({
        variables: {
          completition_status: 'Done',
          brand: valSelectIdCalendars.brand,
          title: valSelectIdCalendars.taskName,
        },
      });
    }
  }, [valSelectIdCalendars]);

  const markAsDoneDisabled = React.useMemo(() => {
    if (itemsMonthlyCalendars) {
      let array = [...itemsMonthlyCalendars.calendars];

      var isTrue = array.map((calendar) =>
        calendar.publications.every(
          (publication) =>
            publication.publicationTime &&
            publication.type &&
            publication.category &&
            publication.category !== '\n' &&
            publication.concept &&
            publication.concept !== '\n',
        ),
      );
      let checker = (arr) => arr.every((v) => v === true);
      return checker(isTrue);

      //   let disabled = true;
      //   for (let element of array) {
      //     for (let publication of element.publications) {
      //       if (
      //         !publication.publicationTime ||
      //         publication.type ||
      //         publication.category ||
      //         publication.concept
      //       ) {
      //         disabled = false;
      //         setDisabledMarkAsone(false);
      //         break;
      //       }
      //     }
      //     if (disabled == false) break;
      //   }
      //   return disabled;
    }
  }, [itemsMonthlyCalendars]);
  console.log('🔥🔥 ~ markAsDoneDisabled ~ markAsDoneDisabled', markAsDoneDisabled);

  React.useEffect(() => {
    // if (selectIdCalendars.brand || selectIdCalendars.taskName) {
    //   setValSelectIdCalendars({
    //     brand: selectIdCalendars.brand,
    //     taskName: selectIdCalendars.taskName,
    //   });
    // }
  }, []);
  console.log('statestatestatestatestatestatestate', state.selectedTask.monthlyCalendar[0].dueDate);
  return (
    <Box mt={3}>
      {loadingMonthlyCalendarsId ? (
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: { xs: 0, sm: '20px' } }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontFamily: 'Gilroy-SemiBold',
                  lineHeight: '160%',
                  color: '#000',
                  mr: { xs: '24px', sm: '10px' },
                }}>
                Task
              </Typography>
              <Box
                sx={{
                  border: '1px solid rgba(245, 194, 188, 0.5)',
                  borderRadius: '4px',
                  padding: '15px',
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Gilroy-SemiBold',
                    fontSize: '14px',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}>
                  {state?.selectedTask?.title}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: { xs: 0, sm: '20px' },
                mt: { xs: '15px', sm: 0 },
              }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontFamily: 'Gilroy-SemiBold',
                  lineHeight: '160%',
                  color: '#000',
                  mr: '10px',
                }}>
                Brand
              </Typography>
              <Box
                sx={{
                  border: '1px solid rgba(245, 194, 188, 0.5)',
                  borderRadius: '4px',
                  padding: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Box sx={{ mr: '5px' }}>
                  <img width="16px" src={state?.selectedTask?.brand[0].picture[0].url} />
                </Box>
                <Typography
                  sx={{
                    fontFamily: 'Gilroy-SemiBold',
                    fontSize: '14px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    textAlign: 'center',
                  }}>
                  {state?.selectedTask?.brand[0].name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            mt={3}
            sx={{
              border: { xs: 'none', md: '1.5px solid rgba(188, 206, 245, 0.5)' },
              borderRadius: '10px',
              p: { xs: 0, md: '16px' },
            }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item container xs={12} sm={10} spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontFamily: 'Gilroy-SemiBold',
                        lineHeight: '160%',
                        color: '#000',
                        display: { xs: 'none', md: 'flex' },
                        mr: '20px',
                      }}>
                      {intl.formatMessage({ id: 'calendar_to_do_monthly_calendar' }) +
                        ' ' +
                        moment(state?.selectedTask?.monthlyCalendar[0].dueDate)
                          .add(1, 'months')
                          .format('MMMM YYYY')}
                    </Typography>
                    <Grid item xs={6} sm={3}>
                      <Box
                        sx={{
                          border: '1px solid rgba(245, 194, 188, 0.5)',
                          borderRadius: '4px',
                          padding: '15px',
                        }}>
                        <Typography
                          sx={{
                            fontFamily: 'Gilroy-SemiBold',
                            fontSize: '14px',
                            color: 'rgba(0, 0, 0, 0.87)',
                            textAlign: 'center',
                          }}>
                          {'Task due date: ' +
                            moment(state?.selectedTask?.dueDate).format('DD/MM/YYYY')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Box sx={{ display: 'flex' }}>
                      <Button
                        sx={{
                          fontSize: '12px',
                          fontFamily: 'Gilroy-SemiBold',
                          letterSpacing: '0.46px',
                          color: '#4CAF50',
                          borderRadius: '10px',
                          padding: '10px',
                          background: 'rgba(76, 175, 80, 0.08)',
                          border: '1px solid rgba(76, 175, 80, 0.08)',
                          mr: '10px',
                          ml: '10px',
                        }}>
                        {taskOfCalendar.status}
                      </Button>
                      <ButtonTasks
                        titleBtn={taskOfCalendar?.completionStatus}
                        status={taskOfCalendar?.completionStatus}
                        colorBtn={
                          taskOfCalendar.completionStatus == 'Done'
                            ? 'green'
                            : handleTimeIsAfter(taskOfCalendar.dueDate)
                            ? 'red'
                            : taskOfCalendar.completionStatus != 'Not open yet' &&
                              taskOfCalendar.completionStatus != 'To do'
                            ? 'orange'
                            : ''
                        }
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={6} sm={2}>
                  <Select
                    disabled={
                      !valSelectIdCalendars.taskName ||
                      !valSelectIdCalendars.brand ||
                      loadingFetchUserTasksWithFilters
                        ? true
                        : false
                    }
                    value={valSelectCalendar}
                    onChange={(e) => handleChangeCalSelectCalendar(e.target.value)}
                    sx={{ width: '100%' }}>
                    {allCalendarPeriods &&
                      allCalendarPeriods.map((selectedCalendar) => (
                        <MenuItem value={selectedCalendar.start + '&' + selectedCalendar.end}>
                          {moment(selectedCalendar.start).format('DD/MM/YYYY') +
                            '-' +
                            moment(selectedCalendar.end).format('DD/MM/YYYY')}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid> */}
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
                  {loadingChangeTaskCompletitionStatus ? (
                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button
                      disabled={!markAsDoneDisabled || taskOfCalendar.completionStatus === 'Done'}
                      onClick={handleChangeCompletionStatusMarkAsDone}
                      sx={{
                        fontSize: '14px',
                        fontFamily: 'Gilroy-SemiBold',
                        letterSpacing: '0.46px',
                        color: '#fff',
                        border: '1px solid #EE9A8F',
                        borderRadius: '10px',
                        padding: '10px',
                        background: '#EE9A8F',
                      }}>
                      Mark As Done
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid
              mt={3}
              container
              p={2}
              sx={{
                background: '#5A6092',
                borderRadius: '10px',
                display: { xs: 'none', md: 'flex' },
              }}>
              <Grid item sx={{ width: '20%' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: 'Gilroy-SemiBold',
                    lineHeight: '160%',
                    color: '#fff',
                  }}>
                  Date
                </Typography>
              </Grid>
              <Grid item sx={{ width: '20%' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: 'Gilroy-SemiBold',
                    lineHeight: '160%',
                    color: '#fff',
                  }}>
                  Type
                </Typography>
              </Grid>
              <Grid item sx={{ width: '20%' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: 'Gilroy-SemiBold',
                    lineHeight: '160%',
                    color: '#fff',
                  }}>
                  Category
                </Typography>
              </Grid>
              <Grid item sx={{ width: '40%' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: 'Gilroy-SemiBold',
                    lineHeight: '160%',
                    color: '#fff',
                  }}>
                  Concept
                </Typography>
              </Grid>
            </Grid>
            {loadingFetchUserTasksWithFilters ? (
              <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <BrowserView>
                  {itemsMonthlyCalendars &&
                    itemsMonthlyCalendars?.calendars.map((calendar) => (
                      <Box
                        key={calendar.id}
                        mt={2}
                        p={2}
                        sx={{ border: '1px solid #EE9A8F', borderRadius: '10px' }}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontFamily: 'Gilroy-SemiBold',
                            lineHeight: '160%',
                            color: '#000',
                          }}>
                          {intl.formatMessage({ id: 'calendar_monthly_calendar_week_of' }) +
                            ' ' +
                            moment(calendar.dueDate).format('DD-MM-YYYY')}
                        </Typography>
                        {calendar.publications.map((publication) => (
                          <Box key={publication.id}>
                            <Publication
                              publication={publication}
                              calendarId={calendarId}
                              shouldFieldsBeDisabled={shouldFieldsBeDisabled}
                            />
                          </Box>
                        ))}
                      </Box>
                    ))}
                </BrowserView>
                <MobileView>
                  {itemsMonthlyCalendars &&
                    itemsMonthlyCalendars?.calendars.map((calendar) => (
                      <Box key={calendar.id} mt={2}>
                        <MobilePublication
                          calendar={calendar}
                          calendarId={calendarId}
                          shouldFieldsBeDisabled={shouldFieldsBeDisabled}
                        />
                      </Box>
                    ))}
                </MobileView>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyWork;
