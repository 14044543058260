import { prodRoutes, devRoutes } from './other';
import { NavItemType } from '../types';

// -----------------------|| MENU ITEMS ||-----------------------//

const menuItems: { items: NavItemType[] } = {
  items: [process.env.NODE_ENV === 'development' ? devRoutes : prodRoutes],
};

export default menuItems;
