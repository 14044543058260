import { Box, Typography, CircularProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../../../../components/select';
import confirmEmailImg from '../../../../assets/images/confirmEmailImg.png';
// import Input from '../../../../../components/input';
import Button from '../../../../components/button';
import Link from '../../../../components/link';
import React from 'react';
import ButtonBack from '../../../../components/buttonBack';
// import { Formik } from 'formik';
// import { api } from '../../../../api/index'
import { makeStyles } from '@material-ui/styles';
import ChangeEmailPartial from '../signUp/partials/changeEmailPartial';
import { useMutation } from '@apollo/client';
import { SNACKBAR_OPEN } from 'store/actions';
import { ASK_RESET_PASSWORD } from 'api/mutations/login';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LanguageSwitcher from 'components/languageSwitcher';

const useStyles = makeStyles({
  link: {
    '&:hover': {
      color: 'rgb(245, 115, 98)',
    },
  },
});
function ResendLinkEmail(props: any) {
  const [changeEmail, setChangeEmail] = React.useState(true);
  const dispatch = useDispatch();

  const classes = useStyles();
  const handleEmailState = () => {
    setChangeEmail(false);
  };

  const [askResetPassword, { loading: isAskResetPasswordLoading, error: askResetPasswordError }] =
    useMutation(ASK_RESET_PASSWORD, {
      onCompleted: (data) => {
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Email was sent successfully. Please check your mail',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        const message = JSON.parse(error.message);
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: ' + message?.content,
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
        if (message?.content) {
          console.log(message?.content);
        }
      },
      context: {
        removeHeader: true,
      },
    });

  return (
    <Box sx={{ background: '#fafafa', height: '100vh' }}>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, padding: '30px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/login" colorLink="grey" underline="none">
            <ButtonBack />
          </Link>
        </Box>
        <Box>
          <LanguageSwitcher />

          {/* <Select startAdornmentProp={true} defaultValue="en">
            <MenuItem value={'en'}>En</MenuItem>
            <MenuItem value={'fr'}>Fr</MenuItem>
          </Select> */}
        </Box>
      </Box>
      <Box>
        {changeEmail ? (
          <Box
            sx={{
              width: { xs: '90%', sm: '40%', md: '30%', lg: '25%', xl: '18%' },
              margin: '0 auto',
              textAlign: 'center',
              paddingTop: { xs: '20%', md: '5%' },
            }}>
            <img src={confirmEmailImg} alt="" />
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: 'bold',
                lineHeight: '35px',
                color: '#252A32',
                mb: 2,
                mt: 1,
              }}>
              The link expired
            </Typography>
            <Typography sx={{ fontSize: '14px', lineHeight: '22px', color: '#717A90' }}>
              The link is expired, please resend the link again to your email
            </Typography>
            {isAskResetPasswordLoading ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '50px', margin: '20px auto 0px' }}>
                  <CircularProgress />
                </Box>
              </Box>
            ) : (
              <Button
                sx={{ borderRadius: { xs: '4px', md: '10px' } }}
                titleBtn={<FormattedMessage id="sign_emailconf_resendlink" />}
                colorBtn="white"
                onClick={() => {
                  askResetPassword({
                    variables: { email: localStorage.getItem('email'), type: 'EMAIL_VERIFICATION' },
                  });
                }}
              />
            )}
            <Typography mt={2} sx={{ fontSize: '14px', lineHeight: '22px', color: '#252A32' }}>
              <FormattedMessage id="sign_emailconf_confirmissues" />
            </Typography>
            <Typography
              className={classes.link}
              onClick={handleEmailState}
              sx={{
                fontSize: '14px',
                cursor: 'pointer',
                textDecoration: 'underline',
                lineHeight: '22px',
                color: '#F0998D',
              }}>
              <FormattedMessage id="sign_emailconf_changeemail" />
            </Typography>
          </Box>
        ) : (
          <ChangeEmailPartial />
        )}
      </Box>
    </Box>
  );
}

export default ResendLinkEmail;
