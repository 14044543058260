import React from 'react';

import { Controller } from 'react-hook-form';
// import { useForm } from 'react-hook-form';
import {
  Button,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  TextField,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CrossChip from '../../../assets/images/icons/crossChip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FormattedMessage } from 'react-intl';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface RenderFieldProps {
  control: any;
  setActiveStep: (activeStep: number) => void;
  activeStep: number;
  watch?: any;
  setValue?: any;
  handlStep: () => void;
  field: {
    name: string;
    label: string;
    description?: string;
    type: string;
    title: string;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    labels?: any;
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
    paddingRight: '10px',
    marginBottom: '5px',
  },
  selectedItemBox: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '10px',
    width: '100%',
    background: '#fff',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px 8px 10px',
  },
  title: {
    fontSize: '28px',
    lineHeight: '134%',
    fontFamily: 'Gilroy-SemiBold',
    color: '#252A32',
    marginBottom: '12px',
  },
  description: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '12px',
  },
  chipLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '143%',
  },
  active: {
    border: '1px solid #F0998D',
    background: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '10px',
    width: '100%',
    padding: '5px 20px',
    marginBottom: '5px',
  },
  colotrlabel: {
    '.MuiFormControlLabel-label': {
      color: '#303778',
    },
  },
  colotrlabelActive: {
    '.MuiFormControlLabel-label': {
      color: '#EE9A8F',
      fontFamily: 'Gilroy-SemiBold',
    },
  },
}));
const TagSelectorSelect = ({
  field,
  control,
  activeStep,
  handlStep,
  watch,
  setValue,
}: RenderFieldProps) => {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = React.useState<any>();

  //convert array to { Topic: '', URL: '' }
  const renderObjectFromArray = () => {
    let obj = {};
    field.labels.map((label) => {
      console.log('🔥🔥 ~ field.labels.map ~ label', label);
      Object.assign(obj, { [label]: '' });
    });
    return obj;
  };

  React.useEffect(() => {
    setSelectedItems(watch()[field.name]);
    if (!watch()[field.name]) setValue(field.name, [renderObjectFromArray()]);
  }, []);

  const hadleValue = (e, position) => {
    const list = [...watch()[field.name]];

    const newData = list.map((obj, index) => {
      if (index === position)
        return {
          ...obj,
          [e.target.name]: e.target.value,
        };
      return obj;
    });

    setValue(field.name, newData);
  };

  const onAddBtnClick = (event) => {
    setValue(field.name, [...watch()[field.name], renderObjectFromArray()]);
  };

  const handleRemoveClick = (index) => {
    const list = [...watch()[field.name]];
    list.splice(index, 1);
    setValue(field.name, list);
  };
  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }} alignItems="center">
      {watch()[field.name] &&
        watch()[field.name].map((i, key) => (
          <Grid container xs={12} alignItems="center" justifyContent="space-between" spacing={4}>
            <Grid
              item
              container
              xs={10}
              alignItems="center"
              justifyContent="space-between"
              spacing={1}>
              {field.labels.map((label) => (
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontSize: '12px',
                      lineHeight: '12px',
                      margin: '20px 0px 10px 0px',
                    }}>
                    <FormattedMessage id={label} />
                  </Typography>
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <TextField
                          sx={{ width: '100%' }}
                          value={i[label]}
                          onChange={(e) => hadleValue(e, key)}
                          variant="outlined"
                          placeholder="Select"
                          name={label}
                          inputProps={{
                            autoComplete: 'off',
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            {key !== 0 && (
              <Grid item xs={2}>
                <Button
                  sx={{
                    marginTop: { xs: '0px', md: '44px' },
                    background: 'rgb(240, 153, 141)',
                    padding: '12px 12px !important',
                    '&:hover': { background: 'rgb(240, 153, 141)' },
                    borderRadius: '10px',
                  }}
                  onClick={() => handleRemoveClick(key)}>
                  <DeleteOutlineIcon sx={{ fill: '#fff' }} />
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              {field.labels.length > 2 && <Divider />}
            </Grid>
          </Grid>
        ))}
      <Box sx={{ display: 'flex' }}>
        <Typography
          onClick={onAddBtnClick}
          sx={{
            color: '#F0998D',
            fontSize: '28px',
            lineHeight: '16px',
            cursor: 'pointer',
            fontFamily: 'Gilroy-SemiBold',
            mt: '20px',
            mr: '5px',
          }}>
          +
        </Typography>
        <Typography
          onClick={onAddBtnClick}
          sx={{
            color: '#F0998D',
            textDecoration: 'underline',
            fontSize: '14px',
            lineHeight: '16px',
            cursor: 'pointer',
            fontFamily: 'Gilroy-SemiBold',
            mt: '20px',
          }}>
          Add More
        </Typography>
      </Box>
    </Grid>
  );
};

export default TagSelectorSelect;
