import { gql } from '@apollo/client';

const VALIDATE_SEVERAL_PUBLICATIONS = gql`
mutation ValidateSeveralSublications($ids: [String]!) {
  validate_publications(arg1: {ids: $ids, status: "Approved"}) {
    validatedIds
    errors
  }
}
`;
const VALIDATE_SPECIFIC_PUBLICATIONS = gql`
mutation ValidateSpecificPublication($id: String!, $clientApproval: String!, $commentsOnDesignOpen: String, $commentsOnTextOpen: String, $additionalCommentsOpen: String, $caption: String) {
  update_publications(id: $id, clientApproval: $clientApproval, commentsOnDesignOpen: $commentsOnDesignOpen, commentsOnTextOpen: $commentsOnTextOpen, additionalCommentsOpen: $additionalCommentsOpen, caption: $caption) {
    id
    clientApproval
    commentsOnTextOpen
    commentsOnDesignOpen
    additionalCommentsOpen
    caption
  }
}
`;
const PROVIDE_FEEDBACK = gql`
mutation ProvideFeedback($id: String!, $feedback: String, $rating: Int) {
  update_calendars(id: $id, feedback: $feedback, rating: $rating) {
    id
    calendarId
    rating
    feedback
  }
}
`;
const UPDATE_PUBLICATIONS = gql`
mutation UpdatePublication($id: String!, $category: String, $type: String, $concept: String) {
  update_publications(id: $id, category: $category, type: $type, concept: $concept) {
    id
    dueDate
    category
    type
    concept 
  }
}
`;

export {
  VALIDATE_SEVERAL_PUBLICATIONS,
  VALIDATE_SPECIFIC_PUBLICATIONS,
  PROVIDE_FEEDBACK,
  UPDATE_PUBLICATIONS
};
