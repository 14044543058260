import React from 'react';
import { Controller } from 'react-hook-form';
import {
  LinearProgress,
  Button,
  Typography,
  Grid,
  Box,
  Stack,
  Link,
  Divider,
  TextField,
} from '@material-ui/core';
import ButtonBack from '../../../../components/buttonBack';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import upload from '../../../../assets/images/upload.jpg';
import font from '../../../../assets/images/font.png';
import './upload.css';
import CrossChip from '../../../../assets/images/icons/crossChip';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CheckIcon from '@material-ui/icons/Check';
import { GENERATE_UPLOAD_URL } from 'api/mutations/onBoard';
import { useLazyQuery, useMutation } from '@apollo/client';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import { AnyPtrRecord } from 'dns';
import pdfImg from '../../../../assets/images/pdfImg.png';
interface RenderFieldProps {
  control: any;
  setActiveStep: (activeStep: number) => void;
  activeStep: number;
  fonts?: boolean;
  watch?: any;
  setValue?: any;
  handlStep: () => void;
  field: {
    name: string;
    label: string;
    description?: string;
    type: string;
    title: string;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
function generateDownload(canvas, crop) {
  if (!crop || !canvas) {
    return;
  }
  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'cropPreview.png';
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    'image/png',
    1,
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
    paddingRight: '10px',
    marginBottom: '5px',
  },
  active: {
    border: '1px solid #F0998D',
    background: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '10px',
    paddingRight: '10px',
    marginBottom: '5px',
  },
  iconCheck: {
    fill: 'rgb(240, 153, 141)',
  },
}));
const UploadFile = ({
  field,
  control,
  activeStep,
  handlStep,
  fonts,
  watch,
  setValue,
}: RenderFieldProps) => {
  const classes = useStyles();
  const [crop, setCrop] = React.useState<any>({
    aspect: field.constraints.cropping.width_factor / field.constraints.cropping.height_factor,
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const imgRef = React.useRef(null);
  const previewCanvasRef = React.useRef(null);
  const [progress, setProgress] = React.useState(0);
  const [progressBar, setProgressBar] = React.useState(false);
  const [croppedStatus, setCroppedStatus] = React.useState(false);
  const [urlCrop, setUrlCrop] = React.useState<any>();
  const [reUoloadImg, setReUoloadImg] = React.useState<any>();
  const [cropImage, setCropImage] = React.useState<any>();
  const [completedCrop, setCompletedCrop] = React.useState<any>();
  const [urlImgS3, setUrlImgS3] = React.useState('');
  const [path, setPath] = React.useState('');
  const [newPath, setNewPath] = React.useState('');
  const [fileToUpload, setfileToUpload] = React.useState(null);
  const [multipleFileToUpload, setMultipleFileToUpload] = React.useState<any>([]);
  const [chipElements, setChipElements] = React.useState<any>([]);
  const [formatFile, setFormatFile] = React.useState(false);
  const [namePDF, setNamePDF] = React.useState('');

  const [generateUploadUrl, { data, loading, error }] = useMutation(GENERATE_UPLOAD_URL);

  React.useEffect(() => {
    if (fonts) {
      if (watch()[field.name]) {
        //remove elements with the same name
        const removedDuplicatedElelements =
          watch()[field.name] &&
          watch()[field.name].filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

        setChipElements(removedDuplicatedElelements);
      } else {
        setValue(field.name, []);
      }
    }
    if (!fonts && watch()[field.name]) {
      setCropImage(watch()[field.name][0]);
      //To fix later, this gives error

      // setUrlCrop(
      //   watch()[field.name].map(
      //     (file) =>
      //       file &&
      //       Object.assign(file, {
      //         preview: URL.createObjectURL(file.preview),
      //       }),
      //   ),
      // );
    }
  }, [field.name, watch()[field.name]]);

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles[0].type === 'application/pdf' || acceptedFiles[0].type === 'pdf') {
      setNamePDF(acceptedFiles[0].name);
      setFormatFile(true);
    } else setFormatFile(false);
    setfileToUpload(acceptedFiles[0]);
    setProgressBar(true);

    //handleUpload for multiple fonts and later for multiple files
    if (fonts === true) {
      let filesNamesAndUrls: { name: string; fileUrl: string }[] = [...chipElements];
      acceptedFiles.map((element, index) => {
        generateUploadUrl({
          variables: {
            path: `${field.constraints.s3_upload_path}${element.name}`,
          },
          onCompleted: (data) => {
            axios({
              method: 'put',
              url: data.generate_upload_url.url,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: element,
            })
              .then(function (response) {
                // setMultipleFileToUpload([
                //   ...multipleFileToUpload,
                //   data.generate_upload_url.url.split('?X-Amz-Algorithm')[0],
                // ]);
                setValue(field.name, [
                  ...watch()[field.name],
                  {
                    name: element.name,
                    fileUrl: data.generate_upload_url.url.split('?X-Amz-Algorithm')[0],
                  },
                ]);

                filesNamesAndUrls.push({
                  name: element.name,
                  fileUrl: data.generate_upload_url.url.split('?X-Amz-Algorithm')[0],
                });
                index === acceptedFiles.length - 1 && setProgressBar(false);
              })
              .catch(function (error) {
                setProgressBar(false);
                console.log(error);
              });
          },
        });
      });

      // setChipElements(filesNamesAndUrls);
    } else {
      setPath(`${field.constraints.s3_upload_path}${acceptedFiles[0].name}`);
      generateUploadUrl({
        variables: {
          path: `${field.constraints.s3_upload_path}${acceptedFiles[0].name}`,
        },
        onCompleted: (data) => {
          setUrlImgS3(data.generate_upload_url.url);
          setNewPath(data.generate_upload_url.url.split('?X-Amz-Algorithm')[0]);
          axios({
            method: 'put',
            url: data.generate_upload_url.url,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: acceptedFiles[0],
          })
            .then(function (response) {
              setValue(field.name, data.generate_upload_url.url.split('?X-Amz-Algorithm')[0]);
              console.log('datadatadata', data);
              setProgressBar(false);
              // console.log('🔥🔥 ~ response axios', response);
            })
            .catch(function (error) {
              setProgressBar(false);
              console.log(error);
            });
        },
      });

      // if (!watch()[field.name]) setValue(field.name, undefined);
      // setValue(field.name, acceptedFiles) ;
      // setCropImage(watch()[field.name][0]);
      // setUrlCrop(
      //   watch()[field.name].map((file) =>
      //     Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     }),
      //   ),
      // );
    }
    const timer = setInterval(() => {
      setProgress((startTimer) => {
        const diff = 30;
        return Math.min(startTimer + diff, 100);
      });
    }, 500);
  };
  const handleDeleteChip = (fileUrl) => {
    let arrName = chipElements;
    let filteredArr = arrName.filter((el) => el.fileUrl !== fileUrl);
    setChipElements(filteredArr);
    setValue(field.name, filteredArr);
  };

  const onImageLoaded = React.useCallback((img) => {
    imgRef.current = img;
  }, []);

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
  }, [completedCrop]);
  const onCropChange = (crop) => {
    setCrop(crop);
    setCroppedStatus(true);
  };

  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }}>
      <Grid container spacing={1}>
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Grid item xs={12} md={6} sx={{ width: '100%', textAlign: 'center' }}>
                {watch()[field.name] !== undefined &&
                fonts !== true &&
                field.constraints.cropping.isAllowed ? (
                  <Box>
                    <ReactCrop
                      className="crop"
                      // circularCrop={true}
                      src={watch()[field.name]}
                      crop={crop}
                      onImageLoaded={onImageLoaded}
                      onComplete={(c) => setCompletedCrop(c)}
                      onChange={onCropChange}
                      style={{ maxWidth: '600px', maxHeight: '800px' }}
                    />
                    {/* <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                      }}
                    />
                    {croppedStatus && (
                      <Box
                        sx={{
                          width: '100%',
                          padding: '15px',
                          border: '1px solid rgb(240, 153, 141)',
                          borderRadius: '10px',
                          marginTop: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <CheckIcon className={classes.iconCheck} />
                        <Typography
                          sx={{
                            fontSize: '20px',
                            lineHeight: '16px',
                            fontFamily: 'Gilroy',
                            color: 'rgb(240, 153, 141)',
                            marginLeft: '10px',
                          }}>
                          Cropped
                        </Typography>
                      </Box>
                    )} */}
                    <Box sx={{ display: 'flex', marginTop: '20px' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          lineHeight: '16px',
                          fontFamily: 'Gilroy-SemiBold',
                          color: '#252A32',
                        }}>
                        Wanna change Profile Picture?
                      </Typography>
                      <Dropzone
                        multiple={false}
                        {...(field.constraints.allowed_extensions.length > 0 && {
                          accept: field.constraints.allowed_extensions.map(
                            (extension) => extension.mime_type,
                          ),
                        })}
                        onDrop={(acceptedFiles) => {
                          handleDrop(acceptedFiles);
                        }}>
                        {({ getRootProps, getInputProps }) => (
                          <label className="lableUpload">
                            <input {...getInputProps()} />
                            Re-upload
                          </label>
                        )}
                      </Dropzone>
                    </Box>
                  </Box>
                ) : watch()[field.name] && !fonts ? (
                  <Box>
                    <Box sx={{ width: '100%' }}>
                      {!formatFile ? (
                        <img
                          width="100%"
                          src={watch()[field.name]}
                          style={{ maxHeight: '500px', objectFit: 'cover' }}
                        />
                      ) : (
                        <Box>
                          <img
                            width="70px"
                            src={pdfImg}
                            style={{ maxHeight: '500px', objectFit: 'cover' }}
                          />

                          <Typography>{namePDF}</Typography>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', marginTop: '20px' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          lineHeight: '16px',
                          fontFamily: 'Gilroy-SemiBold',
                          color: '#252A32',
                        }}>
                        Wanna change Profile Picture?
                      </Typography>
                      <Dropzone
                        multiple={false}
                        {...(field.constraints.allowed_extensions.length > 0 && {
                          accept: field.constraints.allowed_extensions.map(
                            (extension) => extension.mime_type,
                          ),
                        })}
                        // accept={field.constraints.allowed_extensions.map(
                        //   (extension) => extension.mime_type,
                        // )}
                        onDrop={(acceptedFiles) => {
                          handleDrop(acceptedFiles);
                        }}>
                        {({ getRootProps, getInputProps }) => (
                          <label className="lableUpload">
                            <input {...getInputProps()} />
                            Re-upload
                          </label>
                        )}
                      </Dropzone>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Dropzone
                      multiple={fonts === true ? true : false}
                      {...(field.constraints.allowed_extensions.length > 0 && {
                        accept: field.constraints.allowed_extensions.map(
                          (extension) => extension.mime_type,
                        ),
                      })}
                      onDrop={(acceptedFiles) => {
                        handleDrop(acceptedFiles);
                      }}>
                      {({ getRootProps, getInputProps }) => (
                        <section style={{ cursor: 'pointer' }}>
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            {fonts ? (
                              <img src={upload} alt="upload" />
                            ) : (
                              // <span>Files</span>
                              // <img src={font} alt="font" />
                              <img src={upload} alt="upload" />
                            )}
                            <Typography
                              sx={{
                                marginTop: '15px',
                                marginBottom: '10px',
                                color: '#717A90',
                                fontFamily: 'Gilroy-SemiBold',
                                fontSize: '14px',
                                lineHeight: '130%',
                              }}>
                              Drag Your File Here, or <span>browse</span>
                            </Typography>
                            <Typography>
                              Accept{' '}
                              {field.constraints.allowed_extensions
                                .map((extension) => extension.extension)
                                .join(', ')}
                            </Typography>
                          </div>
                        </section>
                      )}
                    </Dropzone>

                    {field.constraints.additional_text_field.show && (
                      <Box>
                        <Box sx={{ width: '100%', py: 3 }}>
                          <Divider>Or</Divider>
                        </Box>
                        <Box sx={{ width: '100%', pb: 1 }}>
                          <Box sx={{ width: '100%', pb: 1, textAlign: 'left' }}>
                            <Typography variant="h4">
                              {field.constraints.additional_text_field.title}
                            </Typography>
                          </Box>

                          <TextField
                            onChange={(e) =>
                              setValue(`${field.name}-additionalLink`, e.target.value)
                            }
                            value={watch()[`${field.name}-additionalLink`]}
                            id="outlined-basic"
                            placeholder={field.constraints.additional_text_field.placeholder}
                            variant="outlined"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                sx={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                {
                  chipElements.length > 0 && fonts === true
                    ? chipElements.map((file, index) => (
                        <Box
                          key={index}
                          sx={{
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            borderRadius: '10px',
                            width: '100%',
                            background: '#fff',
                            marginBottom: '5px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '10px 0px 8px 10px',
                          }}>
                          <Typography>{file.name}</Typography>
                          <Box>
                            <Button
                              sx={{ padding: '0px', marginRight: '-10px' }}
                              onClick={() => handleDeleteChip(file.fileUrl)}>
                              <CrossChip />
                            </Button>
                          </Box>
                        </Box>
                      ))
                    : urlImgS3 && fonts === false && null
                  // <Box sx={{ marginLeft: '20px' }}>
                  //   <Typography
                  //     sx={{
                  //       color: '#252A32',
                  //       fontFamily: 'Gilroy-SemiBold',
                  //       fontSize: '20px',
                  //       lineHeight: '143%',
                  //     }}>
                  //     Profile
                  //     {/* { reUoloadImg.name} */}
                  //   </Typography>
                  //   <Typography>
                  //     {/* {(reUoloadImg.size / 1024 / 1024).toFixed(2)}{' '} */}
                  //     Mb
                  //   </Typography>
                  // </Box>
                }
              </Grid>
            </>
          )}
        />
      </Grid>
      {progressBar && (
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            padding: '15px',
            border: '1px solid #E1E1E1',
            borderRadius: '10px',
            marginTop: '20px',
            position: 'relative',
          }}>
          <Typography
            sx={{
              color: '#000',
              fontFamily: 'Gilroy',
              fontSize: '14px',
              lineHeight: '143%',
              marginBottom: '10px',
            }}>
            Uploading . . .
          </Typography>
          {/* <LinearProgress variant="determinate" value={progress} sx={{ width: '80%' }} /> */}
          <LinearProgress sx={{ width: '80%' }} />
          {/* <Box sx={{ display: 'flex', position: 'absolute', top: '10px', right: '10px' }}>
            <Typography
              sx={{
                color: '#F0998D',
                fontFamily: 'Gilroy',
                fontSize: '10px',
                lineHeight: '14px',
                marginRight: '10px',
              }}>
              50%
            </Typography>
            <Typography
              sx={{ color: '#717A90', fontFamily: 'Gilroy', fontSize: '10px', lineHeight: '14px' }}>
              30s Left
            </Typography>
          </Box> */}
        </Box>
      )}
      {fonts === true &&
        (field.name == 'documents' ? (
          ''
        ) : (
          <Stack direction="row" mt={3} sx={{ width: '100%' }}>
            {/* <Typography
              sx={{
                color: '#252A32',
                fontFamily: 'Gilroy-SemiBold',
                fontSize: '14px',
                lineHeight: '16px',
              }}>
              You don’t have any fonts?
            </Typography> */}
            {/* <Link
              href="#"
              underline="always"
              sx={{
                color: '#F0998D',
                fontFamily: 'Gilroy',
                fontSize: '14px',
                lineHeight: '16px',
                marginLeft: '3px',
              }}>
              Click Here
            </Link> */}
          </Stack>
        ))}
    </Grid>
  );
};

export default UploadFile;
