import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Typography,
  Grid,
  Box,
  Slider,
  Button,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import Colorful from '@uiw/react-color-colorful';
import { makeStyles } from '@material-ui/core/styles';
import CrossChipColor from '../../../assets/images/icons/crossChipColor';
// import { color } from '@uiw/color-convert';
import { RgbaColorPicker } from 'react-colorful';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { HexColorPicker } from 'react-colorful';

interface RenderFieldProps {
  control: any;
  activeStep: number;
  handlStep: () => void;
  watch?: any;
  setValue?: any;
  setActiveStep: (activeStep: number) => void;
  field: {
    label: string;
    name: string;
    type: string;
    title: string;
    placeholder: string;
    subtitle?: any;
    alert?: any;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  btn: {
    border: '1px solid #F0998D ',
    backgound: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '10px',
    lineHeight: '143%',
    width: '100%',
    fontFamily: 'Gilroy-SemiBold',
    '&:hover': {
      background: '#F0998D',
      color: '#fff',
    },
  },
  bgHex: {},
}));
const BrandColor = ({
  field,
  control,
  activeStep,
  handlStep,
  watch,
  setValue,
}: RenderFieldProps) => {
  const classes = useStyles();
  const [hex, setHex] = React.useState('#000000');
  const [colorType, setColorType] = React.useState('hex');
  const [colorRgba, setColorRgba] = React.useState('rgba(0, 0, 0, 1)');
  const [color, setColor] = React.useState({ r: 0, g: 0, b: 0, a: 1 });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // const { rgb, rgba, hsl, hsv, hsla, hsva } = color(hex);
  React.useEffect(() => {
    if (!watch()[field.name]) {
      setValue(field.name, []);
    }
  }, []);
  const handleColor = () => {
    let colorArr = watch()[field.name];
    if (colorType === 'hex') {
      colorArr.push({ id: colorArr.length + 1, color: hex });
      setValue(field.name, colorArr);
    } else {
      colorArr.push({
        id: colorArr.length + 1,
        color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      });

      setValue(field.name, colorArr);
    }
  };
  const handleDeleteChip = (i: any) => {
    let colorArrDelete = watch().brand_colors;
    const filteredChips = colorArrDelete.filter((item) => item.id !== i.id);
    setValue(field.name, filteredChips);
  };
  const handleColorInput = (e) => {
    if (colorType === 'hex') {
      setHex(e.target.value);
    } else {
      setColorRgba(e.target.value);
      if (e.target.value.indexOf(')') !== -1) {
        var valColor = e.target.value.split('(')[1].split(')')[0].split(',');
        console.log(valColor);
        var transparency;
        if (valColor.length > 3) {
          transparency = valColor[3];
        } else {
          transparency = 1;
        }
        let newRgba = { r: +valColor[0], g: +valColor[1], b: +valColor[2], a: +transparency };
        setColor(newRgba);
      }
    }
  };
  const handleChangeColorRgba = (color) => {
    setColorRgba(`rgba(r:${color.r}, g:${color.g}, b:${color.b}, a:${color.a})`);
    setColor(color);
  };
  return (
    <Grid container xs={12} sx={{ padding: { xs: '200px 15px 0px', md: '0px 15px' } }}>
      <Controller
        key={field.name}
        name={field.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Grid container spacing={2}>
            {value && (
              <Grid item xs={12} md={6} lg={4} className="reponsive">
                {/* <Colorful
                  className="colorPicker"
                  color={hex}
                  onChange={(color) => {
                    console.log('color', color);
                    setHex(color);
                  }}
                /> */}
                {colorType === 'hex' ? (
                  <HexColorPicker
                    color={hex}
                    onChange={(color) => {
                      setHex(color);
                    }}
                  />
                ) : (
                  <RgbaColorPicker
                    color={color}
                    onChange={(color) => {
                      handleChangeColorRgba(color);
                    }}
                  />
                )}
              </Grid>
            )}
            <Grid item xs={10} md={4}>
              <Box sx={{ border: '1px solid #E1E1E1', borderRadius: '10px', padding: '15px' }}>
                <Box sx={{ display: 'flex', color: '#000', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Typography
                      aria-describedby={id}
                      onClick={handleClick}
                      sx={{ fontSize: '11px', lineHeight: '16px', textTransform: 'uppercase' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {colorType}
                        <KeyboardArrowDownIcon
                          sx={{
                            ml: '10px',
                            fontSize: '15px',
                            mt: '-2px',
                            fill: 'rgb(156, 156, 156)',
                          }}
                        />
                      </Box>
                    </Typography>
                  </Box>
                  <Popover
                    id={id}
                    sx={{ '.MuiPopover-paper': { borderRadius: '5px', pl: '25px' } }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}>
                    <RadioGroup
                      value={colorType}
                      onChange={(e) => {
                        setColorType(e.target.value);
                        setAnchorEl(null);
                      }}
                      aria-label="gender"
                      defaultValue="hex"
                      name="radio-buttons-group">
                      <FormControlLabel
                        sx={{
                          '.MuiFormControlLabel-label': {
                            color: '#000',
                            fontSize: '11px',
                            padding: '10px',
                            lineHeight: '16px',
                          },
                        }}
                        control={<Radio value="hex" sx={{ display: 'none' }} />}
                        label="HEX"
                      />
                      <FormControlLabel
                        sx={{
                          '.MuiFormControlLabel-label': {
                            color: '#000',
                            fontSize: '11px',
                            padding: '10px',
                            lineHeight: '16px',
                          },
                        }}
                        control={<Radio value="rgba" sx={{ display: 'none' }} />}
                        label="RGBA"
                      />
                    </RadioGroup>
                  </Popover>
                  {/* <Typography sx={{ fontSize: '11px', lineHeight: '16px' }}>{colorType === 'hex' ? hex : `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`}</Typography> */}
                  {/* <input
                    className="inputColor"
                    value={colorType === 'hex' ? hex : colorRgba}
                    onChange={(e: any) => handleColorInput(e)}
                  /> */}
                  <TextField
                    id="standard-basic"
                    className="inputColor"
                    placeholder={colorType === 'hex' ? '#FF0000' : '(255,255,0)'}
                    variant="standard"
                    value={colorType === 'hex' ? hex : colorRgba}
                    onChange={(e: any) => handleColorInput(e)}
                  />
                  {/* <Typography sx={{ fontSize: '11px', lineHeight: '16px' }}>100%</Typography> */}
                </Box>
                <Grid sx={{ marginTop: '-5px' }} container spacing={2}>
                  <Grid item xs={5}>
                    {colorType === 'hex' ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '35px',
                          borderRadius: '60px',
                          background: `${hex}`,
                        }}></Box>
                    ) : (
                      <Box
                        sx={{
                          width: '100%',
                          height: '35px',
                          borderRadius: '60px',
                          background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                        }}></Box>
                    )}
                  </Grid>
                  <Grid item xs={7}>
                    <Button className={classes.btn} onClick={handleColor}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                {value &&
                  value.map((i) => {
                    console.log(i.color);
                    return (
                      <Box
                        key={i.id}
                        sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            background: `${i.color}`,
                          }}></Box>
                        <Typography
                          sx={{
                            fontSize: '11px',
                            lineHeight: '16px',
                            textTransform: 'uppercase',
                            margin: '8px 0px 0px -20px',
                          }}>
                          {i.color}
                        </Typography>
                        <Box>
                          <Button
                            sx={{ padding: '0px', margin: '6px -20px 0px 0px' }}
                            onClick={() => {
                              handleDeleteChip(i);
                            }}>
                            <CrossChipColor />
                          </Button>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
};

export default BrandColor;
