import React from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../button';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px',
    fontSize: '16px',
    lineHeight: '150%',
    fontFamily: 'Gilroy',
  },
  root: {
    border: '1px solid #BDBDBD',
    borderRadius: '10px',
    paddingRight: '10px',
  },
}));
interface DialogProps {
  valuesSelectOrerBy: any;
  handleValuesSelectOrerBy: any;
  applyFilters: () => void;
  setSortByDateValue: any;
  sortByDateValue: string;
  loadingGetFilteredTasks: boolean;
  sortByField: any;
  setSortByField: any;
}

const sortByDescValues = [
  {
    title: 'Ascending',
    value: 'asc',
  },
  {
    title: 'Descending',
    value: 'desc',
  },
];

const sortByFields = [
  {
    title: 'Due Date',
    value: 'dueDate',
  },
  {
    title: 'Brand',
    value: 'brand',
  },
];
const DialogTaks = ({
  valuesSelectOrerBy,
  handleValuesSelectOrerBy,
  applyFilters,
  setSortByDateValue,
  sortByDateValue,
  loadingGetFilteredTasks,
  sortByField,
  setSortByField,
}: DialogProps) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        background: '#FFFFFF',
        boxShadow: '0px 9px 46px 8px rgba(0, 0, 0, 0.08)',
        borderRadius: '10px',
        padding: '20px 15px 15px',
        maxWidth: '445px',
        zIndex: '1111',
      }}>
      <Box>
        <Typography mt={2} className={classes.title}>
          <FormattedMessage id="tasks_order_by" />
        </Typography>
        <RadioGroup
          onChange={(event) => setSortByField(event.target.value)}
          value={sortByField}
          sx={{ ml: '10px' }}
          row
          name="orderBy"
          aria-label="Categories">
          {sortByFields.map((sortByDescField) => (
            <FormControlLabel
              key={sortByDescField.value}
              sx={{
                marginTop: '10px',
                '.MuiFormControlLabel-label': {
                  fontSize: '12px',
                },
                '.MuiFormControlLabel-root': {
                  color: '#BDBDBD',
                },
              }}
              className={classes.root}
              value={sortByDescField.value}
              control={
                <Radio
                  sx={{
                    '&.Mui-checked': {
                      color: '#EE9A8F',
                    },
                  }}
                />
              }
              label={sortByDescField.title}
            />
          ))}
        </RadioGroup>
      </Box>

      <Box>
        <Typography mt={2} className={classes.title}>
          <FormattedMessage id="tasks_sort" />
        </Typography>
        <RadioGroup
          onChange={(event) => setSortByDateValue(event.target.value)}
          value={sortByDateValue}
          sx={{ ml: '10px' }}
          row
          name="sort"
          aria-label="Status">
          {sortByDescValues.map((sortByDescValue) => (
            <FormControlLabel
              key={sortByDescValue.title}
              sx={{
                marginTop: '10px',
                '.MuiFormControlLabel-label': {
                  fontSize: '12px',
                },
              }}
              className={classes.root}
              value={sortByDescValue.value}
              control={
                <Radio
                  sx={{
                    '&.Mui-checked': {
                      color: '#EE9A8F',
                    },
                  }}
                />
              }
              label={sortByDescValue.title}
            />
          ))}
        </RadioGroup>
      </Box>
      <Box mt={1}>
        <Button
          onClick={applyFilters}
          titleBtn={
            loadingGetFilteredTasks ? (
              <CircularProgress size="25px" sx={{ color: '#fff' }} />
            ) : (
              <FormattedMessage id="tasks_apply" />
            )
          }
          colorBtn="red"
        />

        {/* <Button onClick={} titleBtn="Apply" colorBtn="red" /> */}
      </Box>
    </Box>
  );
};

export default DialogTaks;
