import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, ButtonBase, Grid } from '@material-ui/core';
import stepsLogo from '../../../assets/icons/steps-logo.png';
// project imports
import LogoSection from '../LogoSection';
import LocalizationSection from './LocalizationSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import MenuIcon from '@material-ui/icons/Menu';
// assets
import { IconMenu2 } from '@tabler/icons';
import useAuth from '../../../hooks/useAuth';
import LanguageSwitcher from 'components/languageSwitcher';
// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color:
      theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const classes = useStyles();
  const { user } = useAuth();
  console.log('useruser', user);
  return (
    <Box sx={{ borderRadius: '0 0 30px 30px', width: '100%' }}>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit">
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase> */}
      </div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <MenuIcon onClick={handleLeftDrawerToggle} sx={{ fill: '#EE9A8F' }} />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: '83px', margin: '0 auto' }}>
            <img src={stepsLogo} />
          </Box>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* <Box
            sx={{
              color: '#fff',
              background: '#EE9A8F',
              width: '35px',
              height: '35px',
              p: '9px 0',
              textTransform: 'uppercase',
              borderRadius: '10px',
              textAlign: 'center',
            }}>
            A
          </Box> */}
          <Box>
            <LanguageSwitcher />
          </Box>
        </Grid>
      </Grid>
      {/* header search */}
      {/* <div className={classes.grow} />
      <div className={classes.grow} /> */}

      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }} style={{ paddingRight: 20 }}>
        <LocalizationSection />
      </Box> */}

      {/* <ProfileSection /> */}

      {/* mobile header */}
      {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box> */}
    </Box>
  );
};

export default Header;
