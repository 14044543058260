import { gql } from '@apollo/client';

const GET_MANAGER_WORKFLOW = gql`
  query fetchManagerWorfklow {
    fetch_manager_workflow {
      brands
    }
  }
`;

export { GET_MANAGER_WORKFLOW };