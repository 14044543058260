import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  AccordionDetails,
  Box,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
  Checkbox,
  Dialog,
  MenuItem,
  CircularProgress,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Select from '../../select';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { useMutation } from '@apollo/client';
import Carousel from 'react-material-ui-carousel';
import {
  VALIDATE_SPECIFIC_PUBLICATIONS,
  VALIDATE_SEVERAL_PUBLICATIONS,
} from '../../../api/mutations/calendar/weekly';
import moment from 'moment';
import { GET_WEEKLY_CALENDARS } from '../../../api/queries/calendar/weekly';
import EmojiPicker from 'emoji-picker-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageViewer from 'react-simple-image-viewer';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles((theme) => ({
  root: {},
  weekCardContainer: {
    background: '#FFFFFF',
    // border: '1px solid rgba(131, 136, 173, 0.5)',
    // boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '8px',
    marginBottom: '15px',
  },
  weekCardLabel: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '4px',
  },
  weekCardDescription: {
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '10px',
    padding: '20px',
  },
  weekCardTextarea: {
    width: '100%',
    margin: '0 4px',
    background: 'rgba(0, 0, 0, 0.06)',
    border: 'none',
    borderRadius: '10px',
  },
  textFieldBackground: {
    background: 'white',
  },
  textFieldBackgroundDisabled: {
    background: 'rgba(0, 0, 0, 0.06);',
  },
  divider: {
    backgroundColor: '#C4C4C4',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  accordionTitleContainer: {
    display: 'block',
  },
  accordionTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    // color: 'rgba(0, 0, 0, 0.87)',
    color: '#EE9A8F',
    padding: '8px 0',
  },
}));
interface RenderProps {
  publications: any;
  allWeekCalendars?: any;
  handleStepChange?: any;
  valSelectWeek?: string;
  indexOfTheStep?: number;
  periodSelected?: string;
  sharedCalendar?: boolean;
  border?: boolean;
}
const WeekCalendarCard = ({
  publications,
  allWeekCalendars,
  handleStepChange,
  valSelectWeek,
  indexOfTheStep,
  periodSelected,
  sharedCalendar,
  border,
}: RenderProps) => {
  const classes = useStyles();
  const RenderCollapse = ({ publication }) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [active, setActive] = React.useState(true);
    const [currentPublicationToEdit, setCurrentPublicationToEdit] = React.useState<any>();
    const [openEmoji, setOpenEmoji] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    console.log('disableddisabled', disabled)
   
    const onEmojiClick = (event, emojiObject) => {
      setCurrentPublicationToEdit({
        ...currentPublicationToEdit,
        caption: currentPublicationToEdit.caption + emojiObject.emoji,
      });
      setOpenEmoji(false);
    };
    const dispatch = useDispatch();
    const [
      validateSpecificPublication,
      { loading: loadingFeedbackMonthly, error: errorFeedbackMonthly },
    ] = useMutation(VALIDATE_SPECIFIC_PUBLICATIONS, {
      onCompleted: (data) => {
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Comment sent successfully',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        const message = JSON.parse(error.message);
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: ' + message?.content,
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
      },
    });
    const handleValidateSpecific = () => {
      validateSpecificPublication({
        variables: {
          id: publication.id,
          clientApproval: 'Changes required',
          commentsOnTextOpen: currentPublicationToEdit.commentsOnTextOpen
            ? currentPublicationToEdit.commentsOnTextOpen
            : '',
          commentsOnDesignOpen: currentPublicationToEdit.commentsOnDesignOpen
            ? currentPublicationToEdit.commentsOnDesignOpen
            : '',
          additionalCommentsOpen: currentPublicationToEdit.additionalCommentsOpen
            ? currentPublicationToEdit.additionalCommentsOpen
            : '',
          caption: currentPublicationToEdit.caption ? currentPublicationToEdit.caption : '',
        },
        refetchQueries: [{ query: GET_WEEKLY_CALENDARS }],
      });
    };
    const [validateSeveralPublication, { loading: loadingValidateOne, error: errorValidateOne }] =
      useMutation(VALIDATE_SEVERAL_PUBLICATIONS, {
        onCompleted: (data) => {
          dispatch({
            type: SNACKBAR_OPEN,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            transition: 'SlideLeft',
            open: true,
            message: 'Publication have been successfully updated',
            variant: 'alert',
            alertSeverity: 'success',
            autoHideDuration: '6000',
          });
          setDisabled(true)
        },
        onError: (error) => {
          const message = JSON.parse(error.message);
          dispatch({
            type: SNACKBAR_OPEN,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            transition: 'SlideLeft',
            open: true,
            message: 'Error: ' + message?.content,
            variant: 'alert',
            alertSeverity: 'error',
            autoHideDuration: '6000',
          });
        },
        refetchQueries: [
          { query: GET_WEEKLY_CALENDARS, variables: { taskClientReviewStatus: ['Not open yet'] } },
        ],
      });
    const handleValidateOne = () => {
      validateSeveralPublication({
        variables: {
          ids: [`${publication.id}`],
          // status: "Approved"
        },
      });
    };
    React.useEffect(() => {
      setCurrentPublicationToEdit({
        commentsOnTextOpen: publication.commentsOnTextOpen ? publication.commentsOnTextOpen : '',
        commentsOnDesignOpen: publication.commentsOnDesignOpen
          ? publication.commentsOnDesignOpen
          : '',
        additionalCommentsOpen: publication.additionalCommentsOpen
          ? publication.additionalCommentsOpen
          : '',
        caption: publication.caption ? publication.caption : '',
      });
    }, [publication]);
    const handleAdditionalComments = (e) => {
      console.log('🔥🔥 ~ handleAdditionalComments ~ e.target.value', e.target.name);
      console.log('🔥🔥 ~ handleAdditionalComments ~ e.target.value', e.target.value);
      if (e.target.name === 'additionalCommentsOpen') {
        setCurrentPublicationToEdit({
          ...currentPublicationToEdit,
          additionalCommentsOpen: e.target.value,
        });
      }
      if (e.target.name === 'commentsOnDesignOpen') {
        setCurrentPublicationToEdit({
          ...currentPublicationToEdit,
          commentsOnDesignOpen: e.target.value,
        });
      }
      if (e.target.name === 'commentsOnTextOpen') {
        setCurrentPublicationToEdit({
          ...currentPublicationToEdit,
          commentsOnTextOpen: e.target.value,
        });
      }
      if (e.target.name === 'caption') {
        setCurrentPublicationToEdit({
          ...currentPublicationToEdit,
          caption: e.target.value,
        });
      }
    };
    const openImageViewer = React.useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    return (
      <Box>
        {publication && (
          // <Accordion
          //   expanded={active}
          //   //  onChange={() => setActive(!active)}
          // >
          //   <AccordionSummary
          //     sx={{
          //       '.MuiAccordionSummary-content': { margin: '0 !important' },
          //       cursor: 'default !important',
          //       p: '0 0 0 16px',
          //     }}
          //     expandIcon={
          //       <ExpandMoreIcon onClick={() => setActive(!active)} sx={{ cursor: 'pointer' }} />
          //     }
          //     aria-controls="panel1a-content"
          //     id="panel1a-header">
          //     <Grid container justifyContent={'space-between'} alignItems="center">
          //       <Grid item xs={12} sm={6}>
          //         <Box>
          //           <Typography className={classes.accordionTitle}>
          //             {publication.publicationTime && (
          //               <span>
          //                 {moment(publication.publicationTime).format('DD/MM')} @{' '}
          //                 {moment(publication.publicationTime).format('HH.mm')}{' '}
          //               </span>
          //             )}{' '}
          //             - {publication.concept}
          //           </Typography>
          //           {/* <ExpandLessIcon /> */}
          //         </Box>
          //       </Grid>
          //       <Grid
          //         item
          //         xs={12}
          //         sm={6}
          //         container
          //         justifyContent={matchDownXs ? 'flex-start' : 'flex-end'}>
          //         <Box
          //           sx={{
          //             display: 'flex',
          //             flexDirection: 'row',
          //             ml: { xs: '0', sm: '10px' },
          //           }}>
          //           {/* <Button
          //             disabled
          //             sx={{
          //               background: 'white',
          //               margin: '1px',
          //               border: '1px solid #EE9A8F',
          //               borderRadius: '4px',
          //               color: '#E1E1E1',
          //               padding: '0px',
          //               minWidth: { xs: '40px', md: '40px' },
          //               mr: '2px',
          //               '&:disabled': {
          //                 border: '1px solid rgba(0, 0, 0, 0.06) !important',
          //                 color: 'rgba(0, 0, 0, 0.06) !important',
          //               },
          //             }}>
          //             <DeleteIcon fill="rgba(0, 0, 0, 0.06)" />
          //           </Button>
          //           <Button
          //             disabled
          //             sx={{
          //               background: 'white',
          //               margin: '1px',
          //               border: '1px solid #EE9A8F',
          //               borderRadius: '4px',
          //               color: '#E1E1E1',
          //               minWidth: { xs: '40px', md: '40px' },
          //               mr: '3px',
          //               '&:disabled': {
          //                 border: '1px solid rgba(0, 0, 0, 0.06) !important',
          //                 color: 'rgba(0, 0, 0, 0.06) !important',
          //               },
          //             }}>
          //             <EditeIcon fill="rgba(0, 0, 0, 0.06)" />
          //           </Button> */}
          //           {publication.clientApproval !== 'Approved' ? (
          //             <Button
          //               // disabled={(currentPublicationToEdit.clientApproval == null || currentPublicationToEdit.clientApproval == "Waiting for client's approval") && !currentPublicationToEdit.commentsOnTextOpen && !currentPublicationToEdit.commentsOnDesignOpen && !currentPublicationToEdit.additionalCommentsDone ? false : true}
          //               disabled={
          //                 !currentPublicationToEdit?.commentsOnTextOpen &&
          //                 !currentPublicationToEdit?.commentsOnDesignOpen &&
          //                 !currentPublicationToEdit?.additionalCommentsOpen
          //                   ? false
          //                   : true
          //               }
          //               sx={{
          //                 background: 'white',
          //                 margin: '1px',
          //                 border: '1px solid #EE9A8F',
          //                 borderRadius: '4px',
          //                 color: '#EE9A8F',
          //                 fontSize: { xs: '10px', md: '12px' },
          //                 mr: '2px',
          //                 '&:disabled': {
          //                   border: '1px solid rgba(0, 0, 0, 0.06) !important',
          //                   color: 'rgba(0, 0, 0, 0.06) !important',
          //                 },
          //               }}
          //               onClick={handleValidateOne}>
          //               <Typography
          //                 sx={{
          //                   fontSize: { xs: '10px', md: '12px' },
          //                   fontFamily: 'Gilroy-SemiBold',
          //                 }}>
          //                 {loadingValidateOne ? (
          //                   <CircularProgress
          //                     sx={{ height: '26px !important', width: '26px !important' }}
          //                   />
          //                 ) : (
          //                   <FormattedMessage id="calendar_weekly_validate" />
          //                 )}
          //               </Typography>
          //             </Button>
          //           ) : (
          //             <Box
          //               sx={{
          //                 border: '1px solid #EE9A8F',
          //                 borderRadius: '4px',
          //                 display: 'flex',
          //                 alignItems: 'center',
          //                 justifyContent: 'center',
          //                 p: '0px 10px',
          //                 height: '35px',
          //                 mt: '1px',
          //                 fontFamily: 'Gilroy-SemiBold',
          //                 mr: '2px',
          //               }}>
          //               <CheckCircleOutlineOutlinedIcon color="primary" sx={{ fontSize: '20px' }} />
          //               <Typography
          //                 sx={{
          //                   fontSize: { xs: '10px', md: '12px' },
          //                   color: '#EE9A8F',
          //                   ml: '5px',
          //                   fontFamily: 'Gilroy-SemiBold',
          //                 }}>
          //                 <FormattedMessage id="calendar_weekly_validated" />
          //               </Typography>
          //             </Box>
          //           )}
          //           <Button
          //             disabled={
          //               (currentPublicationToEdit?.commentsOnTextOpen ||
          //                 currentPublicationToEdit?.commentsOnDesignOpen ||
          //                 currentPublicationToEdit?.additionalCommentsOpen) &&
          //               publication.clientApproval !== 'Approved'
          //                 ? false
          //                 : true
          //             }
          //             sx={{
          //               background: 'white',
          //               margin: '1px',
          //               border: '1px solid rgba(245, 194, 188, 0.5)',
          //               borderRadius: '4px',
          //               color: '#EE9A8F',
          //               fontSize: { xs: '10px', md: '12px' },
          //               fontFamily: 'Gilroy-SemiBold',
          //               '&:disabled': {
          //                 border: '1px solid rgba(0, 0, 0, 0.06) !important',
          //                 color: 'rgba(0, 0, 0, 0.06) !important',
          //               },
          //             }}
          //             onClick={() => handleValidateSpecific()}>
          //             {loadingFeedbackMonthly ? (
          //               <CircularProgress
          //                 sx={{ height: '26px !important', width: '26px !important' }}
          //               />
          //             ) : (
          //               <FormattedMessage id="calendar_weekly_send_comments" />
          //             )}
          //           </Button>
          //         </Box>
          //       </Grid>
          //     </Grid>
          //   </AccordionSummary>
          //   <AccordionDetails
          //     sx={{
          //       p: '0px 16px 16px',
          //       border: border ? '2px solid #E5E5E5' : '',
          //       borderRadius: border ? '10px' : '',
          //     }}>
          <Box p={2}>
            <Grid container justifyContent={'space-between'} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography className={classes.accordionTitle}>
                    {publication.publicationTime && (
                      <span>
                        {moment(publication.publicationTime).format('DD/MM')} @{' '}
                        {moment(publication.publicationTime).format('HH.mm')}{' '}
                      </span>
                    )}{' '}
                    - {publication.concept}
                  </Typography>
                  {/* <ExpandLessIcon /> */}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                justifyContent={matchDownXs ? 'flex-start' : 'flex-end'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    ml: { xs: '0', sm: '10px' },
                  }}>
                  {/* <Button
                      disabled
                      sx={{
                        background: 'white',
                        margin: '1px',
                        border: '1px solid #EE9A8F',
                        borderRadius: '4px',
                        color: '#E1E1E1',
                        padding: '0px',
                        minWidth: { xs: '40px', md: '40px' },
                        mr: '2px',
                        '&:disabled': {
                          border: '1px solid rgba(0, 0, 0, 0.06) !important',
                          color: 'rgba(0, 0, 0, 0.06) !important',
                        },
                      }}>
                      <DeleteIcon fill="rgba(0, 0, 0, 0.06)" />
                    </Button>
                    <Button
                      disabled
                      sx={{
                        background: 'white',
                        margin: '1px',
                        border: '1px solid #EE9A8F',
                        borderRadius: '4px',
                        color: '#E1E1E1',
                        minWidth: { xs: '40px', md: '40px' },
                        mr: '3px',
                        '&:disabled': {
                          border: '1px solid rgba(0, 0, 0, 0.06) !important',
                          color: 'rgba(0, 0, 0, 0.06) !important',
                        },
                      }}>
                      <EditeIcon fill="rgba(0, 0, 0, 0.06)" />
                    </Button> */}
                  {publication.clientApproval !== 'Approved' ? (
                    <Button
                      // disabled={(currentPublicationToEdit.clientApproval == null || currentPublicationToEdit.clientApproval == "Waiting for client's approval") && !currentPublicationToEdit.commentsOnTextOpen && !currentPublicationToEdit.commentsOnDesignOpen && !currentPublicationToEdit.additionalCommentsDone ? false : true}
                      disabled={
                        !currentPublicationToEdit?.commentsOnTextOpen &&
                          !currentPublicationToEdit?.commentsOnDesignOpen &&
                          !currentPublicationToEdit?.additionalCommentsOpen
                          ? false
                          : true
                      }
                      sx={{
                        background: 'white',
                        margin: '1px',
                        border: '1px solid #EE9A8F',
                        borderRadius: '4px',
                        color: '#EE9A8F',
                        fontSize: { xs: '10px', md: '12px' },
                        mr: '2px',
                        '&:disabled': {
                          border: '1px solid rgba(0, 0, 0, 0.06) !important',
                          color: 'rgba(0, 0, 0, 0.06) !important',
                        },
                      }}
                      onClick={handleValidateOne}>
                      <Typography
                        sx={{
                          fontSize: { xs: '10px', md: '12px' },
                          fontFamily: 'Gilroy-SemiBold',
                        }}>
                        {loadingValidateOne ? (
                          <CircularProgress
                            sx={{ height: '26px !important', width: '26px !important' }}
                          />
                        ) : (
                          <FormattedMessage id="calendar_weekly_validate" />
                        )}
                      </Typography>
                    </Button>
                  ) : (
                    <Box
                      sx={{
                        border: '1px solid #EE9A8F',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: '0px 10px',
                        height: '35px',
                        mt: '1px',
                        fontFamily: 'Gilroy-SemiBold',
                        mr: '2px',
                      }}>
                      <CheckCircleOutlineOutlinedIcon color="primary" sx={{ fontSize: '20px' }} />
                      <Typography
                        sx={{
                          fontSize: { xs: '10px', md: '12px' },
                          color: '#EE9A8F',
                          ml: '5px',
                          fontFamily: 'Gilroy-SemiBold',
                        }}>
                        <FormattedMessage id="calendar_weekly_validated" />
                      </Typography>
                    </Box>
                  )}
                  <Button
                    disabled={
                      (currentPublicationToEdit?.commentsOnTextOpen ||
                        currentPublicationToEdit?.commentsOnDesignOpen ||
                        currentPublicationToEdit?.additionalCommentsOpen) &&
                        publication.clientApproval !== 'Approved'
                        ? false
                        : true
                    }
                    sx={{
                      background: 'white',
                      margin: '1px',
                      border: '1px solid rgba(245, 194, 188, 0.5)',
                      borderRadius: '4px',
                      color: '#EE9A8F',
                      fontSize: { xs: '10px', md: '12px' },
                      fontFamily: 'Gilroy-SemiBold',
                      '&:disabled': {
                        border: '1px solid rgba(0, 0, 0, 0.06) !important',
                        color: 'rgba(0, 0, 0, 0.06) !important',
                      },
                    }}
                    onClick={() => handleValidateSpecific()}>
                    {loadingFeedbackMonthly ? (
                      <CircularProgress
                        sx={{ height: '26px !important', width: '26px !important' }}
                      />
                    ) : (
                      <FormattedMessage id="calendar_weekly_send_comments" />
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid pb={1} container>
              <Grid item sm={3} xs={12} pr={1}>
                <Typography className={classes.weekCardLabel}>
                  <FormattedMessage id="calendar_weekly_picture" />
                </Typography>
                {publication?.assets?.length > 0 && (
                  <Dialog
                    open={isViewerOpen}
                    onClose={closeImageViewer}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <ImageViewer
                      src={publication?.assets?.map((publication) => publication.url)}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      disableScroll={false}
                      backgroundStyle={{
                        backgroundColor: 'rgba(0,0,0,0.9)',
                      }}
                      closeOnClickOutside={true}
                    />
                  </Dialog>
                )}
                {publication?.assets?.length > 0 ? (
                  <Carousel
                    navButtonsAlwaysVisible
                    NextIcon={<KeyboardArrowRightIcon sx={{ fill: '#fff' }} />}
                    PrevIcon={<KeyboardArrowLeftIcon sx={{ fill: '#fff' }} />}
                    indicatorIconButtonProps={{
                      style: {
                        color: '#afafaf !important',
                      },
                    }}
                    activeIndicatorIconButtonProps={{
                      style: {
                        backgroundColor: '#494949',
                      },
                    }}>
                    {publication.assets.map((item, i) => (
                      <Box key={i} height="100%" style={{ cursor: 'pointer' }}>
                        <CardMedia
                          onClick={() => openImageViewer(i)}
                          sx={{ marginBottom: '24px', objectFit: 'contain', pt: '12px' }}
                          component="img"
                          width="180px"
                          // height="220px"
                          image={item.url}
                          alt="alt image"
                        />
                      </Box>
                    ))}
                  </Carousel>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '70%',
                    }}>
                    <Typography sx={{ textAlign: 'center' }}>
                      <FormattedMessage id="calendar_weekly_image_not_available" />
                    </Typography>
                  </Box>
                )}
                {/* </ImageGroup> */}
              </Grid>
              <Grid item container sm={9} xs={12}>
                <Grid item sm={6} xs={12}>
                  <Typography sx={{mb: '10px'}} className={classes.weekCardLabel}>
                    <FormattedMessage id="calendar_weekly_caption" />
                  </Typography>
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <TextField
                      disabled={publication.clientApproval === 'Approved' || publication.clientApproval === 'Changes required'|| disabled}
                      className={classes.weekCardTextarea}
                      inputProps={{
                        className:
                          publication.clientApproval === 'Approved' || publication.clientApproval === 'Changes required'|| disabled
                            ? classes.textFieldBackgroundDisabled
                            : classes.textFieldBackground,
                      }}
                      minRows={12}
                      multiline
                      fullWidth
                      maxRows={12}
                      name="caption"
                      value={currentPublicationToEdit && currentPublicationToEdit.caption}
                      onChange={(e) => handleAdditionalComments(e)}
                    />
                    <Box sx={{ position: 'absolute', top: '10px', right: '5px' }}>
                      <EmojiEmotionsIcon
                        sx={{ fill: 'rgba(0, 0, 0, 0.12)', cursor: 'pointer' }}
                        onClick={() => setOpenEmoji(!openEmoji)}
                      />
                    </Box>
                    {openEmoji && (
                      <Box
                        sx={{ position: 'absolute', top: '40px', right: '5px', zIndex: '222' }}>
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12} sx={{p: {xs: 0, sm: '10px'}, mt: {xs: 0, sm: '15px'}}}>
                  <Grid item  xs={12} sx={{ p: { xs: '12px 0', md: '6px 12px' } }}>
                    <Stack display="flex" flexDirection="row" alignItems="center">
                      <AccountCircleOutlinedIcon
                        style={{ fill: '#757575' }}
                        fontSize="small"
                        color="primary"
                      />
                      <Typography className={classes.weekCardLabel}>
                        <FormattedMessage id="calendar_weekly_accounts_to_tag" />
                      </Typography>
                    </Stack>
                    <Typography style={{ paddingLeft: '24px' }}>{publication.tags}</Typography>
                  </Grid>
                  <Grid item  xs={12} sx={{ p: { xs: '12px 0', md: '6px 12px' } }}>
                    <Stack display="flex" flexDirection="row" alignItems="center">
                      <LocalMallOutlinedIcon style={{ fill: '#757575' }} fontSize="small" />
                      <Typography className={classes.weekCardLabel}>
                        <FormattedMessage id="calendar_weekly_products_to_tag" />
                      </Typography>
                    </Stack>
                    <Typography style={{ paddingLeft: '24px' }}>
                      {publication.productsToTag}
                    </Typography>
                  </Grid>
                  <Grid item  xs={12} sx={{ p: { xs: '12px 0', md: '6px 12px' } }}>
                    <Stack display="flex" flexDirection="row" alignItems="center">
                      <LocationOnOutlinedIcon style={{ fill: '#757575' }} fontSize="small" />
                      <Typography className={classes.weekCardLabel}>
                        <FormattedMessage id="calendar_weekly_location" />
                      </Typography>
                    </Stack>
                    <Typography style={{ paddingLeft: '24px' }}>
                      {publication.location}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ p: { xs: '12px 0', md: '6px 12px' } }}>
                    <Stack display="flex" flexDirection="row" alignItems="center">
                      <CalendarTodayOutlinedIcon style={{ fill: '#757575' }} fontSize="small" />
                      <Typography className={classes.weekCardLabel}>
                        <FormattedMessage id="calendar_weekly_schedule" />
                      </Typography>
                    </Stack>
                    <Typography style={{ paddingLeft: '24px' }}>
                      {publication.publicationTime &&
                        moment(publication.publicationTime).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item sm={8} xs={12} sx={{ p: { xs: '12px 0', md: '6px 12px' } }}>
                    <Stack display="flex" flexDirection="row" alignItems="center">
                      <Checkbox
                        disabled
                        name="toBeBoosted"
                        checked={publication.toBeBoosted}
                        style={{ padding: '0' }}
                        color="secondary"
                      />
                      <Typography className={classes.weekCardLabel}>
                        <FormattedMessage id="calendar_weekly_publication_to_be_boosted" />
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {/* <Grid container>
                    <Grid item sm={12} xs={12} p={1.5}>
                      <Typography className={classes.weekCardLabel}>Additional Comments</Typography>
                      <TextareaAutosize
                        className={classes.weekCardTextarea}
                        aria-label="textarea"
                        minRows={6}
                        name="additionalCommentsOpen"
                        placeholder=""
                        value={
                          currentPublicationToEdit &&
                          currentPublicationToEdit.additionalCommentsOpen
                        }
                        onChange={(e) => handleAdditionalComments(e)}
                      />
                    </Grid>
                  </Grid> */}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.weekCardLabel}>
                  <FormattedMessage id="calendar_weekly_comments_on_visuals" />
                </Typography>
                <TextField
                  disabled={publication.clientApproval === 'Approved' || publication.clientApproval === 'Changes required'|| disabled}
                  className={classes.weekCardTextarea}
                  inputProps={{
                    className:
                      publication.clientApproval === 'Approved' || publication.clientApproval === 'Changes required'|| disabled
                        ? classes.textFieldBackgroundDisabled
                        : classes.textFieldBackground,
                  }}
                  minRows={4}
                  placeholder=""
                  multiline
                  maxRows={4}
                  name="commentsOnDesignOpen"
                  value={
                    currentPublicationToEdit && currentPublicationToEdit.commentsOnDesignOpen
                  }
                  onChange={(e) => handleAdditionalComments(e)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.weekCardLabel}>
                  <FormattedMessage id="calendar_weekly_comments_on_text" />
                </Typography>
                <TextField
                  disabled={publication.clientApproval === 'Approved' || publication.clientApproval === 'Changes required'|| disabled}
                  className={classes.weekCardTextarea}
                  inputProps={{
                    className:
                      publication.clientApproval === 'Approved' || publication.clientApproval === 'Changes required'|| disabled
                        ? classes.textFieldBackgroundDisabled
                        : classes.textFieldBackground,
                  }}
                  minRows={4}
                  multiline
                  maxRows={4}
                  placeholder=""
                  name="commentsOnTextOpen"
                  value={currentPublicationToEdit && currentPublicationToEdit.commentsOnTextOpen}
                  onChange={(e) => handleAdditionalComments(e)}
                />
              </Grid>
            </Grid>
          </Box>

          //   </AccordionDetails>
          // </Accordion>
        )}
      </Box>
    );
  };
  return (
    <Box>
      <Box sx={{ m: '-30px 0 20px 0', display: { xs: 'block', md: 'none' } }}>
        {!sharedCalendar && (
          <Select
            lang="month"
            onChange={(e: any) => handleStepChange(e.target.value)}
            value={valSelectWeek}
            defaultValue={moment(allWeekCalendars[0].dueDate).format('DD/MM/YYYY')}
            sx={{
              width: { xs: '100%', md: '80%' },
              textAlign: 'center',
            }}>
            {allWeekCalendars &&
              allWeekCalendars.map((item) => (
                <MenuItem value={item.dueDate}>
                  {moment(item.dueDate).format('DD/MM/YYYY')}
                </MenuItem>
              ))}
          </Select>
        )}
      </Box>
      {publications &&
        publications.length &&
        publications.map((publication: { id: string }, index) => (
          <Box className={classes.weekCardContainer} key={publication.id as string}>
            <RenderCollapse publication={publication} />
          </Box>
        ))}
    </Box>
  );
};
export default WeekCalendarCard;
