import React from 'react';

import { Controller } from 'react-hook-form';
import { TextField, Typography, Grid, MenuItem, Select, Box } from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import { FormattedMessage } from 'react-intl';

interface IFormInputs {
  name: string;
}
interface RenderFieldProps {
  control: any;
  activeStep: number;
  handlStep: () => void;
  setActiveStep: (activeStep: number) => void;
  select: boolean;
  field: {
    name: string;
    label: string;
    type: string;
    title: string;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const MultipleTextField = ({
  field,
  control,
  select,
  activeStep,
  handlStep,
  setActiveStep,
}: RenderFieldProps) => {
  const [fielsInput, setFielsInput] = React.useState<any>();

  React.useEffect(() => {
    setFielsInput(field.constraints.fieldsInput);
  }, []);

  const handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      setActiveStep(activeStep + 1);
    }
  };
  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }}>
      <Grid container spacing={2}>
        {fielsInput &&
          fielsInput.map((i: any) => (
            <Grid item xs={12} md={6}>
              {i.type === 'DROPDOWN' ? (
                <>
                  <Controller
                    key={field.name}
                    name={i.name}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            lineHeight: '12px',
                            fontFamily: 'Gilroy',
                            color: 'rgba(0, 0, 0, 0.6)',
                            marginBottom: '6px',
                          }}>
                          <FormattedMessage id={i.label} />
                        </Typography>
                        <Select value={value} onChange={onChange} sx={{ width: '100%' }}>
                          {i.enum.map((k: any) => (
                            <MenuItem value={k.key}>
                              <FormattedMessage id={k.value} />
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '12px',
                      fontFamily: 'Gilroy',
                      color: 'rgba(0, 0, 0, 0.6)',
                      marginBottom: '6px',
                    }}>
                    <FormattedMessage id={i.label} />
                  </Typography>
                  <Controller
                    key={field.name}
                    name={i.name}
                    rules={{
                      pattern: {
                        value:
                          i.validation[0] &&
                          (typeof i.validation[0]['regex'] === 'string'
                            ? new RegExp(i.validation[0]['regex'])
                            : i.validation[0]['regex']),
                        message:
                          i.validation[0] !== undefined
                            ? i.validation[0]['error_message']['default']
                            : '',
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                      i.label == 'Description' ? (
                        <TextField
                          autoFocus
                          key={field.name}
                          label={<FormattedMessage id={field.placeholder} />}
                          error={!!error}
                          helperText={error ? <FormattedMessage id={error.message} /> : null}
                          multiline
                          value={value}
                          minRows={4}
                          maxRows={4}
                          onChange={onChange}
                          fullWidth
                          // InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <TextField
                          label={<FormattedMessage id={field.placeholder} />}
                          error={!!error}
                          helperText={error ? <FormattedMessage id={error.message} /> : null}
                          value={value}
                          // placeholder={i.placeholder}
                          onChange={onChange}
                          fullWidth
                        />
                      )
                    }
                  />
                </>
              )}
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default MultipleTextField;
