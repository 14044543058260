import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Divider, Stack, Typography } from '@material-ui/core';
import { publicationToShowType } from './types';
import { findDaysLeftDiff } from '../utils';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: { fontSize: '14px' },
  toDoTableMobileContainer: {
    margin: '8px 0',
    padding: '16px',
    background: '#FFFFFF',
    boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)',
    borderRadius: '10px',
  },
  toDoTableMobileTitle: {
    padding: '12px 0',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  timeLeft: {
    background: 'rgba(245, 194, 188, 0.5)',
    borderRadius: '20px',
    padding: '8px 12px',
    fontSize: '12px',
  },
  validatedButton: {
    background: 'white',
    border: '1px solid #EE9A8F',
    borderRadius: '4px',
    color: '#EE9A8F',
    width: '100%',
  },
  validateButton: {
    background: '#EE9A8F',
    borderRadius: '4px',
    color: 'white',
    width: '100%',
  },
}));

interface ToDoTableProps {
  monthCalendarsToShow: publicationToShowType[];
  handleViewMore: (arg1: string, arg2: string) => void;
}

const ToDoTableMobile: React.FC<ToDoTableProps> = ({ monthCalendarsToShow, handleViewMore }) => {
  const classes = useStyles();
  const [sortedCalenar, setSortedCalendar] = React.useState<any>();
  React.useEffect(() => {
    let sortedItems = monthCalendarsToShow;
    sortedItems.sort(function (a, b) {
      return findDaysLeftDiff(a.dueDate) - findDaysLeftDiff(b.dueDate);
    });
    setSortedCalendar(sortedItems);
  }, []);
  return (
    <Box>
      {sortedCalenar?.map((calendar) => (
        <Box className={classes.toDoTableMobileContainer}>
          <Typography className={classes.toDoTableMobileTitle}>
            {calendar?.calendarType === 'monthlyCalendar' ? (
              <FormattedMessage id="calendar_to_do_monthly_calendar" />
            ) : (
              <FormattedMessage id="calendar_to_do_weekly_calendar" />
            )}
          </Typography>
          <Divider />
          <Stack direction="row" justifyContent="space-between" alignItems="center" my={1}>
            <Box>
              <Typography>
                <FormattedMessage id="calendar_to_do_not_validated" />
              </Typography>
            </Box>
            <Box className={classes.timeLeft}>
              <Typography>{moment(calendar?.dueDate).format('DD/MM/YYYY')}</Typography>{' '}
            </Box>
            <Box className={classes.timeLeft}>
              {findDaysLeftDiff(calendar.dueDate) > 0
                ? `${findDaysLeftDiff(calendar.dueDate)} days left`
                : `${findDaysLeftDiff(calendar.dueDate) * -1}  days late`}
            </Box>
          </Stack>
          {/* {status ? (
            <Box>
              <Button className={classes.validatedButton}>
                <Stack direction="row" alignItems="center" spacing={0.4}>
                  <Typography>Validated</Typography>
                </Stack>
              </Button>
            </Box>
          ) : ( */}
          <Box pt={1}>
            <Button
              className={classes.validateButton}
              onClick={() =>
                handleViewMore(
                  calendar?.calendarType === 'monthlyCalendar' ? 'month' : 'week',
                  calendar?.start || calendar?.dueDateMonth || 'null',
                )
              }>
              <FormattedMessage id="calendar_to_do_view_more" />
            </Button>
          </Box>
          {/* )} */}
        </Box>
      ))}
    </Box>
  );
};

export default ToDoTableMobile;
