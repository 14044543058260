import React from 'react';

import { Controller } from 'react-hook-form';
import { FormControlLabel, Checkbox, Typography, Grid, Box } from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
interface RenderFieldProps {
  control: any;
  activeStep: number;
  handlStep: () => void;
  setActiveStep: (activeStep: number) => void;
  field: {
    name: string;
    label: string;
    type: string;
    title: string;
    subtitle?: any;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
    width: '100%',
    padding: '5px 20px',
    marginBottom: '5px',
  },
  active: {
    border: '1px solid #F0998D',
    background: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '10px',
    width: '100%',
    padding: '5px 20px',
    marginBottom: '5px',
  },
  colotrLable: {
    '.MuiFormControlLabel-label': {
      color: '#303778',
    },
  },
  colotrLableActive: {
    '.MuiFormControlLabel-label': {
      color: '#EE9A8F',
      fontFamily: 'Gilroy-SemiBold',
    },
  },
  tabs: {
    '.MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
}));
const MultipleSelect = ({ field, control, activeStep, handlStep }: RenderFieldProps) => {
  const classes = useStyles();
  const [valuesToShow, setValuesToShow] = React.useState<any>();

  React.useEffect(() => {
    setValuesToShow(field.constraints.enum);
  }, []);

  return (
    <Grid container xs={12} sx={{ padding: { xs: '100px 15px 0px', md: '0px 15px' } }}>
      {valuesToShow &&
        valuesToShow.map((checkboxValue) => (
          <Box
            key={checkboxValue.key}
            className={checkboxValue.active === false ? classes.root : classes.active}>
            <Controller
              name={field.name}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControlLabel
                  className={
                    checkboxValue.active === false ? classes.colotrLable : classes.colotrLableActive
                  }
                  control={
                    <Checkbox
                      value={checkboxValue.key}
                      checked={value?.some((existingValue) => existingValue === checkboxValue.key)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          value
                            ? onChange([...value, checkboxValue.key])
                            : onChange([checkboxValue.key]);
                        } else {
                          onChange(value.filter((value) => value !== checkboxValue.key));
                        }
                      }}
                    />
                  }
                  label={<FormattedMessage id={checkboxValue.value} />}
                />
              )}
            />
          </Box>
        ))}
    </Grid>
  );
};

export default MultipleSelect;
