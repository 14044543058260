// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

interface MenuListProps {
  handleLeftDrawerToggle: () => void;
}

const MenuList = ({ handleLeftDrawerToggle }: MenuListProps) => {
  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup key={item.id} item={item} handleLeftDrawerToggle={handleLeftDrawerToggle} />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
