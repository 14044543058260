/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    label: 'Did you use an agency before ?',
    name: 'agency_past_usage',
    type: 'SINGLE_SELECT_TICKBOXES',
    title: 'onboarding_smobjectives_has-used-agency-before_question_title',
    placeholder: 'onboarding_smobjectives_has-used-agency-before_question_placeholder',
    subtitle: 'onboarding_smobjectives_has-used-agency-before_question_subtitle',

    alert: 'onboarding_smobjectives_has-used-agency-before_question_alert',

    validation: [],
    constraints: {
      enum: [
        {
          key: 'Yes',
          value: 'onboarding_smobjectives_has-used-agency-before_answer-option_yes',
          active: false,
        },
        {
          key: 'No',
          value: 'onboarding_smobjectives_has-used-agency-before_answer-option_no',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_smobjectives_has-used-agency-before_help-and-tips_title',
      content: 'onboarding_smobjectives_has-used-agency-before_help-and-tips_content',
      media: {},
    },
  },
  //Todo: this question should be skippable
  {
    label: 'Why did you use an agency ?',
    name: 'agency_past_usage_reasons',
    type: 'TEXT_AREA',
    title: 'onboarding_smobjectives_experience-with-previous-agency_question_title',
    placeholder: 'onboarding_smobjectives_experience-with-previous-agency_question_placeholder',
    alert: 'onboarding_smobjectives_experience-with-previous-agency_question_alert',
    subtitle: 'onboarding_smobjectives_experience-with-previous-agency_question_subtitle',
    validation: [],
    skippable: true,
    help_and_tips: {
      show: false,
      title: 'onboarding_smobjectives_experience-with-previous-agency_help-and-tips_title',
      content: 'onboarding_smobjectives_experience-with-previous-agency_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'Why do you want to work with Snikpic?',
    name: 'agency_snikpic_usage_reasons',
    type: 'MULTIPLE_SELECT_TICKBOXES',
    title: 'onboarding_smobjectives_why-work-with-snikpic_question_title',
    placeholder: 'onboarding_smobjectives_why-work-with-snikpic_question_placeholder',
    subtitle: 'onboarding_smobjectives_why-work-with-snikpic_question_subtitle',
    alert: 'onboarding_smobjectives_why-work-with-snikpic_question_alert',
    validation: [
      {
        regex:
          '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
        error_message: 'The URL is not valid',
      },
    ],
    constraints: {
      enum: [
        {
          key: 'NO_TIME',
          value: 'onboarding_smobjectives_why-work-with-snikpic_answer-option_notime',
          active: false,
        },
        {
          key: 'EXPENSIVE_AGENCY',
          value: 'onboarding_smobjectives_why-work-with-snikpic_answer-option_expensive',
          active: false,
        },
        {
          key: 'HOW_TO_DO_IT_MYSELF',
          value: 'onboarding_smobjectives_why-work-with-snikpic_answer-option_how-to-do',
          active: false,
        },
        {
          key: 'SOCIAL_MEDIA',
          value: 'onboarding_smobjectives_why-work-with-snikpic_answer-option_want-to-train',
          active: false,
        },
        {
          key: 'INSTAGRAM',
          value: 'onboarding_smobjectives_why-work-with-snikpic_answer-option_professionalize',
          active: false,
        },
        {
          key: 'OTHER',
          value: 'onboarding_smobjectives_why-work-with-snikpic_answer-option_other',
          active: false,
        },
      ],
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_smobjectives_why-work-with-snikpic_help-and-tips_title',
      content: 'onboarding_smobjectives_why-work-with-snikpic_help-and-tips_content',
      media: {},
    },
  },

  {
    label: 'How can Snikpic help you ?',
    name: 'agency_snikpic_ways_to_help',
    type: 'TEXT_AREA',
    title: 'onboarding_smobjectives_how-snikpic-can-help-you_question_title',
    placeholder: 'onboarding_smobjectives_how-snikpic-can-help-you_question_placeholder',
    alert: 'onboarding_smobjectives_how-snikpic-can-help-you_question_alert',
    subtitle: 'onboarding_smobjectives_how-snikpic-can-help-you_question_subtitle',
    validation: [
      {
        regex: '',
        error_message: '',
      },
    ],
    help_and_tips: {
      show: true,
      title: 'onboarding_smobjectives_how-snikpic-can-help-you_help-and-tips_title',
      content: 'onboarding_smobjectives_how-snikpic-can-help-you_help-and-tips_content',
      media: {},
    },
  },
];
