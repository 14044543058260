import React from 'react';
import { CircularProgress, Box, Typography, MenuItem, Button, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Publication from './Publication';
import {
  FETCH_USER_TASKS_WITH_FILTERS,
  FETCH_USER_TASKS_WITH_NAME_BRAND,
  GET_WEEKLY_CALENDAR_TASKS,
} from '../../../api/queries/tasks';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { MobileView, BrowserView } from 'react-device-detect';
import MobilePublication from './publicationMobile';
import { CHANGE_TASKS_COMPLITION_STATUS } from '../../../api/mutations/tasks';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import WeeklyCalendarTask from 'components/tasks/weeklyCalendar';
import { useLocation, useParams, useHistory } from 'react-router-dom';

interface StateProps {
  selectedTask: any;
  propsCalendar: any;
}

const MyWork = () => {
  const dispatch = useDispatch();
  const { state } = useLocation<StateProps>();
  const { calendarId } = useParams<{ calendarId: string }>();
  const [valSelectIdCalendars, setValSelectIdCalendars] = React.useState<any>({
    brand: '',
    taskName: '',
  });
  const [weeklyCalendars, setWeeklyCalendars] = React.useState<any>();

  const { loading: loadingGetWeeklyCalendarsTasks, error: errorGetWeeklyCalendarsTasks } = useQuery(
    GET_WEEKLY_CALENDAR_TASKS,
    {
      variables: { id: calendarId },
      onCompleted: (data) => {
        setWeeklyCalendars(data.user[0].calendars[0]);
      },
      onError: (error) => {
        const message = JSON.parse(error.message);
        console.log(message);
      },
    },
  );

  const handleChangeValSelectIdCalendars = (name, value) => {
    setValSelectIdCalendars({
      ...valSelectIdCalendars,
      [name]: value,
    });
  };

  return (
    <Box mt={3}>
      <WeeklyCalendarTask
        weeklyCalendars={weeklyCalendars}
        loadingGetWeeklyCalendarsTasks={loadingGetWeeklyCalendarsTasks}
        idTask={state?.selectedTask.idTask}
        selectedTask={state?.selectedTask}
      />
    </Box>
  );
};

export default MyWork;
