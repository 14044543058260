import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
// third-party
import clsx from 'clsx';

// project imports
import Breadcrumbs from './../../components/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from './../../menu-items';
import { drawerWidth } from '../../store/constant';
import { SET_MENU } from '../../store/actions';
import { DefaultRootStateProps } from '../../types';

// assets
import { IconChevronRight } from '@tabler/icons';

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0 0 30px 30px',
  },
  appBarWidth: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.default,
    borderRadius: '0 0 30px 30px',
  },
  content: {
    // ...theme.typography.mainContent,
    background: '#fafafa',
    height: '',
    width: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: -(drawerWidth - 20),
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: '20px',
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   padding: '16px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: '10px',
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   padding: '16px',
    //   marginRight: '10px',
    // },
  },
  contentShift: {
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: '20px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: '10px',
    // },
  },
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawerState, setopenDrawerState] = React.useState(true);

  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: DefaultRootStateProps) => state.customization.opened,
  );
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    setopenDrawerState(!openDrawerState);
    // dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  React.useEffect(() => {
    // dispatch({ type: SET_MENU, opened: !matchDownMd });
    setopenDrawerState(!matchDownMd);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  return (
    <Box
      sx={{
        paddingTop: { xs: '65px', sm: 0 },
        background: { xs: '#fafafa', sm: '' },
      }}
      className={classes.root}>
      <CssBaseline />
      {/* header */}
      <Box sx={{ display: { xs: 'block', sm: 'none', borderRadius: '0 0 30px 30px', pt: '75px' } }}>
        <AppBar
          position="fixed"
          color="inherit"
          elevation={0}
          className={openDrawerState ? classes.appBarWidth : classes.appBar}>
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
      </Box>

      {/* drawer */}
      <Sidebar
        drawerOpen={openDrawerState}
        drawerToggle={handleLeftDrawerToggle}
        handleLeftDrawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Box className={classes.content}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Box sx={{ padding: { xs: '30px 15px', md: '30px 50px' } }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
