/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    label: 'Logo',
    name: 'logo_brand_asset',
    type: 'LOGO',
    title: 'onboarding_brand-assets_logo_question_title',
    subtitle: 'onboarding_brand-assets_logo_question_subtitle',
    placeholder: 'onboarding_brand-assets_logo_question_placeholder',
    alert: 'onboarding_brand-assets_logo_question_alert',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/logo/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: false,
        label: 'link',
        title: {},
        placeholder: {},
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_brand-assets_logo_help-and-tips_title',
      content: 'onboarding_brand-assets_logo_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Fonts',
    name: 'fonts_brand_asset',
    type: 'UPLOAD_FILES',
    title: 'onboarding_brand-assets_fonts_question_title',
    subtitle: 'onboarding_brand-assets_fonts_question_subtitle',
    placeholder: 'onboarding_brand-assets_fonts_question_placeholder',
    alert: 'onboarding_brand-assets_fonts_question_alert',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/fonts/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_brand-assets_fonts_answer-additional-text-field_title',
        placeholder: 'onboarding_brand-assets_fonts_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_brand-assets_fonts_help-and-tips_title',
      content: 'onboarding_brand-assets_fonts_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Graphic charter',
    name: 'graphic_charter_assets',
    type: 'UPLOAD_FILES',
    title: 'onboarding_brand-assets_graphic-charter_question_title',
    subtitle: 'onboarding_brand-assets_graphic-charter_question_subtitle',
    placeholder: 'onboarding_brand-assets_graphic-charter_question_placeholder',
    alert: 'onboarding_brand-assets_graphic-charter_question_alert',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/graphic_charter/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_brand-assets_graphic-charter_answer-additional-text-field_title',
        placeholder:
          'onboarding_brand-assets_graphic-charter_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_brand-assets_graphic-charter_help-and-tips_title',
      content: 'onboarding_brand-assets_graphic-charter_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Colors',
    name: 'brand_colors',
    type: 'COLOR_PICKER',
    title: 'onboarding_brand-assets_colors_question_title',
    subtitle: 'onboarding_brand-assets_colors_question_subtitle',
    placeholder: 'onboarding_brand-assets_colors_question_placeholder',
    alert: 'onboarding_brand-assets_colors_question_alert',
    skippable: true,
    validation: [],
    constraints: {},
    help_and_tips: {
      show: false,
      title: 'onboarding_brand-assets_colors_help-and-tips_title',
      content: 'onboarding_brand-assets_colors_help-and-tips_content',
      media: {},
    },
  },
  {
    label: 'Additional files',
    name: 'additional_files',
    type: 'UPLOAD_FILES',
    title: 'onboarding_brand-assets_additional-files_question_title',
    subtitle: 'onboarding_brand-assets_additional-files_question_subtitle',
    placeholder: 'onboarding_brand-assets_additional-files_question_placeholder',
    alert: 'onboarding_brand-assets_additional-files_question_alert',
    validation: [],
    constraints: {
      s3_upload_path: 'assets/other/',
      cropping: {
        isAllowed: false,
      },
      allowed_extensions: [],
      additional_text_field: {
        show: true,
        label: 'link',
        title: 'onboarding_brand-assets_additional-files_answer-additional-text-field_title',
        placeholder:
          'onboarding_brand-assets_additional-files_answer-additional-text-field_placeholder',
      },
    },
    help_and_tips: {
      show: false,
      title: 'onboarding_brand-assets_additional-files_help-and-tips_title',
      content: 'onboarding_brand-assets_additional-files_help-and-tips_content',
      media: {},
    },
  },
];
