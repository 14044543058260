import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Dialog, IconButton, Rating, TextareaAutosize } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContainerCloseButton: {
    color: 'black',
    fontSize: '14px',
  },
  dialogRatingContent: {
    textAlign: 'center',
  },
  dialogRatingTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '28px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '8px 0',
  },
  dialogRatingDescription: {
    fontFamily: 'Gilroy-Regular',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '8px 0',
  },
  dialogRatingStars: {
    padding: '20px 0',
  },
  dialogRatingCommentLabel: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '4px 16px',
  },
  dialogRatingTextarea: { margin: '4px 16px' },
  dialogRatingTextareaComponent: {
    width: '100%',
    padding: '8px 16px',
    background: '#FFFFFF',
    border: '1px solid #EEEEEE',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  dialogRatingButton: {
    background: '#EE9A8F',
    borderRadius: '4px',
    padding: '8px 16px',
    color: '#fff',
  },
  sendButton: {
    paddingTop: '32px',
    paddingBottom: '16px',
    margin: '0 16px',
  },
}));

interface DialogRatingProps {
  open: boolean; //to open and close dialog
  onConfirm: () => void; //called when clicked Send
  onClose: () => void; //called when dialog closed}
  handleFeedback: any;
}

const DialogRating: React.FC<DialogRatingProps> = ({
  open,
  onConfirm,
  onClose,
  handleFeedback,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<any>({
    rating: '',
    comments: '',
  });
  const handleChange = (prop, newValue) => {
    setValue({ ...value, [prop]: newValue });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{
        style: {
          borderRadius: '24px',
          width: '600px',
        },
      }}>
      <Box>
        <Box sx={{ margin: '0 16px' }} display="flex" justifyContent="flex-end">
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.dialogRatingContent}>
          <Box className={classes.dialogRatingTitle}>
            <FormattedMessage id="calendar_monthly_dialog_rating_awesome" />
          </Box>
          <Box className={classes.dialogRatingDescription}>
            <FormattedMessage id="calendar_monthly_dialog_rating_validate_post" />
          </Box>
          <Box>
            <Box className={classes.dialogRatingStars}>
              <Rating
                name="rating"
                value={value.rating}
                onChange={(newValue) => handleChange('rating', newValue)}
              />
            </Box>
          </Box>
          <Box className={classes.dialogRatingCommentLabel}>
            <FormattedMessage id="calendar_monthly_dialog_rating_comments_visuals" />
          </Box>
          <Box className={classes.dialogRatingTextarea}>
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="Label"
              minRows={6}
              name="discription"
              value={value.comments}
              onChange={(newValue) => handleChange('comments', newValue)}
              className={classes.dialogRatingTextareaComponent}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" className={classes.sendButton}>
            <Button className={classes.dialogRatingButton} onClick={() => handleFeedback(value)}>
              <FormattedMessage id="calendar_monthly_dialog_rating_send" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogRating;
