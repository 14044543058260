import React from 'react';

import { Controller } from 'react-hook-form';
import { TextField, Typography, Grid, Box, Stack } from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import IconHelp from '../../../assets/images/icons/helperBrand';
import { FormattedMessage } from 'react-intl';

interface IFormInputs {
  name: string;
}
interface RenderFieldProps {
  control: any;
  activeStep: number;
  handlStep: () => void;
  setActiveStep: (activeStep: number) => void;
  textArea: boolean;
  field: {
    name: string;
    label: string;
    description?: string;
    type: string;
    title: string;
    placeholder: string;
    validation: {
      regex: any;
      error_message: string;
    }[];
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const RenderField = ({
  field,
  control,
  textArea,
  activeStep,
  handlStep,
  setActiveStep,
}: RenderFieldProps) => {
  const handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Grid container xs={12} sx={{ padding: '0px 15px' }}>
      <Controller
        key={field.name}
        name={field.name}
        rules={{
          // validate: (value) =>
          //   (value && field.validation[0] && value.match(field.validation[0]['regex'])) ||
          //   field.validation[0]['error_message']['default'],
          pattern: {
            value:
              field.validation[0] &&
              (typeof field.validation[0]['regex'] === 'string'
                ? new RegExp(field.validation[0]['regex'])
                : field.validation[0]['regex']),
            message:
              field.validation[0] !== undefined
                ? field.validation[0]['error_message']['default']
                : '',
          },
        }}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {textArea === false ? (
              <TextField
                autoFocus
                value={value}
                onChange={onChange}
                onKeyPress={handleKeyPress}
                label={<FormattedMessage id={field.placeholder} />}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
                // InputLabelProps={{ shrink: true }}
              />
            ) : (
              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  autoFocus
                  key={field.name}
                  label={<FormattedMessage id={field.placeholder} />}
                  error={!!error}
                  helperText={error ? error.message : null}
                  multiline
                  value={value}
                  minRows={4}
                  maxRows={4}
                  onChange={onChange}
                  fullWidth
                  // InputLabelProps={{ shrink: true }}
                />
                {/* {value?.length < 320 && (
                  <Box sx={{ position: 'absolute', bottom: '10px', left: '15px' }}>
                    <Box sx={{ display: 'flex' }}>
                      <IconHelp />
                      <Typography
                        sx={{
                          color: '#717A90',
                          margin: '0px 0px 0px 5px',
                          fontSize: '10px',
                          lineHeight: '12px',
                          fontFamily: 'Gilroy',
                        }}>
                        Max 1700 symbols
                      </Typography>
                    </Box>
                  </Box>
                )} */}
              </Box>
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default RenderField;
