import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MobileWeekCard from '../../../components/calendar/monthly-calendar/MobileWeekCard';
import DesktopWeekCard from '../../../components/calendar/monthly-calendar/DesktopWeekCard';
import { MobileView, BrowserView } from 'react-device-detect';

function WeeklyViewCalendar({ calendars }) {
  return (
    <div>
      <BrowserView>
        <DesktopWeekCard calendars={calendars} />
      </BrowserView>
      <MobileView>
        <MobileWeekCard calendars={calendars} />
      </MobileView>
    </div>
  );
}

export default WeeklyViewCalendar;
