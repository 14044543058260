import { Box, Typography, Button } from '@material-ui/core';
// import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import EditIcon from '@material-ui/icons/Edit';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { VALIDATE_ONE_MONTHLY_POST } from 'api/mutations/calendar';
import { GET_MONTHLY_CALENDARS } from 'api/queries/calendar/monthly';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import DialogEditing from '../dialogs/DialogEditing';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '25px !important',
    color: '#252A32 !important',
    fontWeight: 'bold',
  },
  validateButton: {
    background: '#EE9A8F',
    border: '1px solid #EE9A8F',
    borderRadius: '4px',
    color: '#fff',
    '&:hover': {
      background: '#EE9A8F',
    },
  },
  monthlyCalendarContainer: {
    // border: '2px solid #E5E5E5',
  },
  monthlyCalendarHeader: {
    background: '#5A6092',
    color: 'white',
    borderRadius: '10px',
    padding: '16px !important',
  },
  monthlyCalendarWeekContent: {
    background: 'white',
    color: '#000000',
    borderRadius: '10px',
    padding: '16px !important',
  },
  monthlyCalendarHeaderMobile: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 'bold',
  },
  monthlyCalendarContainerMobile: {
    padding: '16px !important',
  },
  dividerStyle: {
    width: '90%',
    margin: '0 auto',
    background: '#BDBDBD',
  },
  weekOf: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  publicationConcept: {
    paddingLeft: '4px',
    borderLeft: '2px solid #EE9A8F',
  },
}));

interface WeekProps {
  calendars: any;
}

const MobileWeekCard: React.FC<WeekProps> = ({ calendars }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [weeklyPostToEdit, setWeeklyPostToEdit] = React.useState();
  const [publicationToEdit, setPublicationToEdit] = React.useState();

  const backToHome = () => {
    setWeeklyPostToEdit(undefined);
  };

  const RenderRow = ({ weeklyPost }) => {
    const [expanded, setExpanded] = React.useState<boolean>(true);

    const RenderActionButtons = ({ publication }) => {
      const [loadingValidate, setLoadingValidate] = React.useState(false);
      const [loadingPublicationId, setLoadingPublicationId] = React.useState();

      const [updateMonthlyPost, { loading: loadingMonthlyPost, error }] = useMutation(
        VALIDATE_ONE_MONTHLY_POST,
        {
          onCompleted: (data) => {
            setLoadingValidate(false);

            dispatch({
              type: SNACKBAR_OPEN,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              transition: 'SlideLeft',
              open: true,
              message: 'The post was successfully validated.',
              variant: 'alert',
              alertSeverity: 'success',
              autoHideDuration: '6000',
            });
          },
          onError: (error) => {
            setLoadingValidate(false);

            const message = JSON.parse(error.message);
            dispatch({
              type: SNACKBAR_OPEN,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              transition: 'SlideLeft',
              open: true,
              message: 'Error: ' + message?.content,
              variant: 'alert',
              alertSeverity: 'error',
              autoHideDuration: '6000',
            });
          },
        },
      );

      const handleValidate = (publication) => {
        setLoadingPublicationId(publication.id);
        setLoadingValidate(true);
        updateMonthlyPost({
          variables: {
            id: publication.id,
            conceptionStatus: 'Done',
          },
          refetchQueries: [{ query: GET_MONTHLY_CALENDARS }],
        });
      };

      return (
        <>
          {loadingValidate && loadingPublicationId === publication.id ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={() => handleValidate(publication)}
              sx={{
                borderRadius: '10px',

                padding: '0px 25px',

                background: '#EE9A8F',
                border: '1px solid #EE9A8F',
                color: '#fff',
                '&:hover': {
                  background: '#EE9A8F',
                },
              }}>
              <FormattedMessage id="calendar_monthly_calendar_validate" />
            </Button>
          )}
        </>
      );
    };

    return (
      <Box width="100%" className={classes.monthlyCalendarContainer}>
        <Box py={1}>
          <Accordion
            sx={{ borderRadius: '10px' }}
            expanded={expanded}
            onChange={() => setExpanded((prevdata) => !prevdata)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Grid container className={classes.monthlyCalendarHeaderMobile}>
                <Typography className={classes.weekOf}>
                  <FormattedMessage id="calendar_monthly_calendar_week_of" />{' '}
                  {moment(weeklyPost.dueDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
            </AccordionSummary>
            {weeklyPost.publications.map((publication) => (
              <Box>
                <Grid
                  container
                  alignItems="center"
                  className={classes.monthlyCalendarContainerMobile}>
                  <Typography sx={{ paddingRight: '8px' }}>
                    {publication.publicationTime &&
                      moment(publication.publicationTime).format('MMMM') +
                        ' ' +
                        moment(publication.publicationTime).format('DD') +
                        ', ' +
                        moment(publication.publicationTime).format('YYYY') +
                        ' | ' +
                        moment(publication.publicationTime).format('ddd')}
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    {publication.conceptionStatus !== 'Done' ? (
                      <>
                        <RenderActionButtons publication={publication} />

                        <Box pl="8px">
                          <IconButton
                            onClick={() => {
                              setWeeklyPostToEdit(weeklyPost);
                              setPublicationToEdit(publication);
                            }}>
                            <EditIcon />
                          </IconButton>
                        </Box>
                      </>
                    ) : (
                      <Box>
                        <Button
                          // className={classes.validateButton}
                          sx={{
                            border: '1px solid rgba(245, 194, 188, 0.5)',
                            borderRadius: '10px',
                            background: 'rgba(245, 194, 188, 0.5)',
                            color: 'rgba(0, 0, 0, 0.87)',
                            padding: '10px 25px',
                          }}
                          // onClick={() => handleValidate(publication)}
                          // onClick={() => console.log('hey')}
                        >
                          <FormattedMessage id="calendar_monthly_validated" />
                        </Button>
                      </Box>
                    )}
                  </Box>

                  {/* <Button
                    sx={{
                      // border: status ? null : '1px solid #EE9A8F',
                      fontSize: '12px',
                      padding: '11px 14px',
                      borderRadius: '10px',
                      // backgroundColor: status ? 'rgba(245, 194, 188, 0.5)' : '#FFFFFF',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    onClick={() => handleValidate(publication)}>
                    {publication.conceptionStatus === 'In client review' ? 'Validate' : 'Validated'}
                  </Button> */}
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  className={classes.monthlyCalendarContainerMobile}>
                  <Typography sx={{ paddingRight: '8px', fontSize: '16px', fontWeight: 'bold' }}>
                    {publication.category}
                  </Typography>
                  {publication.category && <AddBoxOutlinedIcon sx={{ fill: '#EE9A8F' }} />}
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classes.monthlyCalendarContainerMobile}>
                  <Typography className={classes.publicationConcept}>
                    {publication.concept}
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    height: '18px',
                    background: '#fafafa !important',
                    width: '100% !important',
                    mx: '-30px',
                  }}
                  className={classes.dividerStyle}
                />
                {/* <Divider
                  sx={{ height: '2px', background: '#E0E0E0 !important' }}
                  className={classes.dividerStyle}
                /> */}
              </Box>
            ))}
          </Accordion>
        </Box>
        {/* <MonthlyCalendarDetail /> */}
      </Box>
    );
  };
  return calendars ? (
    <div>
      {calendars.map((weeklyPost) => (
        <Box>
          <RenderRow weeklyPost={weeklyPost} />
        </Box>
      ))}
      {weeklyPostToEdit && (
        <DialogEditing
          open={!!weeklyPostToEdit}
          onClose={() => setWeeklyPostToEdit(undefined)}
          onConfirm={backToHome}
          weeklyPost={weeklyPostToEdit}
          publication={publicationToEdit}
        />
      )}
    </div>
  ) : null;
};

export default MobileWeekCard;
