import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, Drawer, Typography, CircularProgress } from '@material-ui/core';
import Stepper from '../../../components/profile/stepper';
import HelpBlock from '../../../components/profile/helpBlock';
import { MobileView } from 'react-device-detect';
import RenderField from './RenderField';
// import fields from './fields';
// import fields from '../blocks/fields/generalInformation';

import { useForm } from 'react-hook-form';
import Button from '../../../components/button';
import Enter from '../../../assets/images/icons/enter';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { INSERT_FORM, UPDATE_FORM } from '../../../api/mutations/onBoard';
import { FETCH_FORM_BY_ID } from '../../../api/queries/onBoard';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import TitleAndSubtitle from './TitleAndSubtitle';
import AlertAtribute from './alertAtribute';
import { FormattedMessage, useIntl } from 'react-intl';

interface ParamsProps {
  formName: string;
  formId?: string;
}

const FieldsContainer = () => {
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: { why_work_with_snikpic: [] },
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const intl = useIntl();

  const fields = location.state.fields;

  console.log('🔥🔥 ~ fetchFormDetailsById ~ watch formData', watch());

  const [activeStep, setActiveStep] = React.useState(1);
  const [totalSteps, setTotalSteps] = React.useState(fields.length);
  const [activeHelp, setActiveHelp] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loadingPage, setloadingPage] = React.useState(true);

  const { formName, formId } = useParams<ParamsProps>();

  //fetch form by id
  const [fetchFormDetailsById] = useLazyQuery(FETCH_FORM_BY_ID, {
    onCompleted: (data) => {
      setloadingPage(false);

      const answers = data?.form_by_pk?.answers;
      if (answers) {
        for (const key of Object.keys(answers)) {
          if (typeof answers[key] === 'object' && answers[key] !== null) {
            for (const key2 of Object.keys(answers[key])) {
              setValue(`${key}.${key2}`, answers[key][key2]);
            }
          } else {
            setValue(key, answers[key]);
          }
        }
      }
    },
  });

  React.useEffect(() => {
    if (fields[activeStep - 1].help_and_tips.show === true) {
      setActiveHelp(true);
    } else {
      setActiveHelp(false);
    }
  }, [activeStep]);
  //if there is a form id, update the answers
  React.useEffect(() => {
    const stepToGo = location?.state?.step;
    if (stepToGo) {
      setActiveStep(stepToGo);
      history.replace({ state: { ...location?.state, step: null } });
    }
    if (formId) {
      fetchFormDetailsById({ variables: { id: formId } });
    } else {
      setloadingPage(false);
    }
  }, [location, formName, formId]);

  const handleActiveHelp = () => {
    setActiveHelp(!activeHelp);
    setOpen(!open);
  };

  //handles back button
  const handlStep = () => {
    if (activeStep == 1) {
      history.push('/profile');
    }
    setActiveStep(activeStep - 1);
  };
  // const handleKeyPress = (event) => {
  //   if (event.key == 'Enter') {

  //     if (!!errors[fields[activeStep - 1]['name']] || !watch()[fields[activeStep - 1]['name']])
  //       setActiveStep(activeStep + 1);
  //   }
  // };

  React.useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter') {
        if (fields[activeStep - 1].type !== 'TEXT_AREA') {
          event.preventDefault();
          if (!checkIfFieldShouldBeDisabled()) {
            if (fields.length === activeStep) {
              handleSave();
            } else {
              setActiveStep((prevData) => prevData + 1);
            }
          }
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [activeStep]);
  //create form with a given formName and values
  const [insertForm, { loading: isinsertFormLoading, error: insertFormError }] = useMutation(
    INSERT_FORM,
    {
      onCompleted: (data) => {
        history.push('/profile');
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Form was updated successfully',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Some error occurred!',
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
      },
    },
  );

  //update form with a given id and values
  const [
    updateForm,
    { data: updateFormResponse, loading: isupdateFormLoading, error: updateFormError },
  ] = useMutation(UPDATE_FORM, {
    onCompleted: (data) => {
      setActiveStep((prevData) => prevData + 1);
    },
    onError: (error) => {
      setActiveStep((prevData) => prevData + 1);
      console.log('🔥🔥 ~ Profile ~ error updateForm onError ', error);
    },
  });

  //update form and close the page
  const [
    updateFormAndClose,
    {
      data: updateFormAndCloseResponse,
      loading: isupdateFormAndCloseLoading,
      error: updateFormAndCloseError,
    },
  ] = useMutation(UPDATE_FORM, {
    onCompleted: (data) => {
      history.push('/profile');
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Form was saved successfully',
        variant: 'alert',
        alertSeverity: 'success',
        autoHideDuration: '6000',
      });
    },
    onError: (error) => {
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'An error occurred',
        variant: 'alert',
        alertSeverity: 'error',
        autoHideDuration: '6000',
      });
    },
  });

  //check if user is on last step
  const isLastStep = fields.length === activeStep;

  //called for each field when updating the form
  const callApiForTheCurrentField = () => {
    updateForm({
      variables: {
        answers: watch(),
        form: formName,
        is_completed: false,
        version: '1.0',
        id: formId && formId,
      },
    });

    // if (!random_boolean) {
    //   setError('Brand_Name', {
    //     type: 'server',
    //     message: 'This will be a message from server if there is error',
    //   });
    //   setLoadingCall(false);
    //   return;
    // }
  };

  //called when user clicks Next
  const handleNextStep = () => {
    if (!formId) {
      setActiveStep((prevData) => prevData + 1);
    } else {
      callApiForTheCurrentField();
    }
  };

  //called when user is on last step
  const handleSubmit = () => {
    if (!formId) {
      insertForm({
        variables: {
          answers: watch(),
          form: formName,
          is_completed: true,
          version: '1.0',
        },
      });
    } else {
      callApiForTheCurrentField();
    }
  };

  //called when button Save from <Stepper/> clicked
  const handleSave = () => {
    if (!formId) {
      insertForm({
        variables: {
          answers: watch(),
          form: formName,
          is_completed: true,
          version: '1.0',
        },
      });
    } else {
      updateFormAndClose({
        variables: {
          answers: watch(),
          form: formName,
          is_completed: false,
          version: '1.0',
          id: formId && formId,
        },
      });
    }
  };

  const checkIfFieldShouldBeDisabled = () => {
    if (fields[activeStep - 1]['skippable']) return false;

    if (!!errors[fields[activeStep - 1]['name']] || !watch()[fields[activeStep - 1]['name']])
      return true;

    return false;
  };
  return (
    <Box>
      {loadingPage ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box sx={{ position: 'fixed', zIndex: '11', top: '0px', width: '100vw' }}>
            <Stepper
              activeStep={activeStep}
              totalSteps={totalSteps}
              fieldsLength={fields.length}
              prevStep={handlStep}
              handleSave={handleSave}
              loading={isupdateFormAndCloseLoading || isinsertFormLoading}
            />
          </Box>
          <Grid container sx={{ zIndex: '1' }}>
            <Grid item xs={12} md={activeHelp ? 9 : 12} sx={{ height: { md: '100vh' } }}>
              <Box sx={{ position: 'relative', height: '100vh' }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%">
                  <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={8}>
                      <TitleAndSubtitle
                        field={fields[activeStep - 1]}
                        activeStep={activeStep}
                        handlStep={handlStep}
                        setActiveStep={setActiveStep}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <RenderField
                          field={fields[activeStep - 1]}
                          control={control}
                          activeStep={activeStep}
                          handlStep={handlStep}
                          setActiveStep={setActiveStep}
                          watch={watch}
                          setValue={setValue}
                        />
                      </Box>
                    </Grid>
                    {fields[activeStep - 1].alert && (
                      <Grid item xs={12} md={8}>
                        <AlertAtribute alert={fields[activeStep - 1].alert} />
                      </Grid>
                    )}
                    <Grid item xs={12} md={8}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ padding: '0px 15px 20px' }}>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              {isupdateFormLoading ||
                              isinsertFormLoading ||
                              isupdateFormAndCloseLoading ? (
                                <Box
                                  height="100%"
                                  width="100%"
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="center">
                                  <CircularProgress />
                                </Box>
                              ) : (
                                <Button
                                  onClick={() => {
                                    !isLastStep ? handleNextStep() : handleSave();
                                  }}
                                  // onKeyPress={handleKeyPress}
                                  titleBtn={
                                    !isLastStep ? (
                                      <FormattedMessage id="sign_bottommenu_next" />
                                    ) : (
                                      'Save'
                                    )
                                  }
                                  colorBtn="red"
                                  disabled={checkIfFieldShouldBeDisabled()}

                                  // disabled={!!errors[fields[activeStep - 1]['name']]}
                                />
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              {fields[activeStep - 1].type !== 'TEXT_AREA' && (
                                <Box sx={{ display: 'flex', marginTop: '25px' }}>
                                  <Typography
                                    sx={{
                                      color: '#717A90',
                                      fontSize: '14px',
                                      lineHeight: '16px',
                                      marginRight: '5px',
                                    }}>
                                    <FormattedMessage id="oboarding_or_press" />
                                  </Typography>
                                  <Typography sx={{ color: '#000', marginTop: '-1px' }}>
                                    {' '}
                                    <FormattedMessage id="oboarding_press_enter" />
                                  </Typography>
                                  <Box sx={{ margin: '4px 0px 0px 10px' }}>
                                    <Enter />
                                  </Box>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {!isLastStep && (
                          <Grid item xs={6} md={3} sx={{ marginTop: { xs: '-15px', md: '0px' } }}>
                            <Button
                              onClick={() => setActiveStep(activeStep + 1)}
                              titleBtn={<FormattedMessage id="onboarding_fill_in_later" />}
                              colorBtn="white"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {intl.formatMessage({ id: fields[activeStep - 1]?.help_and_tips.title }) !=
                  fields[activeStep - 1]?.help_and_tips.title &&
                  intl.formatMessage({ id: fields[activeStep - 1]?.help_and_tips.content }) !=
                    fields[activeStep - 1]?.help_and_tips.content && (
                    <Box
                      onClick={handleActiveHelp}
                      sx={{
                        background: '#303778',
                        cursor: 'pointer',
                        borderRadius: '10px',
                        padding: '20px',
                        position: { xs: 'fixed', md: 'absolute' },
                        right: { xs: '30px', md: '50px' },
                        bottom: { xs: '30px', md: '50px' },
                      }}>
                      <Box
                        sx={{
                          border: '2px solid white',
                          borderRadius: '50%',
                          padding: '5px 10px',
                        }}>
                        <Typography
                          sx={{
                            fontSize: '25px',
                            color: 'white',
                            margin: '-8px -8px',
                            padding: '0px 5px',
                            fontFamily: 'Gilroy-SemiBold',
                          }}>
                          ?
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Grid>
            {activeHelp &&
              intl.formatMessage({ id: fields[activeStep - 1]?.help_and_tips.title }) !=
                fields[activeStep - 1]?.help_and_tips.title &&
              intl.formatMessage({ id: fields[activeStep - 1]?.help_and_tips.content }) !=
                fields[activeStep - 1]?.help_and_tips.content && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    borderLeft: '1px solid #DDE2ED',
                    height: { md: '100vh' },
                    display: { xs: 'none', md: 'block' },
                  }}>
                  <HelpBlock
                    handleActiveHelp={handleActiveHelp}
                    title={fields[activeStep - 1]?.help_and_tips.title}
                    description={fields[activeStep - 1]?.help_and_tips.content}
                  />
                </Grid>
              )}
            <MobileView>
              <Drawer anchor="right" open={open} onClose={handleActiveHelp}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ borderLeft: '1px solid #DDE2ED', height: { md: '100vh' } }}>
                  <HelpBlock
                    handleActiveHelp={handleActiveHelp}
                    title={`${fields[activeStep - 1]?.help_and_tips.title}`}
                    description={`${fields[activeStep - 1]?.help_and_tips.content}`}
                  />
                </Grid>
              </Drawer>
            </MobileView>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FieldsContainer;
