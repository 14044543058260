import { gql } from '@apollo/client';

const GET_USER_INFORMATION = gql`
  query getUserInformation {
    user {
      customer_id
      email
      id
      state
    }
  }
`;

export { GET_USER_INFORMATION };
