import { makeStyles } from '@material-ui/core/styles'
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
interface ButtonProps extends MuiButtonProps {
  titleBtn: string | React.ReactNode;
  colorBtn: 'red' | 'white';
  loading?: boolean;
  padding?: boolean;
}
const useStyles = makeStyles((theme)=>({
  root: {
    background: (props: ButtonProps) =>
      props.colorBtn === 'red' ? '#F0998D ' : 'rgba(240, 153, 141, 0.05) ',
    padding: (props: ButtonProps) =>
      props.padding === true ? '6px 8px' : '',
    marginTop: (props: ButtonProps) =>
      props.padding === true ? '0px' : '10px',
    textTransform: 'none',
    width: '100%',
    lineHeight: '16px ',
    color: (props: ButtonProps) => (props.colorBtn === 'red' ? '#fff ' : '#F0998D '),
    border: (props: ButtonProps) => (props.colorBtn === 'red' ? 'none ' : '0.7px solid #F0998D '),
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: (props: ButtonProps) =>
          props.colorBtn === 'red' ? '#ffe0dc ' : 'rgba(240, 153, 141) ',
        color: (props: ButtonProps) => (props.colorBtn === 'red' ? '#f57362 ' : '#fff '),
      },
    },
    [theme.breakpoints.up('xs')]: {
      '&:hover': {
        backgroundColor: (props: ButtonProps) =>
          props.colorBtn === 'red' ? '#F0998D ' : 'rgba(240, 153, 141, 0.05) ',
        color: (props: ButtonProps) => (props.colorBtn === 'red' ? '#fff' : '#f57362'),
      },
    }

  },
}));

export default function Button(props: ButtonProps) {
  const classes = useStyles(props);
  return (
      <MuiButton
        {...props}
        sx={{
          fontFamily: { xs: 'Gilroy', md: 'Gilroy-SemiBold' },
          letterSpacing: '0.46px',
          fontSize: { xs: '16px', md: '14px' },
          borderRadius: { xs: '4px', md: '10px' },
          padding: { xs: '13px', md: '16px' },
        }}
        className={classes.root}>
        {props.titleBtn}
      </MuiButton>
  );
}
