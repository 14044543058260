import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './route-guard/GuestGuard';
import MinimalLayout from '../layout/MinimalLayout';
// import NavMotion from '../layout/NavMotion';
import Loadable from '../components/Loadable';
import ResendLinkEmail from '../views/pages/authentication/resendLink';
import NavMotion from '../layout/NavMotion';
import Congrats from '../views/pages/authentication/congrats';
import ConfirmEmail from '../views/pages/authentication/confirmEmail';
import AccessDenied from '../views/pages/authentication/Error';
// login routing
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const SignUp = Loadable(lazy(() => import('../views/pages/authentication/signUp')));
const ResetPassword = Loadable(lazy(() => import('../views/pages/authentication/resetPassword')));
const CreateNewPassword = Loadable(
  lazy(() => import('../views/pages/authentication/resetPassword/NewPassword')),
);
const EmailConfirmed = Loadable(lazy(() => import('../views/pages/authentication/emailConfirmed')));
const SharedMonthlyCalendar = Loadable(
  lazy(() => import('../views/calendar/shared-calendar/monthly-calendar')),
);

const SharedWeeklyCalendar = Loadable(
  lazy(() => import('../views/calendar/shared-calendar/weekly-calendar')),
);

// -----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
  const location = useLocation();
  // localStorage.setItem('token', undefined);

  return (
    <Route
      path={[
        '/login',
        '/signup',
        '/reset-password',
        '/email-confirmed',
        '/resend-link-email',
        '/congrats',
        '/email-confirm',
        '/verify/:token',
        '/password/reset/:token',
        '/shared-monthly-calendar',
        '/shared-weekly-calendar',
        '/access-denied',
      ]}>
      <MinimalLayout>
        <Switch location={location} key={location.pathname}>
          <NavMotion>
            <GuestGuard>
              <>
                <Route path="/login" component={AuthLogin} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/verify/:token" component={EmailConfirmed} />
                <Route exact path="/resend-link-email" component={ResendLinkEmail} />
                <Route exact path="/congrats" component={Congrats} />
                <Route exact path="/email-confirm" component={ConfirmEmail} />
                <Route exact path="/access-denied" component={AccessDenied} />
                <Route exact path="/password/reset/:token" component={CreateNewPassword} />
                <Route
                  exact
                  path="/shared-monthly-calendar/:calendarId"
                  component={SharedMonthlyCalendar}
                />
                <Route
                  exact
                  path="/shared-weekly-calendar/:calendarId"
                  component={SharedWeeklyCalendar}
                />
              </>
            </GuestGuard>
          </NavMotion>
        </Switch>
      </MinimalLayout>
    </Route>
  );
};

export default LoginRoutes;
