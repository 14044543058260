import React from 'react';

import {
  Box,
  Tabs,
  Grid,
  Tab,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import ProfileHeader from 'components/profile/ProfileHeader';
import { styled } from '@material-ui/styles';
import BoardCard from './BoardCard';
import { useMutation, useQuery, gql } from '@apollo/client';
import { GET_MANAGER_WORKFLOW } from '../../../api/queries/statusBoard';
import InfiniteScroll from 'react-infinite-scroll-component';

import { FormattedMessage } from 'react-intl';

const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Gilroy-Regular',
  fontSize: '16px',
  color: '#717A90',
  '&.Mui-selected': {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4000000059604645px',
    // color: theme.palette.primary.main,
  },
}));

const StatusBoardContainer = () => {
  const [value, setValue] = React.useState(0);
  const [valueCheckBox, setValueCheckBox] = React.useState(false);
  const [fields, setFields] = React.useState<any>();
  const [count, setCount] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [current, setCurrent] = React.useState<any>([]);
  const getMoreData = () => {
    setCount((prevState) => prevState + 1);
  };
  //   const GET_MANAGER_WORKFLOW = gql`
  //   query fetchManagerWorfklow {
  //     fetch_manager_workflow {
  //       brands(
  //         _page: ${count}
  //         _page_size: 10
  //         )
  //     }
  //   }
  // `;
  const { data, loading, error } = useQuery(GET_MANAGER_WORKFLOW, {
    onCompleted: (data) => {
      console.log('datadatadata', data);
      setFields(data.fetch_manager_workflow.brands);
      setCurrent(data.fetch_manager_workflow.brands);
    },
    onError: (error) => {
      setHasMore(false);
      const message = JSON.parse(error.message);
      console.log(message);
    },
  });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log('fields', fields);
  return (
    <Box>
      <ProfileHeader
        userName="Besart Marku1"
        title="My Status Board"
        description="Track the work porgress for each brand you manage"
      />
      <Box mt={6}>
        <Tabs value={value} onChange={handleChange}>
          <StepsTab
            sx={{
              fontFamily: 'Gilroy-Medium !important',
              textTransform: 'capitalize !important',
              minWidth: { md: 240 },
            }}
            label={<FormattedMessage id="profile_my_information" />}
          />
        </Tabs>
      </Box>
      <Box
        mt={4}
        sx={{
          background: '#fff',
          borderRadius: '10px',
          boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.05)',
          p: '0px 15px',
          width: { xs: '100%', sm: '220px !important' },
        }}>
        <FormControlLabel
          value={valueCheckBox}
          onChange={() => setValueCheckBox(!valueCheckBox)}
          sx={{
            '.MuiFormControlLabel-label': {
              color: '#000',
              fontSize: '14px',
              lineHeight: '125%',
              letterSpacing: '0.15px',
            },
            mr: 0,
          }}
          control={<Checkbox />}
          label="Only brands with issues"
        />
      </Box>
      <Box mt={3}>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {fields && fields.map((item) => <BoardCard publication={item} />)}
        {/* <InfiniteScroll
          dataLength={fieldsToDisplay.length}
          next={getMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}>
          {fieldsToDisplay.length > 0 &&
            fieldsToDisplay.map((i, index) => (
              <BoardCard publication={item} />
            ))}
        </InfiniteScroll> */}
      </Box>
    </Box>
  );
};

export default StatusBoardContainer;
