import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography, Popover, CircularProgress } from '@material-ui/core';
import ButtonTasks from './ButtonTasks';
import moment from 'moment';
import ButtonAction from './ButtonAction';
import CustomChip from './CustomChip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CHANGE_TASKS_COMPLITION_STATUS } from '../../api/mutations/tasks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  toDoCalendarHeader: {
    background: '#5A6092',
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
    padding: '16px !important',
  },
  toDoCalendarContent: {
    color: '#000000',
    padding: '16px !important',
    border: '1px solid #E1E1E1',
    borderTop: 'none',
    alignItems: 'flex-start',
  },
}));
interface ToDoTableProps {
  radioOrderBy: string;
  fields: any;
  getCalendarTask: any;
  hasMore: boolean;
  getMoreData: any;
  setCurrent: any;
  count: number;
  updateData: any;
}

const TaskContentDesktop = ({
  radioOrderBy,
  fields,
  getCalendarTask,
  hasMore,
  getMoreData,
  setCurrent,
  count,
  updateData,
}: ToDoTableProps) => {
  const classes = useStyles();
  const [dateNow, setDateNow] = React.useState<any>();
  React.useEffect(() => {
    setDateNow(moment());
  }, []);

  const handleTime = (time: any) => {
    let nowTime = moment(dateNow);
    time = moment(time);
    return nowTime.diff(time, 'hours').toString().replace('-', '');
  };
  const handleTimeIsAfter = (time: any) => {
    if (dateNow) {
      time = moment(time);
      return dateNow.isAfter(time);
    }
  };
  const ContentTasks = ({ item, after }) => {
    const dispatch = useDispatch();

    const [
      changeTaskCompletitionStatus,
      { loading: loadingChangeTaskCompletitionStatus, error: errorChangeTaskCompletitionStatus },
    ] = useMutation(CHANGE_TASKS_COMPLITION_STATUS, {
      onCompleted: (data) => {
        // getPaginationTasks();
        handleClose();
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Completion status successfully modified',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        handleClose();
        const message = JSON.parse(error.message);
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: ' + message?.content,
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
      },
    });

    const handleChangeCompletionStatus = (id) => {
      changeTaskCompletitionStatus({
        variables: {
          id: id,
          completition_status: 'Rework required',
        },
        update: (cache, { data: { update_tasks } }) => {
          updateData(update_tasks[0]);
        },
      });
    };
    const handleChangeCompletionStatusMarkAsDone = (id) => {
      changeTaskCompletitionStatus({
        variables: {
          id: id,
          completition_status: 'Done',
        },
        update: (cache, { data: { update_tasks } }) => {
          updateData(update_tasks[0]);
        },
      });
    };
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Grid container className={classes.toDoCalendarContent}>
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {item.brand[0].picture && (
              <Box mr={1} mt={0.8}>
                <img width={'21px'} height="20px" src={item.brand[0].picture[0].url} />
              </Box>
            )}
            <Typography>{item.brand[0].name}</Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={2}>
          <Typography>{item.flowGroup}</Typography>
        </Grid> */}
        <Grid item xs={2}>
          <Typography
          // sx={{
          //   textDecoration:
          //     (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) ||
          //     (item.flowGroup === 'Calendar' && item.completion !== 'Client review')
          //       ? 'underline'
          //       : 'none',
          //   cursor:
          //     (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) ||
          //     (item.flowGroup === 'Calendar' && item.completion !== 'Client review')
          //       ? 'pointer'
          //       : 'default',
          // }}
          >
            {item.flowGroup + ' ' + item.title}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{moment(item.dueDate).format('DD/MM/YYYY')}</Typography>
          {after && item.dueDate ? (
            <CustomChip
              label={handleTime(item.dueDate) + ' ' + 'tasks_hours_late'}
              status="danger"
            />
          ) : item.dueDate && +handleTime(item.dueDate) < 7 ? (
            <CustomChip
              label={handleTime(item.dueDate) + ' ' + 'tasks_hours_late'}
              status="warning"
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <ButtonTasks
              titleBtn={item.completion}
              status={item.completion}
              colorBtn={
                item.completion == 'Done'
                  ? 'green'
                  : after
                  ? 'red'
                  : item.completion != 'Not open yet' && item.completion != 'To do'
                  ? 'orange'
                  : ''
              }
            />
          </Box>
          {/* {(i.completion == 'Done' || i.completion == 'notOpen') ? '' :
                                <Box sx={{ justifyContent: "center", display: 'flex', mt: '5px' }}>
                                    <Typography
                                        sx={{
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            fontSize: '12px',
                                            lineHeight: '166%'
                                        }}
                                    >Open Task </Typography>
                                    <OpenInNewIcon sx={{ fill: 'rgba(0, 0, 0, 0.87)', fontSize: '12px', ml: '5px' }} />
                                </Box>} */}
        </Grid>
        <Grid item container spacing={1} xs={3} justifyContent="flex-end">
          <Grid item xs={6} container justifyContent="flex-end">
            <ButtonAction
              disabled={item.completion !== 'To do' ? true : false}
              onClick={() => handleChangeCompletionStatusMarkAsDone(item.id)}
              titleBtn={
                loadingChangeTaskCompletitionStatus ? (
                  <CircularProgress size="20px" sx={{ color: '#fff' }} />
                ) : (
                  <FormattedMessage id="tasks_mark_as_done" />
                )
              }
              colorBtn="green"
            />

            <Box
              sx={{
                display: 'flex',
                mt: '5px',
                cursor: 'pointer',
                justifyContent: 'center',
                textDecoration: 'underline',
              }}>
              <Typography
                onClick={() =>
                  (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) ||
                  (item.flowGroup === 'Calendar' && item.completion !== 'Client review')
                    ? getCalendarTask(item)
                    : window.open(item.viewLink, '_blank')
                }>
                <FormattedMessage id="tasks_access_view" />
              </Typography>
              <ArrowRightAltIcon
                sx={{
                  transform: 'rotate(-45deg)',
                  ml: '5px',
                  fill: 'rgba(0, 0, 0, 0.87)',
                  fontSize: '18px',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            {item.completion === 'To do' && (
              <Box>
                <Typography
                  sx={{
                    background: '#F5F5F5',
                    borderRadius: '15.7333px',
                    width: '35px',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={handleClick}>
                  <KeyboardArrowDownIcon sx={{ fill: '#EE9A8F' }} />
                </Typography>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '.MuiPopover-paper': { boxShadow: 'none !important' },
                    '.MuiPopover-root': { boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)' },
                  }}>
                  <Typography
                    onClick={() => handleChangeCompletionStatus(item.id)}
                    sx={{ p: 2, cursor: 'pointer' }}>
                    <FormattedMessage id="tasks_request_rework" />
                  </Typography>
                </Popover>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const fieldsToDisplay = React.useMemo(() => {
    return fields;
  }, [fields]);
  return (
    <Box>
      <Grid container className={classes.toDoCalendarHeader}>
        <Grid item xs={2}>
          <Typography>
            <FormattedMessage id="tasks_brand" />
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
          <Typography>Task Type</Typography>
        </Grid> */}
        <Grid item xs={2}>
          <Typography>
            <FormattedMessage id="tasks_task_name" />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            <FormattedMessage id="tasks_due_date" />
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Typography>
            <FormattedMessage id="tasks_completion_status" />
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Typography>
            <FormattedMessage id="tasks_action" />
          </Typography>
        </Grid>
      </Grid>
      {fieldsToDisplay && (
        <InfiniteScroll
          dataLength={fieldsToDisplay.length}
          next={getMoreData}
          hasMore={hasMore}
          loader={
            <h4>
              <FormattedMessage id="tasks_loading" />
              ...
            </h4>
          }>
          {fieldsToDisplay.length > 0 &&
            fieldsToDisplay.map((i, index) => (
              <ContentTasks item={i} after={handleTimeIsAfter(i.dueDate)} key={index} />
            ))}
        </InfiniteScroll>
      )}
      {/* {fields &&
        fields.map((i) => {
          let after = handleTimeIsAfter(i.dueDate);
          return <ContentTasks item={i} after={after} />;
        })} */}
    </Box>
  );
};

export default TaskContentDesktop;
