import MuiInput, { InputProps as MuiInputProps } from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/styles';
// import { styled } from '@material-ui/styles';

interface inputProps extends MuiInputProps{
}
// const HtmlInput = styled(({ className, ...props }) => (
//   <Input {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${inputClasses.root}`]: {
//     background: '#fff',
//     border: '1px solid #EFF2F8',
//     borderRadius: '10px',
//     padding: '12px 0px 12px 10px',
//     width: '100%',
//     marginTop: '0px !important',
//     "&::placeholder": {
//       color: 'red !important'
//     }
//   },
  // [`& .${inputClasses.arrow}`]: {
  //     color: '#fff',
  // },
// }));
const useStyles = makeStyles({
    root: {
      background: '#fff',
      border: '1px solid #EFF2F8',
      borderRadius: '10px',
      padding: '8px 0px 8px 10px',
      width: '100%',
      marginTop: '0px !important',
      "&::placeholder": {
        color: 'red !important'
      }
    },
    input:{
      color: '#717A90 !important',
      
    }
  });

const Input = (props: inputProps) => {
    const classes = useStyles(props);
    return (
        <MuiInput {...props} sx={{fontSize: {xs: '16px', md:'14px'}, color: '#717A90'}} className={classes.root} disableUnderline={true} />
    )
}
export default Input