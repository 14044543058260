import React from 'react';
import { Box, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../../../../components/select';
import congrats from '../../../../assets/images/congrats.png';
import Link from '../../../../components/link';
import ButtonBack from '../../../../components/buttonBack';
import Button from 'components/button';
import LanguageSwitcher from 'components/languageSwitcher';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function Congrats(props: any) {
  let history = useHistory();

  const congratsPagedVisited = localStorage.getItem('congratsPagedVisited');

  React.useEffect(() => {
    if (congratsPagedVisited === 'yes') {
      history.push('/profile');
    }
    localStorage.setItem('congratsPagedVisited', 'yes');
  }, []);

  return (
    <Box sx={{ background: '#fafafa', height: '100vh' }}>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, padding: '30px', width: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          {/* <Link to="/login" colorLink="grey" underline="none">
            <ButtonBack />
          </Link> */}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <LanguageSwitcher />

          {/* <Select startAdornmentProp={true} defaultValue="en">
            <MenuItem value={'en'}>En</MenuItem>
            <MenuItem value={'fr'}>Fr</MenuItem>
          </Select> */}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            width: { xs: '90%', sm: '40%', md: '30%', lg: '25%', xl: '18%' },
            margin: '0 auto',
            textAlign: 'center',
            paddingTop: { xs: '20%', md: '5%' },
          }}>
          <img src={congrats} alt="congrats" width="100%" />
          <Typography
            sx={{
              fontSize: '30px',
              fontWeight: 'bold',
              lineHeight: '35px',
              color: '#252A32',
              mb: 2,
              mt: 1,
            }}>
            <FormattedMessage id="onboarding_congrats_congrats" /> 🚀
          </Typography>
          <Typography sx={{ fontSize: '14px', lineHeight: '22px', color: '#717A90' }}>
            <FormattedMessage id="onboarding_congrats_success" />
            <br />
            <FormattedMessage id="onboarding_congrats_emailconfirmation" />
          </Typography>
          <Link to="/profile">
            <Button
              titleBtn={<FormattedMessage id="onboarding_congrats_proceedprofile" />}
              colorBtn="red"
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Congrats;
