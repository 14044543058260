import { lazy } from 'react';

import { Route, Switch, useLocation } from 'react-router-dom';

import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../components/Loadable';
import AuthGuard from './route-guard/AuthGuard';

const Plan = Loadable(lazy(() => import('../views/pages/authentication/plans')));

const AuthenticationRoutes = () => {
  const location = useLocation();

  return (
    <Route path={['/plan']}>
      <MinimalLayout>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <>
              <Route exact path="/plan" component={Plan} />
            </>
          </AuthGuard>
        </Switch>
      </MinimalLayout>
    </Route>
  );
};

export default AuthenticationRoutes;
