import SvgIcon from '@material-ui/core/SvgIcon';

const HelpersPaymentXs = () => (
  <SvgIcon>
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.50033 16.2814C12.4123 16.2814 15.5837 13.1101 15.5837 9.19808C15.5837 5.28606 12.4123 2.11475 8.50033 2.11475C4.58831 2.11475 1.41699 5.28606 1.41699 9.19808C1.41699 13.1101 4.58831 16.2814 8.50033 16.2814Z"
        stroke="#717A90"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 12.0316V9.19824"
        stroke="#717A90"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 6.36475H8.50625"
        stroke="#717A90"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default HelpersPaymentXs;
