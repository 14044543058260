import * as React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// store
import { DefaultRootStateProps } from './types';

// defaultTheme
import theme from './themes';

// project imports
import Locales from './components/Locales';
import SnikpicApolloClient from './components/apolloClient';

import NavigationScroll from './layout/NavigationScroll';
import Snackbar from './components/extended/Snackbar';

import { JWTProvider } from './contexts/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ApolloProvider } from '@apollo/client';

// -----------------------|| APP ||-----------------------//

// Create a client
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const App = (props: any) => {
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);
  const languageSelected = localStorage.getItem('languageSelected');

  React.useEffect(() => {
    if (!languageSelected) {
      localStorage.setItem('languageSelected', 'en');
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <SnikpicApolloClient>
          {(client) => (
            <ApolloProvider client={client}>
              <QueryClientProvider client={queryClient}>
                <CssBaseline />
                <Locales>
                  <NavigationScroll>
                    {/* <JWTProvider> */}
                    <>
                      <JWTProvider>
                        <Routes />
                        <Snackbar />
                      </JWTProvider>
                    </>
                  </NavigationScroll>
                </Locales>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              </QueryClientProvider>
            </ApolloProvider>
          )}
        </SnikpicApolloClient>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
