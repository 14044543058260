import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Chip,
  Checkbox,
  Autocomplete,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../button';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px',
    fontSize: '16px',
    lineHeight: '150%',
    fontFamily: 'Gilroy',
  },
  root: {
    border: '1px solid #BDBDBD',
    borderRadius: '10px',
    paddingRight: '10px',
  },
}));
interface DialogProps {
  items: any;
  valuesfilters: any;
  handleValuesSelectFilters: any;
  handleCheckboxFilter: any;
  applyFilters: any;
  clearFilter: any;
  loadingGetFilteredTasks: boolean;
}
const DialogFilters = ({
  items,
  valuesfilters,
  handleValuesSelectFilters,
  handleCheckboxFilter,
  applyFilters,
  clearFilter,
  loadingGetFilteredTasks,
}: DialogProps) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        background: '#FFFFFF',
        boxShadow: '0px 9px 46px 8px rgba(0, 0, 0, 0.08)',
        borderRadius: '10px',
        padding: '20px 15px 15px',
        maxWidth: '495px',
        zIndex: '1111',
      }}>
      <Box>
        <Typography className={classes.title}>
          <FormattedMessage id="tasks_completion_status" />
        </Typography>
        <RadioGroup
          // sx={{ ml: '10px' }}
          row
          aria-label="Type">
          {items &&
            items.completionStatus.map((i) => (
              <FormControlLabel
                sx={{
                  margin: '10px 0px 0 0',
                  '.MuiFormControlLabel-label': {
                    color: i.active ? '' : '#BDBDBD',
                    fontSize: '12px',
                  },
                }}
                key={i.title}
                className={classes.root}
                value={i.title}
                control={
                  <Checkbox
                    name={i.title}
                    onChange={(e) => {
                      handleCheckboxFilter(e.target.name);
                    }}
                    checked={i.active}
                    icon={<CropSquareIcon sx={{ fill: '#BDBDBD', fontSize: '22px' }} />}
                    checkedIcon={<CheckBoxIcon sx={{ fill: '#EE9A8F', fontSize: '22px' }} />}
                  />
                }
                label={i.title}
              />
            ))}
        </RadioGroup>
      </Box>
      {items.brand && (
        <Box>
          <Typography mt={2} mb={2} className={classes.title}>
            <FormattedMessage id="tasks_brand" />
          </Typography>
          <Autocomplete
            multiple={true}
            value={valuesfilters.brand}
            onChange={(e, newValue) => {
              handleValuesSelectFilters('brand', newValue);
            }}
            getOptionLabel={(option: any) => option}
            fullWidth={true}
            options={items.brand}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index): any => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    background: '#fff !important',
                    color: '#7367F0',
                    borderRadius: '10px !inportant',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                  }}
                  // deleteIcon={<ClearIcon sx={{ fill: '#7367F0', fontSize: '12px !important' }} />}
                />
              ))
            }
            sx={{ '.MuiAutocomplete-root': { padding: '0px' } }}
            popupIcon={''}
            clearIcon={''}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '.MuiOutlinedInput-root': { padding: '0px' },
                }}
                variant="outlined"
              />
            )}
          />
        </Box>
      )}
      {items.taskName && (
        <Box>
          <Typography mt={2} mb={2} className={classes.title}>
            Task Name
          </Typography>
          <Autocomplete
            multiple={true}
            value={valuesfilters.taskName}
            onChange={(e, newValue) => {
              handleValuesSelectFilters('taskName', newValue);
            }}
            getOptionLabel={(option: any) => option}
            fullWidth={true}
            options={items.taskName}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index): any => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    background: '#fff !important',
                    color: '#7367F0',
                    borderRadius: '10px !inportant',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                  }}
                  // deleteIcon={<ClearIcon sx={{ fill: '#7367F0', fontSize: '12px !important' }} />}
                />
              ))
            }
            sx={{ '.MuiAutocomplete-root': { padding: '0px' } }}
            popupIcon={''}
            clearIcon={''}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '.MuiOutlinedInput-root': { padding: '0px' },
                }}
                variant="outlined"
              />
            )}
          />
        </Box>
      )}
      <Box mt={1}>
        <Button
          onClick={applyFilters}
          titleBtn={<FormattedMessage id="tasks_apply" />}
          colorBtn="red"
        />
        <Typography
          onClick={clearFilter}
          mt={4}
          mb={1}
          sx={{ textAlign: 'center', color: '#EE9A8F', fontSize: '16px', cursor: 'pointer' }}>
          <FormattedMessage id="tasks_clear_filters" />
        </Typography>
      </Box>
    </Box>
  );
};

export default DialogFilters;
