import { gql } from '@apollo/client';

const TASKS_COMPLETITION_STATUS = gql`
mutation taskCompletitionStatus($id: String!, $completition_status: String!) {
  update_tasks(id: $id, completion: $completition_status) {
      id
      dueDate
      name
      description
      status
      title
      completion
      brand {
        name
      }
  }
}
`;
const CHANGE_TASKS_COMPLITION_STATUS = gql`
mutation changeTaskCompletitionStatus($id: String!, $completition_status: String!) {
  update_tasks(id: $id, completion: $completition_status) {
      id
      dueDate
      name
      description
      status
      title
      completion
      brand {
        name
      }
  }
}
`;
const CHANGE_PUBLICATION_WEEKLY_CALENDAR_TASKS = gql`
mutation changePublicationWeeklyCalendarTasks($id: String!, $conceptionStatus: String!, $concept: String!, $publicationTime: String!, $type: String!, $category: String!, $caption: String!, $commentsOnTextOpen: String!, $tags: String!, $productsToTag: String!, $location: String!, $toBeBoosted: Boolean!, $commentsOnDesignOpen: String!, $assets: [JSON], $hashtags: String!, $designs: String!) {
  update_publications(id: $id, conceptionStatus: $conceptionStatus, concept: $concept, publicationTime: $publicationTime, type: $type, category: $category, caption: $caption, commentsOnTextOpen: $commentsOnTextOpen, tags: $tags, productsToTag: $productsToTag, location: $location, toBeBoosted: $toBeBoosted, commentsOnDesignOpen: $commentsOnDesignOpen, hashtags: $hashtags, designs: $designs) {
    id
    publicationId
    conceptionStatus
    concept
    publicationTime
    type
    category
    caption
    commentsOnTextOpen
    tags
    productsToTag
    location
    toBeBoosted
    designs
    commentsOnDesignOpen
    hashtags
  }
}
`;

export { TASKS_COMPLETITION_STATUS, CHANGE_TASKS_COMPLITION_STATUS, CHANGE_PUBLICATION_WEEKLY_CALENDAR_TASKS };
