import React from 'react';
import WeekCalendarCard from '../../../components/calendar/week-calendar/WeekCalendarCard';
import Tabs from '@material-ui/core/Tabs';
import { Tab, Grid, MenuItem, Box, Typography, CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/select';

import Button from '../../../components/button';
import { GET_WEEKLY_CALENDARS } from '../../../api/queries/calendar/weekly';
import { useMutation } from '@apollo/client';
import {
  VALIDATE_SEVERAL_PUBLICATIONS,
  PROVIDE_FEEDBACK,
} from '../../../api/mutations/calendar/weekly';
import DialogConfirm from '../../../components/calendar/dialogs/DialogConfirm';
import DialogRating from '../../../components/calendar/dialogs/DialogRating';
import DialogFeedback from '../../../components/calendar/dialogs/DialogFeedback';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  tabs: {
    '.MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
}));
const items = {
  type: [
    {
      title: 'Story',
      active: false,
    },
    {
      title: 'Post',
      active: false,
    },
  ],
  categories: [
    {
      title: 'All',
      active: false,
    },
    {
      title: 'Information',
      active: false,
    },
    {
      title: 'Promotion',
      active: false,
    },
    {
      title: 'News',
      active: false,
    },
    {
      title: 'Inspiration',
      active: false,
    },
    {
      title: 'Portrait',
      active: false,
    },
    {
      title: 'Quote',
      active: false,
    },
    {
      title: 'Meme',
      active: false,
    },
  ],
  status: [
    {
      title: 'Validated',
      active: false,
    },
    {
      title: 'Pending',
      active: false,
    },
  ],
};
const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Gilroy-SemiBold !important',
  fontSize: '16px',
  color: '#000',
  '&.Mui-selected': {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.4000000059604645px',
    color: 'rgba(0, 0, 0, 0.87)',
    borderRight: 'none !important',
    background: '#fff',
    borderRadius: '10px 0 0 10px',
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: '#fff',
  },
  '.MuiTabs-scroller': {
    borderRight: 'none !important',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
}));
const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  '& .MuiTabs-indicator': {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

interface WeekCalendarProps {
  data: any;
  periodToFilterProp: string | null;
}
const WeekCalendar: React.FC<WeekCalendarProps> = ({ data, periodToFilterProp }) => {
  const intl = useIntl();

  const [activeStep, setActiveStep] = React.useState(0);
  const [periodSelected, setPeriodSelected] = React.useState<string>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [allWeekCalendars, setAllWeekCalendars] = React.useState<any>();
  const [valSelectWeek, setValSelectWeek] = React.useState<any>();
  const [weekPublications, setWeekPublications] = React.useState<any>();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  const [openModal3, setOpenModal3] = React.useState(false);
  const [indexOfTheStep, setIndexOfTheStep] = React.useState(0);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const [
    validateSeveralPublication,
    { loading: loadingFeedbackMonthly, error: errorFeedbackMonthly },
  ] = useMutation(VALIDATE_SEVERAL_PUBLICATIONS, {
    onCompleted: (data) => {
      setOpenModal(false)
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'All publications have been successfully updated',
        variant: 'alert',
        alertSeverity: 'success',
        autoHideDuration: '6000',
      });
    },
    onError: (error) => {
      const message = JSON.parse(error.message);
      dispatch({
        type: SNACKBAR_OPEN,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transition: 'SlideLeft',
        open: true,
        message: 'Error: ' + message?.content,
        variant: 'alert',
        alertSeverity: 'error',
        autoHideDuration: '6000',
      });
    },
  });
  const handleValidateSeveral = () => {
    let arrId: any = [];
    weekPublications.forEach((element) => {
      arrId.push(element.id);
    });
    validateSeveralPublication({
      variables: {
        ids: arrId,
        status: 'Approved',
      },
      refetchQueries: [
        { query: GET_WEEKLY_CALENDARS, variables: { taskClientReviewStatus: ['Not open yet'] } },
      ],
    });
  };

  const [provideFeedback, { loading: loadingProvideFeedback, error: errorProvideFeedback }] =
    useMutation(PROVIDE_FEEDBACK, {
      onCompleted: (data) => {
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Thanks for the feedback',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        const message = JSON.parse(error.message);
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: ' + message?.content,
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
      },
    });
  const handleFeedback = (value) => {
    provideFeedback({
      variables: {
        id: weekPublications.id,
        feedback: value.comments,
        rating: value.rating,
      },
      refetchQueries: [{ query: GET_WEEKLY_CALENDARS }],
    });
  };
  const handleStepChange = (index) => {
    setIndexOfTheStep(index);
  };
  const handleChangeSelectWeek = (value) => {
    setValSelectWeek(value);
    allWeekCalendars.forEach((element) => {
      if (element.dueDate === value) setWeekPublications(element.publications);
    });
  };
  const handleConfirmRating = () => {
    setOpenModal2(true);
    setOpenModal(false);
  };
  const handleConfirmFeedback = () => {
    setOpenModal3(true);
    setOpenModal2(false);
  };
  const backToHome = () => {
    setOpenModal3(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (periodToFilterProp) {
      setPeriodSelected(periodToFilterProp);
    }
  }, [periodToFilterProp]);

  React.useEffect(() => {
    // if (periodToFilterProp) {
    //   setPeriodSelected(periodToFilterProp);
    // }
    // let periodToFilterValue = periodToFilterProp ? periodToFilterProp : periodSelected;

    if (periodSelected) {
      setAllWeekCalendars(
        data[0].calendars.filter((calendar) => calendar.dueDateMonth === periodSelected),
      );
    }
  }, [data, periodSelected]);

  React.useEffect(() => {
    if (allWeekCalendars && allWeekCalendars.length > 0) {
      setValSelectWeek(allWeekCalendars[indexOfTheStep].dueDate);
      setWeekPublications(allWeekCalendars[indexOfTheStep].publications);
    }
  }, [allWeekCalendars, indexOfTheStep]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleChangeStep = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };

  const monthsToFilter = React.useMemo(() => {
    if (data[0].calendars) {
      const values = data[0].calendars
        .map((value) => value.dueDateMonth)
        .filter((x, i, a) => a.indexOf(x) == i);

      //sort months ascending
      const valuesSorted = values.sort(function (a, b) {
        var aa = a.split('/').reverse().join(),
          bb = b.split('/').reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });

      if (!periodSelected) {
        setPeriodSelected(values[0]);
      }
      return valuesSorted;
    }
    return [];
  }, [allWeekCalendars]);

  const handlePeriodSelected = (monthSelected) => {
    setActiveStep(0);
    setIndexOfTheStep(0);
    setPeriodSelected(monthSelected);
  };

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item container xs={12} md={4}>
          <Grid>
            <Typography
              sx={{
                fontFamily: 'Gilroy-SemiBold',
                color: '#000',
                fontSize: '20px',
                lineHeight: '160%',
              }}>
              <FormattedMessage id="calendar_weekly_calendar" />
            </Typography>
          </Grid>
          {/* <Grid>
            <Box
              sx={{
                margin: { xs: '25px 0px 0px 20px', md: '5px 0px 0px 20px' },
                display: { xs: 'none', md: 'flex' },
              }}>
              <Box
                aria-describedby={id}
                onClick={(e: any) => handleClick(e)}
                sx={{ display: 'flex', cursor: 'pointer' }}>
                <Typography
                  sx={{
                    fontFamily: 'Gilroy-SemiBold',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#EE9A8F',
                    letterSpacing: '0.4px',
                    marginRight: '5px',
                  }}>
                  Filter
                </Typography>
                <KeyboardArrowDownIcon sx={{ fill: '#EE9A8F' }} />
              </Box>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <FiltersOnSubmit
                  setAllFiltersSelected={setAllFiltersSelected}
                  items={itemsRadio}
                  setItemsRadio={setItemsRadio}
                  handleClose={handleClose}
                />
              </Popover>
            </Box>
          </Grid> */}
        </Grid>
        <Grid
          item
          container
          xs={4}
          sx={{ marginTop: '-14px', display: { xs: 'none', md: 'flex' } }}
          justifyContent="flex-end">
          <Grid xs={5} mr={2}>
            <Button
              titleBtn={<FormattedMessage id="calendar_weekly_validate_all" />}
              colorBtn="white"
              onClick={() => setOpenModal(true)}
            />
          </Grid>
          {/* <Grid xs={5}>
            <Button titleBtn="Add Event" colorBtn="red" />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid
        mt={1}
        container
        sx={{
          background: '#EEEEEE',
          borderRadius: '10px',
          padding: { xs: '5px', md: '20px' },
          border: { xs: '1.5px solid rgba(188, 206, 245, 0.5)', md: 'none' },
        }}>
        <Grid item xs={12} md={2}>
          <Box mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Select
              arrowWhite="white"
              lang="periodSelected"
              onChange={(e: any) => handlePeriodSelected(e.target.value)}
              value={periodSelected || null}
              sx={{
                width: { xs: '100%', md: '80%' },
                textAlign: 'center',
                paddingRight: { md: '8px' },
                borderRadius: '0px',
              }}>
              {monthsToFilter &&
                monthsToFilter.map((item) => (
                  <MenuItem value={item} key={item}>
                    {moment(item, 'MM-YYYY').format('MMMM - YYYY')}
                    {/*{item} */}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <StyledTabs
            orientation="vertical"
            value={activeStep}
            onChange={handleChangeStep}
            aria-label="styled tabs example"
            sx={{ display: { xs: 'none', md: 'block' } }}>
            {allWeekCalendars &&
              allWeekCalendars.map((item, index) => (
                <StepsTab
                  key={index}
                  onClick={() => handleStepChange(index)}
                  sx={{
                    fontFamily: 'Gilroy-Medium !important',
                    borderRadius: '0px',
                    textTransform: 'none !important',
                  }}
                  label={
                    intl.formatMessage({ id: 'calendar_monthly_calendar_week_of' }) +
                    ' ' +
                    moment(item.dueDate).format('DD/MM/YYYY')
                  }
                />
              ))}
          </StyledTabs>
        </Grid>
        <Grid item xs={12} md={10}>
          {weekPublications && (
            <WeekCalendarCard
              handleStepChange={handleChangeSelectWeek}
              allWeekCalendars={allWeekCalendars}
              publications={weekPublications}
              valSelectWeek={valSelectWeek}
              indexOfTheStep={indexOfTheStep}
              periodSelected={periodSelected}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: {
            xs: 'flex',
            md: 'none',
            position: 'fixed',
            bottom: '0',
            background: '#fff',
            width: '101%',
            left: '3px',
            padding: '0 10px 15px 10px',
          },
        }}
        justifyContent="space-between"
        spacing={1}>
        <Grid item xs={12}>
          <Button
            titleBtn={<FormattedMessage id="calendar_weekly_validate_all" />}
            colorBtn="red"
            onClick={() => setOpenModal(true)}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Button titleBtn="Add Event" colorBtn="white" />
        </Grid> */}
      </Grid>
      {openModal && (
        <DialogConfirm
          open={openModal}
          onClose={() => setOpenModal(false)}
          onConfirm={handleConfirmRating}
          handleValidateAll={handleValidateSeveral}
          loadingUpdateMonthlyPostAll={loadingFeedbackMonthly}
        />
      )}
      {openModal2 && (
        <DialogRating
          open={openModal2}
          onClose={() => setOpenModal2(false)}
          onConfirm={handleConfirmFeedback}
          handleFeedback={handleFeedback}
        />
      )}
      {openModal3 && (
        <DialogFeedback
          open={openModal3}
          onClose={() => setOpenModal3(false)}
          onConfirm={backToHome}
        />
      )}
    </Box>
  );
};

export default WeekCalendar;
