import { Redirect } from 'react-router-dom';

// project imports
import useAuth from '../../hooks/useAuth';
import { GuardProps } from '../../types';
import { useLocation } from 'react-router-dom';

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */

const checkRoles = (roles) => {
  const arrRolesUser = JSON.parse(`${localStorage.getItem('role')}`);
  if (arrRolesUser) {
    if (roles) {
      // let stateRole = false
      for (const roleUser of arrRolesUser) {
        if (roles.includes(roleUser)) {
          // stateRole = true
          return true;
        }
      }
      return false;
    }
  }
  return false;
};

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { urlToRiderect: location.pathname },
        }}
      />
    );
  }

  if (!checkRoles(['FREELANCER_USER'])) {
    return (
      <Redirect
        to={{
          pathname: '/access-denied',
          state: { urlToRiderect: location.pathname },
        }}
      />
    );
  }

  return children;
};

export default AuthGuard;
