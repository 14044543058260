import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Popover, Grid, CircularProgress } from '@material-ui/core';
import ButtonTasks from './ButtonTasks';
import moment from 'moment';
import CustomChip from './CustomChip';
import TaskContentMobileDueDate from './TaskContentMobileDueDate';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CHANGE_TASKS_COMPLITION_STATUS } from '../../api/mutations/tasks';
import { useMutation } from '@apollo/client';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ButtonAction from './ButtonAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  toDoCalendarHeader: {
    background: '#5A6092',
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
    padding: '16px !important',
  },
  toDoCalendarContent: {
    color: '#000000',
    padding: '16px !important',
    border: '1px solid #E1E1E1',
    borderTop: 'none',
    alignItems: 'center',
  },
}));
interface ToDoProps {
  radioOrderBy: string;
  fields: any;
  setCurrent: any;
  count: number;
  hasMore: boolean;
  updateData: any;
  getMoreData: any;
  getCalendarTask: any;
}

const TaskContentMobile = ({
  radioOrderBy,
  fields,
  setCurrent,
  count,
  hasMore,
  getMoreData,
  updateData,
  getCalendarTask,
}: ToDoProps) => {
  const [dateNow, setDateNow] = React.useState<any>();
  React.useEffect(() => {
    setDateNow(moment().format('YYYY-MM-DD HH:mm:ss'));
  }, []);

  const handleTime = (time: any) => {
    let nowTime = moment(dateNow);
    time = moment(time);
    return nowTime.diff(time, 'hours').toString().replace('-', '');
  };
  const handleTimeIsAfter = (time: any) => {
    let nowTime = moment(dateNow);
    return nowTime.isAfter(time);
  };

  //   React.useEffect(() => {
  //     if (radioOrderBy == 'Brand') {
  //       let brands: any = [];
  //       fields.forEach((i) => {
  //         brands.push(i.brand[0].name);
  //       });
  //       brands = Array.from(new Set(brands));
  //       let sortBrands: any = [];
  //       let arr = brands;

  //       brands.forEach((i, key) => {
  //         var filteredItems = fields.filter((j) => {
  //           return j.brand[0].name == i;
  //         });
  //         arr[key] = filteredItems;
  //       });
  //       sortBrands.push(arr);
  //       console.log('sortBrands', sortBrands);
  //       let sortedBrands = sortBrands[0];
  //       setItemsState(sortedBrands);
  //     } else if (radioOrderBy == 'Task Type') {
  //       let brands: any = [];
  //       fields.forEach((i) => {
  //         brands.push(i.flowGroup);
  //       });
  //       brands = Array.from(new Set(brands));
  //       let sortBrands: any = [];
  //       let arr = brands;
  //       brands.forEach((i, key) => {
  //         var filteredItems = fields.filter((j) => {
  //           return j.flowGroup == i;
  //         });
  //         arr[key] = filteredItems;
  //       });
  //       sortBrands.push(arr);
  //       let sortedBrands = sortBrands[0];
  //       setItemsState(sortedBrands);
  //     }
  //   }, [radioOrderBy]);
  const dispatch = useDispatch();

  const ContentMobile = ({ item, after, key }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [
      changeTaskCompletitionStatus,
      { loading: loadingChangeTaskCompletitionStatus, error: errorChangeTaskCompletitionStatus },
    ] = useMutation(CHANGE_TASKS_COMPLITION_STATUS, {
      onCompleted: (data) => {
        handleClose();
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Completion status successfully modified',
          variant: 'alert',
          alertSeverity: 'success',
          autoHideDuration: '6000',
        });
      },
      onError: (error) => {
        handleClose();
        const message = JSON.parse(error.message);
        dispatch({
          type: SNACKBAR_OPEN,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transition: 'SlideLeft',
          open: true,
          message: 'Error: ' + message?.content,
          variant: 'alert',
          alertSeverity: 'error',
          autoHideDuration: '6000',
        });
      },
    });

    const handleChangeCompletionStatus = (id) => {
      changeTaskCompletitionStatus({
        variables: {
          id: id,
          completition_status: 'Rework required',
        },
        update: (cache, { data: { update_tasks } }) => {
          updateData(update_tasks[0]);
        },
      });
    };
    const handleChangeCompletionStatusMarkAsDone = (id) => {
      changeTaskCompletitionStatus({
        variables: {
          id: id,
          completition_status: 'Done',
        },
        update: (cache, { data: { update_tasks } }) => {
          updateData(update_tasks[0]);
        },
      });
    };

    return (
      <Box
        mt={2}
        sx={{
          boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)',
          borderRadius: '10px',
          padding: '15px',
          background: '#fff',
        }}>
        <Box mb={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '14px',
              lineHeight: '143%',
              fontFamily: 'Gilroy',
              // textDecoration:
              //   (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) ||
              //   (item.flowGroup === 'Calendar' && item.completion !== 'Client review')
              //     ? 'underline'
              //     : 'none',
              // cursor:
              //   (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) ||
              //   (item.flowGroup === 'Calendar' && item.completion !== 'Client review')
              //     ? 'pointer'
              //     : 'default',
            }}
            onClick={() => getCalendarTask(item)}>
            {item.flowGroup + ' ' + item.title}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ width: '21px' }} mt={0.8}>
              {item.brand[0].picture && (
                <img width="100%" src={item.brand[0].picture[0].url} alt="img" />
              )}
            </Box>
            <Typography
              sx={{
                ml: '10px',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '14px',
                lineHeight: '143%',
                fontFamily: 'Gilroy-SemiBold',
              }}>
              {item.brand[0]['name']}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <Box>
            <Typography sx={{ mb: '5px' }}>{moment(item.dueDate).format('DD/MM/YYYY')}</Typography>
            {after && item.dueDate !== '' ? (
              <CustomChip
                label={handleTime(item.dueDate) + ' ' + 'tasks_hours_late'}
                status="danger"
              />
            ) : item.dueDate && +handleTime(item.dueDate) < 7 ? (
              <CustomChip
                label={handleTime(item.dueDate) + ' ' + 'tasks_hours_late'}
                status="warning"
              />
            ) : (
              ''
            )}
          </Box>
          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <ButtonTasks
              titleBtn={
                item.completion
                // == 'Done'
                //   ? 'Done'
                //   : item.completion == 'Not open yet'
                //   ? 'Not open yet'
                //   : 'To Do'
              }
              status={item.completion}
              colorBtn={
                item.completion == 'Done'
                  ? 'green'
                  : after
                  ? 'red'
                  : item.completion != 'Not open yet' && item.completion != 'To do'
                  ? 'orange'
                  : ''
              }
            />
          </Box>
        </Box>
        {item.length - 1 !== key && (
          <Box sx={{ border: '1px solid #E0E0E0', width: '100%', margin: '20px 0px' }}></Box>
        )}
        <Grid item container spacing={1} justifyContent="center">
          <Grid item xs={10}>
            <ButtonAction
              disabled={item.completion !== 'To do' ? true : false}
              onClick={() => handleChangeCompletionStatusMarkAsDone(item.id)}
              titleBtn={
                loadingChangeTaskCompletitionStatus ? (
                  <CircularProgress size="20px" sx={{ color: '#fff' }} />
                ) : (
                  <FormattedMessage id="tasks_completion_status" />
                )
              }
              colorBtn="green"
            />

            <Box
              sx={{
                display: 'flex',
                mt: '5px',
                cursor: 'pointer',
                justifyContent: 'center',
                textDecoration: 'underline',
              }}>
              <Typography
                onClick={() =>
                  (item.flowGroup === 'Conception' && item.monthlyCalendar.length > 0) ||
                  (item.flowGroup === 'Calendar' && item.completion !== 'Client review')
                    ? getCalendarTask(item)
                    : window.open(item.viewLink, '_blank')
                }>
                <FormattedMessage id="tasks_access_view" />
              </Typography>
              <ArrowRightAltIcon
                sx={{
                  transform: 'rotate(-45deg)',
                  ml: '5px',
                  fill: 'rgba(0, 0, 0, 0.87)',
                  fontSize: '18px',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            {item.completion === 'To do' && (
              <Box>
                <Typography
                  sx={{
                    background: '#F5F5F5',
                    borderRadius: '15.7333px',
                    width: '35px',
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={handleClick}>
                  <KeyboardArrowDownIcon sx={{ fill: '#EE9A8F' }} />
                </Typography>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '.MuiPopover-paper': { boxShadow: 'none !important' },
                    '.MuiPopover-root': { boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)' },
                  }}>
                  <Typography
                    onClick={() => handleChangeCompletionStatus(item.id)}
                    sx={{ p: 2, cursor: 'pointer' }}>
                    <FormattedMessage id="tasks_request_rework" />
                  </Typography>
                </Popover>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const fieldsToDisplay = React.useMemo(() => {
    return fields;
  }, [fields]);

  return (
    <Box mt={2}>
      {radioOrderBy == 'Due Date' ? (
        <TaskContentMobileDueDate radioOrderBy={radioOrderBy} items={fieldsToDisplay} />
      ) : (
        <Box>
          <InfiniteScroll
            dataLength={fieldsToDisplay.length}
            next={getMoreData}
            hasMore={hasMore}
            loader={
              <h4>
                <FormattedMessage id="tasks_loading" />
                ...
              </h4>
            }>
            {fieldsToDisplay.length > 0 &&
              fieldsToDisplay.map((task, index) => {
                let after = handleTimeIsAfter(task.dueDate);
                return (
                  <Box key={task.id}>
                    <ContentMobile item={task} after={after} key={index} />
                  </Box>
                );
              })}
          </InfiniteScroll>
        </Box>
      )}
    </Box>
  );
};

export default TaskContentMobile;
