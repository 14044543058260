import { gql } from '@apollo/client';

const Weekly_Calendars = gql`
  query WeeklyCalendars($dueDate: String) {
    user {
      calendars(dueDate: $dueDate) {
        id
        calendarId
        dueDate
        publications {
          id
          publicationId
          clientApproval
          publicationTime
          concept
          type
          category
          caption
          assets
          tags
          productsToTag
          location
          toBeBoosted
          clientCommentsInProgressDone
          socialManagerInstructionsAndFeedbackOpenReadOnly
          instructionsForDesignerAreAvailable
        }
      }
    }
  }
`;
const GET_WEEKLY_CALENDARS = gql`
  query WeeklyCalendarsAll($taskClientReviewStatus: [String]) {
    user {
      calendars(taskClientReviewStatus: $taskClientReviewStatus) {
        id
        calendarId
        taskClientReviewStatus
        dueDate
        dueDateMonth
        publications {
          id
          publicationId
          clientApproval
          publicationTime
          concept
          type
          category
          caption
          assets
          tags
          productsToTag
          location
          toBeBoosted
          additionalCommentsOpen
          commentsOnTextOpen
          commentsOnDesignOpen
        }
      }
    }
  }
`;
const GET_SHARED_WEEKLY_CALENDAR = gql`
query getSharedWeeklyCalendar($id: String) {
  calendars(id: $id) {
    id
    calendarId
    taskClientReviewStatus
    dueDate
    dueDateMonth
    publications {
      id
      publicationId
      clientApproval
      publicationTime
      concept
      type
      category
      caption
      assets
      tags
      productsToTag
      location
      toBeBoosted
      additionalCommentsDone
      commentsOnTextOpen
      commentsOnDesignOpen
    }
  }
}
`;

// const GET_WEEKLY_CALENDARS = gql`
// query GET_WEEKLY_CALENDARS {
//   user {
//     calendars(taskClientReviewStatus: "To do") {
//       id
//       calendarId
//       publications {
//         id
//         publicationId
//         clientApproval
//         publicationTime
//         concept
//         type
//         category
//         caption
//         assets
//         tags
//         productsToTag
//         location
//         toBeBoosted
//         clientCommentsInProgressDone
//         socialManagerInstructionsAndFeedbackOpenReadOnly
//         instructionsForDesignerAreAvailable
//       }
//     }
//   }
// }
// `;

export { Weekly_Calendars, GET_WEEKLY_CALENDARS, GET_SHARED_WEEKLY_CALENDAR };
