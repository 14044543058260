import { lazy } from 'react';

import { Route, Switch, useLocation } from 'react-router-dom';

import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../components/Loadable';
import AuthGuard from './route-guard/AuthGuard';
import FieldsContainer from '../views/profile/fieldsContainer';
import BusinessUserAuthGuard from './route-guard/BusinessUserAuthGuard';

const AuthenticationRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={['/complete-form/:formName/:formId', '/complete-form/:formName', '/complete-form']}>
      <MinimalLayout>
        <Switch location={location} key={location.pathname}>
          <BusinessUserAuthGuard>
            <>
              <Route
                exact
                path={[
                  '/complete-form/:formName/:formId',
                  '/complete-form/:formName',
                  '/complete-form',
                ]}
                component={FieldsContainer}
              />
            </>
          </BusinessUserAuthGuard>
        </Switch>
      </MinimalLayout>
    </Route>
  );
};

export default AuthenticationRoutes;
