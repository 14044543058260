import React from 'react';
import { Box, Typography, Button, Stack, CircularProgress, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import DialogAddPublication from '../dialogs/DialogAddPublication';

import EditIcon from '@material-ui/icons/Edit';
// import fields from '../../../views/calendar/data/monthlyCalendarFields';

import moment from 'moment';
import DialogEditing from '../dialogs/DialogEditing';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { VALIDATE_ONE_MONTHLY_POST } from 'api/mutations/calendar';
import { GET_MONTHLY_CALENDARS } from 'api/queries/calendar/monthly';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import TypePost from 'assets/images/icons/typePost';
import TypeStory from 'assets/images/icons/typeStory';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '25px !important',
    color: '#252A32 !important',
    fontWeight: 'bold',
  },
  monthlyCalendarContainer: {
    border: '2px solid #E5E5E5',
    borderRadius: '10px',
  },
  monthlyCalendarHeader: {
    background: '#5A6092',
    color: 'white',
    borderRadius: '10px',
    padding: '16px !important',
  },
  monthlyCalendarWeekContent: {
    background: 'white',
    color: '#000000',
    borderRadius: '10px',
    padding: '16px !important',
    minWidth: '100% !important',
  },
  weekOfTitle: {
    fontSize: '16px',
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: '160%',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#000000',
  },
  weekOfTitleEdit: {
    border: '1px solid #EE9A8F',
    padding: '4px 0px',
    fontSize: '12px',
  },
  publicationTimeText: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: '#000',
  },
  publicationCategory: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: '#000',
  },
  publicationConcept: {
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: '#000',
  },
  validateButton: {
    background: '#EE9A8F',
    border: '1px solid #EE9A8F',
    borderRadius: '4px',
    color: '#fff',
    '&:hover': {
      background: '#EE9A8F',
    },
  },
  deleteButton: {
    background: 'white',
    border: '1px solid #EE9A8F',
    borderRadius: '4px',
    color: '#EE9A8F',
  },
  weeklyPost: {
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: '10px',
    background: 'white',
  },
}));

interface WeekProps {
  calendars?: any;
}

const DesktopWeekCard: React.FC<WeekProps> = ({ calendars }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [addEventDialog, setAddEventDialog] = React.useState(false);
  const [value, setValue] = React.useState<Date | null>(null);
  const [weeklyPosts2, setWeeklyPosts] = React.useState<any>(null);
  const [valueSelect, setValueSelect] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);

  const [weeklyPostToEdit, setWeeklyPostToEdit] = React.useState();
  const [publicationToEdit, setPublicationToEdit] = React.useState();

  const openAddEventDialog = () => {
    setAddEventDialog(true);
  };

  const backToHome = () => {
    setOpenModal(false);
  };
  const successAddEventDialog = () => {
    setAddEventDialog(false);
  };

  //TODO if post is validaded, hide validate button, otherwise show it

  const RenderRow = ({ weeklyPost }) => {
    const [editingMode, setEditingMode] = React.useState(false);
    const [loadingValidate, setLoadingValidate] = React.useState(false);
    const [loadingPublicationId, setLoadingPublicationId] = React.useState();

    const RenderActionButtons = ({ publication }) => {
      const [updateMonthlyPost, { loading: loadingMonthlyPost, error }] = useMutation(
        VALIDATE_ONE_MONTHLY_POST,
        {
          onCompleted: (data) => {
            setLoadingValidate(false);

            dispatch({
              type: SNACKBAR_OPEN,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              transition: 'SlideLeft',
              open: true,
              message: 'The post was successfully validated.',
              variant: 'alert',
              alertSeverity: 'success',
              autoHideDuration: '6000',
            });
          },
          onError: (error) => {
            setLoadingValidate(false);

            const message = JSON.parse(error.message);
            dispatch({
              type: SNACKBAR_OPEN,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              transition: 'SlideLeft',
              open: true,
              message: 'Error: ' + message?.content,
              variant: 'alert',
              alertSeverity: 'error',
              autoHideDuration: '6000',
            });
          },
        },
      );

      const handleValidate = (publication) => {
        setLoadingPublicationId(publication.id);
        setLoadingValidate(true);
        updateMonthlyPost({
          variables: {
            id: publication.id,
            conceptionStatus: 'Done',
          },
          refetchQueries: [{ query: GET_MONTHLY_CALENDARS }],
        });
      };
      return (
        <>
          {loadingValidate && loadingPublicationId === publication.id ? (
            <CircularProgress />
          ) : (
            <Button disabled={(!publication.concept || publication.concept=='\n') || !publication.category || !publication.publicationTime || !publication.type? true: false} className={classes.validateButton} onClick={() => handleValidate(publication)}>
              <FormattedMessage id="calendar_monthly_calendar_validate" />
            </Button>
          )}
        </>
      );
    };

    return (
      <>
        <Grid container justifyContent="space-between" sx={{ p: 2 }}>
          <Grid item xs={5}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography className={classes.weekOfTitle}>
                <FormattedMessage id="calendar_monthly_calendar_week_of" />{' '}
                {moment(weeklyPost.dueDate).format('DD/MM/YYYY')}
              </Typography>
              {/* <Button className={classes.weekOfTitleEdit}>Edit</Button> */}
            </Stack>
          </Grid>
          {/* <Grid item xs={2}>
            {loadingMonthlyPost ? <CircularProgress /> : ''}
          </Grid> */}
        </Grid>

        {weeklyPost.publications.map((publication) => (
          <Grid
            container
            className={classes.monthlyCalendarWeekContent}
            alignItems="center"
            key={publication.id}>
            {/* {console.log('🔥🔥 ~ publication', publication)} */}
            <Grid item xs={2}>
              {/* {!publication.publicationTime ? ( */}
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={publication.publicationTime}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '70%', '.MuiFormHelperText-root': { display: 'none' } }}
                    />
                  )}
                />
              </LocalizationProvider> */}
              {/* // ) : ( */}
              <Typography className={classes.publicationTimeText}>
                {publication.publicationTime &&
                  moment(publication.publicationTime).format('DD/MM/YYYY') +
                  ' | ' +
                  moment(publication.publicationTime).format('dddd')}
              </Typography>
              {/* )} */}
            </Grid>
            <Grid item xs={2}>
              <Stack direction="row" alignItems="center" spacing={0.6}>
                {/* TODO - show right icon for the Story type */}
                {publication.type === 'Post' ? <TypePost /> : <TypeStory />}
                <Typography>{publication.type}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              {/* {!publication.category ? (
                <Select
                  sx={{ padding: '6px', width: '70%' }}
                  size="small"
                  labelId="demo-simple-select-disabled-label"
                  id="demo-simple-select-disabled"
                  name="category"
                  onChange={(e) => setValueSelect(e.target.value)}
                  value={valueSelect}>
                  <MenuItem value="category">category1</MenuItem>
                  <MenuItem value={'category2'}>category2</MenuItem>
                  <MenuItem value={'category3'}>category2</MenuItem>
                  <MenuItem value={'category4'}>category2</MenuItem>
                </Select>
              ) : ( */}
              <Typography className={classes.publicationCategory}>
                {publication.category}
              </Typography>
              {/* )} */}
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ width: '90%' }}>
                {/* {!publication.concept ? (
                  <TextField
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    label="Annonce d’ouverture"
                    variant="outlined"
                  />
                ) : ( */}
                <Typography className={classes.publicationConcept}>
                  {publication.concept}
                </Typography>
                {/* )} */}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Grid item container alignItems="center" justifyContent="flex-start" spacing={1}>
                <Grid item md={8} lg={7}>
                  <Box sx={{ display: 'flex' }}>
                    {publication.conceptionStatus !== 'Done' ? (
                      <RenderActionButtons publication={publication} />
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid rgba(245, 194, 188, 0.5)',
                            borderRadius: '4px',
                            p: '5px 10px',
                            mr: '3px',
                          }}>
                          <CheckCircleOutlineIcon sx={{ fill: '#EE9A8F' }} />
                          <Typography
                            sx={{
                              color: '#EE9A8F',
                              fontFamily: 'Gilroy-SemiBold',
                              letterSpacing: '1px',
                              ml: '5px',
                              fontSize: '14px',
                            }}>
                            <FormattedMessage id="calendar_monthly_validated" />
                          </Typography>
                        </Box>
                        {/* <LinkTo /> */}
                      </Box>
                    )}

                    {publication.conceptionStatus !== 'Done' && (
                      <Box pl="8px">
                        <IconButton
                          onClick={() => {
                            setWeeklyPostToEdit(weeklyPost);
                            setPublicationToEdit(publication);
                          }}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>

                  {/* 
                      <Button
                        sx={{
                          background: 'white',
                          border: '1px solid #EE9A8F',
                          borderRadius: '4px',
                          color: '#EE9A8F',
                        }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={0.4}>
                          <CheckCircleOutlineOutlinedIcon fontSize="small" color="primary" />
                          <Typography>Validated</Typography>
                        </Stack>
                      </Button>
                     */}
                </Grid>
                {/* <Grid item md={8} lg={5}>
                    <Button className={classes.deleteButton}>
                      <DeleteOutlineOutlinedIcon fontSize="small" color="primary" />
                    </Button>
                  </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Box px={2} py={2} className={classes.monthlyCalendarContainer}>
      <Grid container className={classes.monthlyCalendarHeader}>
        <Grid item xs={2}>
          <Typography>
            <FormattedMessage id="calendar_monthly_date" />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            <FormattedMessage id="calendar_monthly_type" />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <FormattedMessage id="calendar_monthly_category" />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <FormattedMessage id="calendar_monthly_concept" />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            <FormattedMessage id="calendar_monthly_status" />
          </Typography>
        </Grid>
      </Grid>
      {calendars &&
        calendars.map((weeklyPost, index) => (
          <Box className={classes.weeklyPost} my={2}>
            <RenderRow weeklyPost={weeklyPost} />
          </Box>
        ))}
      {addEventDialog && (
        <DialogAddPublication
          open={addEventDialog}
          onClose={() => setAddEventDialog(false)}
          onSuccess={successAddEventDialog}
        />
      )}

      {weeklyPostToEdit && (
        <DialogEditing
          open={!!weeklyPostToEdit}
          onClose={() => setWeeklyPostToEdit(undefined)}
          onConfirm={backToHome}
          weeklyPost={weeklyPostToEdit}
          publication={publicationToEdit}
        />
      )}
    </Box>
  );
};

export default DesktopWeekCard;
