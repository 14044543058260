
import { Chip } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
interface ChipProps {
    label: string | React.ReactNode;
    status?: string;
}

export default function CustomChip({ label, status }: ChipProps) {
    return (
        <Chip
            icon={<AccessAlarmIcon sx={{ fontSize: '20px', fill: status === "warning" ? '#FF9800' : '#F44336' }} />}
            label={label}
            sx={{
                background: status === "warning" ? 'rgba(255, 152, 0, 0.08)' : 'rgba(244, 67, 54, 0.08)',
                borderRadius: '10px',
                padding: '15px 0px',
                fontSize: '12px',
                color: status === "warning" ? '#FF9800' : '#F44336',
                fontFamily: 'Gilroy-SemiBold'
            }}
        />
    );
}
