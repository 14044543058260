import React from 'react';
import ProfileHeader from '../../components/profile/ProfileHeader';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { styled } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import MonthlyCalendar from './monthly-calendar';
import WeekCalendar from './weekly-calendar';
import ToDoCalendar from './todo-calendar';
import { useQuery } from '@apollo/client';
import { GET_MONTHLY_CALENDARS } from 'api/queries/calendar/monthly';
import { GET_WEEKLY_CALENDARS } from '../../api/queries/calendar/weekly';
import { MobileView, BrowserView } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 999,
    width: '100%',
  },
  content: {
    width: ' 100%',
    height: '100%',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  tabs: {
    '.MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
  },
}));
const StepsTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: 'Gilroy-Regular',
  fontSize: '16px',
  color: '#717A90',
  '&.Mui-selected': {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4000000059604645px',
  },
}));

interface Props {}

const Calendar = (props: Props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [periodToFilterMonth, setPeriodToFilterMonth] = React.useState<string | null>(null);
  const [periodToFilterWeekly, setPeriodToFilterWeekly] = React.useState<string | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPeriodToFilterMonth(null);
    setPeriodToFilterWeekly(null);
    setActiveStep(newValue);
  };

  const {
    data: monthlyCalendarsData,
    loading: loadingMonthlyCalendars,
    error,
  } = useQuery(GET_MONTHLY_CALENDARS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const {
    loading: loadingWeeklyCalendars,
    error: errorWeeklyCalendar,
    data: weeklyCalendarsData,
  } = useQuery(GET_WEEKLY_CALENDARS, {
    variables: { taskClientReviewStatus: ['Not open yet'] },
    onCompleted: (data) => {},
    onError: (error) => {
      const message = JSON.parse(error.message);
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const toDoCalendars = React.useMemo(() => {
    let weekCalendars = [];
    let monthCalendars = [];
    if (monthlyCalendarsData?.user[0].monthlyCalendars.length) {
      let monthlyCalendars = monthlyCalendarsData?.user[0].monthlyCalendars;
      const toDoCalendars = monthlyCalendars
        .filter((monthlyCalendar) => monthlyCalendar.conceptionStatus === 'In client review')
        .map((monthlyCalendar) => {
          return { ...monthlyCalendar, calendarType: 'monthlyCalendar' };
        });
      monthCalendars = toDoCalendars;
    }

    if (weeklyCalendarsData?.user[0].calendars.length) {
      let weeklyCalendars = weeklyCalendarsData?.user[0].calendars;
      const toDoCalendars = weeklyCalendars
        .filter((monthlyCalendar) =>
          monthlyCalendar.taskClientReviewStatus?.includes('Not open yet'),
        )
        .map((monthlyCalendar) => {
          return { ...monthlyCalendar, calendarType: 'weeklyCalendar' };
        });

      weekCalendars = toDoCalendars;
    }

    return [...weekCalendars, ...monthCalendars];
  }, [monthlyCalendarsData, weeklyCalendarsData]);

  const handleViewMore = (type, date) => {
    if (type === 'month') {
      setActiveStep(1);
      setPeriodToFilterMonth(date);
    }

    if (type === 'week') {
      setActiveStep(2);
      setPeriodToFilterWeekly(date);
    }
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <ProfileHeader
          userName="Besart Marku1"
          title="calendar_my_calendar"
          description="calendar_my_calendar_description"
        />
      </Grid>
      <Grid mt={2} item xs={12}>
        <BrowserView>
          <Tabs value={activeStep} onChange={handleChange} className={classes.tabs}>
            <StepsTab
              sx={{ fontFamily: 'Gilroy-Medium !important' }}
              label={<FormattedMessage id="calendar_to_do_tab" />}
            />
            <StepsTab
              disabled={loadingMonthlyCalendars ? true : false}
              sx={{ fontFamily: 'Gilroy-Medium !important' }}
              label={<FormattedMessage id="calendar_monthly_calendar_tab" />}
            />
            <StepsTab
              disabled={loadingWeeklyCalendars ? true : false}
              sx={{ fontFamily: 'Gilroy-Medium !important' }}
              label={<FormattedMessage id="calendar_monthly_weekly_tab" />}
            />
          </Tabs>
        </BrowserView>
        <MobileView>
          <Tabs value={activeStep} onChange={handleChange} className={classes.tabs}>
            <StepsTab sx={{ fontFamily: 'Gilroy-Medium !important', width: '33%' }} label="To do" />
            <StepsTab
              sx={{ fontFamily: 'Gilroy-Medium !important', width: '33%' }}
              label="Monthly"
            />
            <StepsTab
              sx={{ fontFamily: 'Gilroy-Medium !important', width: '33%' }}
              label="Weekly"
            />
          </Tabs>
        </MobileView>
      </Grid>
      <Grid item xs={12}>
        {activeStep === 0 && (
          <ToDoCalendar
            calendars={toDoCalendars || []}
            loadingMonthlyCalendars={loadingMonthlyCalendars || loadingWeeklyCalendars}
            handleViewMore={handleViewMore}
          />
        )}

        {activeStep === 1 && (
          <MonthlyCalendar
            calendars={monthlyCalendarsData?.user || []}
            loadingMonthlyCalendars={loadingMonthlyCalendars}
            periodToFilterProp={periodToFilterMonth}
          />
        )}

        {activeStep === 2 && (
          <WeekCalendar
            data={weeklyCalendarsData?.user || []}
            periodToFilterProp={periodToFilterWeekly}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Calendar;
