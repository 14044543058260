import { makeStyles } from '@material-ui/styles';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';

interface ButtonProps extends MuiButtonProps {
  titleBtn: string | React.ReactNode;
  colorBtn?: string;
  status?: string;
}

const useStyles = makeStyles({
  root: {
    background: (props: ButtonProps) => (props.status === 'Done' ? '#4CAF50' : '#fff !important'),
    textTransform: 'none',
    lineHeight: '143%',
    color: (props: ButtonProps) =>
      props.status === 'Done' || props.status === 'Not open' ? '#fff ' : 'rgba(0, 0, 0, 0.87)',
    border: '0.7px solid #F0998D ',
    borderColor: (props: ButtonProps) =>
      props.colorBtn === 'red'
        ? '#F44336 '
        : props.colorBtn === 'green'
        ? '#4CAF50'
        : props.colorBtn === 'orange'
        ? '#FF9800'
        : '#E5E5E5',
    // borderColor: '#F44336'
    // '&:hover': {
    //     backgroundColor: (props: ButtonProps) =>
    //         props.colorBtn === 'red' ? '#ffe0dc ' : 'rgba(240, 153, 141) ',
    //     color: (props: ButtonProps) => (props.colorBtn === 'red' ? '#f57362 ' : '#fff '),
    // },
  },
});

export default function ButtonTasks(props: ButtonProps) {
  const classes = useStyles(props);
  return (
    <MuiButton
      {...props}
      sx={{
        fontFamily: 'Gilroy-SemiBold',
        letterSpacing: '0.15px',
        fontSize: { xs: '12px', md: '14px' },
        borderRadius: '10px',
        background: '#f0998d',
        padding: '7px',
        cursor: 'default',
      }}
      className={classes.root}>
      {props.titleBtn}
    </MuiButton>
  );
}
