import SvgIcon from '@material-ui/core/SvgIcon';

const CrossChipColor = () => (
  <SvgIcon>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2505 0.5C13.7922 0.5 15.5 2.2835 15.5 4.937V11.063C15.5 13.7165 13.7923 15.5 11.2498 15.5H4.74875C2.207 15.5 0.5 13.7165 0.5 11.063V4.937C0.5 2.2835 2.207 0.5 4.74875 0.5H11.2505ZM11.2505 1.625H4.74875C2.85125 1.625 1.625 2.92475 1.625 4.937V11.063C1.625 13.0752 2.85125 14.375 4.74875 14.375H11.2498C13.148 14.375 14.375 13.0752 14.375 11.063V4.937C14.375 2.92475 13.148 1.625 11.2505 1.625ZM6.59787 5.79695L7.9985 7.1975L9.39837 5.79852C9.61812 5.57877 9.97363 5.57877 10.1934 5.79852C10.4131 6.01828 10.4131 6.37377 10.1934 6.59352L8.7935 7.9925L10.1949 9.3947C10.4146 9.61445 10.4146 9.96995 10.1949 10.1897C10.0854 10.2999 9.94063 10.3547 9.79737 10.3547C9.65337 10.3547 9.50937 10.2999 9.39987 10.1897L7.9985 8.7875L6.59937 10.1875C6.48987 10.2978 6.34587 10.3525 6.20187 10.3525C6.05788 10.3525 5.91388 10.2978 5.80438 10.1875C5.58463 9.96778 5.58463 9.61228 5.80438 9.39252L7.2035 7.9925L5.80288 6.59195C5.58313 6.3722 5.58313 6.0167 5.80288 5.79695C6.02338 5.5772 6.37887 5.5772 6.59787 5.79695Z" fill="#9C9C9C" />
    </svg>
  </SvgIcon>
);

export default CrossChipColor;
