import {
  Grid,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import food from '../../../assets/images/food.png';
import React from 'react';
import CircleBrand from '../../../assets/images/icons/circleBrand';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBack from '../../../components/buttonBack';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface RenderProps {
  activeStep: number;
  control: any;
  setValue: any;
  watch: any;
  handlStep: () => void;
  field: {
    name: string;
    label: string;
    description?: string;
    type: string;
    title: string;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  trueClass: {
    border: '1px solid #303778',
    boxShadow: '0px 4px 25px rgba(51, 51, 51, 0.08)',
  },
}));

const Brand = ({ activeStep, control, handlStep, field, setValue, watch }: RenderProps) => {
  const classes = useStyles();
  const [valuesToShow, setValuesToShow] = React.useState<any>();
  const [showTextFeild, setShowValueTextFeild] = React.useState(false);

  React.useEffect(() => {
    setValuesToShow(field.constraints.enum);
  }, []);
  React.useEffect(() => {
    if (watch()[field.name]) {
      if (watch()[field.name] === 'OTHER') {
        setShowValueTextFeild(true);
        return;
      } else {
        setShowValueTextFeild(false);
      }
      let arr: any = [];
      field.constraints.enum.forEach((i): any => {
        arr.push(i.key);
      });
      if (arr.includes(watch()[field.name])) {
        setShowValueTextFeild(false);
      } else {
        setShowValueTextFeild(true);
      }
    }
  }, [watch()]);
  const findValue = (val: string) => {
    console.log('🔥🔥 ~ findValue ~ val', val);

    if (val && valuesToShow) {
      const value = valuesToShow.find((element) => element.key === val);
      if (value) {
        // setValue(field.name, value)
        return value;
      }
    }
  };
  const handleValue = (e: any) => {
    if (e.target.value === 'OTHER') {
      setShowValueTextFeild(true);
    } else {
      setShowValueTextFeild(false);
    }
  };
  return (
    <Grid container xs={12} sx={{ padding: { xs: '0px 15px 0px', md: '0px 0px 0px 15px' } }}>
      <Grid container>
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <RadioGroup
                value={findValue(value)}
                row
                onChange={(e: any) => {
                  handleValue(e);
                  onChange(e);
                }}
                sx={{ width: '100%' }}>
                {valuesToShow &&
                  valuesToShow.map((i: any) => (
                    <Grid
                      key={i.key}
                      item
                      direction="column"
                      justifyContent="center"
                      xs={6}
                      sm={3}
                      sx={{
                        marginRight: '5px',
                        maxWidth: {
                          xs: '48% !important',
                          sm: '24.22% !important',
                          minHeight: '113px',
                        },
                      }}>
                      <Box
                        onClick={onChange}
                        className={i.key === value ? classes.trueClass : ''}
                        sx={{
                          border: '1px solid #CECFCF',
                          borderRadius: '10px',
                          padding: { xs: '30px 0px !important', md: '30px 10px !important' },
                          textAlign: 'center',
                          position: 'relative',
                          display: 'flex',
                          width: '100%',
                          marginBottom: '5px',
                          minHeight: '113px',
                        }}>
                        {/* {field.name === 'collaboration_gift' ? (
                        ''
                      ) : (
                        <Box
                          sx={{
                            width: '30px',
                            position: 'absolute',
                            marginBottom: '10px',
                            top: '20px',
                            left: '40%',
                          }}>
                          <img src={food} width="30px" />
                        </Box>
                      )} */}
                        <FormControlLabel
                          sx={{
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: '20px',
                            '.MuiFormControlLabel-label': {
                              color: '#8388AD',
                              fontSize: { xs: '14px', sm: '12px', lg: '14px' },
                            },
                          }}
                          labelPlacement="bottom"
                          value={i.key}
                          control={<Radio sx={{ display: 'none' }} />}
                          label={<FormattedMessage id={i.value} />}
                        />
                        {i.key === value && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              background: '#303778',
                              borderRadius: '50%',
                              width: '25px',
                              height: '25px',
                            }}>
                            <CircleBrand />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  ))}
              </RadioGroup>
            </>
          )}
        />
        {showTextFeild && (
          <TextField
            sx={{ margin: '15px 15px 0px 0px' }}
            autoFocus
            value={watch()[field.name] === 'OTHER' ? '' : watch()[field.name]}
            onChange={(e: any) => {
              setValue(field.name, e.target.value);
            }}
            label={<FormattedMessage id={field.constraints.other_placeholder} />}
            fullWidth
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Brand;
