import React from 'react';

import { Controller } from 'react-hook-form';
// import { useForm } from 'react-hook-form';
import {
  Button,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  TextField,
} from '@material-ui/core';
import ButtonBack from '../../../components/buttonBack';
import { makeStyles } from '@material-ui/core/styles';

import CrossChip from '../../../assets/images/icons/crossChip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormattedMessage, useIntl } from 'react-intl';
var _ = require('lodash');

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface RenderFieldProps {
  control: any;
  setActiveStep: (activeStep: number) => void;
  activeStep: number;
  watch?: any;
  setValue?: any;
  handlStep: () => void;
  field: {
    name: string;
    label: string;
    description?: string;
    type: string;
    title: string;
    placeholder: string;
    validation: {
      regex: string;
      error_message: string;
    }[];
    constraints?: any;
    help_and_tips: {
      show: boolean;
      title: string;
      content: string;
      media: Object;
    };
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D3D3D3',
    borderRadius: '10px',
    paddingRight: '10px',
    marginBottom: '5px',
  },
  selectedItemBox: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '10px',
    width: '100%',
    background: '#fff',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px 8px 10px',
  },
  title: {
    fontSize: '28px',
    lineHeight: '134%',
    fontFamily: 'Gilroy-SemiBold',
    color: '#252A32',
    marginBottom: '12px',
  },
  description: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: '12px',
  },
  chipLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '14px',
    lineHeight: '143%',
  },
  active: {
    border: '1px solid #F0998D',
    background: 'rgba(240, 153, 141, 0.05)',
    borderRadius: '10px',
    width: '100%',
    padding: '5px 20px',
    marginBottom: '5px',
  },
  colotrLable: {
    '.MuiFormControlLabel-label': {
      color: '#303778',
    },
  },
  colotrLableActive: {
    '.MuiFormControlLabel-label': {
      color: '#EE9A8F',
      fontFamily: 'Gilroy-SemiBold',
    },
  },
}));
const UploadFile = ({ field, activeStep, handlStep }: any) => {
  const classes = useStyles();
  const intl = useIntl();

  const [selectedItems, setSelectedItems] = React.useState<any>();

  return (
    <Grid
      container
      xs={12}
      sx={{ padding: field.name == 'brand_category' ? '300px 15px 0px' : '0px 15px' }}>
      {activeStep !== 1 && (
        <Grid item xs={12}>
          <Box sx={{ maxWidth: '50px' }} onClick={handlStep}>
            <ButtonBack title="Previous question" />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '28px',
            lineHeight: '134%',
            fontFamily: 'Gilroy-SemiBold',
            color: '#252A32',
            marginBottom: '10px',
          }}>
          <FormattedMessage id={field?.title} defaultMessage="" />
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.description}>
          {' '}
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '16px',
              fontFamily: 'Gilroy-Medium',
              color: '#717A90',
              marginBottom: '20px',
              width: '100%',
            }}>
            {/* <FormattedMessage id={field?.subtitle} defaultMessage="" /> */}
            {intl.formatMessage({ id: field?.subtitle }) != field?.subtitle &&
              intl.formatMessage({ id: field?.subtitle })}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UploadFile;
