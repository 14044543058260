import CollapseSection from '../../components/profile/CollapseSection';
import { LinearProgress, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import blocksToRender from './blocks/blocks';
import { FormattedMessage } from 'react-intl';

const MyInformation = ({
  totalPercentage,
  checkIfFieldsCompleted,
  findThePercentageCompleted,
  data,
  findForm,
}) => {
  return (
    <Box>
      <Box
        sx={{
          background: '#FFFFFF',
          borderRadius: '10px',
          boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)',
          marginTop: '20px',
        }}>
        <Grid container alignItems="center" sx={{ padding: '10px' }}>
          <Grid item xs={12} md={1}>
            <Typography sx={{ fontFamily: 'Gilroy-SemiBold', color: '#252A32', fontSize: '16px' }}>
              {totalPercentage}%
            </Typography>
            <Typography sx={{ fontFamily: 'Gilroy', color: '#F0998D', fontSize: '12px' }}>
              <FormattedMessage id="profile_complete" />
            </Typography>
          </Grid>
          <Grid item xs={10} md={11} sx={{ marginTop: { xs: '10px', md: '0px' } }}>
            <LinearProgress
              variant="determinate"
              value={+totalPercentage}
              sx={{ height: { xs: '5px', md: '10px' }, borderRadius: '5px' }}
            />
          </Grid>
        </Grid>
      </Box>
      {blocksToRender.map((block) => (
        <CollapseSection
          fields={block.fieldsToRender}
          sectionTitle={block.title.default}
          formDataFromApi={findForm(data?.form, block.name)}
          formName={block.name}
          sectionDescription={block.description.default}
          items={checkIfFieldsCompleted(block.name, block.fields)}
          complitedProgress={Number(
            findThePercentageCompleted(block.fields, block.name, data?.form),
          )}
        />
      ))}
    </Box>
  );
};

export default MyInformation;
