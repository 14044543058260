import React from 'react';
import { Box, AccordionDetails, Grid, Accordion, AccordionSummary, Button, CardMedia, Typography, LinearProgress, Avatar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CollectionsIcon from '@material-ui/icons/Collections';
import VideoCameraBackIcon from '@material-ui/icons/VideoCameraBack';
import PeopleIcon from '@material-ui/icons/People';
import BoardCardDetail from './BoardCardDetail';
import moment from 'moment';
const BoardCard = ({ publication }) => {
  const [active, setActive] = React.useState(true);
  const [inactiveWorkflows, setInactiveWorkflows] = React.useState(0);
  const [overdueWorkflows, setOverdueWorkflows] = React.useState(0);
  const handleTime = (time: any) => {
    let nowTime = moment();
    time = moment(time);
    return nowTime.diff(time, 'hours').toString().replace('-', '');
  };
  const handleTimeIsAfter = (time: any) => {
    let nowTime = moment();
    time = moment(time);
    return moment().isAfter(time);
  };
  React.useEffect(() => {
    if (publication.calendars) {
      let arrInactiveWorkflows: any = []
      let arrOverdueWorkflows: any = []
      publication.calendars.forEach(element => {
        if (element.status === "Late" && handleTimeIsAfter(element.dueDate)) {
          arrInactiveWorkflows.push(element)
        } else if (element.status === "Late" && !handleTimeIsAfter(element.dueDate)) {
          arrOverdueWorkflows.push(element)
        }

      });
      setInactiveWorkflows(arrInactiveWorkflows.length)
      setOverdueWorkflows(arrOverdueWorkflows.length)
    }
  }, []);

  return (
    <Box mb={3}>
      {publication && <Accordion
        expanded={active}
        onChange={() => setActive(!active)}
      >
        <AccordionSummary
          sx={{
            '.MuiAccordionSummary-content': { margin: '0 !important' },
            cursor: 'default !important',
            p: '0',
          }}
          expandIcon={
            <ExpandMoreIcon sx={{ cursor: 'pointer' }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Grid p={2} container>
            <Grid item xs={12} sm={1}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {publication.picture && <CardMedia
                  sx={{ width: '60px' }}
                  component="img"
                  width="100%"
                  height="100%"
                  image={publication.picture[0]?.thumbnails.large.url}
                  alt="alt image"
                />}
                <Typography sx={{
                  color: '#000',
                  fontSize: '20px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 600,
                  display: { xs: 'block', sm: 'none' }
                }}>{publication.name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: '10px', sm: '' } }}>
                <Typography sx={{
                  color: '#000',
                  fontSize: '20px',
                  lineHeight: '125%',
                  letterSpacing: '0.15px',
                  fontWeight: 600,
                  display: { xs: 'none', sm: 'block' },
                  mr: '20px'
                }}>{publication.name}</Typography>
                <Grid container spacing={1}>
                  {3 - +publication.counts?.fbPostsLastSevenDaysCount > 0 && <Grid item>
                    <Box sx={{
                      borderRadius: '5px',
                      background: 'rgba(244, 67, 54, 0.08)',
                      p: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>

                      <CancelIcon sx={{ fill: '#F44336', fontSize: '12px' }} />
                      <Typography sx={{
                        color: '#F44336',
                        fontSize: '11px',
                        lineHeight: '125%',
                        letterSpacing: '0.15px',
                        fontWeight: 600,
                        ml: '5px'
                      }}>{`${3 - +publication.counts?.fbPostsLastSevenDaysCount} post missing`}</Typography>
                    </Box>
                  </Grid>}
                  {inactiveWorkflows !== 0 && <Grid item>
                    <Box sx={{
                      borderRadius: '5px',
                      background: 'rgba(244, 67, 54, 0.08)',
                      p: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <CancelIcon sx={{ fill: '#F44336', fontSize: '12px' }} />
                      <Typography sx={{
                        color: '#F44336',
                        fontSize: '11px',
                        lineHeight: '125%',
                        letterSpacing: '0.15px',
                        fontWeight: 600,
                        ml: '5px'
                      }}>{`${inactiveWorkflows} late workflow`}</Typography>
                    </Box>
                  </Grid>}
                  {overdueWorkflows > 0 && <Grid item>
                    <Box sx={{
                      borderRadius: '5px',
                      background: 'rgba(255, 152, 0, 0.08)',
                      p: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <WarningIcon sx={{ fill: '#FF9800', fontSize: '12px' }} />
                      <Typography sx={{
                        color: '#FF9800',
                        fontSize: '11px',
                        lineHeight: '125%',
                        letterSpacing: '0.15px',
                        fontWeight: 600,
                        ml: '5px'
                      }}>{`${overdueWorkflows} late workflow`}</Typography>
                    </Box>
                  </Grid>}
                </Grid>
              </Box>
              <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box mr={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <CollectionsIcon sx={{ fill: { xs: '#000', sm: '#828282' }, fontSize: '14px' }} />
                  <Typography sx={{
                    color: { xs: '#000', sm: '#828282' },
                    fontSize: '12px',
                    lineHeight: '125%',
                    letterSpacing: '0.15px',
                    fontWeight: 400,
                    ml: '5px'
                  }}>{publication.counts?.fbPostsLastSevenDaysCount + '/3 Post'}</Typography>
                </Box>
                <Box mr={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <VideoCameraBackIcon sx={{ fill: { xs: '#000', sm: '#828282' }, fontSize: '14px' }} />
                  <Typography sx={{
                    color: { xs: '#000', sm: '#828282' },
                    fontSize: '12px',
                    lineHeight: '125%',
                    letterSpacing: '0.15px',
                    fontWeight: 400,
                    ml: '5px'
                  }}>{2 + '/' + 10 + ' Story'}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PeopleIcon sx={{ fill: { xs: '#000', sm: '#828282' }, fontSize: '14px' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{
                      color: +publication.counts?.igFollowersLifetimeDeltaLastSevenDaysAbs < 0 ? '#F44336' : '#828282',
                      fontSize: '12px',
                      lineHeight: '125%',
                      letterSpacing: '0.15px',
                      fontWeight: 400,
                      ml: '5px'
                    }}>{publication.counts?.igFollowersLifetimeDeltaLastSevenDaysAbs + ' Followers'}</Typography>
                    {/* <Typography sx={{
                      color: publication.counts?.igFollowersLifetimeDeltaLastSevenDaysAbs?.includes('-') ? '#F44336' : '#4CAF50',
                      fontSize: '12px',
                      lineHeight: '125%',
                      letterSpacing: '0.15px',
                      fontWeight: 600,
                      ml: '5px'
                    }}>{`(${publication.counts?.igFollowersLifetimeDeltaLastSevenDaysAbs})`}</Typography> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary >
        <AccordionDetails>
          <BoardCardDetail publication={publication} />
        </AccordionDetails>
      </Accordion >}
    </Box >
  );
};

export default BoardCard;
