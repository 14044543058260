import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import ButtonTasks from './ButtonTasks';
import moment from 'moment';
import CustomChip from './CustomChip';
const useStyles = makeStyles((theme) => ({
  toDoCalendarHeader: {
    background: '#5A6092',
    color: 'white',
    borderRadius: '10px 10px 0px 0px',
    padding: '16px !important',
  },
  toDoCalendarContent: {
    color: '#000000',
    padding: '16px !important',
    border: '1px solid #E1E1E1',
    borderTop: 'none',
    alignItems: 'center',
  },
}));
interface ToDoItemsOrderProps {
  Late: any;
  Urgent: any;
  Soon: any;
}
const TaskContentMobileDueDate = ({ radioOrderBy, items }) => {
  const [dateNow, setDateNow] = React.useState<any>();
  const [itemsState, setItemsState] = React.useState<any>();
  React.useEffect(() => {
    setDateNow(moment().format('YYYY-MM-DD HH:mm:ss'));
  }, []);

  const handleTime = (time: any) => {
    let nowTime = moment(dateNow);
    time = moment(time);
    return nowTime.diff(time, 'hours').toString().replace('-', '');
  };
  const handleTimeIsAfter = (time: any) => {
    let nowTime = moment(dateNow);
    return nowTime.isAfter(time);
  };
  React.useEffect(() => {
    if (radioOrderBy == 'Due Date') {
      let timeFilteredArr: ToDoItemsOrderProps = {
        Late: [],
        Urgent: [],
        Soon: [],
      };
      items.forEach((i) => {
        if (i.date) {
          if (handleTimeIsAfter(i.date)) {
            timeFilteredArr.Urgent.push(i);
          } else if (handleTimeIsAfter(i.date) == false) {
            timeFilteredArr.Late.push(i);
          }
        } else {
          timeFilteredArr.Soon.push(i);
        }
      });
      setItemsState(timeFilteredArr);
    }
  }, [radioOrderBy]);

  return (
    <Box mt={2}>
      <Box>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '18px',
            lineHeight: '160%',
            fontFamily: 'Gilroy-SemiBold',
            margin: '20px 0px',
          }}>
          Late
        </Typography>
      </Box>
      <Box
        sx={{
          boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)',
          borderRadius: '10px',
          padding: '15px',
        }}>
        {itemsState &&
          itemsState.Late.map((f, key) => {
            let after = handleTimeIsAfter(f.date);
            return (
              <Box>
                <Box
                  mb={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '14px',
                      lineHeight: '143%',
                      fontFamily: 'Gilroy',
                    }}>
                    {f.taskName}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: '19px' }}>
                      <img width="100%" src={f.brandSrc} alt="img" />
                    </Box>
                    <Typography
                      sx={{
                        ml: '10px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: '14px',
                        lineHeight: '143%',
                        fontFamily: 'Gilroy-SemiBold',
                      }}>
                      {f.brand[0].name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography sx={{ mb: '5px' }}>
                      {moment(f.dueDate).format('DD/MM/YYYY')}
                    </Typography>
                    {!after && f.dueDate !== '' ? (
                      <CustomChip
                        label={handleTime(f.dueDate) + ' ' + 'tasks_hours_late'}
                        status="danger"
                      />
                    ) : f.dueDate !== '' ? (
                      <CustomChip
                        label={handleTime(f.dueDate) + ' ' + 'tasks_hours_late'}
                        status="warning"
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                    <ButtonTasks
                      titleBtn={
                        f.completion == 'Done'
                          ? 'Done'
                          : f.completion == 'Not open yet'
                          ? 'Not open yet'
                          : 'To Do'
                      }
                      status={
                        f.completion == 'Done'
                          ? 'done'
                          : f.completion == 'Not open yet'
                          ? 'notOpen'
                          : ''
                      }
                      colorBtn={
                        after
                          ? 'orange'
                          : !after && f.date
                          ? 'red'
                          : f.completion == 'new'
                          ? 'green'
                          : f.completion == 'Done'
                          ? ''
                          : f.completion == 'Not open yet'
                          ? ''
                          : ''
                      }
                    />
                  </Box>
                </Box>
                {itemsState.Late.length - 1 !== key && (
                  <Box
                    sx={{ border: '1px solid #E0E0E0', width: '100%', margin: '20px 0px' }}></Box>
                )}
              </Box>
            );
          })}
      </Box>
      <Box>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '18px',
            lineHeight: '160%',
            fontFamily: 'Gilroy-SemiBold',
            margin: '20px 0px',
          }}>
          Urgent
        </Typography>
      </Box>
      <Box
        sx={{
          boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)',
          borderRadius: '10px',
          padding: '15px',
        }}>
        {itemsState &&
          itemsState.Urgent.map((f, key) => {
            let after = handleTimeIsAfter(f.date);
            return (
              <Box>
                <Box
                  mb={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '14px',
                      lineHeight: '143%',
                      fontFamily: 'Gilroy',
                    }}>
                    {f.taskName}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: '19px' }}>
                      <img width="100%" src={f.brandSrc} />
                    </Box>
                    <Typography
                      sx={{
                        ml: '10px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: '14px',
                        lineHeight: '143%',
                        fontFamily: 'Gilroy-SemiBold',
                      }}>
                      {f.brand[0].name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography sx={{ mb: '5px' }}>
                      {moment(f.dueDate).format('DD/MM/YYYY')}
                    </Typography>
                    {!after && f.dueDate !== '' ? (
                      <CustomChip
                        label={handleTime(f.dueDate) + ' ' + 'tasks_hours_late'}
                        status="danger"
                      />
                    ) : f.dueDate !== '' ? (
                      <CustomChip
                        label={handleTime(f.dueDate) + ' ' + 'tasks_hours_late'}
                        status="warning"
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                    <ButtonTasks
                      titleBtn={
                        f.completion == 'Done'
                          ? 'Done'
                          : f.completion == 'Not open yet'
                          ? 'Not open yet'
                          : 'To Do'
                      }
                      status={
                        f.completion == 'Done'
                          ? 'done'
                          : f.completion == 'Not open yet'
                          ? 'notOpen'
                          : ''
                      }
                      colorBtn={
                        after
                          ? 'orange'
                          : !after && f.date
                          ? 'red'
                          : f.completion == 'new'
                          ? 'green'
                          : f.completion == 'Done'
                          ? ''
                          : f.completion == 'Not open yet'
                          ? ''
                          : ''
                      }
                    />
                  </Box>
                </Box>

                {itemsState.Urgent.length - 1 !== key && (
                  <Box
                    sx={{ border: '1px solid #E0E0E0', width: '100%', margin: '20px 0px' }}></Box>
                )}
              </Box>
            );
          })}
      </Box>
      <Box>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '18px',
            lineHeight: '160%',
            fontFamily: 'Gilroy-SemiBold',
            margin: '20px 0px',
          }}>
          Soon
        </Typography>
      </Box>
      <Box
        sx={{
          boxShadow: '0px 6px 30px rgba(234, 237, 244, 0.6)',
          borderRadius: '10px',
          padding: '15px',
        }}>
        {itemsState &&
          itemsState.Soon.map((f, key) => {
            let after = handleTimeIsAfter(f.date);
            return (
              <Box>
                <Box
                  mb={2}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '14px',
                      lineHeight: '143%',
                      fontFamily: 'Gilroy',
                    }}>
                    {f.taskName}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: '19px' }}>
                      <img width="100%" src={f.brandSrc} />
                    </Box>
                    <Typography
                      sx={{
                        ml: '10px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: '14px',
                        lineHeight: '143%',
                        fontFamily: 'Gilroy-SemiBold',
                      }}>
                      {f.brand[0].name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography sx={{ mb: '5px' }}>
                      {moment(f.dueDate).format('DD/MM/YYYY')}
                    </Typography>
                    {!after && f.dueDate !== '' ? (
                      <CustomChip
                        label={handleTime(f.dueDate) + ' ' + 'tasks_hours_late'}
                        status="danger"
                      />
                    ) : f.dueDate !== '' ? (
                      <CustomChip
                        label={handleTime(f.dueDate) + ' ' + 'tasks_hours_late'}
                        status="warning"
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                    <ButtonTasks
                      titleBtn={
                        f.completion == 'Done'
                          ? 'Done'
                          : f.completion == 'Not open yet'
                          ? 'Not open yet'
                          : 'To Do'
                      }
                      status={
                        f.completion == 'Done'
                          ? 'done'
                          : f.completion == 'Not open yet'
                          ? 'notOpen'
                          : ''
                      }
                      colorBtn={
                        after
                          ? 'orange'
                          : !after && f.date
                          ? 'red'
                          : f.completion == 'new'
                          ? 'green'
                          : f.completion == 'Done'
                          ? ''
                          : f.completion == 'Not open yet'
                          ? ''
                          : ''
                      }
                    />
                  </Box>
                </Box>

                {itemsState.Soon.length - 1 !== key && (
                  <Box
                    sx={{ border: '1px solid #E0E0E0', width: '100%', margin: '20px 0px' }}></Box>
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default TaskContentMobileDueDate;
