import { Redirect } from 'react-router-dom';

// project imports
import useAuth from '../../hooks/useAuth';
import { GuardProps } from '../../types';
import { useLocation } from 'react-router-dom';

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { urlToRiderect: location.pathname },
        }}
      />
    );
  }

  return children;
};

export default AuthGuard;
