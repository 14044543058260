import SvgIcon from '@material-ui/core/SvgIcon';

const TypePost = () => (
  <SvgIcon viewBox="0 0 36 36">
    <svg width="35" height="35" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.5" y="3" width="18" height="18" rx="3" stroke="#757575" stroke-width="2" />
      <path d="M12.5 8V16" stroke="#757575" stroke-width="1.88889" stroke-linecap="round" />
      <path d="M8.5 12L16.5 12" stroke="#757575" stroke-width="1.88889" stroke-linecap="round" />
    </svg>
  </SvgIcon>
);

export default TypePost;
