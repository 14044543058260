import React from 'react';
import {
  CircularProgress,
  Box,
  MenuItem,
  TextField,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import DateTimePicker from '@material-ui/lab/DateTimePicker';

import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { withStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import TypePost from 'assets/images/icons/typePost';
import TypeStory from 'assets/images/icons/typeStory';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { EDIT_SPECIFIC_CONCEPT } from '../../../api/mutations/calendar';
import { GET_MONTHLY_CALENDARS } from '../../../api/queries/calendar/monthly';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GET_MONTHLY_CALENDARS_ID } from 'api/queries/tasks';
import { FormattedMessage, useIntl } from 'react-intl';

interface PropsPublication {
  publication: any;
  calendarId: string;
  tshouldFieldsBeDisabled?: boolean;
}
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '10px',
      padding: '10px',
    },

    input: {
      padding: '3px',
      fontSize: '16px',
    },
    adornedStart: {
      paddingLeft: '10px',
    },
  }),
)(InputBase);
const MobilePublication = ({ calendar, calendarId, shouldFieldsBeDisabled }) => {
  const intl = useIntl();

  const [active, setActive] = React.useState(true);

  const Publication = ({ publication }: PropsPublication) => {
    const dispatch = useDispatch();

    const [values, setValues] = React.useState<any>({
      publicationTime: null,
      type: null,
      category: null,
      concept: null,
    });

    const [editConcept, { loading: loadingEditConcept, error }] = useMutation(
      EDIT_SPECIFIC_CONCEPT,
      {
        onCompleted: (data) => {
          dispatch({
            type: SNACKBAR_OPEN,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            transition: 'SlideLeft',
            open: true,
            message: 'Publication was edited successfully',
            variant: 'alert',
            alertSeverity: 'success',
            autoHideDuration: '6000',
          });
        },
        onError: (error) => {
          const message = JSON.parse(error.message);
          dispatch({
            type: SNACKBAR_OPEN,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            transition: 'SlideLeft',
            open: true,
            message: 'Error: ' + message?.content,
            variant: 'alert',
            alertSeverity: 'error',
            autoHideDuration: '6000',
          });
        },
        refetchQueries: [{ query: GET_MONTHLY_CALENDARS_ID, variables: { id: calendarId } }],
      },
    );

    const handleCalendarChange = (value) => {
      setValues({
        ...values,
        publicationTime: value,
      });
    };

    const handleChange = (name, value) => {
      setValues({
        ...values,
        [name]: value,
      });

      if (name !== 'concept')
        editConcept({
          variables: {
            id: publication.id,
            conceptionStatus: 'In client review',
            ...values,
            [name]: value,
          },
        });
    };

    const handleOnBlur = () => {
      editConcept({
        variables: {
          id: publication.id,
          conceptionStatus: 'In client review',
          ...values,
        },
      });
    };

    React.useEffect(() => {
      setValues({
        publicationTime: publication.publicationTime,
        type: publication.type,
        category: publication.category,
        concept: publication.concept,
      });
    }, []);

    return (
      <Box>
        {publication && (
          <Box>
            <Grid mt={3} mb={3} container justifyContent="space-between" alignItems="center">
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '14px',
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                  }}>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      disabled={shouldFieldsBeDisabled}
                      // value={values.publicationTime}
                      // onChange={(newValue) => {
                      //     setValues(newValue);
                      // }}
                      inputFormat="dd-MM-yyyy hh:mm"
                      value={moment(values.publicationTime)}
                      onChange={(newValue) => handleChange('publicationTime', moment(newValue))}
                      // onClose={() => handleChange('publicationTime', values.publicationTime)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: '90%', '.MuiFormHelperText-root': { display: 'none' } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
            <Grid mt={3} mb={3} container justifyContent="space-between" alignItems="center">
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '14px',
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                  }}>
                  Category
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <MuiSelect
                    disabled={shouldFieldsBeDisabled}
                    value={values.type}
                    onChange={(e) => handleChange('type', e.target.value)}
                    variant="outlined"
                    input={<BootstrapInput sx={{ textAlign: 'center' }} />}
                    onBlur={() => handleOnBlur()}>
                    <MenuItem value={'Story'}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TypeStory />
                        <Box>&nbsp;&nbsp;Story </Box>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'Post'}>
                      {' '}
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TypePost />
                        <Box>&nbsp;&nbsp;Post </Box>
                      </Box>
                    </MenuItem>
                  </MuiSelect>
                </Box>
              </Grid>
            </Grid>
            <Grid mt={3} mb={3} container justifyContent="space-between" alignItems="center">
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '14px',
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                  }}>
                  Type
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <TextField
                    disabled={shouldFieldsBeDisabled}
                    value={values.category}
                    onChange={(e) => handleChange('category', e.target.value)}
                    onBlur={() => handleOnBlur()}
                    sx={{ width: '100%' }}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid mt={3} mb={3} container justifyContent="space-between" alignItems="center">
              <Typography
                mb={4}
                sx={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: '14px',
                  lineHeight: '12px',
                  letterSpacing: '0.15px',
                }}>
                Concept
              </Typography>
              <TextField
                disabled={shouldFieldsBeDisabled}
                value={values.concept}
                onChange={(e) => handleChange('concept', e.target.value)}
                onBlur={() => handleOnBlur()}
                sx={{ width: '100%' }}
                variant="outlined"
                multiline
                minRows={4}
              />
            </Grid>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <Box>
      <Accordion expanded={active} onChange={() => setActive(!active)}>
        <AccordionSummary
          sx={{
            '.MuiAccordionSummary-content': { margin: '0 !important' },
            cursor: 'default !important',
            p: '0 0 0 16px',
          }}
          expandIcon={<ExpandMoreIcon sx={{ cursor: 'pointer' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Gilroy-SemiBold',
              lineHeight: '160%',
              color: '#000',
            }}>
            {intl.formatMessage({ id: 'calendar_monthly_calendar_week_of' }) +
              ' ' +
              moment(calendar.dueDate).format('DD/MM/YYYY')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {calendar &&
            calendar.publications.map((i, key) => (
              <Box key={i.id}>
                <Publication publication={i} calendarId={calendar.id} />
                {key + 1 !== i.length && <Divider />}
              </Box>
            ))}
        </AccordionDetails>
      </Accordion>
      {/* <Box p={2}>
        <Button
          sx={{
            fontSize: '14px',
            fontFamily: 'Gilroy-SemiBold',
            letterSpacing: '0.46px',
            color: '#fff',
            border: '1px solid #EE9A8F',
            borderRadius: '10px',
            padding: '10px',
            background: '#EE9A8F',
            width: '100%',
          }}>
          Mark As Done
        </Button>
      </Box> */}
    </Box>
  );
};
export default MobilePublication;
