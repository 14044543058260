import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { publicationToShowType } from './types';
import { findDaysLeftDiff } from '../utils';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: { fontSize: '16px' },
  toDoCalendarContainer: {},
  toDoCalendarTitle: {
    paddingTop: '26px',
    paddingBottom: '14px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    color: '#000000',
  },
  toDoCalendar: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  toDoCalendarHeader: {
    background: '#5A6092',
    color: 'white',
    borderRadius: '10px',
    padding: '16px !important',
  },
  toDoCalendarContent: {
    background: 'white',
    color: '#000000',
    borderRadius: '10px',
    padding: '16px !important',
  },
  toDoCalendarBox: {
    border: '1.5px solid rgba(188, 206, 245, 0.5)',
    borderRadius: '10px',
    padding: '20px',
    background: '#fff',
  },
  itemsContainer: {
    boxSizing: 'border-box',
    borderRadius: '10px',
    background: 'white',
  },
  viewMoreButton: {
    background: '#EE9A8F',
    borderRadius: '4px',
    color: 'white',
  },
}));

interface ToDoTableProps {
  monthCalendarsToShow: publicationToShowType[];
  handleViewMore: (arg1: string, arg2: string) => void;
}

const ToDoTableDesktop: React.FC<ToDoTableProps> = ({ monthCalendarsToShow, handleViewMore }) => {
  const classes = useStyles();
  const [sortedCalenar, setSortedCalendar] = React.useState<any>();
  React.useEffect(() => {
    let sortedItems = monthCalendarsToShow;
    sortedItems.sort(function (a, b) {
      return findDaysLeftDiff(a.dueDate) - findDaysLeftDiff(b.dueDate);
    });
    setSortedCalendar(sortedItems);
  }, []);
  return sortedCalenar ? (
    <Box className={classes.toDoCalendarContainer}>
      <Typography className={classes.toDoCalendarTitle}>
        <FormattedMessage id="calendar_to_do" />
      </Typography>
      <Box className={classes.toDoCalendarBox} width="100%">
        <Grid container className={classes.toDoCalendarHeader}>
          <Grid item sx={{ width: '20%' }}>
            <Typography>
              <FormattedMessage id="calendar_to_do_type" />
            </Typography>
          </Grid>
          <Grid item sx={{ width: '20%' }}>
            <Typography>
              <FormattedMessage id="calendar_to_do_due_date" />
            </Typography>
          </Grid>
          <Grid item sx={{ width: '20%' }}>
            <Typography>
              <FormattedMessage id="calendar_to_do_time_left" />
            </Typography>
          </Grid>
          <Grid item sx={{ width: '20%' }}>
            <Typography>
              <FormattedMessage id="calendar_to_do_status" />
            </Typography>
          </Grid>
          <Grid item sx={{ width: '20%' }}>
            <Typography>
              <FormattedMessage id="calendar_to_do_actions" />
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.itemsContainer} my={2}>
          {sortedCalenar.map((calendar: publicationToShowType) => (
            <Grid
              container
              className={classes.toDoCalendarContent}
              alignItems="center"
              key={calendar?.id}>
              <Grid item sx={{ width: '20%' }}>
                <Typography className={classes.toDoCalendar}>
                  {calendar?.calendarType === 'monthlyCalendar' ? (
                    <FormattedMessage id="calendar_to_do_monthly_calendar" />
                  ) : (
                    <FormattedMessage id="calendar_to_do_weekly_calendar" />
                  )}
                </Typography>
              </Grid>
              <Grid item sx={{ width: '20%' }}>
                <Typography>{moment(calendar?.dueDate).format('DD/MM/YYYY')}</Typography>
              </Grid>
              <Grid item sx={{ width: '20%' }}>
                <Typography>
                  {' '}
                  {findDaysLeftDiff(calendar.dueDate) > 0 ? (
                    <span>
                      {findDaysLeftDiff(calendar.dueDate)}{' '}
                      <FormattedMessage id="calendar_to_do_days_left" />
                    </span>
                  ) : (
                    <span>
                      {findDaysLeftDiff(calendar.dueDate) * -1}{' '}
                      <FormattedMessage id="calendar_to_do_days_late" />
                    </span>
                  )}
                </Typography>
              </Grid>
              <Grid item sx={{ width: '20%' }}>
                <Typography>
                  <FormattedMessage id="calendar_to_do_not_validated" />
                </Typography>
              </Grid>
              <Grid item sx={{ width: '20%' }}>
                {/* {calendar?.conceptionStatus == 'done' ? (
                  <Grid item container alignItems="center" spacing={1}>
                    <Grid item md={8} lg={7}>
                      <Button
                        sx={{
                          background: 'white',
                          border: '1px solid #EE9A8F',
                          borderRadius: '4px',
                          color: '#EE9A8F',
                        }}>
                        <Stack direction="row" alignItems="center" spacing={0.4}>
                          <Typography>Validated</Typography>
                        </Stack>
                      </Button>
                    </Grid>
                    <Grid item md={8} lg={5}></Grid>
                  </Grid>
                ) : ( */}
                <Grid item container alignItems="center" spacing={1}>
                  <Grid item md={8} lg={6}>
                    <Button
                      className={classes.viewMoreButton}
                      sx={{
                        ':hover': {
                          bgcolor: 'primary.main', // theme.palette.primary.main
                          color: 'white',
                        },
                      }}
                      onClick={() =>
                        handleViewMore(
                          calendar?.calendarType === 'monthlyCalendar' ? 'month' : 'week',
                          calendar?.start || calendar?.dueDateMonth || 'null',
                        )
                      }>
                      <FormattedMessage id="calendar_to_do_view_more" />
                    </Button>
                  </Grid>
                  <Grid item md={8} lg={6}></Grid>
                </Grid>
                {/* )} */}
              </Grid>
            </Grid>
          ))}
        </Box>
      </Box>
    </Box>
  ) : (
    <div></div>
  );
};

export default ToDoTableDesktop;
