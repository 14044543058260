import { gql } from '@apollo/client';

const FETCH_FORMS = gql`
  query fetchForms {
    form {
      id
      is_completed
      version
      form
      answers
    }
  }
`;
const FETCH_FORM_BY_ID = gql`
  query fetchFormById($id: uuid!) {
    form_by_pk(id: $id) {
      id
      is_completed
      version
      form
      answers
    }
  }
`;

const GENERATE_CUSTOMER_PORTAL = gql`
  query generateCustomerPortal {
    stripe_customer_portal_generate {
      url
    }
  }
`;
const GET_STRATEGIES= gql`
 query getStrategies{
  user {
    brandAssets {
      id
      readOnlyEmbedTagFromDesignTemplates
      readOnlyEmbedTagFromFacebookBoostStrategies
      readOnlyEmbedTagFromGoogleAdsStrategies
      readOnlyEmbedTagFromPublications
      readOnlyEmbedTagFromStrategies
    }
  }
}
`;
export { FETCH_FORMS, FETCH_FORM_BY_ID, GENERATE_CUSTOMER_PORTAL, GET_STRATEGIES };
