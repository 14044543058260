import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import AuthGuard from './route-guard/AuthGuard';
import BusinessUserAuthGuard from './route-guard/BusinessUserAuthGuard';
import FreelancerAuthGuard from './route-guard/FreelancerAuthGuard';
// import MinimalLayout from '../layout/MinimalLayout';
import Profile from '../views/profile';
import Calendar from '../views/calendar';
import Tasks from '../views/tasks';
import StatusBoardContainer from 'views/pages/statusBoard';

// sample page routing
// const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
// const MyProfile = Loadable(lazy(() => import('../views/profile')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route path={['/tasks']}>
      <MainLayout>
        <Switch location={location}>
          <FreelancerAuthGuard>
            <>
              <Route path="/tasks" component={Tasks} />
            </>
          </FreelancerAuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
