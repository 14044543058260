import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import AuthGuard from './route-guard/AuthGuard';
import BusinessUserAuthGuard from './route-guard/BusinessUserAuthGuard';
// import MinimalLayout from '../layout/MinimalLayout';
import Profile from '../views/profile';
import Calendar from '../views/calendar';
import Tasks from '../views/tasks';
import StatusBoardContainer from 'views/pages/statusBoard';

// sample page routing
// const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
// const MyProfile = Loadable(lazy(() => import('../views/profile')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route path={['/form', '/profile', '/calendar', '/calendar2', '/managersTasks']}>
      <MainLayout>
        <Switch location={location}>
          <BusinessUserAuthGuard>
            <>
              {/* <Route path="/form" component={SamplePage} /> */}
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/calendar" component={Calendar} />
              {/* <Route path="/tasks" component={Tasks} /> */}
              <Route path="/managersTasks" component={StatusBoardContainer} />
            </>
          </BusinessUserAuthGuard>
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
